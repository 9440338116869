import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';

class LoginError extends Component {

    render(){
        return (
            this.props.show ?
            (
            <p className={loginError}>
                {this.props.content}
            </p>
            ):
            (<div></div>)
        );
    }
}

const loginError = css({
    margin: '0px',
    marginBottom: '10px',
    fontSize: '12px',
    letterSpacing: '0.5px',
    width: '100%',
    boxSizing: 'border-box',
    color: 'rgba(0, 0, 0, 0.54)',
    lineHeight: '18px',
    padding: '10px',
    background: 'rgba(255, 113, 75, 0.1)',
    border: '1px solid #FF714B',
    borderRadius: '1.75px'
});

LoginError.propTypes = {
    show: PropTypes.bool,
    content: PropTypes.string
}

LoginError.defaultProps = {
    show: false,
    content: 'Sorry! Your attempt to login has failed. Please try again.'
}

export default LoginError;
