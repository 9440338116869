import React, { Component } from 'react';
import closeIcon from '../../assets/close-issue-icon.svg';
import { css } from 'emotion';
import classNames from 'classnames';

export default class CloseIssue extends Component {

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    // if(this.props.disabled) return;
    this.props.onClose();
  }

  render() {
    const button = css({
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      borderRadius: '2px',
      backgroundColor: '#FFFFFF',
      width: '153px',
      fontFamily: 'OpenSans',
      fontSize: '14px',
      fontWeight: 'bold',
      fontStyle: 'normal',
      fontStretch: 'normal',
      letterSpacing: '1px',
      color: '#5e6c84',
      border: '1px solid #C4CDD5',
      lineHeight: 1.71,
      textAlign: 'center',
      '&:hover': {
        backgroundColor: '#F4F5F7',
      },
    });
    const btnDisabledContainer = css({
      cursor: 'default!important',
      backgroundColor: '#ebecf0!important',
      '&:hover': {
        backgroundColor: '#ebecf0!important'
      },
      '& div':{
        color: '#c1c7d0!important'
      }
  });
    let buttonClass = classNames({
      [`${button}`]: true,
      [`${btnDisabledContainer}`]: this.props.disabled
    });

    return (
      <div className={buttonClass} onClick={this.onClick}>
        <img className="close-issue-btn-icons-mob" src={closeIcon} alt=""/>
        <div>CLOSE ISSUE</div>
      </div>
    );
  }

}
