import { get2xBgStyle, get2xSelectedBgStyle, get2xOutlineStyle } from '../styles/2x-styles';

const defaultOptions = {
  minZoom: 11,
  maxZoom: 15.99999999999999
};

export function getSource(features) {
  return {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: features
    }
  };
}

export function applyStyles(sourceId, options = defaultOptions) {
  let bgStyle = get2xBgStyle('2x-bg-style', sourceId, options.minZoom, options.maxZoom);
  let bgSelectedStyle = get2xSelectedBgStyle('2x-selected-bg-style', sourceId, options.minZoom, options.maxZoom)
  let outlineStyle = get2xOutlineStyle('2x-outline-style', sourceId, options.minZoom, options.maxZoom);
  return [bgStyle, bgSelectedStyle, outlineStyle];
}

export function getSourceLayer(source, sourceId, options = defaultOptions) {
  return {
    source: { id: sourceId, sourceObj: source },
    styles: applyStyles(sourceId, options)
  };
}
