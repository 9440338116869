import React, { Component } from 'react';
import { css } from 'emotion';
import Tabs from '../tabs/tabs.js';
import downArrow from '../../assets/sort-desc-arrow.svg';
import { tabs } from '../constants/tabs';
import { getDeviceListFromDb } from '../../modules/inventory.js';
import { getIssuesListFromDb } from '../../modules/issues';
import AppLoader from '../Loader/loader';
import {getSitesListFromDb} from "../../modules/site";
import Bulb from '../../assets/bulb.png';
import Flag from '../../assets/flag.png';
import Sites from '../../assets/sites.png';
import WorkOrders from '../../assets/workorders.png';
import {getWorkOrderListFromDb} from "../../modules/work-order";


// import AutoComplete from '../autocomplete/autocomplete.js';

export default class Overview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shown: false,
            totalSites: 0,
            totalLights: 0,
            totalIssue: 0,
            totalSitesNotInstalled: 0,
            totalSitesInstalled: 0,
            totalSitesWithMacAddresses: 0,
            totalMaintainence: 0,
            totalReplacement: 0,
            totalAuto: 0,
            totalModeOne: 0,
            totalModeTwo: 0,
            totalOff: 0,
            totalFlash: 0,
            showLoader: false,
            isIssueApiCall: false,
            isDeviceApiCall: false,
            iSiteApiCall: false,
            totalCancelled: 0,
            totalReported: 0,
            totalPending: 0,
            totalWorkOrders: 0

        };
        this.showMenuOnMobile = this.showMenuOnMobile.bind(this);
    }

    componentDidMount() {
        this.setState({ showLoader: true }, async () => {
            await this.getDevicesList();
            await this.getIssuesList();
            await this.getSitesList();
            await this.getWorkOrdersList();
        });
    }

    showMenuOnMobile() {
        this.setState({ shown: !this.state.shown });
    }

    getDevicesList = async () => {
        let data = await getDeviceListFromDb();

        if (data !== undefined && data !== null) {
            data = data.filter(l => {
                if (l.device_type !== undefined) {
                    return l.device_type.toLowerCase() === "fybrlyte";
                }
            });
            let auto = data.filter(item => {
                if (item.mode !== undefined && item.mode !== null) {
                    return Number(item.mode) === 0;
                }
            });
            let modeOne = data.filter(item => {
                if (item.mode !== undefined && item.mode !== null) {
                    return Number(item.mode) === 1;
                }
            });
            let modeTwo = data.filter(item => {
                if (item.mode !== undefined && item.mode !== null) {
                    return Number(item.mode) === 2;
                }
            });
            let off = data.filter(item => {
                if (item.mode !== undefined && item.mode !== null) {
                    return Number(item.mode) === 3;
                }
            });
            let flash = data.filter(item => {
                if (item.mode !== undefined && item.mode !== null) {
                    return Number(item.mode) === 4;
                }
            });

            this.setState({ totalLights: data.length, totalModeOne: modeOne.length, totalModeTwo: modeTwo.length, totalOff: off.length, totalAuto: auto.length, totalFlash: flash.length, isDeviceApiCall: true }, () => {
                if (this.state.isDeviceApiCall) {
                    this.setState({ showLoader: false });
                }
            });
        }
    }

    getIssuesList = async () => {
        let data = await getIssuesListFromDb(); //api call for getting the issue list from database
        if (data !== undefined && data !== null) {

            let replacementFilter = data.filter(item => {
                return item.issue_type.toUpperCase() === "REPLACEMENT";
            });

            let maintanaceFilter = data.filter(item => {
                return item.issue_type.toUpperCase() === "MAINTENANCE";
            });

            this.setState({ totalIssue: data.length, totalReplacement: replacementFilter.length, totalMaintainence: maintanaceFilter.length, isIssueApiCall: true }, () => {
                if (this.state.isIssueApiCall) {
                    this.setState({ showLoader: false });
                }
            })

        }
    }

    getSitesList = async () => {
       let data = await getSitesListFromDb(); //api call for getting the issue list from database
        if (data !== undefined && data !== null) {

            let installed = data.filter(item => {
                return item.mac_address;
            });

            let notInstalled = data.filter(item => {
                return !item.mac_address;
            });

            this.setState({ totalSites: data.length, totalSitesInstalled: installed.length, totalSitesNotInstalled: notInstalled.length, isSiteApiCall: true }, () => {
                if (this.state.isSiteApiCall) {
                    this.setState({ showLoader: false });
                }
            })

        }
    }

    getWorkOrdersList = async () => {
        let data = await getWorkOrderListFromDb(); //api call for getting the issue list from database
        if (data !== undefined && data !== null) {

            let reported = data.filter(item => {
                return item.status === 'REPORTED'
            });

            let cancelled = data.filter(item => {
                return item.status === 'CANCELLED'
            });

            let pending = data.filter(item => {
                return item.status === 'PENDING'
            })

            this.setState({ totalWorkOrders: data.length, totalReported: reported.length, totalCancelled: cancelled.length, totalPending: pending.length, isSiteApiCall: true }, () => {
                if (this.state.isSiteApiCall) {
                    this.setState({ showLoader: false });
                }
            })

        }
    }



    render() {

        return (
            <div className="customTab">
                {/* <AutoComplete SuggestionList={languages} setSelectedValue={this.setAutoselectValue} PlaceHolderText="Type Name"/> */}
                <div className="desktopViewForTabs">
                    <Tabs tabs={tabs} activeTab="overview" />
                </div>
                <div className="mobileViewForTabs">
                    <div onClick={() => this.showMenuOnMobile()}>
                        <i className="fa fa-bars mobile-menu-icon" aria-hidden="true"></i>
                    </div>
                    <div className={this.state.shown ? "show" : "hide"}>
                        <Tabs tabs={tabs} activeTab="overview" />
                    </div>
                </div>
                {/* <Tabs tabs={tabs} activeTab="overview" /> */}
                <div className={headerStyle}>
                    <div className={iconStyle}>
                        <h2>OVERVIEW</h2>
                    </div>
                </div>
                <div className={panel1}>
                    <div className={orangeBorder}></div>
                    <div className={contentBox}>
                        <div className="overviewtoprow">
                            <div>
                                <img alt="Bulb" src={Bulb} />
                            </div>
                            <div className="overviewlightcount" >
                                {this.state.totalLights}<br></br>
                                <span className={subTitle}>Controllers</span>
                            </div>
                        </div>

                        <hr className={between} />
                    <div className="overviewtoprow">
                            <div className="overview-left-column">
                            Auto
                            </div>
                            <div className="overview-right-column">
                            {this.state.totalAuto}
                            </div>
                            <div className="overview-left-column">
                            Mode 1
                            </div>
                            <div className="overview-right-column">
                            {this.state.totalModeOne}
                            </div>
                            <div className="overview-left-column">
                            Mode 2
                            </div>
                            <div className="overview-right-column">
                            {this.state.totalModeTwo}
                            </div>
                            <div className="overview-left-column">
                            Off
                            </div>
                            <div className="overview-right-column">
                            {this.state.totalOff}
                            </div>
                            <div className="overview-left-column">
                            Flash
                            </div>
                            <div className="overview-right-column">
                            {this.state.totalFlash}
                            </div>
                        </div>

                        {/* <table className={table}>
                            <tbody>

                                <tr>
                                    <td colSpan='2'>
                                        <hr className={between} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className={leftContent}>Auto</td>
                                    <td className={rightContent}>{this.state.totalAuto}</td>
                                </tr>
                                <tr>
                                    <td className={leftContent}>Mode 1</td>
                                    <td className={rightContent}>{this.state.totalModeOne}</td>
                                </tr>
                                <tr>
                                    <td className={leftContent}>Mode 2</td>
                                    <td className={rightContent}>{this.state.totalModeTwo}</td>
                                </tr>
                                <tr>
                                    <td className={leftContent}>Off</td>
                                    <td className={rightContent}>{this.state.totalOff}</td>
                                </tr>
                                <tr>
                                    <td className={leftContent}>Flash</td>
                                    <td className={rightContent}>{this.state.totalFlash}</td>
                                </tr>

                            </tbody>
                        </table> */}
                    </div>
                </div>
                <div className={panel1}>
                    <div className={greenBorder}></div>
                    <div className={contentBox}>

                        <div className="overviewtoprow">
                            <div>
                                <img alt="Flag" src={Sites} />
                            </div>
                            <div className="overviewlightcount" >
                                {this.state.totalSites}<br></br>
                                <span className={subTitle}>Sites</span>
                            </div>

                        </div>
                        <hr className={between} />
                        <div className="overviewtoprow">

                            <div className="overview-left-column">
                                Not-Installed
                            </div>
                            <div className="overview-right-column">
                                {this.state.totalSitesNotInstalled}
                            </div>
                            <div className="overview-left-column">
                                Installed
                            </div>
                            <div className="overview-right-column">
                                {this.state.totalSitesInstalled}
                            </div>
                        </div>


                    </div>
                </div>
                <div className={panel1}>
                    <div className={blueBorder}></div>
                    <div className={contentBox}>

                        <div className="overviewtoprow">
                            <div>
                                <img alt="Flag" src={WorkOrders} />
                            </div>
                            <div className="overviewlightcount" >
                                {this.state.totalWorkOrders}<br></br>
                                <span className={subTitle}>Work Orders</span>
                            </div>

                        </div>
                        <hr className={between} />
                        <div className="overviewtoprow">

                            <div className="overview-left-column">
                                Cancelled
                            </div>
                            <div className="overview-right-column">
                                {this.state.totalCancelled}
                            </div>
                            <div className="overview-left-column">
                                Reported
                            </div>
                            <div className="overview-right-column">
                                {this.state.totalReported}
                            </div>
                            <div className="overview-left-column">
                                Pending
                            </div>
                            <div className="overview-right-column">
                                {this.state.totalPending}
                            </div>
                        </div>


                    </div>
                </div>
                <div className={panel1}>
                    <div className={redBorder}></div>
                    <div className={contentBox}>

                        <div className="overviewtoprow">
                            <div>
                                <img alt="Flag" src={Flag} />
                            </div>
                            <div className="overviewlightcount" >
                                {this.state.totalIssue}<br></br>
                                <span className={subTitle}>Issues</span>
                            </div>

                        </div>
                        <hr className={between} />
                        <div className="overviewtoprow">

                            <div className="overview-left-column">
                                Maintainence
                            </div>
                            <div className="overview-right-column">
                                {this.state.totalMaintainence}
                            </div>
                            <div className="overview-left-column">
                                Replacement
                            </div>
                            <div className="overview-right-column">
                                {this.state.totalReplacement}
                            </div>
                        </div>


                    </div>
                </div>
                {
                    this.state.showLoader &&
                    (
                        <div className="overlay">
                            <div className="application-loader">
                                <AppLoader />
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
}
const headerStyle = css({
    alignItems: 'center',
    backgroundColor: '#f4f5f7',
    padding: '5px 30px',
    textTransform: 'uppercase',
    fontFamily: 'OpenSans',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: 1.5,
    color: 'black',
});
const iconStyle = css({
    display: 'flex',
    flex: '0.8'
});
const panel1 = css({
    width: '232px',
    margin: '20px',
    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.18)',
    borderRadius: '0 0 4px 4px',
    float: 'left'
});

const orangeBorder = css({
    border: ' 3px orange solid',
    borderRadius: '4px 4px 0 0',
});

const redBorder = css({
    border: ' 3px red solid',
    borderRadius: '4px 4px 0 0',
});
const blueBorder = css({
    border: ' 3px blue solid',
    borderRadius: '4px 4px 0 0',
});
const greenBorder = css({
    border: ' 3px green solid',
    borderRadius: '4px 4px 0 0',
});
const between = css({
    width: '201px',
    height: '2px',
    backgroundColor: '#ebecf0',
    border: '0',
});
const leftContent = css({
    width: '82px',
    height: '21px',
    textAlign: 'left',
    fontFamily: 'OpenSans',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#112138'
});
const rightContent = css({
    width: '82px',
    height: '21px',
    textAlign: 'right',
    fontFamily: 'OpenSans',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#112138'
});
const content3 = css({
    width: '92px',
    height: '32px',
    fontSize: '32px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1,
    letterSpacing: 'normal',
    color: '#112138'
});
const time = css({
    textAlign: 'right',
    width: '58px',
    height: '21px',
    fontFamily: 'OpenSans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#112138'
});
const image = css({
});
const subTitle = css({
    fontSize: '13px'
});
const center = css({
    textAlign: 'left'
});
const table = css({
    width: '100%'
})
const contentBox = css({
    padding: '1rem'
})
