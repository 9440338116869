import 'mapbox-gl/dist/mapbox-gl.css';
import 'mapbox-gl/dist/svg/mapboxgl-ctrl-compass.svg';
import 'mapbox-gl/dist/svg/mapboxgl-ctrl-geolocate.svg';
import 'mapbox-gl/dist/svg/mapboxgl-ctrl-zoom-in.svg';
import 'mapbox-gl/dist/svg/mapboxgl-ctrl-zoom-out.svg';
import mapboxgl from 'mapbox-gl';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  zoom,
  selectedDistricts,
  selectedBlocks,
  selectedLights,
  hasAdjustedZoom,
  onZoomEnd,
  updateLayer
} from '../../modules/map.js';

import './mapbox.css';
import {
  TOKEN,
  DEFAULT_ZOOM,
  DEFAULT_CENTER,
  getLayerTypeForZoom,
  LAYERS
} from '../constants/map.js';

import { dismissInfoBox } from '../../modules/map.js';
import OneXLayer from './layers/1x-layer';
import TwoXLayer from './layers/2x-layer';
import ThreeXLayer from './layers/3x-layer';
import mapDarkThemeStylesheet from '../../assets/map-dark-theme.json';
import {debug} from "mapbox-gl/src/shaders";
import {LAYER_1X_ZOOM_LEVEL, LAYER_2X, LAYER_3X} from "../constants/map";

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigatedTo: false,
      navigatedToReset: true
    }
  }
  componentDidMount = async () => {
    if (this.props && this.props.routing.location &&
        this.props.routing.location.state &&
        this.props.routing.location.state.navigatedTo !== undefined) {
      this.setState({navigatedTo: this.props.routing.location.state.navigatedTo});
      this.setState({navigatedToReset: true});
    }
    mapboxgl.accessToken = TOKEN;
    this.map = new mapboxgl.Map({
      attributionControl: false,
      container: 'map',
      style: mapDarkThemeStylesheet,
      center: this.props.map.center || DEFAULT_CENTER,
      zoom: this.props.map.zoomLevel || DEFAULT_ZOOM
    });
    this.map.addControl(new mapboxgl.NavigationControl({
      showZoom: true,
      showCompass: false,
      visualizePitch: false
    }), 'bottom-left');
    this.map.on('load', async () => await this.onMapZoomEnd());
    this.map.on('zoomend', () => this.onMapZoomEnd());
    this.map.on('dragend', () => this.onMapZoomEnd());
    this.map.setMinZoom(DEFAULT_ZOOM);
    this.map.setZoom(LAYER_1X_ZOOM_LEVEL.DEFAULT);
    this.map.doubleClickZoom.disable();
    await this.waiting();
    //this.map.on('mousedown', () => this.onClick());
  }

  waiting = async () => {
    if (!this.map.isStyleLoaded()) {
      setTimeout(this.waiting, 600);
    } else {
      await this.onMapStylesLoaded();
    }
  };

  onMapZoomEnd = () => {
    let bbox = this.map.getBounds();
    const layerType = getLayerTypeForZoom(this.map.getZoom());
    this.props.onZoomEnd({viewPort: bbox, layerType});
  }

  //componentWillReceiveProps = async (nextProps) => {
  componentDidUpdate = async (prevProps) => {
    let {zoomLevel} = this.props.map;
    let {updateLayer} = this.props;
    let shouldAdjustZoom = this.props.map.shouldAdjustZoom;

    //if (this.props && this.props.routing.location &&
    //    this.props.routing.location.state && this.props.routing.location.state.navigatedTo !== undefined)
    //  navigatedTo = this.props.routing.location.state.navigatedTo;

    const layerType = this.props.map.layerType ? this.props.map.layerType : getLayerTypeForZoom(this.map.getZoom());

    const selectedDistricts = this.props.map.selectedDistricts;
    if (this.oneXLayer)
      this.oneXLayer.updateSelections(selectedDistricts);

    const selectedBlocks = this.props.map.selectedBlocks;
    if (this.twoXLayer)
      this.twoXLayer.updateSelections(selectedBlocks);

    const selectedLights = this.props.map.selectedLights;
    if (this.threeXLayer)
      this.threeXLayer.updateSelections(selectedLights);

    if ((layerType === LAYER_2X || layerType === LAYER_3X) && ((prevProps.isPageRefresh !== this.props.isPageRefresh) || (prevProps.map.currentDeviceCategory !== this.props.map.currentDeviceCategory) || (this.state.navigatedTo && this.state.navigatedToReset && this.threeXLayer))) {
      await this.threeXLayer.updateFeatureData(this.props);
      this.setState({navigatedToReset: false});
      this.props.pageRefresh(false);
    }

    if (this.props.map.updateLayer) {
      this.props.updateLayer(false);
    }

      /*
      debugger;
      debugger;
      if ((prevProps.map.showDevice !== this.props.map.showDevice) || this.navigatedTo) {
        debugger;
        debugger;
        await this.threeXLayer.updateFeatureData(this.props);
      }
    }

    if (this.props.map.updateLayer)
      this.props.updateLayer(false);

       */

    //if (shouldAdjustZoom || (this.state.navigatedTo && this.threeXLayer)) {
    if (shouldAdjustZoom) {
      const zoomLevel = this.props.map.zoomLevel;
      const center =
          this.props.map.center === null
              ? this.map.getCenter()
              : this.props.map.center;
      this.map.flyTo({ zoom: zoomLevel, center: center });
      this.props.hasAdjustedZoom();
      this.setState({navigatedTo: false});
    }
  }

  onClick = () => {
    this.props.dismissInfoBox();
  };

  onMapStylesLoaded = async () => {
    const {map} = this.props;
    const {zoomLevel} = map;
    const layerType = this.props.map.layerType ? this.props.map.layerType : getLayerTypeForZoom(this.map.getZoom());

    this.oneXLayer = new OneXLayer(
        this.map,
        [],
        this.props
    );
    //await this.oneXLayer.updateFeatureData();

    this.twoXLayer = new TwoXLayer(
        this.map,
        [],
        this.props
    );
    //await this.twoXLayer.updateFeatureData();

    this.threeXLayer = new ThreeXLayer(
        this.map,
        [],
        this.props
    );

    this.oneXLayer.setSecondLevelMarkers(async () => {
      await this.twoXLayer.addLayerTwoMarkers();
      this.twoXLayer.toggleMarkersHide(false);
    });

    this.twoXLayer.setThirdLevelMarkers(async () => {
      await this.threeXLayer.addLayerThreeMarkers();
    });

    //await this.threeXLayer.updateFeatureData();


    await this.oneXLayer.updateFeatureData();
    this.oneXLayer.setMarkersAdded(true);
    await this.twoXLayer.addLayerTwoMarkers();
    this.twoXLayer.toggleMarkersHide(false);
    this.oneXLayer.toggleMarkersHide(true);
  };

  clearMarkersFor = (layers = []) => {
    const layerObjects = {
      LAYER_1X: this.oneXLayer,
      LAYER_2X: this.twoXLayer,
      LAYER_3X: this.threeXLayer
    };

    layers.forEach(layer => {
      const layerObj = layerObjects[layer];

      if (layerObj) {
        layerObj.clearMarkers();
      }
    });
  }

  render() {
    return (
        <div id="map-container" className={this.props.className}>
          <div id="map" />
        </div>
    );
  }
}

const mapStateToProps = state => ({
  map: state.map,
  currentDeviceCategory: state.map.currentDeviceCategory,
  routing: state.routing
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
          dismissInfoBox,
          zoom,
          selectedDistricts,
          selectedBlocks,
          selectedLights,
          hasAdjustedZoom,
          onZoomEnd,
          updateLayer
        },
        dispatch
    );

Map.PropTypes = {
  className: PropTypes.string,
  currentDeviceCategory: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);
