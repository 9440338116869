import React, { Component } from 'react';
import { css } from 'emotion';
import dateIcon from '../../assets/date-icon.svg';
import cross from '../../assets/cross-dark.svg';
import Calendar from 'react-calendar';
import classNames from 'classnames';

class calendarField extends Component {
  constructor(props) {
    super(props);
    this.node = null;
  }
  state = {
    showCal: false,
    inputValue: this.props.val
  };
    
  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = e => {
    if (this.node !== null && this.node.contains(e.target)) {
      return;
    }
    this.setState({
      showCal: false
    });
  };

  showCal = () => {
    this.setState({
      showCal: true
    });
  };

  onDateChange = val => {   
    let trimmedString = val.toLocaleDateString('en-GB').trim();
    let dateFields = trimmedString.split('/');
    trimmedString = dateFields[2] + '-' + dateFields[1] + '-' + dateFields[0];     
    this.setState({
      showCal: false,
      inputValue: trimmedString
    });   
    this.props.onDateSelect(trimmedString); 
  };

  clearDate = () => {
    this.setState({
      showCal: false,
      inputValue: ''
    });  
    this.props.onDateSelect('');
  };

  render() {     
    let inputContainerClass = classNames({
      [`${borderClass}`]: this.state.showCal,
      [`${inputContainer}`]: true
    });

    return (
      <div ref={node => (this.node = node)}>
        <div className={inputContainerClass}>
          <input
            type="text"
            readOnly
            placeholder="Select..."
            className={inputClass}
            onFocus={this.showCal}
            value={this.props.val}
            //value={this.state.inputValue}
          />
          {!this.state.inputValue && (
            <img
              className={dateIconClass}
              src={dateIcon}
              alt=""
              onClick={this.showCal}
            />
          )}
          {this.state.inputValue && (
            <img
              className={dateIconClass}
              src={cross}
              alt="x"
              onClick={this.clearDate}
            />
          )}
        </div>
        {this.state.showCal && (
          <Calendar
            onChange={this.onDateChange}
            className={calClass}
            value={
              this.props.val ? new Date(this.props.val) : null
              //this.state.inputValue ? new Date(this.state.inputValue) : null
            }
            tileClassName={tileClass}
          />
        )}
      </div>
    );
  }
}

const dateIconClass = css({
  background: '#FFFFFF',
  padding: '0px 8px'
});
const inputContainer = css({
  border: '1px solid #dbdbdb',
  display: 'flex',
  flexDirection: 'row',
  borderRadius: '2px',
  width: '250px',
  height: '38px'
});
const borderClass = css({
  border: 'solid 1.5px #6463ca',
  padding: '1.5px'
});
const inputClass = css({
  width: '100%',
  height: '28px',
  boxSizing: 'border-box', 
  padding: '10px 12px',
  background: '#FFFFFF',
  border: 'none',
  borderRadius: '2px',

  '&:focus': {
    outline: 'none',
    border: 'none'
  }
});
const calClass = css({
  width: '280px!important',
  position: 'absolute',
  marginTop: '8px',
  borderRadius: '4px',
  boxShadow: '0 3px 10px 0 rgba(0, 0, 0, 0.09)',
  border: 'solid 1px #e5e5e5!important',
  backgroundColor: '#ffffff',
  '& .react-calendar__tile--active, & .react-calendar__tile--hasActive': {
    color: '#ffffff!important'
  },
  '& .react-calendar__navigation__prev2-button': {
    display: 'none'
  },
  '& .react-calendar__navigation__next2-button': {
    display: 'none'
  },
  '& .react-calendar__navigation__label': {
    fontFamily: 'OpenSans',
    fontSize: '12px',
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: '0.5px',
    textAlign: 'center',
    color: '#5e6c84'
  },
  '& .react-calendar__navigation__arrow': {
    color: '#c1c7d0',
    fontSize: '16px'
  },
  '& .react-calendar__month-view__weekdays__weekday': {
    fontFamily: 'OpenSans',
    fontSize: '12px',
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: '0.5px',
    textAlign: 'center',
    color: '#c1c7d0'
  },
  '& .react-calendar__month-view': {
    padding: '0 16px 16px 16px'
  },
  '& .react-calendar__navigation': {
    marginBottom: '0px!important'
  }
});
const tileClass = css({
  borderRadius: '2px!important',
  padding: '9px 4px',
  fontFamily: 'Poppins',
  fontSize: '12px',
  fontWeight: 500,
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.17,
  letterSpacing: 'normal',
  textAlign: 'center',
  color: '#c1c7d0!important'
});
export default calendarField;
