import React, { Component } from 'react';
import { css } from 'emotion';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Tabs from '../tabs/tabs.js';
import { tabs } from '../constants/tabs';
import { checkLoginTimeout, clearLoginTimeout } from '../../modules/user.js';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { Cookies } from 'react-cookie';
import { toast } from 'react-toastify';
var cookies = new Cookies();

class ReportsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
          shown : false
        }       
    }

    componentWillMount() {
        this.props.checkLoginTimeout(this.props.user);
    }

    showMenuOnMobile = () => {
        this.setState({ shown: !this.state.shown });
    }
    componentWillUnmount() {
        this.props.clearLoginTimeout(this.props.user);
    }

    priceFormatter(cell, row) {
        return '<i class="glyphicon glyphicon-usd"></i> ' + cell;
    }
    
    onEditClicked(cell, row) {
        
    }

    onDeleteClicked(cell, row) {
        
    }

    enumFormatter(cell, row, enumObject) {
        return enumObject[cell];
    }
   
    remote(remoteObj) {
        // it means that only pagination you will handle by your own
        remoteObj.pagination = true
        return remoteObj
    }

    // showToaster = () => {
    //     toast.success("I am clicked");
    // }

    render() {       
        return (
            <div className="customTab">
                <div className="desktopViewForTabs">
                    <Tabs tabs={tabs} activeTab="reports" />
                </div>
                <div className="mobileViewForTabs">
                    <div onClick={() => this.showMenuOnMobile()}>
                        <i className="fa fa-bars mobile-menu-icon" aria-hidden="true"></i>
                    </div>
                    <div className={this.state.shown ? "show" : "hide"}>
                        <Tabs tabs={tabs} activeTab="reports" />
                    </div>
                </div>
                {/* <Tabs tabs={tabs} activeTab="reports" /> */}
                <div className={spacing}>
                    {/* <button onClick={this.showToaster}></button> */}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            checkLoginTimeout,
            clearLoginTimeout
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(ReportsPage);

const spacing = css({
    marginTop: '20px'
});



