import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '../button/button.js';
import BootstrapTable from 'react-bootstrap-table-next';
import Modal from 'react-responsive-modal';
import DevicesList from '../group/devices-list';
import Confirmation from '../confirmation/confirmation.js';
import Messages from '../constants/messages.js';
import { MODETYPE, createGroupInDb, getGroupFromDb, updateGroupInDb, deleteDeviceFromGroupInDb, getDevicesInsideGroupById } from '../../modules/grouping';
import { toast } from 'react-toastify';
import AppLoader from '../Loader/loader';
import DevicesListMap from "./devices-list-map";


class CreateGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            groupName: '',
            deviceList: [],
            errors: [],
            selectDeviceModal: false,
            showDeleteConfirmation: false,
            rowToBeDeleted: '',
            createdGroupId: 0,
            showLoader: false,
            editedGroupId: 0,
            subGroupValue: '',
            subGroupValues: [1],
            selectDeviceFromMapModal: false
        }
    }

    componentDidMount = () => {
        if (this.props.selectedGroupId !== undefined && this.props.selectedGroupId !== null) {
            this.setState({ editedGroupId: this.props.selectedGroupId, showLoader: true }, async () => {
                await this.getGroupDetailsById();
                await this.getDevicesInsideGroup();
            });
        }
    }

    getGroupDetailsById = async () => {
        if (this.state.editedGroupId !== undefined && this.state.editedGroupId !== null && this.state.editedGroupId !== 0) {
            let response = await getGroupFromDb(this.state.editedGroupId);
            if (response !== undefined && response !== null) {
                this.setState({ groupName: response.group_name });
            }
        }
    }

    handleValidation = () => {
        let isValid = true;
        let errors = [];
        let state = this.state;

        if (!state["groupName"]) {
            errors["groupName"] = Messages.GroupNameRequiredText;
            isValid = false;
        }

        this.setState({ errors: errors });
        return isValid;
    }

    handleGroupNameChanged = (e) => {
        let {isFormDirty} = this.props;
        this.setState({ groupName: e.target.value });
        isFormDirty(true);
    }

    onCreateGroupClicked = () => {
        let {isFormDirty} = this.props;
        if (!this.handleValidation()) {
            return;
        }
        if (this.state.editedGroupId === undefined || this.state.editedGroupId === null || this.state.editedGroupId === 0) {
            if (this.state.createdGroupId === 0) {
                this.setState({ showLoader: true }, async () => {
                    this.createGroup();
                });
            }
        }
        isFormDirty(false);
    }

    onUpdateGroupClicked = () => {
        let {isFormDirty} = this.props;

        if (!this.handleValidation()) {
            return;
        }
        if (this.state.editedGroupId !== undefined || this.state.editedGroupId !== null || this.state.editedGroupId !== 0) {
            this.setState({ showLoader: true }, async () => {
                this.updateGroup();
            });

        }
        isFormDirty(false);
    }

    updateGroup = async () => {
        let groupToUpdate = {
            groupId: this.state.editedGroupId,
            groupName: this.state.groupName
        }
        let data = await updateGroupInDb(groupToUpdate); //api call for updating a single group entry in database
        if (data !== null && data !== undefined) {
            this.props.OnUpdateData()
            toast.success(Messages.UpdateGroupSuccessText);
            setTimeout(() => {
               // window.location.reload(false);
            }, 2000);
        }
    }

    createGroup = async () => {
        let data = await createGroupInDb(this.state.groupName); //api call for creating a single group entry in database
        if (data !== null && data !== undefined && data.headers !== undefined && data.headers !== null) {
            this.props.onCreate();
            let location = data.headers.get('location');
            this.setState({ createdGroupId: location.split('/')[3], showLoader: false }, () => {
                toast.success(Messages.CreateGroupSuccessText);
            });
        }
    }

    openDevicesModal = () => {
        if (this.handleValidation()) {
            if (this.state.deviceList.length < 8) {
                if (this.state.createdGroupId !== 0) {
                    this.setState({ selectDeviceModal: true });
                } else {
                    if (this.state.editedGroupId === undefined || this.state.editedGroupId === null || this.state.editedGroupId === 0) {
                        this.setState({ showLoader: true }, () => {
                            this.createGroup().then(() => {
                                this.setState({ selectDeviceModal: true });
                            });
                        });
                    } else {
                        this.setState({ selectDeviceModal: true });
                    }
                }
            } else {
                let errors = [];
                errors["extraRecords"] = Messages.GroupExtraRecordsText;
                this.setState({ errors: errors });
            }
        }
    }

    openDevicesMapModal = () => {
        this.setState({ selectDeviceFromMapModal: true });
    }

    cellButton = (cell, row) => {
        return (
            <div className="row">
                <div title="Delete" className="action-items" onClick={() => this.onDeleteClicked(cell, row)}><i className="fa fa-trash action-items" aria-hidden="true"></i>Delete </div>
            </div>
        );
    };

    onDeleteClicked = (cell, row) => {
        this.setState({ showDeleteConfirmation: true, rowToBeDeleted: row.site_id });
    }

    onDeleteConfirm = () => {
        this.setState({ showLoader: true }, () => {
            this.deleteDevice();
        })
    }

    deleteDevice = async () => {
        let siteId = this.state.rowToBeDeleted;
        let groupId = this.state.createdGroupId;
        if (this.state.editedGroupId !== undefined && this.state.editedGroupId !== null && this.state.editedGroupId !== 0) {
            groupId = this.state.editedGroupId;
        }
        let deviceToDelete = {
            siteId: siteId,
            groupId: groupId
        }
        let response = await deleteDeviceFromGroupInDb(deviceToDelete);
        if (response !== null && response !== undefined) {
            let deviceId = this.state.rowToBeDeleted;
            var filtered = this.state.deviceList.filter(function (item) {
                return item.site_id !== deviceId;
            });
            this.setState({ showLoader: false, deviceList: filtered, showDeleteConfirmation: false }, () => {
                toast.success(Messages.DeleteDevicesFromGroupSuccess);
            })
        }
    }

    closeDeviceListModal = () => {
        this.setState({ selectDeviceModal: false });
    }

    closeDeviceMapListModal = () => {
        this.setState({ selectDeviceFromMapModal: false });
    }

    handleDataFromDeviceList = (dataFromChild) => {
        this.setState({ selectDeviceModal: false, showLoader: true }, async () => {
            await this.getDevicesInsideGroup();
        });
    }

    getDevicesInsideGroup = async () => {
        let groupId = this.state.createdGroupId;
        if (this.state.editedGroupId !== undefined && this.state.editedGroupId !== null && this.state.editedGroupId !== 0) {
            groupId = this.state.editedGroupId;
        }
        let response = await getDevicesInsideGroupById(groupId);
        if (response !== undefined && response !== null) {
            if (response.length > 0) {
                this.state.deviceList = [];
                this.state.deviceList.push(...response);
                this.setState({ showLoader: false });
            } else {
                this.setState({ showLoader: false });
            }
        } else {
            this.setState({ showLoader: false });
        }
    }

    closeDeleteConfirmation = () => {
        this.setState({ showDeleteConfirmation: false });
    }

    renderInstallDate = (cell) => {
        let date = new Date(cell);
        let localDate = date.toLocaleDateString();
        return (
            <span>{localDate}</span>
        );
    }

    renderModeText = (cell) => {
        if (cell !== undefined && cell !== null && cell !== "") {
            return MODETYPE[cell];
        } else {
            return "";
        }
    }

    setSubGroupId = (e) => {
        let errors = [];
        let {isFormDirty} = this.props;
        if (Number(e.target.value) > 80) {
            errors["noSubGroupAdded"] = Messages.MaxSubgroupValueForAdd;
            this.setState({ errors: errors });
        } else {
            if (Number(e.target.value) < 0) {
                errors["noSubGroupAdded"] = Messages.NegativeValue;
                this.setState({ errors: errors });
            } else if ((Number(e.target.value) - Math.floor(Number(e.target.value))) !== 0) {
                errors["noSubGroupAdded"] = Messages.DecimalValue;
                this.setState({ errors: errors });
            } else {
                this.setState({ subGroupValue: e.target.value, errors: errors});
                isFormDirty(true);
            }
        }
    }

    addSubgroupValues = () => {
        let errors = [];
        if (this.state.subGroupValues.length > 80) {
            errors["noSubGroupAdded"] = Messages.MaxSubgroupLengthForAdd;
            this.setState({ errors: errors });
        }
        // else
        // if (this.state.subGroupValues.length === 1 && (Number(this.state.subGroupValues[0]) === 1)) {
        //     let val = Number(this.state.subGroupValue);
        //     if (val !== '' && val !== 0) {
        //         this.setState({ subGroupValues: [val], subGroupValue: '', errors: [] });
        //     } else {
        //         errors["noSubGroupAdded"] = Messages.NoSubgroupInputForAdd;
        //         this.setState({ errors: errors });
        //     }
        // }
        else {
            let val = Number(this.state.subGroupValue);
            let duplicatVal = this.state.subGroupValues.filter(function (item) {
                return item == val
            });
            if (duplicatVal.length > 0) {
                errors["noSubGroupAdded"] = Messages.DuplicateSubGroupValue;
                this.setState({ errors: errors });
            }
            else {
                if (val !== '' && val !== 0) {
                    this.setState({ subGroupValues: [...this.state.subGroupValues, val], subGroupValue: '', errors: [] });
                } else {
                    errors["noSubGroupAdded"] = Messages.NoSubgroupInputForAdd;
                    this.setState({ errors: errors });
                }
            }
        }
    }

    removeSubgroupValues = () => {
        let {isFormDirty} = this.props;
        let val = Number(this.state.subGroupValue);
        if (this.state.subGroupValues.length === 1) {
            this.setState({ subGroupValues: 1, subGroupValue: '', errors: [] });
        }
        else

        if (val !== '' && val !== 0) {
            let index = this.state.subGroupValues.indexOf(val);
            if (index !== -1) {
                this.state.subGroupValues.splice(index, 1);
                this.setState({ subGroupValues: this.state.subGroupValues, subGroupValue: '', errors: []});
                isFormDirty(true);
            }
        } else {
            let errors = [];
            errors["noSubGroupAdded"] = Messages.NoSubgroupInputForRemove;
            this.setState({ errors: errors });
        }
    }

    checkForSpace = (e) => {
        let errors = {};
        let formIsValid = true;
        e = e || window.event;
        if (e.charCode == 32 && e.target.value.length === 0) {
            errors["groupName"] = Messages.FirstCharacterAsSpaceErrorText;
            formIsValid = false;
        }
        if (!formIsValid) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.setState({ errors: errors });
        return formIsValid;
    }


    render() {
        let createUpdateBtn = <Button text="CREATE" buttonClickCallback={this.onCreateGroupClicked} buttonTextStyle="application-button" btnClassName="" />;
        let {isDirty, isFormDirty} = this.props;

        let title = "Create Group";
        if (this.props.selectedGroupId !== undefined && this.props.selectedGroupId !== null) {
            title = "Edit Group";
            createUpdateBtn = <Button text="UPDATE" buttonClickCallback={this.onUpdateGroupClicked} buttonTextStyle="application-button" btnClassName="" />
        }

        const dataColumns = [{
            dataField: 'site_id',
            text: 'SITE ID',
            sort: true
        }, {
            dataField: 'site_name',
            text: 'SITE NAME',
            sort: true
        }, {
            dataField: 'mac_address',
            text: 'MAC',
            sort: true
        },
        //  {
        //     dataField: 'installation_date',
        //     text: 'INSTALL DATE',
        //     sort: true,
        //     formatter: this.renderInstallDate
        // },
         {
            dataField: 'mode',
            text: 'MODE',
            sort: true,
            formatter: this.renderModeText
        }, {
            dataField: 'group_mask',
            text: 'SUBGROUPS',
            sort: true
        }, {
            dataField: 'site_status',
            text: 'SITE STATUS',
            sort: true
        }, {
            dataField: 'button',
            text: 'ACTIONS',
            formatter: this.cellButton
        }];
        return (
            <div>
                {/* <div className="popup-header">
                   <span className="popup-header-text">{title}</span>
                </div> */}
                <h4>{title}</h4>
                <div className="create-group-parent-div">
                    <div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="create-group-name-div">
                                    <div>
                                        <div>Group Name <span className="important-fields">*</span></div>
                                    </div>
                                    <div>
                                        <div><input value={this.state.groupName} className="create-group-name-input" onChange={this.handleGroupNameChanged} onKeyPress={(e) => this.checkForSpace(e)} type="text" maxLength="100"></input></div>
                                        <div className="application-error-messages">{this.state.errors["groupName"]}</div>
                                    </div>
                                </div>
                                <div className="create-group-save-btn-div">
                                    {createUpdateBtn}
                                </div>
                            </div>
                            <div className="col-sm-6 margin-top-4">
                                <div className="div-sub-group-control-body">
                                    <div>
                                        <div>Add Subgroup </div>
                                    </div>
                                    <span className="display-inline-flex">
                                        <span><input className="inputSubGroupValue" type="number" value={this.state.subGroupValue} onChange={(e) => this.setSubGroupId(e)} step="any" /></span>
                                        <span className="margin-top-negative-4"><i title="Add" onClick={() => this.addSubgroupValues()} className="fa fa-plus-circle" style={{ color: 'green', fontSize: '36px', marginLeft: '30px' }}></i></span>
                                        <span className="margin-top-negative-4"><i title="Remove" onClick={() => this.removeSubgroupValues()} className="fa fa-times-circle" style={{ color: 'red', fontSize: '36px', marginLeft: '10px' }}></i></span>
                                    </span>
                                    <div id="divSubgroupValues" className="margin-top-10 subgroup-array">Subgroups : [{this.state.subGroupValues.toString()}]</div>
                                    <div className="application-error-messages margin-top-10">{this.state.errors["noSubGroupAdded"]}</div>
                                </div>
                            </div>
                        </div>

                        <div className="create-group-listing-table">
                            <div className="application-error-messages center-aligned">{this.state.errors["extraRecords"]}</div>
                            <div className="row create-group-table-header-div">
                                <div className="col-sm-6 create-group-table-header-title">
                                    Devices
                                </div>
                                <div className="col-sm-6">
                                    <button title="Add Devices from Map" className="group-name-link create-group-table-header-link" onClick={this.openDevicesMapModal} style={{float: 'right'}}>Add Devices from Map</button>
                                    <button title="Add Devices" className="group-name-link create-group-table-header-link" onClick={this.openDevicesModal} style={{marginRight: '20px', float: 'right'}}>Add Devices</button>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <BootstrapTable wrapperClasses="table-common table-create-group-mob" keyField='site_id' data={this.state.deviceList} columns={dataColumns} />
                                {this.state.deviceList.length === 0 &&
                                    <div className="create-group-no-record">No records found.</div>
                                }
                            </div>
                            <div className="application-error-messages center-aligned">{this.state.errors["noRecords"]}</div>
                        </div>

                        <div id="selectDevicesModalDiv">
                            <Modal classNames={{ modal: "device-details-modal" }} open={this.state.selectDeviceModal} onClose={this.closeDeviceListModal} center closeOnOverlayClick={false}>
                                <div>
                                    <DevicesList groupLevelSubgroupValues={this.state.subGroupValues} editedGroupId={this.state.editedGroupId} createdGroupId={this.state.createdGroupId} dataFromParent={this.state.deviceList} handlerFromParant={this.handleDataFromDeviceList} isFormDirty={(isThisFormDirty) => {isFormDirty(isThisFormDirty)}}></DevicesList>
                                </div>
                            </Modal>
                        </div>

                        <div id="selectDevicesMapModalDiv">
                            <Modal classNames={{ modal: "select-devices-modal" }} open={this.state.selectDeviceFromMapModal} onClose={this.closeDeviceMapListModal} center closeOnOverlayClick={false}>
                                <div>
                                    <DevicesListMap groupLevelSubgroupValues={this.state.subGroupValues} isFormDirty={isFormDirty} isDirty={isDirty} editedGroupId={this.state.editedGroupId} createdGroupId={this.state.createdGroupId}></DevicesListMap>
                                </div>
                            </Modal>
                        </div>
                        {
                            this.state.showDeleteConfirmation &&
                            (
                                <Confirmation confirmationText="Are you sure you want to delete this device ?" confirmButtonText="Confirm" rejectButtonText="Cancel" openConfirmation={true} closeConfirmationModal={this.closeDeleteConfirmation} onConfirmClicked={this.onDeleteConfirm}></Confirmation>
                            )
                        }
                    </div>
                </div>
                {
                    this.state.showLoader &&
                    (
                        <div className="overlay">
                            <div className="application-loader">
                                <AppLoader />
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    sensors: state.sensors,
    map: state.map,
    user: state.user
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroup);
