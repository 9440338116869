import {
  getClusteredSourceLayer,
  getGroupSourceLayer,
  getSource,
  getSourceCountLayer,
  getSourceLayer
} from './3x-features';
import { addLayer } from '../utils';
import {getLayerTypeForZoom, LAYER_3X_ZOOM_LEVEL, LAYERS} from '../../constants/map';
import {getDistrictsList, getSelectedSitesByViewPort} from "../../../modules/map";
import center from '@turf/center';

const defaultOptions = {
  minZoom: LAYER_3X_ZOOM_LEVEL.START,
  maxZoom: LAYER_3X_ZOOM_LEVEL.END
};

export default class ThreeXLayer {

  constructor(map, features = [], props, options = defaultOptions) {
    this.map = map;
    this.features = features.map((feature) => {
      let featureWithSelectionData = Object.assign({}, feature);
      featureWithSelectionData.properties.selected = false;
      return featureWithSelectionData;
    });

    this.options = options;
    this.map.on('click', e => this.onFeatureClicked(e));
    this.map.on('zoomend', () => this.onZoomChange());
    this.map.on('dragend', () => this.onDrag());
    this.selections = new Set();
    this.props = props;
    this.oneXLayer = null;
    this.addSecondLevelMarkers = null;
  }

  _addToSelections = (id) => {
    if(this.selections.has(id)) {
      this.selections.delete(id);
    } else {
      this.selections.clear();
      this.selections.add(id);
    }

    //this.props.selectedLights([...this.selections]);
    this.props.selectedLights([...this.selections]);
  }

  _clearSelecions = () => {
    this.props.selectedLights([]);
  }

  _getFeaturesWithSelectionData = () => {
    return this.features.map((feature) => {
      let featureWithSelectionData = Object.assign({}, feature);
      //let lightId = feature.properties.id;
      let lightId = feature.properties.mac_address;
      featureWithSelectionData.properties.selected = this.selections.has(lightId);
      return featureWithSelectionData;
    });
  }

  updateFeatureData = async (props = this.props) => {
    this.props = props;
    let { selectedLights } = props;
    let {currentDeviceCategory} = props.map;

    let bbox = this.map.getBounds();
    let sitePointFeaturesNew = await getSelectedSitesByViewPort(bbox, currentDeviceCategory);

    if (sitePointFeaturesNew && sitePointFeaturesNew.features && sitePointFeaturesNew.features.length > 0) {
      let coordinates = sitePointFeaturesNew.features.filter((item) => item.geometry.coordinates[0]);
      let centerPoint = center(coordinates[0]);
      //sitePointFeaturesNew.features = coordinates;

      this.features = coordinates;
      this.updateLayer(props);
      //this.features = sitePointFeaturesNew;
      //this.initLayer();
    }
  }

  static calculateCenterPoint = async (bbox, currentDeviceCategory = 'DEVICES') => {
    let centerPoint = null;

    let sitePointFeaturesNew = await getSelectedSitesByViewPort(bbox, currentDeviceCategory);

    if (sitePointFeaturesNew && sitePointFeaturesNew.features && sitePointFeaturesNew.features.length > 0) {
      let coordinates = sitePointFeaturesNew.features.filter((item) => item.geometry.coordinates[0]);
      centerPoint = center(coordinates[0]);
      //let centerPoint = center(coordinates[0]);
    }

    return centerPoint;
  }

  onDrag = async () => {
      /*
    const zoom = this.map.getZoom();
    if(zoom >= this.options.minZoom && zoom <= this.options.maxZoom) {
      this._clearSelecions();
      this.clearMarkers();
      await this.updateFeatureData();

      if (this.addSecondLevelMarkers)
        await this.addSecondLevelMarkers();
    }
       */
  }

  onZoomChange = async () => {
    const zoom = this.map.getZoom();
    if(zoom > this.options.maxZoom || zoom < this.options.minZoom) {
      if(this.markersAdded) {
        //this.clearMarkers();
        this.markersAdded = false;
      }
    }

    if(zoom >= this.options.minZoom && zoom <= this.options.maxZoom) {
      if(!this.markersAdded) {
        this.markersAdded = true;
        //await this.updateFeatureData();
        //this.props.updateLayer(true);
      }
    }
  }

  updateLayer = (props) => {
    let source = getSource(this.features);

    let clusteredSourceLayer = getClusteredSourceLayer(source, '3x-layer-clustered', this.options);
    let clusteredCountTextLayer = getSourceCountLayer(source, '3x-layer-clustered-count', this.options);
    let groupTextLayer = getGroupSourceLayer(source, '3x-layer-group-layer', this.options);
    addLayer(clusteredSourceLayer, this.map);
    addLayer(clusteredCountTextLayer, this.map);
    addLayer(groupTextLayer, this.map);

    let sourceLayer = getSourceLayer(source, '3x-layer', this.options);
    addLayer(sourceLayer, this.map);
  }

  updateSelections = (lights) => {
    let lightIndices = lights.map((light) => {
      return light.mac_address;
    });
    this.selections = new Set(lightIndices);
    this.features = this.features.map(feature => {
      let featureWithSelectionData = Object.assign({}, feature);
      let id = feature.properties.mac_address;
      featureWithSelectionData.properties.selected = lightIndices.indexOf(id) !== -1;
      return featureWithSelectionData;
    });
    // Update features
    let source = getSource(this.features);

    let clusteredSourceLayer = getClusteredSourceLayer(source, '3x-layer', this.options);
    addLayer(clusteredSourceLayer, this.map);
    let sourceLayer = getSourceLayer(source, '3x-layer-clustered', this.options);
    addLayer(sourceLayer, this.map);
    let groupTextLayer = getGroupSourceLayer(source, '3x-layer-group-layer', this.options);
    addLayer(groupTextLayer, this.map);
  }

  /*
  onFeatureClicked = (e) => {
    debugger;
    debugger;

    let features = this.map.queryRenderedFeatures(e.point);
    if (features && features.length > 0) {
      let feature = features[0];

      let layerId = feature.layer.id;
      let layerIds = layerId.split('-');
      if (layerIds && layerIds.length > 0) {
        if (layerIds[0] === '3x') {
          // 2x feature clicked

          let lightId = feature.properties.mac_address;
          this._addToSelections(lightId);
          this.features = this._getFeaturesWithSelectionData();
          this.drawFeatures(this.features);
        }
      }
    }
  }
   */

  onFeatureClicked = (e) => {
    this.onFeatureClickedClustered(e);
    this.onFeatureClickedNonClustered(e);
  }

  onFeatureClickedNonClustered = (e) => {
    let features = this.map.queryRenderedFeatures(e.point, {
      layers: ['3x-bg-style', '3x-turned-on-one-style', '3x-selected-bg-style', '3x-selected-outline-style', '3x-selected-outline-bg-site-style', '3x-selected-outline-site-style',
              '3x-selected-outline-bg-site-off-style', '3x-selected-outline-site-off-style', '3x-bg-style-site', '3x-bg-group-style', '3x-selected-bg-group-style', '3x-selected-outline-group-style']
    });
    if (features && features.length > 0) {
      let feature = features[0];

      let layerId = feature.layer.id;
      let layerIds = layerId.split('-');
      if (layerIds && layerIds.length > 0) {
        if (layerIds[0] === '3x') {
          // 2x feature clicked

          let lightId = feature.properties.mac_address;
          this._addToSelections(lightId);
          this.features = this._getFeaturesWithSelectionData();
          this.drawFeatures(this.features);
        }
      }
    }
  }

  onFeatureClickedClustered = (e) => {
    let features = this.map.queryRenderedFeatures(e.point, { layers: ['3x-bg-clustered-style', '3x-turned-on-one-clustered-style', '3x-selected-bg-clustered-style', '3x-selected-outline-clustered-style', '3x-selected-outline-bg-site-clustered-style',
                                                                 '3x-selected-outline-site-clustered-style', '3x-selected-outline-bg-site-off-clustered-style', '3x-selected-outline-site-off-clustered-style', '3x-bg-style-clustered-site'] });
    //let features = this.map.queryRenderedFeatures(e.point, {
     // layers: ['3x-bg-clustered-style', '3x-turned-on-one-clustered-style', '3x-selected-bg-clustered-style', '3x-selected-outline-clustered-style', '3x-selected-outline-bg-site-clustered-style',
     //         '3x-selected-outline-site-clustered-style', '3x-selected-outline-bg-site-off-clustered-style', '3x-selected-outline-site-off-clustered-style', '3x-bg-style-clustered-site']
    //});
    if (features && features.length > 0) {
      //let clusterId = features[0].properties.cluster_id;
      //let features = map.queryRenderedFeatures(e.point, { layers: ['clusters'] });
      let clusterId = features[0].properties.cluster_id,
          point_count = features[0].properties.point_count,
          clusterSource = this.map.getSource('3x-layer-clustered');

      // Get Next level cluster Children
      //
      clusterSource.getClusterChildren(clusterId, function(err, aFeatures){
        console.log('getClusterChildren', err, aFeatures);
      });

      // Get all points under a cluster
      clusterSource.getClusterLeaves(clusterId, point_count, 0, function(err, aFeatures){
        console.log('getClusterLeaves', err, aFeatures);
      })
      /*
      this.map.getSource('3x-layer-clustered').getClusterExpansionZoom(
          clusterId,
          function (err, zoom) {
            if (err) return;
          debugger;
          debugger;

            this.map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom
            });
          }
      );
      */
    }
  }

  clearMarkers = () => {
    return;
  }

  drawFeatures = (features = this.features) => {
    let source = getSource(this.features);

    let clusteredSourceLayer = getClusteredSourceLayer(source, '3x-layer-clustered', this.options);
    addLayer(clusteredSourceLayer, this.map);
    let sourceLayer = getSourceLayer(source, '3x-layer', this.options);
    addLayer(sourceLayer, this.map);
    let groupTextLayer = getGroupSourceLayer(source, '3x-layer-group-layer', this.options);
    addLayer(groupTextLayer, this.map);
  }

  initLayer = () => {
    let source = getSource(this.features);

    let clusteredSourceLayer = getClusteredSourceLayer(source, '3x-layer-clustered', this.options);
    addLayer(clusteredSourceLayer, this.map);
    let sourceLayer = getSourceLayer(source, '3x-layer', this.options);
    addLayer(sourceLayer, this.map);
    let groupTextLayer = getGroupSourceLayer(source, '3x-layer-group-layer', this.options);
    addLayer(groupTextLayer, this.map);
  }

  clear = () => {
    this.map.off('zoom', () => this.onZoomChange());
  }

  setProps = (props) => {
    this.props = props;
  }

  setMarkersAdded = (markersAdded) => {
    this.markersAdded = markersAdded;
  }

  addLayerThreeMarkers = async () => {
    await this.updateFeatureData();
  }

  setSecondLevelMarkers = (addSecondLevelMarkers) => {
    this.addSecondLevelMarkers = addSecondLevelMarkers;
  }
}
