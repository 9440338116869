export function getGroup3xBgStyle(id, sourceId, minZoom, maxZoom) {
  return {
    id: id,
    type: 'circle',
    source: sourceId,
    paint: {
      'circle-color': '#f8ceff',
      'circle-radius': 10,
      'circle-opacity': 0.6
    },
    minzoom: minZoom,
    maxzoom: maxZoom,
      filter: ['all', ['==', 'selected', false],
                      ['==', 'group', true]]
  };
}

export function getGroup3xSelectedOutlineSiteStyle(id, sourceId, minZoom, maxZoom) {
    return {
        id: id,
        type: 'circle',
        source: sourceId,
        paint: {
            'circle-color': '#a42dcb',
            'circle-radius': 14,
            'circle-opacity': 1
        },
        minzoom: minZoom,
        maxzoom: maxZoom,
        filter: ['all', ['==', 'selected', true],
                ['==', 'group', true]]
    };
}

export function getGroup3xSelectedBgSiteStyle(id, sourceId, minZoom, maxZoom) {
    return {
        id: id,
        type: 'circle',
        source: sourceId,
        paint: {
            //'circle-color': '#e3a2ff',
            'circle-color': '#f8ceff',
            'circle-radius': 10,
            'circle-opacity': 0.4
        },
        minzoom: minZoom,
        maxzoom: maxZoom,
        filter: ['all', ['==', 'selected', true],
                ['==', 'group', true]]
    };
}
