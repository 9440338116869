import { REHYDRATE } from 'redux-persist/constants';
import orderby from 'lodash.orderby';
import {
  arrayToObject,
  getLimits,
  addItemsToArray,
  removeItemsFromArray
} from '../utils/utils';
import {CHECK_USER_TIMEOUT} from './user';
import flow from 'lodash.flow';

import { getScheduleList, getScheduleData, deleteScheduleData, createSchedule, updateSchedule, createEntry, deleteEntry, getScheduleEntriesData, updateEntry } from '../components/api/schedule';
import { API_ROOT } from '../components/constants/api';

export const PRIORITY = {
  MEDIUM: 'Medium',
  HIGH: 'High'
};

export const SPECIFICATION = {
  REPLACEMENT: 'Replacement',
  MAINTENANCE: 'Maintenance'
};

export const STATUS = {
  CANCELLED: 'Cancelled',
  REPORTED: 'Reported',
  PENDING: 'Pending'
};

export const DEVICETYPE = {
  LIGHTCONTROLLER: 'Light Controller',
  GATEWAY: 'Gateway'
};
let WORK_ORDER = {
  WORK_ORDER_1: 'Work Order No. 1',
  WORK_ORDER_2: 'Work Order No. 2',
  WORK_ORDER_3: 'Work Order No. 3',
  WORK_ORDER_4: 'Work Order No. 4'
};

let SCHEDULING = [];

export const KEYS = {
  SCHEDULINGID : 'schedule_id',
  SCHEDULENAME: 'schedule_name' 
};

export const COLUMNS = [
  { label: 'SCHEDULE ID', key: KEYS.SCHEDULINGID, isRequired: true },
  { label: 'SCHEDULE NAME', key: KEYS.SCHEDULENAME, isRequired: true } 
];  

export const MODETYPE = {
  0: 'AUTO',
  1: 'MODE 1', 
  2: 'MODE 2',
  3: 'OFF',
  4: 'FLASH'
};

export const initialFilters = {
  deviceId: null,
  priority: [PRIORITY.MEDIUM, PRIORITY.HIGH],
  deviceType: [DEVICETYPE.LIGHTCONTROLLER, DEVICETYPE.GATEWAY],
  type: [SPECIFICATION.REPLACEMENT, SPECIFICATION.MAINTENANCE],
  location: null,
  dateCreated: null,
  status: [STATUS.CANCELLED, STATUS.REPORTED, STATUS.PENDING],
  selected: null
};

const CURRENT_PAGE = 1;
const LIMIT = Math.min(SCHEDULING.length, 10);
const defaultLimits = getLimits(SCHEDULING, CURRENT_PAGE, LIMIT);

const END = defaultLimits.END;
const START = defaultLimits.START;

const initialState = {
  data: SCHEDULING.slice(0),
  workOrder: WORK_ORDER,
  filters: initialFilters,
  sortBy: null,
  allSelected: false,
  columns: COLUMNS,
  currentPage: CURRENT_PAGE,
  showingStart: START,
  showingEnd: END,
  maxPages: defaultLimits.MAXPAGES,
  columnInSort: null,
  message: null,
  limit: LIMIT,
  defaultLimit: LIMIT
};

const TYPES = {
  FILTER: 'FILTER',
  SELECT_ALL: 'SELECT_ALL',
  SELECT: 'SELECT',
  SEARCH_STRING: 'SEARCH_STRING',
  RESET: 'RESET',
  SORT: 'SORT',
  PAGINATE_RIGHT: 'PAGINATE_RIGHT',
  PAGINATE_LEFT: 'PAGINATE_LEFT',
  CHANGE_LIMIT: 'CHANGE_LIMIT',
  NEW_ISSUE: 'NEW_ISSUE',
  CLOSE_SELECTED_SCHEDULING: 'CLOSE_SELECTED_SCHEDULING',
  SHOW_COLUMNS: 'SHOW_COLUMNS',
  FILTER_TYPES_IN_COLUMN: 'FILTER_TYPES_IN_COLUMN',
  DISMISS_NOTIFICATION: 'DISMISS_NOTIFICATION',
  SHOW_NOTIFICATION: 'SHOW_NOTIFICATION',
  POST_COMMENT: 'POST_COMMENT',
  ADD_TO_WORK_ORDER: 'ADD_TO_WORK_ORDER',
  NEW_WORK_ORDER: 'NEW_WORK_ORDER',
  SET_SCHEDULE_LIST  : 'SET_SCHEDULE_LIST'
};

export default (state = initialState, action) => {  
  switch (action.type) {
    case REHYDRATE:
      const newState = { ...initialState };
      return newState;
    case TYPES.SELECT_ALL:
      return performMultiSelection(state, action.payload.select);
    case TYPES.SELECT:
      const id = action.payload.id;
      const checked = action.payload.select;
      return performSelection(state, checked, id);
    case TYPES.SEARCH_STRING:
      const searchString = action.payload.searchString;
      const columnId = action.payload.columnId;
      const trimmedString = searchString.trim();
      const verifiedString = trimmedString.length === 0 ? null : trimmedString;
      const filters = { ...state.filters, [columnId]: verifiedString };
      return recomputePages(applyFilters(state, SCHEDULING, filters));
    case TYPES.SORT:
      const order = action.payload.order;
      const key = action.payload.key;
      return performSort(state, order, key);
    case TYPES.PAGINATE_RIGHT:
      return paginateRight(state);
    case TYPES.PAGINATE_LEFT:
      return paginateLeft(state);
    case TYPES.CHANGE_LIMIT:
      const changeLimitForData = flow([changeLimit, recomputePages]);
      return changeLimitForData(
        state,
        action.payload.limit,
        action.payload.defaultLimit
      );
    case TYPES.RESET:
      return { ...initialState };
    case TYPES.NEW_ISSUE:
      const scheduling = action.payload.scheduling;
      return addNewScheduling(state, scheduling, SCHEDULING);
    case TYPES.CLOSE_SELECTED_SCHEDULING:
      SCHEDULING = SCHEDULING.filter(filterDeselectedScheduling);
      return removeScheduling(state);
    case TYPES.SHOW_COLUMNS:
      const columnIdentifier = action.payload.identifier;
      const show = action.payload.show;
      return filterColumns(state, columnIdentifier, show, COLUMNS);
    case TYPES.FILTER_TYPES_IN_COLUMN:
      const stateAfterSubTypeFilter = filterSubColumns(state, action.payload);
      return recomputePages(
        applyFilters(
          stateAfterSubTypeFilter,
          SCHEDULING,
          stateAfterSubTypeFilter.filters
        )
      );
    case TYPES.SHOW_NOTIFICATION:
      let message = action.payload.message;
      return { ...state, message: message };
    case TYPES.DISMISS_NOTIFICATION:
      return { ...state, message: null };
    case TYPES.POST_COMMENT:
      return postComments(state, action.payload.comment);
    case TYPES.ADD_TO_WORK_ORDER:
      return addWorkOrder(state, action.payload.order);
    case TYPES.NEW_WORK_ORDER:
      return newWorkOrder(state, action.payload.order);
    case TYPES.SET_SCHEDULE_LIST :
      return state;
    default:
      return state;
  }
};

function changeLimit(state, limit, defaultLimit) {
  return {
    ...state,
    limit: limit || state.limit,
    defaultLimit: defaultLimit || state.defaultLimit
  };
}

function recomputePages(state) {
  const curPage = 1;
  const limits = getLimits(state.data, curPage, state.limit);
  const stateWithPagesRecomputed = {
    ...state,
    currentPage: curPage,
    showingEnd: limits.END,
    showingStart: limits.START,
    maxPages: limits.MAXPAGES
  };
  return stateWithPagesRecomputed;
}

function applyFilters(state, initialData, filters) {
  const deviceId = filters.deviceId;
  const priorities = filters.priority;
  const deviceTypes = filters.deviceType;
  const schedulingTypes = filters.type;
  const selected = filters.selected;
  const location = filters.location;
  const statusTypes = filters.status;
  const dateCreated = filters.dateCreated;

  const scheduling = arrayToObject(state.data, 'id');

  const filteredData = initialData.filter(scheduling => {
    const deviceFilter =
      deviceId === null
        ? true
        : scheduling.deviceId.toLowerCase().indexOf(deviceId.toLowerCase()) !== -1;
    const locationFilter =
      location === null
        ? true
        : scheduling.location.toLowerCase().indexOf(location.toLowerCase()) !== -1;
    const priorityFilter = priorities.indexOf(scheduling.priority) !== -1;
    const deviceTypeFilter = deviceTypes.indexOf(scheduling.deviceType) !== -1;
    const schedulingTypeFilter = schedulingTypes.indexOf(scheduling.type) !== -1;
    const statusTypeFilter = statusTypes.indexOf(scheduling.status) !== -1;
    const selectionFilter =
      selected === null ? true : scheduling.isSelected === selected;
    const dateFilter =
      dateCreated === null
        ? true
        : scheduling.dateCreated.toLowerCase().indexOf(dateCreated.toLowerCase()) !==
          -1;

    const isFiltered =
      deviceFilter &&
      priorityFilter &&
      deviceTypeFilter &&
      schedulingTypeFilter &&
      statusTypeFilter &&
      selectionFilter &&
      locationFilter &&
      dateFilter;
    return isFiltered;
  });

  const filteredDataWithMergedState = filteredData.map(data => {
    if (scheduling[data.id]) return scheduling[data.id];
    return data;
  });

  return { ...state, data: filteredDataWithMergedState, filters: filters };
}

function addNewScheduling(state, scheduling, initialData) {
  const identifier =
    initialData.reduce((prev, next) => {
      const max = Math.max(prev, next.id);
      return max;
    }, 0) + 1;
  const newScheduling = { id: identifier, ...scheduling };
  initialData.push(newScheduling);
  return { ...state, data: [...state.data, newScheduling] };
}

const filterDeselectedScheduling = scheduling => {
  return !scheduling.isSelected;
};

function removeScheduling(state) {
  const deselectedScheduling = state.data.filter(filterDeselectedScheduling);
  const extras = { allSelected: false };
  return { ...state, data: deselectedScheduling, ...extras };
}

function filterColumns(state, columnId, show, columns = []) {
  const filteredColumns = state.columns.map(column => {
    let isRequired = column.key === columnId ? show : column.isRequired;
    return { ...column, isRequired };
  });
  return { ...state, columns: filteredColumns };
}

function filterSubColumns(state, payload) {
  const { columnId, types, show } = payload;
  // E.g., columnId = 'priority', type: 'Medium', show: false
  const filters = state.filters;
  const currentTypeFiltersForCoumn = filters[columnId];
  const newTypeFilter = show
    ? addItemsToArray(currentTypeFiltersForCoumn, types)
    : removeItemsFromArray(currentTypeFiltersForCoumn, types);
  const newFilter = { ...filters, [columnId]: newTypeFilter };
  return { ...state, filters: newFilter };
}

function performMultiSelection(state, select) {
  const shouldBeChecked = select;
  const newData = state.data.map(data => {
    const scheduling = { ...data, isSelected: shouldBeChecked };
    return scheduling;
  });
  return { ...state, data: newData, allSelected: select };
}

function postComments(state, comment) {
  let newData = state.data.map(i => {
    if (i.isSelected) {
      return { ...i, comments: comment };
    }
    return i;
  });
  return { ...state, data: newData };
}

export function postComment(comment) {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TYPES.POST_COMMENT,
      payload: {
        comment
      }
    });
  };
}

function addWorkOrder(state, order) {
  let newData = state.data.map(i => {
    if (i.isSelected) {
      return { ...i, status: state.workOrder[order] };
    }
    return i;
  });
  return { ...state, data: newData };
}

export function addToWorkOrder(order) {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TYPES.ADD_TO_WORK_ORDER,
      payload: {
        order
      }
    });
  };
}

function newWorkOrder(state, order) {
  let count = Object.keys(state.workOrder).length + 1;
  let nextWorkOrder = Object.assign({}, state.workOrder);
  nextWorkOrder['WORK_ORDER_' + count] = order;
  return { ...state, workOrder: nextWorkOrder };
}

export function createNewWorkOrder(order) {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TYPES.NEW_WORK_ORDER,
      payload: {
        order
      }
    });
  };
}

export function selectAll(select) {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TYPES.SELECT_ALL,
      payload: {
        select
      }
    });
  };
}

function performSelection(state, select, id) {
  const shouldBeChecked = select;
  const newData = state.data.map(data => {
    if (parseInt(data.id, 10) === parseInt(id, 10)) {
      const scheduling = { ...data, isSelected: shouldBeChecked };
      return scheduling;
    }
    return data;
  });
  return { ...state, data: newData, allSelected: false };
}

export function select(id, select) {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TYPES.SELECT,
      payload: {
        select,
        id
      }
    });
  };
}

export function search(searchString, columnId) {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TYPES.SEARCH_STRING,
      payload: {
        searchString,
        columnId
      }
    });
  };
}

function performSort(state, order, key) {
  const scheduling = state.data.slice(0);
  const sortState = { columnInSort: key, sortBy: order };
  if (order === 'asc' || order === 'desc') {
    return { ...state, data: orderby(scheduling, [key], [order]), ...sortState };
  } else {
    return { ...state, data: orderby(scheduling, ['id'], ['asc']), ...sortState };
  }
}

function paginateRight(state) {
  const currentPage = state.currentPage;
  const numItems = state.data.length;
  const adjustedLimit = Math.min(numItems, state.limit);
  const maxPages = Math.ceil(numItems / adjustedLimit);
  const nextPage = Math.min(currentPage + 1, maxPages);

  const limits = getLimits(state.data, nextPage, state.limit);
  if (currentPage === limits.MAXPAGES) {
    return state;
  }

  return {
    ...state,
    currentPage: nextPage,
    showingEnd: limits.END,
    showingStart: limits.START,
    maxPages: limits.MAXPAGES
  };
}

function paginateLeft(state) {
  const currentPage = state.currentPage;
  if (currentPage === 1) {
    return state;
  }
  const nextPage = Math.max(currentPage - 1, 1);
  const limits = getLimits(state.data, nextPage, state.limit);

  return {
    ...state,
    currentPage: nextPage,
    showingEnd: limits.END,
    showingStart: limits.START,
    maxPages: limits.MAXPAGES
  };
}

export function changeDataLimit(limit, defaultLimit) {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TYPES.CHANGE_LIMIT,
      payload: {
        limit,
        defaultLimit
      }
    });
  };
}

export function paginateNext() {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TYPES.PAGINATE_RIGHT
    });
  };
}

export function paginatePrev() {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TYPES.PAGINATE_LEFT
    });
  };
}

export function sort(key, order) {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TYPES.SORT,
      payload: {
        key,
        order
      }
    });
  };
}

export function reset() {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TYPES.RESET
    });
  };
}

export function createScheduling(scheduling) {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TYPES.NEW_ISSUE,
      payload: {
        scheduling
      }
    });
  };
}

export function closeSelectedScheduling() {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TYPES.CLOSE_SELECTED_SCHEDULING
    });
  };
}

export function showColumns(identifier, show) {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TYPES.SHOW_COLUMNS,
      payload: {
        identifier,
        show
      }
    });
  };
}

export function filterSubColumnTypes(columnId, types, show) {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TYPES.FILTER_TYPES_IN_COLUMN,
      payload: {
        columnId,
        types,
        show
      }
    });
  };
}

export function showNotification(message) {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TYPES.SHOW_NOTIFICATION,
      payload: {
        message
      }
    });
  };
}

export function dimissNotification() {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TYPES.DISMISS_NOTIFICATION
    });
  };
}

//for getting the list of schedules from database
export async function getScheduleListFromDb(){ 
    let data = await getScheduleList(API_ROOT + 'schedules');
    return data; 
};

export function setScheduleListInStore() {
  return dispatch => {
    dispatch({
      type: TYPES.SET_SCHEDULE_LIST
    });
  };
}

export function setScheduleDetailsInStore() {
  return dispatch => {
    dispatch({
      type: TYPES.SET_SCHEDULE_DATA
    });
  };
}

//for deleting the schedule details from database
export async function deleteScheduleFromDb(scheduleId) {  
    let data = await deleteScheduleData(API_ROOT + 'schedules/' + scheduleId);
    return data;  
};

//for creating a schedule in database
export async function createScheduleInDb(scheduleName){  
    let data = await createSchedule(API_ROOT + 'schedules', scheduleName);
    return data;  
};

//for creating new entry for schedule while creating
export async function createScheduleEntry(entryForSchedule){ 
  let data = await createEntry(API_ROOT + 'schedules/' + entryForSchedule.scheduleId + '/entries', entryForSchedule);
  return data; 
};

//for deleting a schedule entry from database
export async function deleteScheduleEntry(createdScheduleId , entryId){  
  let data = await deleteEntry(API_ROOT + 'schedules/' + createdScheduleId + '/entries/' + entryId);
  return data;  
};

//for getting the schedule details from database
export async function getScheduleDetailsFromDb(scheduleId) {  
  let data = await getScheduleData(API_ROOT + 'schedules/' + scheduleId);
  return data;  
};

//for getting the schedule entry details from database
export async function getScheduleEntriesDetailsFromDb(scheduleId) {  
  let data = await getScheduleEntriesData(API_ROOT + 'schedules/' + scheduleId + '/entries');
  return data;  
};

//for updating entry for schedule 
export async function updateScheduleEntry(updatedEntryForSchedule){ 
  let data = await updateEntry(API_ROOT + 'schedules/' + updatedEntryForSchedule.scheduleId + '/entries/' + updatedEntryForSchedule.entryId, updatedEntryForSchedule);
  return data; 
};

//for updating schedule in database
export async function updateScheduleInDb(scheduleToUpdate){  
  let data = await updateSchedule(API_ROOT + 'schedules/' + scheduleToUpdate.scheduleId, scheduleToUpdate);
  return data;  
};
