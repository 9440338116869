import React, { Component } from 'react';
import { css } from 'emotion';
import workOrder from '../../assets/work-order.svg';
import up from '../../assets/sort-asc-arrow.svg';
import down from '../../assets/sort-desc-arrow.svg';
import RadioBox from '../radiobox/radiobox.js';
import classNames from 'classnames';
import Messages from '../constants/messages';
import Confirmation from '../confirmation/confirmation.js';
import AppLoader from '../Loader/loader';

class AddWorkOrder extends Component {
    constructor(props) {
        super(props);
        this.setState({
            showAssignIssuesConfirmation: false,
            selectWorkOrder:0,
            showLoader:false,
        })
    }
        
    state = {
        show: false
    }
    handleClickOutside() {
        this.setState({
            show: false
        })
    }

    handleClick = (e) => {
        if (this.node !== null && this.node.contains(e.target)) {
            return;
        }
        this.handleClickOutside();
    }

    componentWillMount() {
        document.addEventListener('mousedown', this.handleClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false);
    }

    toggle = () => {
        if (this.props.disabled) return;
        this.setState({
            show: !this.state.show
        });
    }
    post = (e) => {        
        this.setState({selectWorkOrder: e.target.value ,showAssignIssuesConfirmation: true });
      
    }
    
    onCloseConfirm = () => {
        this.setState({showAssignIssuesConfirmation:false});
    }

    onConfirmWorkClicked = () => {
       
        this.setState({showAssignIssuesConfirmation:false,showLoader:true}, () => {
            this.props.submit(this.state.selectWorkOrder);
           });
    }

    render() {
        let containerClass = this.props.className ? this.props.className : defaultContainerClass;
        let arrow = this.state.show ? up : down;
        let btnContainerClass = classNames({
            [`${btnContainer}`]: true,
            [`${btnDisabledContainer}`]: this.props.disabled
        });
        return (
            <div>
                <div className={containerClass} ref={node => this.node = node}>
                    <div className={btnContainerClass} onClick={this.toggle}>
                        <img src={workOrder} alt='' className="add-worker-btn-icon-mob"></img>
                        <div className={btnText}>
                            ADD TO WORK ORDER
                        </div>
                        <img src={arrow} alt=''></img>
                    </div>
                    {this.state.show &&
                        (
                            <div className="issue-work-order-listing">
                                {this.props.workOrder.map((k) => {
                                    return (
                                        <div
                                            className={rowClass}
                                            key={k.work_order_id}
                                        >
                                            <RadioBox
                                                id={k.work_order_id}
                                                label={k.work_order_name}
                                                onSelect={this.post}
                                            />
                                        </div>
                                    );
                                })}
                                <div className={separatorClass}>
                                    <span>OR</span>
                                </div>

                                <div className={specialRowClass} onClick={this.props.onCreateNew} >
                                    Create new work order
                            </div>
                            </div>
                        )
                    }
                    {
                        this.state.showAssignIssuesConfirmation &&
                        (
                            <Confirmation confirmationText={Messages.AssignIssuesConfirmation} confirmButtonText="Confirm" rejectButtonText="Cancel" openConfirmation={true} closeConfirmationModal={this.onCloseConfirm} onConfirmClicked={this.onConfirmWorkClicked} />
                        )
                    }
                    {
          this.state.showLoader &&
          (
            <div className="overlay">
              <div className="application-loader">
                <AppLoader />
              </div>
            </div>
          )
        }
                </div>
            </div>
        );
    }
}

const btnContainer = css({
    width: '257px',
    height: '52px',
    borderRadius: '2px',
    backgroundColor: '#ffffff',
    display: 'flex',
    justifyContent: 'space-around',
    border: '1px solid #c4cdd5',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: '#F4F5F7',
    }
});
const btnDisabledContainer = css({
    cursor: 'default!important',
    backgroundColor: '#ebecf0!important',
    '&:hover': {
        backgroundColor: '#ebecf0!important'
    },
    '& div': {
        color: '#c1c7d0!important'
    }
});
const btnText = css({
    fontFamily: 'OpenSans',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.71,
    letterSpacing: '1px',
    textAlign: 'center',
    color: '#5e6c84',
    '&::-moz-selection': {
        background: 'none'
    },
    '&::selection': {
        background: 'none'
    }
});
const boxContainer = css({
    width: '184px',
    minHeight: '188px',
    borderRadius: '4px',
    boxShadow: '0 3px 10px 0 rgba(0, 0, 0, 0.09)',
    backgroundColor: '#ffffff',
    position: 'absolute',
    marginTop: '8px',
    padding: '16px 8px',
    zIndex: 1
});
const defaultContainerClass = css({
    width: 'auto',
    padding: '0px 20px 0px 16px',
});
const rowClass = css({
    paddingBottom: '16px'
});
const specialRowClass = css({
    fontFamily: 'OpenSans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#0151cb',
    cursor: 'pointer'
});
const separatorClass = css({
    fontFamily: 'OpenSans',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.71,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#c1c7d0',
    height: '12px',
    borderBottom: '1px solid #ebecf0',
    marginBottom: '10px',
    '& span': {
        background: '#ffffff',
        padding: '0 10px'
    }
});
export default (AddWorkOrder);
