import React, { Component } from 'react';
import { css } from 'emotion';

class Modal extends Component {
  render() {
    return (
      <div className={modalClass}>
        <span className={modalTitle}>{this.props.title}</span>
        <span className={modalSubTitle}>{this.props.subtitle}</span>
        {this.props.children}
      </div>
    );
  }
}

const modalClass = css({
  width: '400px',
  height: '530px',
  borderRadius: '8px',
  backgroundColor: '#ffffff',
  boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.18)',
  padding: '32px',
  margin: 'auto',
  textAlign: 'center',
  '@media (max-width: 480px)': {
    width: '75%'
  }
});
const modalTitle = css({
  fontFamily: 'OpenSans',
  fontSize: '36px',
  fontWeight: 300,
  lineHeight: 1.5,
  letterSpacing: '0.5px',
  textAlign: 'center',
  color: '#212121'
});

const modalSubTitle = css({
  fontFamily: 'OpenSans',
  fontSize: '16px',
  lineHeight: 1.5,
  letterSpacing: '0.5px',
  color: '#757575',
  display: 'block',
  marginTop: '8px',
  marginBottom: '40px'
});

export default Modal;
