import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Confirmation from '../confirmation/confirmation.js';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { getScheduleListFromDb } from '../../modules/scheduling';
import { AssignScheduleToDevice } from '../../modules/streetlight';
import { AssignScheduleToGroup } from '../../modules/grouping';
import AppLoader from '../Loader/loader';
import { toast } from 'react-toastify';
import Messages from '../constants/messages.js';

const { SearchBar, ClearSearchButton } = Search;

class AssignSchedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            showAssignConfirmation: false,
            showLoader: true,
            selectedScheduleId: 0,
            selectedDeviceId : 0,
            selectedGroupId : 0,
            selectedGroupName : ''
        };
    }

    componentDidMount() {
        //for device/site assign
        if(this.props.siteId !== undefined && this.props.siteId !== null && this.props.siteId !== ''){
           this.setState({ selectedDeviceId : this.props.siteId });
        }
        //for group assign
        if(this.props.groupId !== undefined && this.props.groupId !== null && this.props.groupId !== ''){
            this.setState({ selectedGroupId : this.props.groupId, selectedGroupName : this.props.groupName });
         }
        this.getScheduleList();
    }

    getScheduleList = async () => {
        let data = await getScheduleListFromDb(); //api call for getting the schedule list from database
        this.setState({ tableData: data, showLoader: false });
    }

    cellButton = (cell, row) => {
        return (
            <div>
                <div title="Assign" className="action-items" onClick={() => this.onAssignClick(cell, row)}><i className="fa fa-tasks action-items" aria-hidden="true"></i> Assign </div>
            </div>
        );
    };

    onAssignClick = (cell, row) => {
        this.setState({ showAssignConfirmation: true, selectedScheduleId: row.schedule_id });
    }

    closeAssignConfirmation = () => {
        this.setState({ showAssignConfirmation: false });
    }

    onAssignConfirm = () => {
        this.setState({ showLoader : true, showAssignConfirmation : false }, () => {
            if(this.state.selectedDeviceId !== 0){
                this.assignScheduleToDevice();
            }
            if(this.state.selectedGroupId !== 0){
                this.assignScheduleToGroup();
            }
        });
    }

    assignScheduleToDevice = async() => {
        let siteId = this.state.selectedDeviceId;
        let scheduleId = this.state.selectedScheduleId;
        let scheduleToAssign = {
            schedule_id : scheduleId,
            site_id : siteId
        }

        let response = await AssignScheduleToDevice(scheduleToAssign);

        if(response !== undefined && response !== null){
           this.setState({ showAssignConfirmation : false , showLoader : false }, () => {
               toast.success(Messages.ScheduleAssignToDevicesuccess);
               this.props.closeAssignModal();
            //    setTimeout(() => {
            //      window.location.reload(false);
            //    }, 2000);
           });
        } else {
            toast.error(Messages.ScheduleAssignToDevicefailure);
            this.setState({ showLoader : false });
        }
    }

    assignScheduleToGroup = async() => {
        let groupId = this.state.selectedGroupId;
        let groupName = this.state.selectedGroupName;
        let scheduleId = this.state.selectedScheduleId;
        let scheduleToAssign = {
            schedule_id : scheduleId,
            group_id : groupId,
            group_name : groupName
        }
        let response = await AssignScheduleToGroup(scheduleToAssign);
        if(response !== undefined && response !== null){
            // this.props.OnUpdateData();
           this.setState({ showAssignConfirmation : false, showLoader : false}, () => {
               toast.success(Messages.ScheduleAssignToGroupsuccess);
               this.props.closeAssignModal();
               setTimeout(() => {

                // window.location.reload(false);
               }, 2000);
           });
        } else {
            toast.error(Messages.ScheduleAssignToGroupfailure);
            this.setState({ showLoader : false });
        }
    }

    render() {
        const dataColumns = [{
            dataField: 'schedule_id',
            text: 'SCHEDULE ID',
            sort: true
        }, {
            dataField: 'schedule_name',
            text: 'SCHEDULE NAME',
            sort: true
        }, {
            dataField: 'button',
            text: 'ACTIONS',
            formatter: this.cellButton
        }];

        const defaultSorted = [{
            dataField: 'name',
            order: 'desc'
        }];

        const searchProps = {
            delay: 100
        };

        return (
            <div className="customTab">
                <h5 className="class-bold">Assign Schedule</h5>
                <ToolkitProvider
                    keyField="id"
                    data={this.state.tableData}
                    columns={dataColumns}
                    search={searchProps}
                >
                    {
                        props => (
                            <div>
                                <div className="device-list-search-parent-div row">
                                    <div className="device-list-search-input-div col-sm-8">
                                        <SearchBar {...props.searchProps} />
                                    </div>
                                    <div className="device-list-search-button-div col-sm-4">
                                        <ClearSearchButton className="table-search-btn" {...props.searchProps} />
                                    </div>
                                </div>

                                <div className="table-responsive fixed-table-footer-mobile padding-10">
                                    <BootstrapTable wrapperClasses="table-common table-assign-schedule-mob" {...props.baseProps} keyField='id' defaultSorted={defaultSorted} data={this.state.tableData} columns={dataColumns} pagination={paginationFactory()} />
                                </div>
                            </div>
                        )
                    }
                </ToolkitProvider>
                {
                    this.state.showAssignConfirmation &&
                    (
                        <Confirmation confirmationText="Are you sure you want to assign this schedule ?" confirmButtonText="Confirm" rejectButtonText="Cancel" openConfirmation={true} closeConfirmationModal={this.closeAssignConfirmation} onConfirmClicked={this.onAssignConfirm}></Confirmation>
                    )
                }
                {
                    this.state.showLoader &&
                    (
                        <div className="overlay">
                            <div className="application-loader">
                                <AppLoader />
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    sensors: state.sensors,
    map: state.map,
    user: state.user
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(AssignSchedule);
