import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Modal from 'react-responsive-modal';

class Confirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        this.onConfirmClicked = this.onConfirmClicked.bind(this);
        this.closeConfirmationModal = this.closeConfirmationModal.bind(this);
    }

    closeConfirmationModal(){
        this.props.closeConfirmationModal();
    }

    onConfirmClicked(){
        this.props.onConfirmClicked();
    }

    render() {       
        return (
            <div>
                <Modal classNames={{ modal: "confirmation-modal" }} open={this.props.openConfirmation} onClose={this.closeConfirmationModal} center closeOnOverlayClick={false}>
                    <div>
                       <h4>Confirmation</h4>
                       <div className="confirmation-text">
                            {this.props.confirmationText}
                       </div>
                       <div className="confirmation-btn-div">
                           <button className="confirmation-ok-btn" onClick={this.onConfirmClicked}>{this.props.confirmButtonText}</button>
                           <button className="confirmation-cancel-btn" onClick={this.closeConfirmationModal}>{this.props.rejectButtonText}</button>
                       </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);