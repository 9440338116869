import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import classnames from 'classnames';

class Toggle extends React.Component {
  state = {
    active: this.props.active?this.props.active:0
  };

  onChange = () => {
    let active = this.state.active===1?0:1;
    this.setState({
      active: active
    });
    this.props.onChange(active);
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      active: nextProps.active
    });
  }

  render() {
    let toggleClasses = classnames({
      [`${toggleClass}`]: true,
      [`${altToggleClass}`]: this.props.active===2,
      [`${this.props.className}`]: this.props.className ? true : false
    });
    return (
      <input
        type="checkbox"
        className={toggleClasses}
        checked={this.state.active===1?true:false}
        onChange={this.onChange}
      />
    );
  }
}

const toggleClass = css({
  WebkitAppearance: 'none',
  appearance: 'none',
  MozAppearance: 'none',
  backgroundColor: '#DBDBDB',
  border: '1px solid #d3d3d3',
  borderRadius: '26px',
  boxShadow: 'inset 0 0 0 1px #d3d3d3',
  cursor: 'pointer',
  height: '24px',
  position: 'relative',
  transition: 'border .25s .15s, box-shadow .25s .3s, padding .25s',
  width: '48px',
  verticalAlign: 'top',

  ':focus': {
    outline: 'none'
  },
  '&:after': {
    content: "''",
    backgroundColor: '#ffffff',
    border: '1px solid #d3d3d3',
    borderRadius: '22px',
    boxShadow:
      'inset 0 -3px 3px hsla(0,0%,0%,.025), 0 1px 4px hsla(0,0%,0%,.15), 0 4px 4px hsla(0,0%,0%,.1)',
    display: 'block',
    height: '20px',
    width: '20px',
    left: 0,
    position: 'absolute',
    right: '24px',
    top: 0,
    transition: 'border .25s .15s, left .25s .1s, right .15s .175s'
  },
  '&:checked': {
    borderColor: '#00C94D',
    boxShadow: 'inset 0 0 0 13px #00C94D',
    paddingLeft: '22px',
    transition: 'border .25s, box-shadow .25s, padding .25s .15s',

    '&:after': {
      borderColor: '#00C94D',
      left: '24px',
      right: 0,
      transition: 'border .25s, left .15s .25s, right .25s .175s'
    }
  }
});
const altToggleClass = css({
  WebkitAppearance: 'none',
  appearance: 'none',
  MozAppearance: 'none',
  backgroundColor: '#FFF2D0',
  border: '1px solid #FFF2D0',
  borderRadius: '26px',
  boxShadow: 'none',
  cursor: 'pointer',
  height: '24px',
  position: 'relative',
  transition: 'border .25s .15s, box-shadow .25s .3s, padding .25s',
  width: '48px',
  verticalAlign: 'top',

  ':focus': {
    outline: 'none'
  },
  '&:after': {
    content: "''",
    backgroundColor: '#ffffff',
    border: '1px solid #d3d3d3',
    borderRadius: '22px',
    boxShadow:
      'inset 0 -3px 3px hsla(0,0%,0%,.025), 0 1px 4px hsla(0,0%,0%,.15), 0 4px 4px hsla(0,0%,0%,.1)',
    display: 'block',
    height: '20px',
    width: '20px',
    left: '12px',
    position: 'absolute',
    right: '0px',
    top: 0,
    transition: 'border .25s .15s, left .25s .1s, right .15s .175s'
  },
  '&:checked': {
    borderColor: '#00C94D',
    boxShadow: 'inset 0 0 0 13px #00C94D',
    paddingLeft: '22px',
    transition: 'border .25s, box-shadow .25s, padding .25s .15s',

    '&:after': {
      borderColor: '#00C94D',
      left: '24px',
      right: 0,
      transition: 'border .25s, left .15s .25s, right .25s .175s'
    }
  }
});

Toggle.PropTypes = {
  active: PropTypes.number,
  className: PropTypes.className,
  onChange: PropTypes.func
};

Toggle.defaultProps = {
  active: 0,
  className: '',
  onChange: () => {}
};

export default Toggle;
