export function get1xBgStyle(id, sourceId, minZoom, maxZoom) {

  return {
    id: id,
    type: 'fill',
    source: sourceId,
    paint: {
      'fill-color': '#2584ff',
      'fill-opacity': 0.2
    },
    minzoom: minZoom,
    maxzoom: maxZoom,
    //filter: ['==', 'selected', false]
  };
}

export function get1xSelectedBgStyle(id, sourceId, minZoom, maxZoom) {
  return {
    id: id,
    type: 'fill',
    source: sourceId,
    paint: {
      'fill-color': '#2584ff',
      'fill-opacity': 0.6
    },
    minzoom: minZoom,
    maxzoom: maxZoom,
    //filter: ['in', 'id', '']
  };
}

export function get1xOutlineStyle(id, sourceId, minZoom, maxZoom) {
  return {
    id: id,
    type: 'line',
    source: sourceId,
    paint: {
      'line-width': 2,
      'line-color': '#2584ff'
    },
    minzoom: minZoom,
    maxzoom: maxZoom
  };
}
