import { lightReadings, getMacDevices, AssignScheduleToSite, getDistricts, getBlocks } from '../components/api/LightReading';
import { API_ROOT } from '../components/constants/api';

//for getting the data according to the mac ids
export const getLightData = (macIds) => {
    return dispatch => {
        const json = lightReadings(API_ROOT + 'readings', macIds);
        return json;
    };
};

//for getting the mac device list according to the district id
export const getMacDeviceList = (districtId) => {
    return dispatch => {
        const json = getMacDevices(API_ROOT + 'readings', districtId);
        return json;
    };
};

//for assigning schedule to selected device/site
export async function AssignScheduleToDevice(scheduleToAssign){
    let data = await AssignScheduleToSite(API_ROOT + 'sites/' + scheduleToAssign.site_id + '/schedule', scheduleToAssign);
    return data;
};


//for getting the district list
export const getDistrictsList = () => {
    return dispatch => {
        const json = getDistricts(API_ROOT + 'districts');
        return json;
    };
}

//for getting blocks by district Id
export const getBlocksByDistricId =(districId)=>{
     const data=getBlocks(API_ROOT+ 'districts/' + districId +'/blocks' );
    return data;
}


