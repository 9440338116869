import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import filterFactory, { textFilter, selectFilter, dateFilter } from 'react-bootstrap-table2-filter';
import { MODETYPE , getDevicesInsideGroupById } from '../../modules/grouping';
import AppLoader from '../Loader/loader';

export default class ShowGroupDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedGroupId : 0,
            tableData: [],
            showLoader : false,
            deviceType: {
                'Light Controller': 'Light Controller',
                'Gateway': 'Gateway',
                'Others': 'Others'
            }
        };
    }

    componentDidMount() {          
          if(this.props.selectedGroupId !== null && this.props.selectedGroupId !== undefined && this.props.selectedGroupId !== ""){
            this.setState({ selectedGroupId : this.props.selectedGroupId, showLoader : true }, () => {
               this.getDevicesInsideGroup();
            });
          }      
    }   

    getDevicesInsideGroup = async () => {
        let groupId = this.state.selectedGroupId;       
        let response = await getDevicesInsideGroupById(groupId);             
        if (response !== undefined && response !== null) {
            if (response.length > 0) {               
                this.setState({ tableData : response , showLoader : false });
            } else {
                this.setState({ showLoader: false });
            }
        } else {
            this.setState({ showLoader: false });
        }
    }

    renderModeText = (cell) => {
        if (cell !== undefined && cell !== null && cell !== "") {
            return MODETYPE[cell];
        } else {
            return "";
        }
    }

    renderInstallDate = (cell) => {
        let date = new Date(cell);
        let localDate = date.toLocaleDateString();
        return (
            <span>{localDate}</span>
        );
    }

    render() {  
        let dataLength = this.state.tableData.length;     
        const dataColumns = [{
            dataField: 'site_id',
            text: 'SITE ID',
            sort: true
        }, {
            dataField: 'site_name',
            text: 'SITE NAME',
            sort: true
        }, {
            dataField: 'mac_address',
            text: 'MAC',
            sort: true
        }, 
        // {
        //     dataField: 'installation_date',
        //     text: 'INSTALL DATE',
        //     sort: true,
        //     formatter: this.renderInstallDate
        // }, 
        {
            dataField: 'mode',
            text: 'MODE',
            sort: true,
            formatter: this.renderModeText
        }, {
            dataField: 'group_mask',
            text: 'SUBGROUPS',
            sort: true
        }, {
            dataField: 'site_status',
            text: 'SITE STATUS',
            sort: true
        }];

        const defaultSorted = [{
            dataField: 'site_name',
            order: 'desc'
        }];

        return (
            <div className="group-details-modal">
                <div id="divGroupDetails">                    
                        <div className="table-responsive fixed-table-footer-mobile">
                            <div className="group-modal-header-div"> 
                                <span className="class-bold">Group Name :</span>                               
                                <span className="group-modal-name">{(this.props.selectedGroupData === undefined) ? '' : this.props.selectedGroupData.group_name}</span>
                            </div>
                            <div className="class-bold lbl-device-list">Device List : </div>
                            {
                                dataLength > 0 && <BootstrapTable wrapperClasses="table-common table-group-details-mob" keyField='id' data={this.state.tableData} columns={dataColumns} defaultSorted={defaultSorted} pagination={paginationFactory()} filter={filterFactory()} />
                            }
                            {
                                dataLength <= 0 && <div>No devices to display.</div>
                            }
                        </div>                    
                </div>
                {
                    this.state.showLoader &&
                    (
                        <div className="overlay">
                            <div className="application-loader">
                                <AppLoader />
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
}
