import React, { Component } from 'react';
import whiteTickImage from '../../assets/white-blue-tick.svg';
import { css } from 'emotion';

export default class Option extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: this.props.isSelected
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      checked: nextProps.isSelected
    });
  }

  handleChange(event) {
    this.setState({ checked: event.target.checked });
    this.props.onSelect(event.target.id, event.target.checked);
  }

  render() {
    const container = css({
      paddingBottom: '10px',
      paddingTop: '10px',
      width: '100%'
    });

    const labelStyle = css({
      marginLeft: '50px',
      marginTop: '3px'
    });

    const checkbox = css({
      display: 'flex',
      width: 'auto',
      height: '0px',
      position: 'relative',
      '& input[type=checkbox]': {
        visibility: 'hidden'
      },
      '& label': {
        display: 'inline-block',
        cursor: 'pointer',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '18px',
        height: '18px',
        boxSizing: 'border-box',
        marginLeft: '15px',
        marginTop: '3px',
        border: 'solid 1px #c1c7d0',
        borderRadius: '2px'
      },
      '& input[type=checkbox]:checked + label': {
        content: `url(${whiteTickImage})`,
        transition: 'all .5s ease',
        border: 0,
        backgroundColor: '#0151cb'
      }
    });

    const labelBuilder = this.props.labelBuilder || '';

    return (
      <div className={container}>
        <div className={checkbox}>
          <input
            id={this.props.keyId}
            type="checkbox"
            onChange={this.handleChange}
            checked={this.state.checked}
            value={' '}
          />
          <label htmlFor={this.props.keyId} />
        </div>
        <div className={`${labelStyle} ${labelBuilder}`}>
          {this.props.label}
        </div>
      </div>
    );
  }
}
