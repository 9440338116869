import React, { Component } from 'react';       
import { css } from 'emotion';
       
class InputSlider extends Component {

    render(){
        let range = this.props.range;
        let position = 0,size = 0;
        if(range[1]!==range[0]){
            size = ((range[1]-range[0]) / 4095) * 100;
            position = (range[0]  / 4095) * 100;
        }else{
            if(range[1]){
                size = (range[1] / 4095) *100;
            }
        }
        let bgcolor = {
            marginLeft: `${position}%`,
            width: `${size}%`,
          };

        return (
            <div className={inputRangeClass}>
                <input
                    type="range"
                    min="0"
                    max="4095"
                    step="1" 
                    value={range[0]}
                    className={sliderClass}
                    onChange={e => {
                        this.props.adjustBrightness(e);
                    }}
                    onMouseUp={e => {
                        this.props.adjustBrightness(e);
                    }}
                />
                <input
                    type="range"
                    min="0"
                    max="4095"
                    step="1" 
                    value={range[1]}
                    className={sliderClass}
                    onChange={e => {
                        this.props.adjustBrightness(e);
                    }}
                    onMouseUp={e => {
                        this.props.adjustBrightness(e);
                    }}
                />
                <div 
                    className={displayColor}
                    style={bgcolor}
                ></div>
            </div>
        );
    }
}
const inputRangeClass = css({
    display: 'block',
    padding: '16px 0',
    alignItems: 'center'
  });

const displayColor = css({
    height: '2px',
    backgroundColor: '#FFC402',
    marginTop: '-2px'
});
const sliderClass = css({
    width: '100%',
    boxShadow: 'inset 0 1px 3px 0 rgba(0,0,0,0.15)',
    borderRadius: '4px',
    height: '2px',
    WebkitAppearance: 'none',
    appearance: 'none',
    MozAppearance: 'none',
    outline: 'none',

    display: 'block',
    margin: 0,
    pointerEvents: 'none',
    
    '& + input[type="range"]': {
        marginTop: '-2px',
    },
    ':first-child': {
        '&::-webkit-slider-thumb':{
            WebkitAppearance: 'none',
            appearance: 'none',
            MozAppearance: 'none',
            width: '20px',
            height: '20px',
            boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.3)',
            border: 'solid 1px #dfe1e5',
            borderRadius: '20px',
            backgroundColor: '#f4f5f7'
        },
        '&::-moz-range-thumb':{
            WebkitAppearance: 'none',
            appearance: 'none',
            MozAppearance: 'none',
            width: '20px',
            height: '20px',
            boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.3)',
            border: 'solid 1px #dfe1e5',
            borderRadius: '20px',
            backgroundColor: '#f4f5f7'
        },
        '&::-ms-thumb':{
            WebkitAppearance: 'none',
            appearance: 'none',
            MozAppearance: 'none',
            width: '20px',
            height: '20px',
            boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.3)',
            border: 'solid 1px #dfe1e5',
            borderRadius: '20px',
            backgroundColor: '#f4f5f7'
        },
    },
    ':nth-child(2)': {
        '&::-webkit-slider-thumb':{
            WebkitAppearance: 'none',
            appearance: 'none',
            MozAppearance: 'none',
            width: '20px',
            height: '20px',
            boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.3)',
            borderRadius: '20px',
            backgroundColor: '#ffffff',
            pointerEvents: 'all'
        },
        '&::-moz-range-thumb':{
            WebkitAppearance: 'none',
            appearance: 'none',
            MozAppearance: 'none',
            width: '20px',
            height: '20px',
            boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.3)',
            borderRadius: '20px',
            backgroundColor: '#ffffff',
            pointerEvents: 'all'
        },
        '&::-ms-thumb':{
            WebkitAppearance: 'none',
            appearance: 'none',
            MozAppearance: 'none',
            width: '20px',
            height: '20px',
            boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.3)',
            borderRadius: '20px',
            backgroundColor: '#ffffff',
            pointerEvents: 'all'
        },
    }
  });
  
export default InputSlider;
