import 'isomorphic-fetch';
import { Cookies } from 'react-cookie';
import {getApiToken} from "../../utils/user"; //added on 9th september for getting the authentication token value from cookies
const cookies = new Cookies();

//for getting the data according to the mac ids
export async function lightReadings(url, macIds) {
  let response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    },
    body: JSON.stringify({
        macs: macIds
    })
  });
  let status = response.status;
  let data = status >= 400 ? null : await response.json();
  return data;
}

//for getting the mac device list
export async function getMacDevices(url) {
  let response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    }
  });
  let status = response.status;
  let data = status >= 400 ? null : await response.json();

  return data;
}

//for updating the light GPS
export async function GpsUpdate(url, gpsStatus, immediate) {
  let response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    },
    body: JSON.stringify({
      "command": "gps",
      "parameter": gpsStatus,
      "immediate": immediate
    })
  });
  let status = response.status;
  let data = status >= 400 ? null : await response;
  return data;
}

//for updating the light MODE
export async function ModeUpdate(url, modeType, immediate) {
  let response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    },
    body: JSON.stringify({
      "command": "set_mode",
      "parameter": modeType,
      "immediate": immediate
    })
  });
  let status = response.status;

  let data = status >= 400 ? null : await response;
  return data;
}

//for updating the ambient threshold and level for light
export async function ThresholdAndLevelUpdate(url, lightToUpdate) {
  let response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    },
    body: JSON.stringify({
      "ambient_threshold": lightToUpdate.lightAmbientThreshold,
      "mode_1_level": lightToUpdate.lightModeLevel1,
      "mode_2_level": lightToUpdate.lightModeLevel2
    })
  });
  let status = response.status;
  let data = status >= 400 ? null : await response;

  return data;
}

//for assigning schedule to a particular device/site
export async function AssignScheduleToSite(url, scheduleToAssign) {
  let response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    },
    body: JSON.stringify({
      "schedule_id": Number(scheduleToAssign.schedule_id),
    })
  });
  let status = response.status;
  let data = status >= 400 ? null : await response;
  return data;
}


//for getting the districts list
export async function getDistricts(url) {
  let response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    }
  });
  let status = response.status;
  let data = status >= 400 ? null : await response.json();
  return data;
}

//for getting the block list by distric id
export async function getBlocks(url) {
  let response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    }
  });
  let status = response.status;
  let data = status >= 400 ? null : await response.json();
  return data;
}
