import './mapbox.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'mapbox-gl/dist/svg/mapboxgl-ctrl-compass.svg';
import 'mapbox-gl/dist/svg/mapboxgl-ctrl-geolocate.svg';
import 'mapbox-gl/dist/svg/mapboxgl-ctrl-zoom-in.svg';
import 'mapbox-gl/dist/svg/mapboxgl-ctrl-zoom-out.svg';
import mapboxgl from 'mapbox-gl';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  zoom,
  selectedDistricts,
  selectedBlocks,
  selectedLights,
  hasAdjustedZoom,
  onZoomEnd,
  updateLayer
} from '../../modules/map.js';

import './mapbox.css';
import {
  TOKEN,
  DEFAULT_ZOOM_MODAL,
  DEFAULT_CENTER,
  getLayerTypeForZoom,
  LAYERS
} from '../constants/map.js';

import { dismissInfoBox } from '../../modules/map.js';
import mapDarkThemeStylesheet from '../../assets/map-dark-theme.json';
import {debug} from "mapbox-gl/src/shaders";
import {LAYER_1X_ZOOM_LEVEL, LAYER_1X_ZOOM_LEVEL_DEVICE} from "../constants/map";
import OneXLayer from "../deviceMap/layers/1x-layer";
import {css} from "emotion";

import {
 toggleDeviceSiteDisplay
} from '../../modules/map.js';
import TwoXLayer from "../deviceMap/layers/2x-layer";

class DeviceMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasMounted: false,
      currentDeviceCategory: ''
    }
  }

  componentDidMount = async () => {
    mapboxgl.accessToken = TOKEN;

    this.map = new mapboxgl.Map({
      attributionControl: false,
      container: this.mapContainer,
      style: mapDarkThemeStylesheet,
      center: this.props.map.center || DEFAULT_CENTER,
      zoom: DEFAULT_ZOOM_MODAL
    });

    this.map.addControl(new mapboxgl.NavigationControl({
      showZoom: true,
      showCompass: false,
      visualizePitch: false
    }), 'bottom-left');
    this.map.on('load', async () => await this.onMapZoomEnd());
    this.map.on('zoomend', () => this.onMapZoomEnd());
    this.map.on('dragend', () => this.onMapZoomEnd());
    this.map.setMinZoom(DEFAULT_ZOOM_MODAL);
    this.map.setZoom(LAYER_1X_ZOOM_LEVEL_DEVICE.DEFAULT);
    this.map.doubleClickZoom.disable();
    this.map.boxZoom.disable();
    this.setState({currentDeviceCategory: 'DEVICES'});
    toggleDeviceSiteDisplay('DEVICES');
    await this.waiting();
  }

  waiting = async () => {
    if (!this.map.isStyleLoaded()) {
      setTimeout(this.waiting, 600);
    } else {
      await this.onMapStylesLoaded();
    }
  };

  onMapZoomEnd = () => {
    let bbox = this.map.getBounds();
    const layerType = getLayerTypeForZoom(this.map.getZoom());
    this.props.onZoomEnd({viewPort: bbox, layerType});
  }

  //componentWillReceiveProps = async (nextProps) => {
  componentDidUpdate = async (prevProps) => {
    let {zoomLevel} = this.props.map;
    let {updateLayer} = this.props;
    let shouldAdjustZoom = this.props.map.shouldAdjustZoom;

    const layerType = this.props.map.layerType ? this.props.map.layerType : getLayerTypeForZoom(this.map.getZoom());

    const selectedDistricts = this.props.map.selectedDistricts;
    if (this.oneXLayer)
      this.oneXLayer.updateSelections(selectedDistricts);

    const selectedLights = this.props.map.selectedLights;
    if (this.twoXLayer)
      this.twoXLayer.updateSelections(selectedLights);

    if (shouldAdjustZoom) {
      const zoomLevel = this.props.map.zoomLevel;
      const center =
          this.props.map.center === null
              ? this.map.getCenter()
              : this.props.map.center;
      this.map.flyTo({ zoom: zoomLevel, center: center });
      this.props.hasAdjustedZoom();
    }
  }

  onClick = () => {
    this.props.dismissInfoBox();
  };

  onMapStylesLoaded = async () => {
    const {map, toggleDeviceSiteDisplay} = this.props;
    const {zoomLevel} = map;
    const layerType = this.props.map.layerType ? this.props.map.layerType : getLayerTypeForZoom(this.map.getZoom());

    this.oneXLayer = new OneXLayer(
        this.map,
        [],
        this.props
    );

    this.twoXLayer = new TwoXLayer(
        this.map,
        [],
        this.props
    );

    await this.oneXLayer.updateFeatureData(this.props);
    this.oneXLayer.setMarkersAdded(true);
  };

  clearMarkersFor = (layers = []) => {
    const layerObjects = {
      LAYER_1X: this.oneXLayer,
      LAYER_2X: this.twoXLayer
    };

    layers.forEach(layer => {
      const layerObj = layerObjects[layer];

      if (layerObj) {
        layerObj.clearMarkers();
      }
    });
  }

  render() {
    return (
        <div id="map-container" className={this.props.className}>
          <div ref={el => this.mapContainer = el} className={mapProperties} />
        </div>
    );
  }
}

const mapStateToProps = state => ({
  map: state.map,
  routing: state.routing
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
          dismissInfoBox,
          zoom,
          selectedDistricts,
          selectedBlocks,
          selectedLights,
          hasAdjustedZoom,
          onZoomEnd,
          updateLayer,
          toggleDeviceSiteDisplay
        },
        dispatch
    );

Map.PropTypes = {
  className: PropTypes.string
};

const mapProperties = css({
  position: 'absolute !important',
  top: '0px !important',
  right: '0px !important',
  left: '0px !important',
  bottom: '0px !important',
  /*width: '100%',
  height: '100%',
  float: 'left'*/
});

/*
position: absolute;
top: 0;
right: 0;
left: 0;
bottom: 0;
 */

export default connect(mapStateToProps, mapDispatchToProps)(DeviceMap);
