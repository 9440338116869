import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { MODETYPE , createScheduleEntry ,updateScheduleEntry } from '../../modules/scheduling.js';
import TimePicker from 'react-times';
import 'react-times/css/material/default.css';
import Button from '../button/button.js';
import Select from "react-select";
import moment from 'moment';
import AppLoader from '../Loader/loader';
import { toast } from 'react-toastify';
import Messages from '../constants/messages.js';

class SetSchedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modeType: '',
            modeTypeText: '',
            time: '12:00',
            isSunChecked: false,
            isMonChecked: false,
            isTueChecked: false,
            isWedChecked: false,
            isThuChecked: false,
            isFriChecked: false,
            isSatChecked: false,
            daysInBinary: '',
            errors: [],
            timeInSeconds: '',
            editedRow: [],
            editedRowId : 0,
            selectedMode: '',
            showLoader: false,
            createdEntryId : 0
        }
        this.onModeTypeSelected = this.onModeTypeSelected.bind(this);
        this.selectDays = this.selectDays.bind(this);
        this.getSelectedDaysInBinary = this.getSelectedDaysInBinary.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.setStateOnEdit = this.setStateOnEdit.bind(this);
    }

    componentDidMount() {
        let selectedRow = this.props.dataFromParent;
        this.setState({ editedRow: selectedRow, editedRowId : selectedRow.id }, () => {
            this.setStateOnEdit(selectedRow);
        })
    }

    setStateOnEdit(selectedRow) {
        let sunChecked = false, monChecked = false, tueChecked = false, wedChecked = false, thuChecked = false, friChecked = false, satChecked = false, selectedTime = '12:00', selectedMode = '', modeType = '', modeText = "";

        //for setting days values
        if (selectedRow.daysOfWeek !== undefined && selectedRow.daysOfWeek !== "") {
            if (selectedRow.daysOfWeek.indexOf('Su') >= 0) {
                sunChecked = true;
            }
            if (selectedRow.daysOfWeek.indexOf('Mo') >= 0) {
                monChecked = true;
            }
            if (selectedRow.daysOfWeek.indexOf('Tu') >= 0) {
                tueChecked = true;
            }
            if (selectedRow.daysOfWeek.indexOf('We') >= 0) {
                wedChecked = true;
            }
            if (selectedRow.daysOfWeek.indexOf('Th') >= 0) {
                thuChecked = true;
            }
            if (selectedRow.daysOfWeek.indexOf('Fr') >= 0) {
                friChecked = true;
            }
            if (selectedRow.daysOfWeek.indexOf('Sa') >= 0) {
                satChecked = true;
            }
        }

        //for setting mode value
        if (selectedRow.modeType !== undefined && selectedRow.modeType !== "") {
            selectedMode = { key: selectedRow.modeType, label: selectedRow.mode };
            modeType = selectedRow.modeType;
            modeText = selectedRow.mode;
        }

        //for setting time
        if (selectedRow.time !== undefined && selectedRow.time !== "") {
            selectedTime = selectedRow.time;
        }

        this.setState({
            selectedMode: selectedMode, modeType: modeType, modeTypeText: modeText, time: selectedTime, isSunChecked: sunChecked, isMonChecked: monChecked, isTueChecked: tueChecked, isWedChecked: wedChecked, isThuChecked: thuChecked, isFriChecked: friChecked, isSatChecked: satChecked
        });
    }

    handleValidation() {
        let isValid = true;
        let errors = [];
        let state = this.state;

        if (!(state["isSunChecked"] || state["isMonChecked"] || state["isTueChecked"] || state["isWedChecked"] || state["isThuChecked"] || state["isFriChecked"] || state["isSatChecked"])) {
            errors["schedulingDays"] = "Please select a day for scheduling.";
            isValid = false;
        }

        if (state["selectedMode"] === "") {
            errors["schedulingMode"] = "Please select a mode for scheduling.";
            isValid = false;
        }

        this.setState({ errors: errors });
        return isValid;
    }

    onModeTypeSelected(type) {
        const {isFormDirty} = this.props;
        this.setState({
            modeType: type.key,
            modeTypeText: type.label,
            selectedMode: { key: type.key, label: type.label }
        });
        isFormDirty(true);
    }

    onTimeChange(options) {
        const {isFormDirty} = this.props;
        let hour = options.hour;
        let minute = options.minute;
        let newTime = hour + ':' + minute;
        this.setState({ time: newTime });
        isFormDirty(true);
    }

    selectDays(e, day) {
        const {isFormDirty} = this.props;
        switch (day) {
            case 'sun':
                this.setState({ isSunChecked: e.target.checked });
                isFormDirty(true);
                break;
            case 'mon':
                this.setState({ isMonChecked: e.target.checked });
                isFormDirty(true);
                break;
            case 'tue':
                this.setState({ isTueChecked: e.target.checked });
                isFormDirty(true);
                break;
            case 'wed':
                this.setState({ isWedChecked: e.target.checked });
                isFormDirty(true);
                break;
            case 'thu':
                this.setState({ isThuChecked: e.target.checked });
                isFormDirty(true);
                break;
            case 'fri':
                this.setState({ isFriChecked: e.target.checked });
                isFormDirty(true);
                break;
            case 'sat':
                this.setState({ isSatChecked: e.target.checked });
                isFormDirty(true);
                break;
            default:
                break;
        }
    }

    getSelectedDaysInBinary() {
        let sun = 0, mon = 0, tue = 0, wed = 0, thu = 0, fri = 0, sat = 0;
        if (this.state.isSunChecked) {
            sun = 1;
        }
        if (this.state.isMonChecked) {
            mon = 1;
        }
        if (this.state.isTueChecked) {
            tue = 1;
        }
        if (this.state.isWedChecked) {
            wed = 1;
        }
        if (this.state.isThuChecked) {
            thu = 1;
        }
        if (this.state.isFriChecked) {
            fri = 1;
        }
        if (this.state.isSatChecked) {
            sat = 1;
        }
        let binaryForm = "0" + sun + mon + tue + wed + thu + fri + sat;
        return binaryForm;
    }

    onCreateEntry = () => {
        const {isFormDirty} = this.props;
        if (!this.handleValidation()) {
            return;
        }
        this.setState({ daysInBinary: this.getSelectedDaysInBinary(), showLoader : true }, () => {
           this.createEntry();
           isFormDirty(false);
        });
    }

    onUpdateEntry = () => {
        const {isFormDirty} = this.props;
        if (!this.handleValidation()) {
            return;
        }
        this.setState({ daysInBinary: this.getSelectedDaysInBinary(), showLoader : true }, () => {
           this.updateEntry();
           isFormDirty(false);
        });
    }

    createEntry = async() => {
        let scheduleId = this.props.createdScheduleId;
        if(this.props.editedScheduleId !== null && this.props.editedScheduleId !== undefined && this.props.editedScheduleId !== 0){
            scheduleId = this.props.editedScheduleId;
        }
        let entryForSchedule = {
            selectedDays: parseInt(this.state.daysInBinary, 2),
            scheduleId: parseInt(scheduleId),
            utcSeconds: moment(this.state.time, 'HH:mm').diff(moment().startOf('day'), 'seconds'),
            modeType: parseInt(this.state.modeType, 2),
        }
        let response = await createScheduleEntry(entryForSchedule);
        if(response !== undefined && response !== null && response.headers !== undefined && response.headers !== null){
            let location = response.headers.get('location');
            this.setState({ createdEntryId : location.split('/')[5], showLoader : false }, () => {
            toast.success(Messages.CreateScheduleEntrySuccess);
            this.props.handlerFromParant(this.state);
           })
        }
    }


    updateEntry = async() => {
        let scheduleId = this.props.createdScheduleId;
        if(this.props.editedScheduleId !== undefined && this.props.editedScheduleId !== undefined && this.props.editedScheduleId !== 0){
            scheduleId = this.props.editedScheduleId;
        }
        let updatedEntryForSchedule = {
            entryId : this.state.editedRowId,
            selectedDays: parseInt(this.state.daysInBinary, 2),
            scheduleId: parseInt(scheduleId),
            utcSeconds: moment(this.state.time, 'HH:mm').diff(moment().startOf('day'), 'seconds'),
            modeType: parseInt(this.state.modeType, 2),
        }
        let response = await updateScheduleEntry(updatedEntryForSchedule);
        if(response !== undefined && response !== null){
            this.setState({ showLoader : false }, () => {
            toast.success(Messages.UpdateScheduleEntrySuccess);
            this.props.handlerFromParant(this.state);
           })
        }
    }

    render() {
        const {isFormDirty, isDirty} = this.props;
        const objectToArray = item => {
            return Object.keys(item).map(key => {
                return { key: key, label: item[key] };
            });
        };
        const modeTypes = objectToArray(MODETYPE);

        let createUpdateBtn =  <Button buttonTextStyle="application-button" text="CREATE" buttonClickCallback={this.onCreateEntry} />

        let title = "Create Schedule Entry";
        if(this.props.dataFromParent.id !== undefined && this.props.dataFromParent.id !== null){
            title = "Edit Schedule Entry";
            createUpdateBtn = <Button buttonTextStyle="application-button" text="UPDATE" buttonClickCallback={this.onUpdateEntry} />;
        }

        return (
            <div>
                <h5 className="class-bold">{title}</h5>
                <div className="padding-10 table-responsive">
                    <span className="class-bold">Days of the week <span className="important-fields">*</span></span>
                    <div className="set-schedule-week-days">
                        <div>
                            <div className="class-bold">Sun</div>
                            <div><input checked={this.state.isSunChecked} onClick={e => this.selectDays(e, "sun")} className="big-checkbox" type="checkbox"></input></div>
                        </div>
                        <div className="set-schedule-week">
                            <div className="class-bold">Mon</div>
                            <div><input checked={this.state.isMonChecked} onClick={e => this.selectDays(e, "mon")} className="big-checkbox" type="checkbox"></input></div>
                        </div>
                        <div className="set-schedule-week">
                            <div className="class-bold">Tue</div>
                            <div><input checked={this.state.isTueChecked} onClick={e => this.selectDays(e, "tue")} className="big-checkbox" type="checkbox"></input></div>
                        </div>
                        <div className="set-schedule-week">
                            <div className="class-bold">Wed</div>
                            <div><input checked={this.state.isWedChecked} onClick={e => this.selectDays(e, "wed")} className="big-checkbox" type="checkbox"></input></div>
                        </div>
                        <div className="set-schedule-week">
                            <div className="class-bold">Thu</div>
                            <div><input checked={this.state.isThuChecked} onClick={e => this.selectDays(e, "thu")} className="big-checkbox" type="checkbox"></input></div>
                        </div>
                        <div className="set-schedule-week">
                            <div className="class-bold">Fri</div>
                            <div><input checked={this.state.isFriChecked} onClick={e => this.selectDays(e, "fri")} className="big-checkbox" type="checkbox"></input></div>
                        </div>
                        <div className="set-schedule-week">
                            <div className="class-bold">Sat</div>
                            <div><input checked={this.state.isSatChecked} onClick={e => this.selectDays(e, "sat")} className="big-checkbox" type="checkbox"></input></div>
                        </div>
                    </div>
                    <div className="application-error-messages">{this.state.errors["schedulingDays"]}</div>
                </div>
                <div className="row padding-10">
                    <div className="col-sm-6">
                        <div className="class-bold">Time UTC <span className="important-fields">*</span></div>
                        <div>
                            <TimePicker time={this.state.time} onTimeChange={this.onTimeChange.bind(this)} />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="class-bold">Mode <span className="important-fields">*</span></div>
                        <div className="create-schedule-mode-select">
                            <Select value={this.state.selectedMode} onChange={this.onModeTypeSelected} options={modeTypes}></Select>
                        </div>
                        <div className="application-error-messages">{this.state.errors["schedulingMode"]}</div>
                    </div>
                </div>
                <div className="padding-10 set-schedule-button">
                   {createUpdateBtn}
                </div>
                {
                    this.state.showLoader &&
                    (
                        <div className="overlay">
                            <div className="application-loader">
                                <AppLoader />
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    sensors: state.sensors,
    map: state.map,
    user: state.user
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            //setLightSchedule //for set state of selected light
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(SetSchedule);
