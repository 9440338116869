import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '../button/button.js';
import BootstrapTable from 'react-bootstrap-table-next';
import Modal from 'react-responsive-modal';
import SetSchedule from '../scheduling/set-schedule';
import moment from 'moment';
import Confirmation from '../confirmation/confirmation.js';
import Messages from '../constants/messages.js';
import { MODETYPE, createScheduleInDb, deleteScheduleEntry, getScheduleDetailsFromDb, getScheduleEntriesDetailsFromDb, updateScheduleInDb } from '../../modules/scheduling';
import { toast } from 'react-toastify';
import AppLoader from '../Loader/loader';

class CreateSchedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scheduleName: '',
            setScheduleModal: false,
            dataFromScheduleEntry: [],
            scheduleList: [],
            errors: [],
            selectedRow: [],
            showDeleteConfirmation: false,
            createdScheduleId: 0,
            showLoader: false,
            editedScheduleId: 0,
            showCloseConfirmation: false
        }
    }

    componentDidMount() {
        if (this.props.selectedScheduleId !== undefined && this.props.selectedScheduleId !== null) {
            this.setState({ editedScheduleId: this.props.selectedScheduleId, showLoader: true }, async () => {
                await this.getScheduleDetailsById();
                await this.getScheduleEntriesDetailsById();
            });
        }
    }

    getScheduleDetailsById = async () => {
        if (this.state.editedScheduleId !== undefined && this.state.editedScheduleId !== null && this.state.editedScheduleId !== 0) {
            let response = await getScheduleDetailsFromDb(this.state.editedScheduleId);
            if (response !== undefined && response !== null) {
                this.setState({ scheduleName: response.schedule_name });
            }
        }
    }

    getScheduleEntriesDetailsById = async () => {
        if (this.state.editedScheduleId !== undefined && this.state.editedScheduleId !== null && this.state.editedScheduleId !== 0) {
            let response = await getScheduleEntriesDetailsFromDb(this.state.editedScheduleId);
            if (response !== undefined && response !== null) {
                if (response.length > 0) {
                    let fillData = [];
                    for (let i = 0; i < response.length; i++) {
                        let entryId = Number.isInteger(response[i].schedule_entry_id) ? parseInt(response[i].schedule_entry_id) : response[i].schedule_entry_id;
                        let daysOfWeek = this.getDaysString(Number((response[i].day_of_week_mask).toString()).toString(2));
                        let timeString = this.getTimeInHourMinute(response[i].second_of_day);
                        let utcSecondOfDay = response[i].second_of_day;
                        let modeType = MODETYPE[response[i].mode];
                        let mode = Number.isInteger(response[i].mode) ? parseInt(response[i].mode) : response[i].mode;

                        let data = { id: entryId, daysOfWeek: daysOfWeek, utcSecondsOfDay: utcSecondOfDay, modeType: modeType, mode: mode, time: timeString };
                        fillData = [...fillData, data];
                    }
                    this.setState({ scheduleList: fillData, showLoader: false });
                }
                else {
                    this.setState({ showLoader: false });
                }
            }
            else {
                this.setState({ showLoader: false });
            }
        }
        else {
            this.setState({ showLoader: false });
        }
    }

    getDaysString(daysInBitForm) {
        let daysInStringFormat = '';
        daysInBitForm = this.modifyDayValue(daysInBitForm);

        for (let j = 0; j <= daysInBitForm.length - 1; j++) {
            if (daysInBitForm[j] === '1') {
                switch (j) {
                    case 6:
                        daysInStringFormat = daysInStringFormat + ' Sa';
                        break;
                    case 5:
                        daysInStringFormat = daysInStringFormat + ' Fr';
                        break;
                    case 4:
                        daysInStringFormat = daysInStringFormat + ' Th';
                        break;
                    case 3:
                        daysInStringFormat = daysInStringFormat + ' We';
                        break;
                    case 2:
                        daysInStringFormat = daysInStringFormat + ' Tu';
                        break;
                    case 1:
                        daysInStringFormat = daysInStringFormat + ' Mo';
                        break;
                    case 0:
                        daysInStringFormat = daysInStringFormat + ' Su';
                        break;
                }
            }
        }
        return daysInStringFormat;
    }

    modifyDayValue(daysInBitForm) {
        if (daysInBitForm.length < 7) {
            switch (daysInBitForm.length) {
                case 6:
                    daysInBitForm = "0" + daysInBitForm;
                    break;
                case 5:
                    daysInBitForm = "00" + daysInBitForm;
                    break;
                case 4:
                    daysInBitForm = "000" + daysInBitForm;
                    break;
                case 3:
                    daysInBitForm = "0000" + daysInBitForm;
                    break;
                case 2:
                    daysInBitForm = "00000" + daysInBitForm;
                    break;
                case 1:
                    daysInBitForm = "000000" + daysInBitForm;
                    break;
                default:
                    daysInBitForm;
            }
        }
        return daysInBitForm;
    }

    getTimeInHourMinute(given_seconds) {
        let hours = Math.floor(given_seconds / 3600);
        if (hours < 10) {
            hours = '0' + hours;
        }
        let minutes = Math.floor((given_seconds - (hours * 3600)) / 60);
        if (minutes < 10) {
            minutes = '0' + minutes;
        }
        let timeString = hours + ':' + minutes;
        return timeString;
    }

    handleDataFromSetSchedule = (dataFromChild) => {
        if (dataFromChild !== undefined && dataFromChild !== null) {
            let timeSelected = dataFromChild.time;
            let utcSeconds = moment(timeSelected, 'HH:mm').diff(moment().startOf('day'), 'seconds');
            let daysString = this.getSelectedDaysString(dataFromChild);
            let entryId = dataFromChild.createdEntryId;
            if (dataFromChild.editedRowId !== undefined && dataFromChild.editedRowId !== null && dataFromChild.editedRowId !== 0) {
                entryId = dataFromChild.editedRowId;
            };

            let data = { id: entryId, daysOfWeek: daysString, utcSecondsOfDay: utcSeconds, modeType: dataFromChild.modeType, mode: dataFromChild.modeTypeText, time: timeSelected };

            if (dataFromChild.editedRow.id !== undefined) {
                var val = dataFromChild.editedRow.id;
                var index = this.state.scheduleList.findIndex(function (item, i) {
                    return item.id === val
                });
                //data.id = dataFromChild.editedRow.id;
                this.state.scheduleList.splice(index, 1);
                this.state.scheduleList.splice(index, 0, data);
            } else {
                let allSchedule = [...this.state.scheduleList, data];
                this.setState({ scheduleList: allSchedule });
            }
        }
        this.closeScheduleModal();
    }

    cellButton = (cell, row) => {
        return (
            <div className="row">
                <div title="Edit" className="action-items" onClick={() => this.onEditClicked(cell, row)}><i className="fa fa-edit action-items" aria-hidden="true"></i>Edit </div>
                <div title="Delete" className="action-items" onClick={() => this.onDeleteClicked(cell, row)}><i className="fa fa-trash action-items" aria-hidden="true"></i>Delete </div>
            </div>
        );
    };

    onEditClicked(cell, row) {
        this.setState({ setScheduleModal: true, selectedRow: row });
    }

    onDeleteClicked(cell, row) {
        this.setState({ showDeleteConfirmation: true, rowToBeDeleted: row.id });
    }

    onDeleteConfirm = () => {
        this.setState({ showLoader: true }, () => {
            this.deleteEntry();
        })
    }

    closeCloseConfirmation = () => {
        this.setState({ showCloseConfirmation: false });
    }

    onCloseConfirm = () => {
        this.setState({ showCloseConfirmation: false, setScheduleModal: false}, () => {
            //window.location.reload(false);
            this.setFormDirty(false);
        });
    }

    setFormDirty = (isDirty) => {
        this.setState({isFormDirty: isDirty})
    }

    deleteEntry = async () => {
        let scheduleId = this.state.createdScheduleId;
        if (this.state.editedScheduleId !== undefined && this.state.editedScheduleId !== null && this.state.editedScheduleId !== 0) {
            scheduleId = this.state.editedScheduleId;
        }
        let entryId = this.state.rowToBeDeleted;
        let response = await deleteScheduleEntry(scheduleId, entryId);
        if (response !== undefined && response !== null && response.headers !== undefined && response.headers !== null) {
            var filtered = this.state.scheduleList.filter(function (item) {
                return item.id !== entryId;
            });
            this.setState({ scheduleList: filtered, showDeleteConfirmation: false, showLoader: false }, () => {
                toast.success(Messages.DeleteScheduleEntrySuccess);
            });
        }
    }

    getSelectedDaysString = (data) => {
        let dayString = "";

        if (data.isMonChecked) {
            dayString = dayString + "Mo ";
        }
        if (data.isTueChecked) {
            dayString = dayString + "Tu ";
        }
        if (data.isWedChecked) {
            dayString = dayString + "We ";
        }
        if (data.isThuChecked) {
            dayString = dayString + "Th ";
        }
        if (data.isFriChecked) {
            dayString = dayString + "Fr ";
        }
        if (data.isSatChecked) {
            dayString = dayString + "Sa ";
        }
        if (data.isSunChecked) {
            dayString = dayString + "Su ";
        }

        return dayString.substr(0, (dayString.length - 1));
    }

    closeScheduleModal = () => {
        this.setState({ showCloseConfirmation: this.state.isFormDirty });

        if (!this.state.isFormDirty)
            this.setState({setScheduleModal: false});
    }

    openScheduleModal = () => {
        if (this.handleNameValidation()) {
            if (this.state.scheduleList.length < 8) {
                if (this.state.createdScheduleId !== 0) {
                    this.setState({ setScheduleModal: true, selectedRow: [] });
                } else {
                    if (this.state.editedScheduleId === undefined || this.state.editedScheduleId === null || this.state.editedScheduleId === 0) {
                        this.setState({ showLoader: true }, () => {
                            this.createSchedule().then(() => {
                                this.setState({ setScheduleModal: true, selectedRow: [] });
                            });
                        });
                    } else {
                        this.setState({ setScheduleModal: true, selectedRow: [] });
                    }
                }
            } else {
                let errors = [];
                errors["extraRecords"] = Messages.ScheduleExtraRecordsText;
                this.setState({ errors: errors });
            }
        }
    }

    handleNameValidation = () => {
        let isValid = true;
        let errors = [];
        let state = this.state;

        if (!state["scheduleName"]) {
            errors["scheduleName"] = Messages.ScheduleNameRequiredText;
            isValid = false;
        }

        this.setState({ errors: errors });
        return isValid;
    }

    handleScheduleNameChanged = (e) => {
        const {isFormDirty} = this.props;
        this.setState({ scheduleName: e.target.value })
        isFormDirty(true);
    }

    onSaveScheduleClicked = () => {
        if (!this.handleNameValidation()) {
            return;
        }
        if (this.state.editedScheduleId === undefined || this.state.editedScheduleId === null || this.state.editedScheduleId === 0) {
            if (this.state.createdScheduleId === 0) {
                this.setState({ showLoader: true }, () => {
                    this.createSchedule();
                    this.setFormDirty(false);
                });
            }
        }
        //this.props.onConfirmClicked();
    }

    onUpdateScheduleClicked = () => {
        if (!this.handleNameValidation()) {
            return;
        }
        if (this.state.editedScheduleId !== undefined || this.state.editedScheduleId !== null || this.state.editedScheduleId !== 0) {
            this.setState({showLoader : true}, () => {
                this.updateSchedule();
                this.setFormDirty(false);
            });
        }
    }

    createSchedule = async () => {
        let data = await createScheduleInDb(this.state.scheduleName); //api call for creating a single schedule entry in database
        if (data !== undefined && data !== null && data.headers !== undefined && data.headers !== null) {
            this.props.createSchedule();
            let location = data.headers.get('location');
            this.setState({ createdScheduleId: location.split('/')[3], showLoader: false }, () => {
                toast.success(Messages.CreateScheduleSuccessText);

            });
        }
    }

    updateSchedule = async () => {
        let scheduleToUpdate = {
            scheduleName: this.state.scheduleName,
            scheduleId: this.state.editedScheduleId
        }
        let data = await updateScheduleInDb(scheduleToUpdate); //api call for creating a single schedule entry in database
        if (data) {
            this.props.UpdateSchedule();
            toast.success(Messages.UpdateScheduleSuccessText);
            setTimeout(() => {
                this.setState({showLoader:false})
                //window.location.reload(false);
            }, 2000);
        }
    }

    checkForSpace = (e) => {
        let errors = {};
        let formIsValid = true;
        e = e || window.event;
        if (e.charCode == 32 && e.target.value.length === 0) {
            errors["scheduleName"] = Messages.FirstCharacterAsSpaceErrorText;
            formIsValid = false;
        }
        if (!formIsValid) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    closeDeleteConfirmation = () => {
        this.setState({ showDeleteConfirmation: false });
    }

    render() {
        let createUpdateBtn = <Button text="CREATE" buttonClickCallback={this.onSaveScheduleClicked} buttonTextStyle="application-button" btnClassName="" />;

        let title = "Create Schedule";
        if (this.props.selectedScheduleId !== undefined && this.props.selectedScheduleId !== null) {
            title = "Edit Schedule";
            createUpdateBtn = <Button text="UPDATE" buttonClickCallback={this.onUpdateScheduleClicked} buttonTextStyle="application-button" btnClassName="" />;
        }

        const dataColumns = [{
            dataField: 'id',
            text: 'ID',
            hidden: true,
            sort: true,
        },
        {
            dataField: 'modeType',
            text: 'Mode Type',
            hidden: true,
            sort: true,
        }, {
            dataField: 'daysOfWeek',
            text: 'DAYS OF WEEK',
            sort: true
        }, {
            dataField: 'time',
            text: 'TIME',
            sort: true
        }, {
            dataField: 'utcSecondsOfDay',
            text: 'UTC SECONDS OF DAY',
            sort: true
        }, {
            dataField: 'mode',
            text: 'MODE',
            sort: true
        }, {
            dataField: 'button',
            text: 'ACTIONS',
            formatter: this.cellButton
        }];
        return (
            <div>
                <h4>{title}</h4>
                <div className="create-schedule-parent-div">
                    <div>
                        <div className="create-schedule-name-div">
                            <div>
                                <div>Schedule Name <span className="important-fields">*</span></div>
                            </div>
                            <div>
                                <div><input value={this.state.scheduleName} className="create-schedule-name-input" onChange={this.handleScheduleNameChanged} type="text" maxLength="100" onKeyPress={(e) => this.checkForSpace(e)}></input></div>
                                <div className="application-error-messages">{this.state.errors["scheduleName"]}</div>
                            </div>
                        </div>
                        <div className="create-schedule-save-btn-div">
                            {createUpdateBtn}
                        </div>
                        <div className="create-schedule-listing-table">
                            <div className="application-error-messages center-aligned">{this.state.errors["extraRecords"]}</div>
                            <div className="row create-schedule-table-header-div">
                                <div className="col-sm-6 create-schedule-table-header-title">
                                    Entries
                                </div>
                                <div className="col-sm-6">
                                    <button title="Add Entry" className="group-name-link create-schedule-table-header-link" onClick={this.openScheduleModal}>Add Entry</button>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <BootstrapTable wrapperClasses="table-common table-create-schedule-mob" keyField='id' data={this.state.scheduleList} columns={dataColumns} />
                                {this.state.scheduleList.length === 0 &&
                                    <div className="create-schedule-no-record">No records found.</div>
                                }
                            </div>
                            <div className="application-error-messages center-aligned">{this.state.errors["noRecords"]}</div>
                        </div>

                        <div id="setScheduleModalDiv">
                            <Modal classNames={{ modal: "set-schedule-modal" }} open={this.state.setScheduleModal} onClose={this.closeScheduleModal} center closeOnOverlayClick={false}>
                                <div>
                                    <SetSchedule editedScheduleId={this.state.editedScheduleId} createdScheduleId={this.state.createdScheduleId} handlerFromParant={this.handleDataFromSetSchedule} dataFromParent={this.state.selectedRow} isFormDirty={this.setFormDirty} isDirty={this.state.isFormDirty} />
                                </div>
                            </Modal>
                        </div>
                        {
                            this.state.showCloseConfirmation &&
                            (
                                <Confirmation confirmationText={Messages.ScheduleCloseConfirmation} confirmButtonText="Confirm" rejectButtonText="Cancel" openConfirmation={true} closeConfirmationModal={this.closeCloseConfirmation} onConfirmClicked={this.onCloseConfirm}></Confirmation>
                            )
                        }
                        {
                            this.state.showDeleteConfirmation &&
                            (
                                <Confirmation confirmationText={Messages.DeleteScheduleEntry} confirmButtonText="Confirm" rejectButtonText="Cancel" openConfirmation={true} closeConfirmationModal={this.closeDeleteConfirmation} onConfirmClicked={this.onDeleteConfirm} />
                            )
                        }
                    </div>
                </div>
                {
                    this.state.showLoader &&
                    (
                        <div className="overlay">
                            <div className="application-loader">
                                <AppLoader />
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    sensors: state.sensors,
    map: state.map,
    user: state.user
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CreateSchedule);
