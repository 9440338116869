export function get2xBgStyle(id, sourceId, minZoom, maxZoom) {

  return {
    id: id,
    type: 'fill',
    source: sourceId,
    paint: {
      'fill-color': '#2584ff',
      'fill-opacity': 0.2
    },
    minzoom: minZoom,
    maxzoom: maxZoom
  };
}

export function get2xSelectedBgStyle(id, sourceId, minZoom, maxZoom) {
  return {
    id: id,
    type: 'fill',
    source: sourceId,
    paint: {
      'fill-color': '#2584ff',
      'fill-opacity': 0.6
    },
    minzoom: minZoom,
    maxzoom: maxZoom,
    filter: ['==', 'selected', true]
  };
}

export function get2xOutlineStyle(id, sourceId, minZoom, maxZoom) {
  return {
    id: id,
    type: 'line',
    source: sourceId,
    paint: {
      'line-width': 2,
      'line-color': '#2584ff'
    },
    minzoom: minZoom,
    maxzoom: maxZoom
  };
}



