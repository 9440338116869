import React, { Component } from 'react';
import { css } from 'emotion';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { checkLoginTimeout, clearLoginTimeout } from '../../modules/user.js';
import Toggle from '../toggle/toggle.js';
import Tabs from '../tabs/tabs.js';
import { tabs } from '../constants/tabs';
import inputCheckedSVG from '../../assets/input_checked.svg';
import InventoryTable from '../inventory/inventory-table';
import RowResizer from '../inventory/row-resizer';
import ColumnEditor from '../inventory/column-editor';
import { showColumns, getDeviceListFromDb,  } from '../../modules/inventory.js';
import AppLoader from '../Loader/loader';
import {getSiteSuggestionListFromDb} from "../../modules/inventory";
import CreateWorkOrder from "../work-order/create-work-order";
class Inventory extends Component {
  constructor(props) {
    super(props);
    let lampStates = [];
    Object.values(props.map.lights).forEach(() =>
      lampStates.push(false)
    );
    this.state = {
      map: props.map,
      selectAll: false,
      toggleLampState: lampStates,
      shown: false,
      tableData: [],
      showLoader: true,
      armLength: [],
      conditions: [],
      fixtureModels: [],
      fixtureTypes: [],
      lampTypes: [],
      lightingAreas: [],
      poleTypes: [],
      powerFeedTypes: [],
      isFormDirty: false
    };
  }

  componentDidMount() {
    if (this.state.map.lights !== undefined && this.state.map.lights !== null && this.state.map.lights.length > 0) {
      for (var key in this.state.map.lights) {    //for changing data coming from database to normal data array for binding in table.
        if (this.state.map.lights.hasOwnProperty(key)) {
          this.state.tableData.push(this.state.map.lights[key]);
        }
      }
    } else {
      this.getDevicesList();
    }
  }

  getDevicesList = async () => {
    let data = await getDeviceListFromDb(); //api call for getting the device list from database
    if (data !== undefined && data !== null) {
      data = data.filter(l => {
        if (l.device_type !== undefined) {
          return l.device_type.toLowerCase() === "fybrlyte";
        }
      });
      this.setState({ tableData: data, showLoader: false });
    }
  }

  setFormDirty = (isDirty) => {
    this.setState({isFormDirty: isDirty})
  }

  getSiteValuesList = async (mac_address) => {
    let data = await getSiteSuggestionListFromDb(mac_address); //api call for getting the device list from database
    if (data !== undefined && data !== null) {
      return data;
    }
  }

  componentWillMount() {
    this.props.checkLoginTimeout(this.props.user);
  }

  showMenuOnMobile = () => {
    this.setState({ shown: !this.state.shown });
  }

  componentWillUnmount() {
    this.props.clearLoginTimeout(this.props.user);
  }

  onRowSizeChanged = (type) => {
    this.setState({
      rowType: type
    });
  }

  onEditColumns = (id, show) => {
    this.props.showColumns(id, show);
  }

  selectAll = (e) => {
    let state = this.state;
    for (let key in state.map.lights) {
      state.map.lights[key].selected = e.target.checked;
    }
    this.setState({
      selectAll: e.target.checked,
      map: state.map
    });
  }

  onToggle = (e, index) => {
    let { toggleLampState } = this.state;
    toggleLampState[index] = e
    this.setState({
      toggleLampState: toggleLampState
    });
  };

  deviceChecked = (deviceId, e) => {
    let state = this.state;
    let lights = state.map.lights;
    lights[deviceId].selected = e.target.checked
    this.setState(state);
  }

  onAddAndUpdate=()=>{
    this.getDevicesList();
  }

  getSiteValuesList = async (site_name) => {
    let data = await getSiteSuggestionListFromDb(site_name); //api call for getting the device list from database
    if (data !== undefined && data !== null) {
      return data;
    }
  }

  renderDevices = (sensors) => {
    let deviceChecked = this.deviceChecked;
    let toggleLampState = this.state.toggleLampState;
    let onToggle = this.onToggle;
    let jsx = Object.values(sensors).map(function (light, index) {
      return <div key={light.id} className={rowStyle}>
        <div className={itemStyle + ' backgroundStyle'}>
          <div className={checkbox}>
            <input id={"checkboxInput" + light.id} type="checkbox"
              onChange={deviceChecked.bind(this, light.id)}
              checked={light.selected}
              value={" "}>
            </input>
            <label htmlFor={"checkboxInput" + light.id}></label>
          </div>
        </div>
        <div className={itemStyle + ' backgroundStyle'}><Toggle
          onChange={e => {
            onToggle(e, index);
          }}
          active={toggleLampState[index]}
        /></div>
        <div className={itemStyle + ' backgroundStyle'}>{light.mac}</div>
        <div className={itemStyle + ' backgroundStyle'}>Light Sensor</div>
        <div className={itemStyle + ' backgroundStyle'}>{light.install_date}</div>
        <div className={itemStyle + ' backgroundStyle'}>{moment(light.install_date).add('25', 'hours').format('YYYY-MM-DD HH:mm:ss')}</div>
        <div className={itemStyle + ' backgroundStyle'}>{light.sent_messages}</div>
        <div className={itemStyle + ' backgroundStyle'}>{light.site_name}</div>
        <div className={itemStyle + ' backgroundStyle'}>{light.site_id}</div>
        <div className={itemStyle + ' backgroundStyle'}>{light.status}</div>
        <div className={itemStyle + ' backgroundStyle'}><div className={editButtonStyle}>EDIT</div></div>
      </div>
    });
    return jsx;
  }

  render() {
    const { columns } = this.props.inventory;
    return (
      <div className="customTab">
        <div className="desktopViewForTabs">
          <Tabs tabs={tabs} activeTab="inventory" />
        </div>
        <div className="mobileViewForTabs">
          <div onClick={() => this.showMenuOnMobile()}>
            <i className="fa fa-bars mobile-menu-icon" aria-hidden="true"></i>
          </div>
          <div className={this.state.shown ? "show" : "hide"}>
            <Tabs tabs={tabs} activeTab="inventory" />
          </div>
        </div>

        <div className={container}>
          <div className="toprow">
            <div className={lhs}>
              <ColumnEditor onEdit={this.onEditColumns} columns={columns} />
            </div>
            <div className="midbox"></div>
            <div className={rhs}>

            </div>
          </div>

          <div className={row}>
            <div className="table-responsive">
              <InventoryTable
                columns={columns.filter(col => col.isRequired)}
                data={this.state.tableData}
                rowType={this.state.rowType}
                onUpdate={this.onAddAndUpdate}
                siteSuggestion={this.getSiteValuesList}
                isFormDirty={this.setFormDirty}
                isDirty={this.state.isFormDirty}
              />
            </div>
          </div>
          <div className={rowResizerStyle}>
            <RowResizer onRowSizeChanged={this.onRowSizeChanged} />
          </div>
        </div>
        {
          this.state.showLoader &&
          (
            <div className="overlay">
              <div className="application-loader">
                <AppLoader />
              </div>
            </div>
          )
        }
      </div>);
  }
}

const mapStateToProps = state => ({
  sensors: state.sensors,
  map: state.map,
  user: state.user,
  inventory: state.inventory
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      checkLoginTimeout,
      clearLoginTimeout,
      showColumns
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Inventory);

const lhs = css({
  alignSelf: 'center',
});

const row = css({
  display: 'flex',
});

const rowResizerStyle = css({
  display: 'flex',
  marginLeft: '5px'
});

const rhs = css({});

const btnStyle = css({
  fontFamily: 'OpenSans',
  fontSize: '13px',
  fontWeight: 'bold',
  fontStyle: 'normal',
  fontStretch: 'normal',
  letterSpacing: '1px'
});

const itemStyle = css({
  display: 'flex',
  alignItems: 'center',
  padding: '10px 0',
  height: '63px',
  boxSizing: 'border-box',
});

const rowStyle = css({
  display: 'contents',
  '&:nth-child(2n+1)': {
    '& .backgroundStyle': {
      backgroundColor: '#fafbfc',
    }
  }
});

const container = css({
  display: 'flex',
  flexDirection: 'column',
  padding: '10px'
});

const editButtonStyle = css({
  borderRadius: '2px',
  border: 'solid 1px #dfe1e5',
  fontFamily: 'OpenSans',
  fontSize: '14px',
  fontWeight: 600,
  color: '#5e6c84',
  textAlign: 'center',
  lineHeight: 1.5,
  marginLeft: '5px',
  padding: '10px',
  width: '50px',
});

const checkbox = css({
  display: 'inline-block',
  width: '12px',
  height: '12px',
  position: 'relative',
  '& input[type=checkbox]': {
    visibility: 'hidden',
  },
  '& label': {
    display: 'inline-block',
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '14px',
    height: '14px',
    boxSizing: 'border-box',
    marginLeft: '15px',
    marginTop: '3px',
    border: 'solid 1px #c1c7d0',
    borderRadius: '2px',
  },
  '& input[type=checkbox]:checked + label': {
    content: `url(${inputCheckedSVG})`,
    transition: 'all .5s ease',
    border: 0,
  }
});
