import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { checkLoginTimeout, clearLoginTimeout } from '../../modules/user.js';
import { css } from 'emotion';
import Select from "react-select";
import { STATUS, PRIORITY, createWorkOrderInDb, getWorkOrderDetailsFromDb, updateWorkOrderInDb } from '../../modules/work-order.js';
import CalendarField from '../Calender/calendar-field.js';
import Button from '../button/button.js';
import Messages from '../constants/messages.js';
import { toast } from 'react-toastify';
import AppLoader from '../Loader/loader';

class CreateWorkOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            workOrderName: '',
            selectedStatus: '',
            selectedPriority: '',
            selectedAssignedDate: '',
            assignedTo: '',
            comment: '',
            errors: [],
            showLoader: false,
            editedWorkOrderId: 0
        };
    }

    componentDidMount() {
        if (this.props.editedWorkOrderId !== undefined && this.props.editedWorkOrderId !== null) {
            this.setState({ editedWorkOrderId: this.props.editedWorkOrderId, showLoader: true }, () => {
                this.getWorkOrderDetailsById();
            });
        }
    }

    getWorkOrderDetailsById = async () => {
        if (this.state.editedWorkOrderId !== undefined && this.state.editedWorkOrderId !== null && this.state.editedWorkOrderId !== 0) {
            let response = await getWorkOrderDetailsFromDb(this.state.editedWorkOrderId);
            if (response !== undefined && response !== null) {
                let assignDate = new Date(response.date_assigned);
                assignDate = assignDate.toLocaleDateString('en-GB').trim();;
                assignDate = assignDate.split('/')[2] + '-' + assignDate.split('/')[1] + '-' + assignDate.split('/')[0];
                this.setState({ assignedTo: response.assigned_to, comment: response.comments, workOrderName: response.work_order_name, selectedPriority: { key: response.priority, label: PRIORITY[response.priority] }, selectedStatus: { key: response.status, label: STATUS[response.status] }, selectedAssignedDate: assignDate, showLoader: false })
            }
        }
    }

    handleWorkOrderNameChanged = (e) => {
        const {isFormDirty} = this.props;
        this.setState({ workOrderName: e.target.value, errors: [] });
        isFormDirty(true);
    }

    onStatusSelected = (status) => {
        const {isFormDirty} = this.props;
        this.setState({ selectedStatus: { key: status.key, label: status.label }, errors: [] });
        isFormDirty(true);
    }

    onPrioritySelected = (priority) => {
        const {isFormDirty} = this.props;
        this.setState({ selectedPriority: { key: priority.key, label: priority.label }, errors: [] });
        isFormDirty(true);
    }

    selectAssignedDate = (val) => {
        const {isFormDirty} = this.props;
        this.setState({ selectedAssignedDate: val, errors: [] });
        isFormDirty(true);
    }

    handleAssignedToChanged = (e) => {
        const {isFormDirty} = this.props;
        this.setState({
            assignedTo: e.target.value,
            errors: []
        });
        isFormDirty(true);
    }

    onCreateClick = () => {
        const {isFormDirty} = this.props;
        if (this.handleValidation()) {
            this.setState({ showLoader: true }, () => {
                this.CreateWorkOrder();
                isFormDirty(false);
            })
        }
    }

    onUpdateClick = () => {
        const {isFormDirty} = this.props;
        if (this.handleValidation()) {
            this.setState({ showLoader: true }, () => {
                this.UpdateWorkOrder();
                isFormDirty(false);
            })
        }
    }

    CreateWorkOrder = async () => {
        //api call for updating work order entry in database
        let { workOrderName, selectedStatus, selectedPriority, selectedAssignedDate, assignedTo, comment } = this.state;

        let orderToUpdate = {
            workOrderName: workOrderName,
            status: selectedStatus.key,
            priority: selectedPriority.key,
            assignedDate: selectedAssignedDate,
            assignedTo: assignedTo,
            comment: comment,
        };

        let response = await createWorkOrderInDb(orderToUpdate);

        if (response !== undefined && response !== null) {
            this.props.onAddWork();
            toast.success(Messages.CreateWorkOrderSuccessText);
        }
    }

    UpdateWorkOrder = async () => {
        //api call for creating work order entry in data base
        let { editedWorkOrderId, workOrderName, selectedStatus, selectedPriority, selectedAssignedDate, assignedTo, comment } = this.state;
        let orderToUpdate = {
            id: editedWorkOrderId,
            workOrderName: workOrderName,
            status: selectedStatus.key,
            priority: selectedPriority.key,
            assignedDate: selectedAssignedDate,
            assignedTo: assignedTo,
            comment: comment
        };

        let response = await updateWorkOrderInDb(orderToUpdate);
        if (response !== undefined && response !== null) {
            this.props.onUpdate();
            toast.success(Messages.UpdateWorkOrderSuccessText);
            setTimeout(() => {
                this.props.closeModal();
                this.setState({ showLoader: false });
            }, 2000);
        }
    }

    handleValidation = () => {
        let isValid = true;
        let errors = [];
        let state = this.state;

        if (!state["workOrderName"]) {
            errors["workOrderName"] = Messages.WorkOrderNameRequiredText;
            isValid = false;
        }
        if (!state["selectedStatus"]) {
            errors["selectedStatus"] = Messages.WorkOrderStatusRequiredText;;
            isValid = false;
        }
        if (!state["selectedPriority"]) {
            errors["selectedPriority"] = Messages.WorkOrderPriorityRequiredText;
            isValid = false;
        }
        if (!state["selectedAssignedDate"]) {
            errors["selectedAssignedDate"] = Messages.WorkOrderAssignDateRequiredText;
            isValid = false;
        }
        if (!state["assignedTo"]) {
            errors["assignedTo"] = Messages.WorkOrderAssignToRequiredText;
            isValid = false;
        }
        this.setState({ errors: errors });
        return isValid;
    }

    checkForSpace = (e) => {
        let errors = {};
        let formIsValid = true;
        e = e || window.event;
        if (e.charCode == 32 && e.target.value.length === 0) {
            errors["generalErrors"] = Messages.FirstCharacterAsSpaceErrorText;
            formIsValid = false;
        }
        if (!formIsValid) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    render() {
        const {isFormDirty} = this.props;
        const objectToArray = item => {
            return Object.keys(item).map(key => {
                return { key: key, label: item[key] };
            });
        };
        const statusOptions = objectToArray(STATUS);
        const priorityOptions = objectToArray(PRIORITY);

        let createUpdateBtn = <Button buttonTextStyle={btnStyle} text="CREATE" buttonClickCallback={this.onCreateClick} />;

        let title = "Create Work Order";
        if (this.props.editedWorkOrderId !== undefined && this.props.editedWorkOrderId !== null) {
            title = "Edit Work Order";
            createUpdateBtn = <Button buttonTextStyle={btnStyle} text="UPDATE" buttonClickCallback={this.onUpdateClick} />;
        }

        return (
            <div>
                <h4 className="class-bold">{title}</h4>
                <div className={table}>
                    <div className={wrapper}>
                        <div className={item}>
                            <div className="class-bold">Name <span className="important-fields">*</span></div>
                            <input className="text-box" value={this.state.workOrderName} onChange={this.handleWorkOrderNameChanged} type="text" maxLength="100" onKeyPress={(e) => this.checkForSpace(e)}></input>
                            <div className="application-error-messages">{this.state.errors["workOrderName"]}</div>
                        </div>
                        <div className={item}>
                            <div className="class-bold">Status <span className="important-fields">*</span></div>
                            <div className="dropdown-select">
                                <Select value={this.state.selectedStatus} onChange={this.onStatusSelected} options={statusOptions}></Select>
                            </div>
                            <div className="application-error-messages">{this.state.errors["selectedStatus"]}</div>
                        </div>
                        <div className={item}>
                            <div className="class-bold">Priority <span className="important-fields">*</span></div>
                            <div className="dropdown-select">
                                <Select
                                    value={this.state.selectedPriority}
                                    onChange={this.onPrioritySelected}
                                    options={priorityOptions}></Select>
                            </div>
                            <div className="application-error-messages">{this.state.errors["selectedPriority"]}</div>
                        </div>
                        <div className={item}>
                            <div className="class-bold">Assign Date <span className="important-fields">*</span></div>
                            <div>
                                <CalendarField val={this.state.selectedAssignedDate} onDateSelect={this.selectAssignedDate}></CalendarField>
                            </div>
                            <div className="application-error-messages">{this.state.errors["selectedAssignedDate"]}</div>
                        </div>
                        <div className={item}>
                            <div className="class-bold">Assign To <span className="important-fields">*</span></div>
                            <input className="text-box" value={this.state.assignedTo} onChange={this.handleAssignedToChanged} type="text" maxLength="100" onKeyPress={(e) => this.checkForSpace(e)}></input>
                            <div className="application-error-messages">{this.state.errors["assignedTo"]}</div>
                        </div>
                        <div className={rightItem}>
                            <div className="class-bold">Comments </div>
                            <textarea className={textAreaStyle} value={this.state.comment} onChange={(e) => { isFormDirty(true); this.setState({ comment: e.target.value }) }} placeholder={'Add Comments...'} onKeyPress={(e) => this.checkForSpace(e)} />
                        </div>
                    </div>
                </div>
                <div className="application-error-messages error-messages-center">{this.state.errors["generalErrors"]}</div>
                <div className={footer}>
                    <div className={cancelButtonContainer} onClick={this.props.onClose}>
                        <div className={cancelButton}>
                            <div>CANCEL</div>
                        </div>
                    </div>
                    <div>
                        {createUpdateBtn}
                    </div>
                </div>
                {
                    this.state.showLoader &&
                    (
                        <div className="overlay">
                            <div className="application-loader">
                                <AppLoader />
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
}

const btnStyle = css({
    fontFamily: 'OpenSans',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: '1px'
});

const footer = css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '70px'
});

const table = css({
});

const wrapper = css({
    display: 'grid',
    gridTemplateColumns: '300px 300px',
    gridTemplateRows: '80px 80px 80px 80px',
    gridGap: '10px',
});

const item = css({
    padding: '20px 20px 5px 20px'
});

const rightItem = css({
    gridColumn: '2 / 2',
    gridRow: '2 / 5',
    padding: '20px 20px 20px 20px'
});

const textAreaStyle = css({
    height: '100%',
    width: '96%',
    borderRadius: '2px',
    resize: 'none',
    backgroundColor: '#ffffff',
    border: 'solid 1px #dbdbdb',
    '&:focus': {
        outline: 'none',
        border: '1.5px solid #0151cb'
    },
    boxSizing: 'border-box',
    padding: '10px'
});

const cancelButton = css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderRadius: '2px',
    backgroundColor: '#FFFFFF',
    width: '125px',
    height: '100%',
    fontFamily: 'OpenSans',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: '1px',
    color: '#5e6c84',
    border: '1px solid #C4CDD5',
    '&:hover': {
        backgroundColor: '#F4F5F7',
        cursor: 'pointer'
    },
});

const cancelButtonContainer = css({
    display: 'flex',
    flexGrow: '0.1'
});

const mapStateToProps = state => ({
    workorder: state.workorder,
    user: state.user
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            checkLoginTimeout,
            clearLoginTimeout
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CreateWorkOrder);
