import 'isomorphic-fetch';
import { Cookies } from 'react-cookie';
import {getApiToken} from "../../utils/user";
const cookies = new Cookies();

//for getting the list of schedules from database
export async function getScheduleList(url) {
  let response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    }
  });
  let status = response.status;
  let data = status >= 400 ? null : await response.json();
  return data;
}

//for deleting the schedule details from schedule id
export async function deleteScheduleData(url) {
  let response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    }
  });
  let status = response.status;
  let data = status >= 400 ? null : await response;
  return data;
}

//for creating a schedule entry in database
export async function createSchedule(url, scheduleName) {
  let response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    },
    body: JSON.stringify({
      schedule_name: scheduleName
    })
  });
  let status = response.status;
  let data = status >= 400 ? null : await response;
  return data;
}

//for creating a schedule entry in database
export async function createEntry(url, entryForSchedule) {
  let response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    },
    body: JSON.stringify({
      schedule_id : entryForSchedule.scheduleId,
      day_of_week_mask : entryForSchedule.selectedDays,
      second_of_day : entryForSchedule.utcSeconds,
      mode : entryForSchedule.modeType
    })
  });
  let status = response.status;
  let data = status >= 400 ? null : await response;
  return data;
}

//for creating a schedule entry in database
export async function deleteEntry(url) {
  let response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    }
  });
  let status = response.status;
  let data = status >= 400 ? null : await response;
  return data;
}

//for updating a schedule in database
export async function updateSchedule(url, scheduleToUpdate) {
  let response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    },
    body: JSON.stringify({
      "schedule_name": scheduleToUpdate.scheduleName
    })
  });
  let status = response.status;
  let data = status >= 400 ? null : await response;
  return data;
}

//for getting the schedule details from schedule id
export async function getScheduleData(url) {
  let response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    }
  });
  let status = response.status;
  let data = status >= 400 ? null : await response.json();
  return data;
}

//for getting the schedule entry details from schedule id
export async function getScheduleEntriesData(url) {
  let response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    }
  });
  let status = response.status;
  let data = status >= 400 ? null : await response.json();
  return data;
}

//for creating a schedule entry in database
export async function updateEntry(url, updatedEntryForSchedule) {
  let response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    },
    body: JSON.stringify({
      schedule_entry_id : updatedEntryForSchedule.entryId,
      schedule_id : updatedEntryForSchedule.scheduleId,
      day_of_week_mask : updatedEntryForSchedule.selectedDays,
      second_of_day : updatedEntryForSchedule.utcSeconds,
      mode : updatedEntryForSchedule.modeType
    })
  });
  let status = response.status;
  let data = status >= 400 ? null : await response;
  return data;
}
