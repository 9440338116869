import flatten from 'lodash.flatten';
import { REHYDRATE } from 'redux-persist/constants';
import { DEFAULT_ZOOM, DEFAULT_CENTER, ZOOM_LEVELS, LAYERS, LAYER_0X, LAYER_1X, LAYER_2X, LAYER_3X } from '../components/constants/map.js';
import { CHECK_USER_TIMEOUT } from './user';
import {LIGHT_API, API_ROOT, GROUP_API, SITES_API} from '../components/constants/api';
import { getMacDevices, GpsUpdate, ModeUpdate, ThresholdAndLevelUpdate, getDistricts, getBlocks } from '../components/api/LightReading';
import {isJsonString} from "../utils/utils";
import {getGroupList} from "../components/api/group";

export const DISMISS_INFOBOX = 'DISMISS_INFOBOX';
export const ZOOM_IN = 'ZOOM_IN';
export const ZOOM_SLIDER = 'ZOOM_SLIDER';
export const UPDATE_LAYER = 'UPDATE_LAYER';
export const ZOOM_OUT = 'ZOOM_OUT';
export const SELECTED_ALL_DISTRICTS = 'SELECTED_ALL_DISTRICTS';
export const SELECTED_DISTRICTS = 'SELECTED_DISTRICTS';
export const SELECTED_BLOCKS = 'SELECTED_BLOCKS';
export const SELECTED_LIGHTS = 'SELECTED_LIGHTS';
export const ADJUSTED_ZOOM = 'ADJUSTED_ZOOM';
export const ON_ZOOM_END = 'ON_ZOOM_END';
export const UPDATE_SITE_LIST = 'UPDATE_SITE_LIST';
export const UPDATE_BLOCK_LIST = 'UPDATE_BLOCK_LIST';
export const BRIGHTNESS_UPDATE = 'BRIGHTNESS_UPDATE';
export const SWITCH_UPDATE = 'SWITCH_UPDATE';
export const APPLY_CHANGES = 'APPLY_CHANGES';
export const SET_LIGHT_DATA = 'SET_LIGHT_DATA'; //Added on 23rd sept 2019
export const SET_LIGHT_GPS = 'SET_LIGHT_GPS';
export const SET_LIGHT_MODE = 'SET_LIGHT_MODE';
export const SET_LIGHT_THRESHOLD_LEVEL = 'SET_LIGHT_THRESHOLD_LEVEL';
export const TOGGLE_DEVICE_SITE_DISPLAY = 'TOGGLE_DEVICE_SITE_DISPLAY';

export const MODETYPE = {
  0: 'AUTO',
  1: 'MODE 1',
  2: 'MODE 2',
  3: 'OFF',
  4: 'FLASH'
};

export const MODELEVELS = {
  400: '10 %',
  1000: '25 %',
  2000: '50 %',
  3000: '75 %',
  4000: '100 %'
}

export const DELIVERY = {
  true: 'Immediate',
  false: 'Queued'
}

export const DEVICETYPES = {
  'DEVICES': 'Devices',
  'SITES': 'Sites',
  'GROUPS': 'Groups'
}

const getDetails = (details, feature) => {
  details[feature.properties.id] = {
    name: feature.properties.name,
    id: feature.properties.id
  };
  return details;
};

const getBlockDetails = (details, feature) => {
  details[feature.properties.id] = {
    name: feature.properties.name,
    id: feature.properties.id,
    parent: feature.properties.parentId
  };
  return details;
};

const getLightDetails = (details, feature) => {
  details[feature.properties.mac_address] = {
    lampStatus: feature.properties['lamp-status'],
    brightness: feature.properties.brightness,
    ambient_threshold: feature.properties.ambient_threshold,
    block_id: feature.properties.block_id,
    block_name: feature.properties.block_name,
    current_amps: feature.properties.current_amps,
    district_id: feature.properties.district_id,
    frequency_hz: feature.properties.frequency_hz,
    gps_satellites: feature.properties.gps_satellites,
    gps_status: feature.properties.gps_status,
    group_id: feature.properties.group_id,
    group_mask: feature.properties.group_mask,
    installation_date: feature.properties.installation_date,
    latitude: feature.properties.latitude,
    longitude: feature.properties.longitude,
    mac: feature.properties.mac,
    mac_address: feature.properties.mac_address,
    mag_x: feature.properties.mag_x,
    mag_y: feature.properties.mag_y,
    mag_z: feature.properties.mag_z,
    mode: feature.properties.mode,
    mode_1_level: feature.properties.mode_1_level,
    mode_2_level: feature.properties.mode_2_level,
    power_watts: feature.properties.power_watts,
    reactive_power_watts: feature.properties.reactive_power_watts,
    schedule_id: feature.properties.schedule_id,
    site_id: feature.properties.site_id,
    site_name: feature.properties.site_name,
    site_status: feature.properties.site_status,
    site_details: feature.properties.site_details,
    temperature_celsius: feature.properties.temperature_celsius,
    device_id: feature.properties.device_id,
    voltage: feature.properties.voltage
  };
  return details;
};

export function getChildBlocksForDistrict(id = null, blocks) {
  if (id === null) throw new Error('District id has to be a number');
  return blocks.filter(block => {
    return parseInt(block.parent, 10) === parseInt(id, 10);
  });
}

export function getLightsForDistrict(id = null, lights, blocks, districts) {
  let childBlocks = getChildBlocksForDistrict(id, blocks);
  return flatten(
    childBlocks.map(block => {
      return getLightsForBlock(block.id, lights);
    })
  );
}

export function getLightsForBlock(id = null, lights) {
  if (id === null) throw new Error('Block id has to be a number');
  return lights.filter(lights => lights.parent === id);
}

export function getLightsForDistricts(districtIds, lights, blocks, districts) {
  return flatten(
    districtIds.map(districtId => {
      return getLightsForDistrict(districtId, lights, blocks, districts);
    })
  );
}

export function getLightsForBlocks(blockIds, lights) {
  return flatten(
    blockIds.map(blockId => {
      return getLightsForBlock(blockId, lights);
    })
  );
}

//for getting  device list for show on map.
let macDatas;
export async function getMacDeviceList() {
  let data = await getMacDevices(LIGHT_API);
  //for filtering by device type as fybrlyte

  if (data) {
    data = data.filter(l => {
      if (l.device_type !== undefined) {
        return l.device_type.toLowerCase() === "fybrlyte";
      }
    });
    let jsonData = [];
    let FeatureCollection = {};
    //Code for dynamically creating JSON object
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (data[i] !== undefined) {
          let dataObj = {};
          let mapNode = data[i];
          //geometry node
          let geometry = {};
          geometry["type"] = "Point";
          geometry["coordinates"] = [mapNode.longitude, mapNode.latitude];
          //properties node
          let properties = {};
          properties["marker-color"] = "#7e7e7e";
          properties["lamp-status"] = "on";
          properties["brightness"] = 4095;
          //new properties from api
          properties["ambient_threshold"] = mapNode.ambient_threshold;
          properties["block_id"] = mapNode.block_id;
          properties["block_name"] = mapNode.block_name;
          properties["current_amps"] = mapNode.current_amps;
          properties["district_id"] = mapNode.district_id;
          properties["frequency_hz"] = mapNode.frequency_hz;
          properties["gps_satellites"] = mapNode.gps_satellites;
          properties["gps_status"] = mapNode.gps_status;
          properties["group_id"] = mapNode.group_id;
          properties["group_mask"] = mapNode.group_mask;
          properties["installation_date"] = mapNode.installation_date;
          properties["latitude"] = mapNode.latitude;
          properties["longitude"] = mapNode.longitude;
          properties["mac"] = mapNode.mac;
          properties["mac_address"] = mapNode.mac_address;
          properties["mag_x"] = mapNode.mag_x;
          properties["mag_y"] = mapNode.mag_y;
          properties["mag_z"] = mapNode.mag_z;
          properties["mode"] = mapNode.mode;
          properties["mode_1_level"] = mapNode.mode_1_level;
          properties["mode_2_level"] = mapNode.mode_2_level;
          properties["power_watts"] = mapNode.power_watts;
          properties["reactive_power_watts"] = mapNode.reactive_power_watts;
          properties["schedule_id"] = mapNode.schedule_id;
          properties["site_id"] = mapNode.site_id;
          properties["device_id"] = mapNode.device_id;
          properties["site_name"] = mapNode.site_name;
          properties["site_status"] = mapNode.site_status;
          properties["temperature_celsius"] = mapNode.temperature_celsius;
          properties["voltage"] = mapNode.voltage;

          //final object
          dataObj = {};
          dataObj["type"] = "Feature";
          dataObj["geometry"] = geometry;
          dataObj["properties"] = properties;
          //push the object in array
          jsonData.push(dataObj);
        }
      }
      //main json object after loop complete
      FeatureCollection["type"] = "FeatureCollection";
      FeatureCollection["features"] = jsonData;
      //properties node
      macDatas = FeatureCollection;
    }
    return FeatureCollection;
  };
};

export async function getMacDeviceListByViewPort(bbox) {
  let data = await getMacDevices(LIGHT_API + '?top_latitude=' + bbox._ne.lat + '&bottom_latitude=' + bbox._sw.lat +
      '&left_longitude=' + bbox._sw.lng + '&right_longitude=' + bbox._ne.lng);
  //for filtering by device type as fybrlyte
  if (data) {
    data = data.filter(l => {
      if (l.device_type !== undefined) {
        return l.device_type.toLowerCase() === "fybrlyte";
      }
    });
    let jsonData = [];
    let FeatureCollection = {};
    //Code for dynamically creating JSON object
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (data[i] !== undefined) {
          let dataObj = {};
          let mapNode = data[i];
          //geometry node
          let geometry = {};
          geometry["type"] = "Point";
          geometry["coordinates"] = [mapNode.longitude, mapNode.latitude];
          //properties node
          let properties = {};
          properties["marker-color"] = "#7e7e7e";
          properties["lamp-status"] = "on";
          properties["brightness"] = 4095;
          //new properties from api
          properties["ambient_threshold"] = mapNode.ambient_threshold;
          properties["block_id"] = mapNode.block_id;
          properties["block_name"] = mapNode.block_name;
          properties["current_amps"] = mapNode.current_amps;
          properties["district_id"] = mapNode.district_id;
          properties["frequency_hz"] = mapNode.frequency_hz;
          properties["gps_satellites"] = mapNode.gps_satellites;
          properties["gps_status"] = mapNode.gps_status;
          properties["group_id"] = mapNode.group_id;
          properties["group_mask"] = mapNode.group_mask;
          properties["installation_date"] = mapNode.installation_date;
          properties["latitude"] = mapNode.latitude;
          properties["longitude"] = mapNode.longitude;
          properties["mac"] = mapNode.mac;
          properties["mac_address"] = mapNode.mac_address;
          properties["mag_x"] = mapNode.mag_x;
          properties["mag_y"] = mapNode.mag_y;
          properties["mag_z"] = mapNode.mag_z;
          properties["mode"] = mapNode.mode;
          properties["mode_1_level"] = mapNode.mode_1_level;
          properties["mode_2_level"] = mapNode.mode_2_level;
          properties["power_watts"] = mapNode.power_watts;
          properties["reactive_power_watts"] = mapNode.reactive_power_watts;
          properties["schedule_id"] = mapNode.schedule_id;
          properties["site_id"] = mapNode.site_id;
          properties["site_name"] = mapNode.site_name;
          properties["site_status"] = mapNode.site_status;
          properties["temperature_celsius"] = mapNode.temperature_celsius;
          properties["device_id"] = mapNode.device_id;
          properties["voltage"] = mapNode.voltage;

          //final object
          dataObj = {};
          dataObj["type"] = "Feature";
          dataObj["geometry"] = geometry;
          dataObj["properties"] = properties;
          //push the object in array
          jsonData.push(dataObj);
        }
      }
      //main json object after loop complete
      FeatureCollection["type"] = "FeatureCollection";
      FeatureCollection["features"] = jsonData;
      //properties node
      macDatas = FeatureCollection;
    }
    return FeatureCollection;
  };
};

export async function buildSiteObject(data) {
  if (data) {

    data = data.filter(l => {
      if (l.longitude !== null && l.longitude !== null) {
        return l;
      }
    });
    let jsonData = [];
    let FeatureCollection = {};
    /*data = data.filter(l => {
      if (l.device_type !== undefined) {
        return l.device_type.toLowerCase() === "fybrlyte";
      }
    });
    let jsonData = [];
    let FeatureCollection = {};*/
    //Code for dynamically creating JSON object

    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (data[i] !== undefined) {

          let dataObj = {};
          let mapNode = data[i];
          //geometry node
          let geometry = {};
          geometry["type"] = "Point";
          geometry["coordinates"] = [mapNode.longitude, mapNode.latitude];
          //properties node
          let properties = {};
          properties["marker-color"] = "#7e7e7e";
          //properties["lamp-status"] = "on";
          properties["lamp-status"] = mapNode.mode === 0 || mapNode.mode === 3 ? "OFF" : "ON";
          properties["brightness"] = 4095;
          //new properties from api
          properties["ambient_threshold"] = mapNode.ambient_threshold;
          properties["block_id"] = mapNode.block_id;
          properties["block_name"] = mapNode.block_name;
          properties["current_amps"] = mapNode.current_amps;
          properties["district_id"] = mapNode.district_id;
          properties["frequency_hz"] = mapNode.frequency_hz;
          properties["gps_satellites"] = mapNode.gps_satellites;
          properties["gps_status"] = mapNode.gps_status;
          properties["group_id"] = mapNode.group_id;
          properties["group_mask"] = mapNode.group_mask;
          properties["installation_date"] = mapNode.installation_date;
          properties["latitude"] = mapNode.latitude;
          properties["longitude"] = mapNode.longitude;
          properties["mac"] = mapNode.mac;
          properties["mac_address"] = mapNode.mac_address;
          properties["mag_x"] = mapNode.mag_x;
          properties["mag_y"] = mapNode.mag_y;
          properties["mag_z"] = mapNode.mag_z;
          properties["mode"] = mapNode.mode;
          properties["mode_1_level"] = mapNode.mode_1_level;
          properties["mode_2_level"] = mapNode.mode_2_level;
          properties["power_watts"] = mapNode.power_watts;
          properties["reactive_power_watts"] = mapNode.reactive_power_watts;
          properties["schedule_id"] = mapNode.schedule_id;
          properties["site_id"] = mapNode.site_id;
          properties["site_name"] = mapNode.site_name;
          properties["site_status"] = mapNode.site_status;
          properties["temperature_celsius"] = mapNode.temperature_celsius;
          properties["voltage"] = mapNode.voltage;
          properties["device_id"] = mapNode.device_id;
          properties["site_details"] = mapNode.site_details && isJsonString(mapNode.site_details) ? JSON.parse(mapNode.site_details) : mapNode.site_details;

          //final object
          dataObj = {};
          dataObj["type"] = "Feature";
          dataObj["geometry"] = geometry;
          dataObj["properties"] = properties;

          //push the object in array
          jsonData.push(dataObj);
        }
      }
      //main json object after loop complete
      FeatureCollection["type"] = "FeatureCollection";
      FeatureCollection["features"] = jsonData;
      //properties node

      macDatas = FeatureCollection;
    }

    return FeatureCollection;
  };
};

export async function buildGroupObject(data) {
  if (data) {

    let jsonData = [];
    let FeatureCollection = {};
    /*data = data.filter(l => {
      if (l.device_type !== undefined) {
        return l.device_type.toLowerCase() === "fybrlyte";
      }
    });
    let jsonData = [];
    let FeatureCollection = {};*/
    //Code for dynamically creating JSON object

    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (data[i] !== undefined) {

          let dataObj = {};
          let mapNode = data[i];
          //geometry node
          let geometry = {};
          geometry["type"] = "Point";
          geometry["coordinates"] = [mapNode.longitude, mapNode.latitude];
          //properties node
          let properties = {};
          properties["device_count"] = mapNode.device_count;
          properties["device_id"] = mapNode.group_id;
          properties["mac_address"] = mapNode.group_mac;
          properties["site_name"] = mapNode.group_name;
          properties["group"] = true;

          //final object
          dataObj = {};
          dataObj["type"] = "Feature";
          dataObj["geometry"] = geometry;
          dataObj["properties"] = properties;

          //push the object in array
          jsonData.push(dataObj);
        }
      }
      //main json object after loop complete
      FeatureCollection["type"] = "FeatureCollection";
      FeatureCollection["features"] = jsonData;
      //properties node

      macDatas = FeatureCollection;
    }

    return FeatureCollection;
  };
};

/*
export async function buildSiteObject(data) {
  if (data && typeof data.filter === 'function') {

    data = data.filter(l => {
      if (l.device_type !== undefined) {
        return l.device_type.toLowerCase() === "fybrlyte";
      }
    });
    let jsonData = [];
    let FeatureCollection = {};
    //Code for dynamically creating JSON object
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (data[i] !== undefined) {
          let dataObj = {};
          let mapNode = data[i];
          //geometry node
          let geometry = {};
          geometry["type"] = "Point";
          geometry["coordinates"] = [mapNode.longitude, mapNode.latitude];
          //properties node
          let properties = {};
          properties["marker-color"] = "#7e7e7e";
          properties["lamp-status"] = "on";
          properties["brightness"] = 4095;
          //new properties from api
          properties["ambient_threshold"] = mapNode.ambient_threshold;
          properties["block_id"] = mapNode.block_id;
          properties["block_name"] = mapNode.block_name;
          properties["current_amps"] = mapNode.current_amps;
          properties["district_id"] = mapNode.district_id;
          properties["frequency_hz"] = mapNode.frequency_hz;
          properties["gps_satellites"] = mapNode.gps_satellites;
          properties["gps_status"] = mapNode.gps_status;
          properties["group_id"] = mapNode.group_id;
          properties["group_mask"] = mapNode.group_mask;
          properties["installation_date"] = mapNode.installation_date;
          properties["latitude"] = mapNode.latitude;
          properties["longitude"] = mapNode.longitude;
          properties["mac"] = mapNode.mac;
          properties["mac_address"] = mapNode.mac_address;
          properties["mag_x"] = mapNode.mag_x;
          properties["mag_y"] = mapNode.mag_y;
          properties["mag_z"] = mapNode.mag_z;
          properties["mode"] = mapNode.mode;
          properties["mode_1_level"] = mapNode.mode_1_level;
          properties["mode_2_level"] = mapNode.mode_2_level;
          properties["power_watts"] = mapNode.power_watts;
          properties["reactive_power_watts"] = mapNode.reactive_power_watts;
          properties["schedule_id"] = mapNode.schedule_id;
          properties["site_id"] = mapNode.site_id;
          properties["site_name"] = mapNode.site_name;
          properties["site_status"] = mapNode.site_status;
          properties["temperature_celsius"] = mapNode.temperature_celsius;
          properties["voltage"] = mapNode.voltage;

          //final object
          dataObj = {};
          dataObj["type"] = "Feature";
          dataObj["geometry"] = geometry;
          dataObj["properties"] = properties;
          //push the object in array
          jsonData.push(dataObj);
        }
      }
      //main json object after loop complete
      FeatureCollection["type"] = "FeatureCollection";
      FeatureCollection["features"] = jsonData;
      //properties node
      macDatas = FeatureCollection;
    }
    return FeatureCollection;
  };
};
 */

//for getting  district list.
let districtDatas;
let blockDatas;
let blockList = [];
let deviceList = [];
export async function getDistrictsList() {
  const data = await getDistricts(API_ROOT + 'districts');
   if(blockList.length >0)
   {
     blockList.splice(0, blockList.length);
   }
  let jsonData = [];
  let blockData = [];
  let FeatureCollection = {};
  let blockCollection = {};
  //Code for dynamically creating JSON object

  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      if (data[i]) {
        let dataObj = {};
        let mapdistrict = data[i];
        let geojson = mapdistrict.geojson;//JSON.parse(mapdistrict.geojson);
        if(geojson != null && geojson.features && geojson.features.length > 0){
        //geometry node
          let geometry = geojson.features[0].geometry;
          //new properties from api

          let properties = geojson.features[0].properties;
          properties["name"] = mapdistrict.district_name;
          properties["id"] = mapdistrict.district_id;
          properties["site_count"] = data[i].site_count;
          //final object
          dataObj = {};
          dataObj["type"] = "Feature";
          dataObj["geometry"] = geometry;
          dataObj["properties"] = properties;
          //push the object in array
          jsonData.push(dataObj);
        }
        //let blockList = await getBlocks(API_ROOT + 'districts/' + mapdistrict.district_id + '/blocks');
        //blockData.push(blockList);
      }
    }
    /*
      for (let i = 0; i < blockData.length; i++) {
        let block = blockData[i];
        for (let i = 0; i < block.length; i++) {
          blockList.push(block[i]);
        }
      }

     */
    FeatureCollection["type"] = "FeatureCollection";
    FeatureCollection["features"] = jsonData;
    //properties node
    districtDatas = FeatureCollection.features.reduce(getDetails, {});
  }

  return FeatureCollection;
}

export async function getDistrictsListByViewPort(bbox) {
  const data = await getDistricts(API_ROOT + 'districts?top_latitude=' + bbox._ne.lat + '&bottom_latitude=' + bbox._sw.lat +
      '&left_longitude=' + bbox._sw.lng + '&right_longitude=' + bbox._ne.lng);
  if(blockList.length >0)
  {
    blockList.splice(0, blockList.length);
  }
  let jsonData = [];
  let blockData = [];
  let FeatureCollection = {};
  let blockCollection = {};
  //Code for dynamically creating JSON object

  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      if (data[i]) {
        let dataObj = {};
        let mapdistrict = data[i];
        let geojson = mapdistrict.geojson;//JSON.parse(mapdistrict.geojson);
        if(geojson != null && geojson.features && geojson.features.length > 0){
          //geometry node
          let geometry = geojson.features[0].geometry;
          //new properties from api

          let properties = geojson.features[0].properties;
          properties["name"] = mapdistrict.district_name;
          properties["id"] = mapdistrict.district_id;
          properties["site_count"] = data[i].site_count;
          //final object
          dataObj = {};
          dataObj["type"] = "Feature";
          dataObj["geometry"] = geometry;
          dataObj["properties"] = properties;
          //push the object in array
          jsonData.push(dataObj);
        }
        //let blockList = await getBlocks(API_ROOT + 'districts/' + mapdistrict.district_id + '/blocks');
        //blockData.push(blockList);
      }
    }
    /*
      for (let i = 0; i < blockData.length; i++) {
        let block = blockData[i];
        for (let i = 0; i < block.length; i++) {
          blockList.push(block[i]);
        }
      }

     */
    FeatureCollection["type"] = "FeatureCollection";
    FeatureCollection["features"] = jsonData;
    //properties node
    districtDatas = FeatureCollection.features.reduce(getDetails, {});
  }

  return FeatureCollection;
}

export async function getSelectedBlocksList(districts) {
  if(blockList.length >0)
  {
    blockList.splice(0, blockList.length);
  }
  let jsonData = [];
  let blockData = [];
  let FeatureCollection = {};
  let blockCollection = {};
  //Code for dynamically creating JSON object
  if (districts && districts.length > 0) {
    await Promise.all(districts.map(async (item) => {

      let blockList = await getBlocks(API_ROOT + 'districts/' + item.id + '/blocks');
      blockData.push(blockList);
    }));

    blockData.map((block) => {
      block.map((blockItem) => {
        blockList.push(blockItem);
      });
    });

    FeatureCollection["type"] = "FeatureCollection";
    FeatureCollection["features"] = blockList.map((item) => buildBlockObject(item));

    blockDatas = FeatureCollection.features.filter(item => item.geometry).reduce(getBlockDetails, {});
  }

  return FeatureCollection;
}

export async function getSelectedBlocksListByViewPort(bbox) {
  let jsonData = [];
  let blockData = [];
  let FeatureCollection = {};
  let blockCollection = {};
  //Code for dynamically creating JSON object
  let blockList = await getBlocks(API_ROOT + 'blocks?top_latitude=' + bbox._ne.lat + '&bottom_latitude=' + bbox._sw.lat +
      '&left_longitude=' + bbox._sw.lng + '&right_longitude=' + bbox._ne.lng);
  blockData.push(blockList);
  /*
  blockData.map((block) => {
    block.map((blockItem) => {
      blockList.push(blockItem);
    });
  });
   */

  FeatureCollection["type"] = "FeatureCollection";
  FeatureCollection["features"] = blockList.map((item) => buildBlockObject(item));

  blockDatas = FeatureCollection.features.filter(item => item.geometry).reduce(getBlockDetails, {});

  return FeatureCollection;
}

export async function getSelectedSitesByViewPort(bbox, currentDeviceCategory, excludeCoords = false) {
  let jsonData = [];
  let deviceData = [];
  let FeatureCollection = {};
  let blockCollection = {};
  let deviceList = [];
  let coordString = '?top_latitude=' + bbox._ne.lat + '&bottom_latitude=' + bbox._sw.lat +
      '&left_longitude=' + bbox._sw.lng + '&right_longitude=' + bbox._ne.lng
  //Code for dynamically creating JSON object

  if (bbox) {
    if (currentDeviceCategory === 'DEVICES') {
      deviceList = await getMacDevices(API_ROOT + 'devices' + (excludeCoords ? '' : coordString));
      FeatureCollection = await buildSiteObject(deviceList);
    } else if (currentDeviceCategory === 'SITES') {
      deviceList = await getMacDevices(API_ROOT + 'sites' + (excludeCoords ? '' : coordString));
      FeatureCollection = await buildSiteObject(deviceList);
    } else if (currentDeviceCategory === 'GROUPS') {
      deviceList = await getGroupList(GROUP_API);
      FeatureCollection = await buildGroupObject(deviceList);
    }
  }

  return FeatureCollection;
}

export async function getSelectedSitesByBlockList(districts, blocks) {
  if(deviceList.length >0)
  {
    deviceList.splice(0, deviceList.length);
  }
  let jsonData = [];
  let deviceData = [];
  let FeatureCollection = {};
  let blockCollection = {};
  //Code for dynamically creating JSON object
  if (blocks && blocks.length > 0) {
    await Promise.all(districts.map(async (item) => {
      await Promise.all(blocks.map(async (item2) => {
        let deviceList = await getMacDevices(API_ROOT + '/devices/district_ids=${item.id}&block_ids=${item2.id}');

        deviceData.push(deviceList);
      }));
    }));

    deviceData.map((device) => {
      device.map((deviceItem) => {
        deviceList.push(deviceItem);
      });
    });

    FeatureCollection["type"] = "FeatureCollection";
    FeatureCollection["features"] = deviceList.map((item) => buildBlockObject(item));
  }

  return FeatureCollection;
}

export const buildBlockObject = (block) => {
  let jsonData = [];
  if (block !== undefined) {
    let dataObj = {};
    let mapBlock = block;
    //geometry node
    let geojson = mapBlock.geojson;//JSON.parse(mapBlock.geojson);
    let geometry;
    let properties = {};
    if (geojson != null && geojson.features && geojson.features.length >0) {
      geometry = geojson.features[0].geometry;
      properties = geojson.features[0].properties;
    }
    else {
      geometry = null;
    }
    //new properties from api
    properties["name"] = mapBlock.block_name;
    properties["id"] = mapBlock.block_id;
    properties["parentId"] = mapBlock.district_id;
    properties["site_count"] = mapBlock.site_count;
    dataObj = {};
    dataObj["type"] = "Feature";
    dataObj["geometry"] = geometry;
    dataObj["properties"] = properties;

    return dataObj;
  }
}

//for getting Block List
export async function getBlockList() {
  let jsonData = [];
  let FeatureCollection = {};

  if (blockList.length > 0) {
    for (let i = 0; i < blockList.length; i++) {
      if (blockList[i] !== undefined) {
        let dataObj = {};
        let mapBlock = blockList[i];
        //geometry node
        let geojson = JSON.parse(mapBlock.geojson);
        let geometry;
        let properties = {};
        if (geojson != null && geojson.features && geojson.features.length >0) {
          geometry = geojson.features[0].geometry;
          properties = geojson.features[0].properties;
        }
        else {
          geometry = null;
        }
        //new properties from api
        properties["name"] = mapBlock.block_name;
        properties["id"] = mapBlock.block_id;
        properties["parentId"] = mapBlock.district_id;
        //KT: Added this to fix count issue
        properties["site_count"] = mapBlock.site_count;
        dataObj = {};
        dataObj["type"] = "Feature";
        dataObj["geometry"] = geometry;
        dataObj["properties"] = properties;
        jsonData.push(dataObj);
      }
    }
    //main json object after loop complete
    FeatureCollection["type"] = "FeatureCollection";
    FeatureCollection["features"] = jsonData;
  }
  return FeatureCollection;
}
let districtMappings = [];
let blockMappings = []
let lightMappings = [];

const initialState = {
  lights: lightMappings,
  blocks: blockMappings,
  districts: districtMappings,
  showInfoBox: true,
  showSideBar: true,
  showBottomButtons: true,
  brightnessReset: false,
  zoomLevel: DEFAULT_ZOOM,
  center: DEFAULT_CENTER,
  layerType: LAYERS[0],
  selectedDistricts: [],
  selectedBlocks: [],
  selectedLights: [],
  updateSites: false,
  viewPort: {},
  shouldAdjustZoom: false,
  currentDeviceCategory: 'DEVICES',
  updateLayer: true,
  adjustedCenter: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REHYDRATE:
      let newState = Object.assign({}, initialState);
      if (action.payload.map) {
        newState.showInfoBox = action.payload.map.showInfoBox;
      }
      return newState;
    case DISMISS_INFOBOX:
      return {
        ...state,
        showInfoBox: false
      };
    case TOGGLE_DEVICE_SITE_DISPLAY:
      return {
        ...state,
        currentDeviceCategory: action.payload.currentDeviceCategory
      }
    case UPDATE_LAYER:

      return {
          ...state,
          updateLayer: action.payload.updateLayer,
          shouldAdjustZoom: action.payload.shouldAdjustZoom
        };
    case ZOOM_SLIDER:
      let zoomInSliderLevel = getZoomLevelForLayer(state.layerType, state.currentZoomLevel);
      let zoomInSliderLayer = getLayer(state.layerType, state.currentZoomLevel);
      let zoomInSliderCenter = action.payload.center;

      return {
          ...state,
          updateLayer: true,
          shouldAdjustZoom: true,
          //zoomLevel: nextZoomInLevel.START + 0.5,
          zoomLevel: zoomInSliderLayer === LAYER_3X && (state.currentZoomLevel <= zoomInSliderLevel.DEFAULT) ? zoomInSliderLevel.DEFAULT : state.currentZoomLevel,
          layerType: zoomInSliderLayer,
          center: zoomInSliderCenter
      };
    case ZOOM_IN:
      let nextZoomInLevel = getNextZoomLevelForLayer(state.layerType, 'IN');
      let nextZoomInLayer = getNextLayer(state.layerType, 'IN');
      let zoomInCenter = action.payload.center;

      return {
        ...state,
        updateLayer: true,
        shouldAdjustZoom: true,
        //zoomLevel: nextZoomInLevel.START + 0.5,
        zoomLevel: nextZoomInLevel.DEFAULT,
        layerType: nextZoomInLayer,
        center: zoomInCenter
      };
    case ZOOM_OUT:
      let nextZoomOutLevel = getNextZoomLevelForLayer(state.layerType, 'OUT');
      let nextZoomOutLayer = getNextLayer(state.layerType, 'OUT');
      let zoomOutCenter = action.payload.center;

      return {
        ...state,
        selectedDistricts: [],
        selectedBlocks: [],
        selectedLights: [],
        shouldAdjustZoom: true,
        updateLayer: true,
        zoomLevel: nextZoomOutLevel.DEFAULT,
        layerType: nextZoomOutLayer,
        center: zoomOutCenter
      };
    case UPDATE_SITE_LIST:
      return {
        ...state,
        updateSites: action.payload.updateSites
      }
    case UPDATE_BLOCK_LIST:
      return {
        ...state,
        updateBlocks: action.payload.updateBlocks
      }
    case ON_ZOOM_END:

      let curLayerType = action.payload.layerType;
      let viewPort = action.payload.viewPort;
      let layerDistricts = state.selectedDistricts;
      let layerBlocks = state.selectedBlocks;
      let layerLights = state.selectedLights;
      let layerChange = [
        [LAYERS[2], LAYERS[1]],
        [LAYERS[1], LAYERS[0]],
        [LAYERS[1], LAYERS[0]]
      ];
      if (
        JSON.stringify(layerChange).includes(
          JSON.stringify([state.layerType, curLayerType])
        )
      ) {
        layerLights = [];
        layerBlocks = [];
        layerDistricts = [];
      }

      return {
        ...state,
        selectedDistricts: layerDistricts,
        selectedBlocks: layerBlocks,
        selectedLights: layerLights,
        layerType: curLayerType,
        viewPort: viewPort
      };
    case ADJUSTED_ZOOM:
      return {
        ...state,
        shouldAdjustZoom: false
      };
    case SELECTED_DISTRICTS:
      let selectedDistricts = action.payload.districts;
      let selectedDistrictsWithDetails = selectedDistricts.map(districtId => {
        return districtDatas[districtId];
      });
      return {
        ...state,
        brightnessReset: true,
        selectedDistricts: selectedDistrictsWithDetails
      };
    case SELECTED_BLOCKS:
      let selectedBlocks = action.payload.blocks;
      let selectedBlocksWithDetails = selectedBlocks.map(blockId => {
        return blockDatas[blockId];
      });
      return {
        ...state,
        brightnessReset: true,
        selectedBlocks: selectedBlocksWithDetails
      };
    case SELECTED_LIGHTS:
      let selectedLights = action.payload.lights;
      let selectedLightsWithDetails = [];
      if (macDatas && macDatas.features) {
        let lightsArray = macDatas.features.reduce(getLightDetails, {}); //added on 19th sept 2019
        selectedLightsWithDetails = selectedLights.map(lightId => {
          return lightsArray[lightId]; //modified on 19th sept 2019
        });
      }

      return {
        ...state,
        brightnessReset: true,
        selectedLights: selectedLightsWithDetails
      };
    case BRIGHTNESS_UPDATE:
      return {
        ...state
      };
    case SWITCH_UPDATE:
      return {
        ...state
      };
    case APPLY_CHANGES:
      let brightness = action.payload.brightness;
      let lampStatus = action.payload.lampStatus;
      let curLayerTypeOnApplyState = state.layerType;
      if (curLayerTypeOnApplyState === LAYER_1X) {
        let selectedDistricts = state.selectedDistricts;
        let districtIds = selectedDistricts.map(district => district.id);
        let lights = Object.keys(state.lights).map(id => state.lights[id]);
        let blocks = Object.keys(state.blocks).map(id => state.blocks[id]);
        let districts = Object.keys(state.districts).map(
          id => state.districts[id]
        );
        let lightsForDistricts = getLightsForDistricts(
          districtIds,
          lights,
          blocks,
          districts
        );
        let modifiedLights = lightsForDistricts.map(light => {
          return Object.assign({}, light, {
            brightness,
            lampStatus
          });
        });
        //TODO Needs refactoring
        modifiedLights.forEach(light => {
          state.lights[light.id] = light;
        });
        return {
          ...state
        };
      } else if (curLayerTypeOnApplyState === LAYER_2X) {
        let selectedBlocks = state.selectedBlocks;
        let blockIds = selectedBlocks.map(block => block.id);
        let lights = Object.keys(state.lights).map(id => state.lights[id]);

        let lightsForBlocks = getLightsForBlocks(blockIds, lights);
        let modifiedLights = lightsForBlocks.map(light => {
          return Object.assign({}, light, {
            brightness,
            lampStatus
          });
        });
        modifiedLights.forEach(light => {
          state.lights[light.id] = light;
        });
        return {
          ...state
        };
      } else if (curLayerTypeOnApplyState === LAYER_3X) {
        let selectedLights = state.selectedLights;
        let modifiedLights = selectedLights.map(light => {
          return Object.assign({}, light, {
            brightness,
            lampStatus
          });
        });
        modifiedLights.forEach(light => {
          state.lights[light.id] = light;
        });
        return {
          ...state,
          selectedLights: modifiedLights
        };
      }
      return {
        ...state
      };
    case SET_LIGHT_DATA: //Added for setting light values from api on 23rd sept 2019
      let data = action.payload.data;
      let newVal = Object.assign({}, initialState);
      if (data) {
        newVal.lights = data.features.reduce(getLightDetails, {});;
      }
      return newVal;
    default:
      return state;
  }
};

//Added for setting light values from api on 23rd sept 2019
export function setLightsDataInStore(data) {
  return dispatch => {
    dispatch({
      type: SET_LIGHT_DATA,
      payload: {
        data
      }
    });
  };
}

export function dismissInfoBox() {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: DISMISS_INFOBOX
    });
  };
}

export function updateLayer(shouldUpdate) {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: UPDATE_LAYER,
      payload: {
        updateLayer: shouldUpdate,
        shouldAdjustZoom: shouldUpdate
      }
    });
  };
}

export function zoom(zoomInOut, center = DEFAULT_CENTER) {
  let zoomType = zoomInOut === 'IN' ? ZOOM_IN : ZOOM_OUT;
  let useCoords = center === DEFAULT_CENTER;
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: zoomType,
      payload: {
        center,
        useCoords
      }
    });
  };
}

export function zoomSlider(center = DEFAULT_CENTER, currentZoomLevel) {
  let useCoords = center === DEFAULT_CENTER;
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: ZOOM_SLIDER,
      payload: {
        center,
        useCoords,
        currentZoomLevel
      }
    });
  };
}

export function toggleDeviceSiteDisplay(currentDeviceCategory) {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TOGGLE_DEVICE_SITE_DISPLAY,
      payload: {
        currentDeviceCategory
      }
    });
  };
}

function getZoomLevelForLayer(layer = LAYERS[0], currentZoomLevel) {
  return ZOOM_LEVELS.find((level) => currentZoomLevel >= level.start && currentZoomLevel <= level.end);
}

function getLayer(layer = LAYERS[0], currentZoomLevel) {
  let index = ZOOM_LEVELS.findIndex((level) => currentZoomLevel >= level.start && currentZoomLevel <= level.end);

  return LAYERS[index];
}

function getNextZoomLevelForLayer(layer = LAYERS[0], zoomInOut = 'IN') {
  let layerIndex = LAYERS.indexOf(layer);

  let nextZoomIn = ZOOM_LEVELS[Math.min(layerIndex + 1, ZOOM_LEVELS.length - 1)];
  let nextZoomOut = ZOOM_LEVELS[Math.max(layerIndex - 1, 0)];

  return zoomInOut === 'IN' ? nextZoomIn : nextZoomOut;
}

function getNextLayer(layer = LAYERS[0], zoomInOut = 'IN') {
  let layerIndex = LAYERS.indexOf(layer);
  let nextZoomInLayer = LAYERS[Math.min(layerIndex + 1, LAYERS.length - 1)];
  let nextZoomOutLayer = LAYERS[Math.max(layerIndex - 1, 0)];

  return zoomInOut === 'IN' ? nextZoomInLayer : nextZoomOutLayer;
}

export function selectedDistricts(districts) {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: SELECTED_DISTRICTS,
      payload: {
        districts: districts
      }
    });
  };
}

export function selectedBlocks(blocks) {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: SELECTED_BLOCKS,
      payload: {
        blocks: blocks
      }
    });
  };
}

export function selectedLights(lights) {

  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: SELECTED_LIGHTS,
      payload: {
        lights: lights
      }
    });
  };
}

export function hasAdjustedZoom() {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: ADJUSTED_ZOOM
    });
  };
}

export function onZoomEnd({layerType, viewPort}) {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: ON_ZOOM_END,
      payload: {
        layerType,
        viewPort
      }
    });
  };
}

export function updateBlockList(updateBlocks) {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: UPDATE_BLOCK_LIST,
      payload: {
        updateBlocks
      }
    });
  };
}

export function updateSiteList(updateSites) {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: UPDATE_SITE_LIST,
      payload: {
        updateSites
      }
    });
  };
}

export function updateSelectedBrightness() {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: BRIGHTNESS_UPDATE
    });
  };
}

export function updateSelectedSwitch() {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: SWITCH_UPDATE
    });
  };
}

export function applyChanges(changes) {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: APPLY_CHANGES,
      payload: {
        brightness: changes.brightness,
        lampStatus: changes.lampStatus
      }
    });
  };
}

//for updating the gps status of light
export const updateGps = (siteId, gpsStatus, immediate) => {
  return dispatch => {
    GpsUpdate(LIGHT_API + '/' + siteId + '/command', gpsStatus, immediate).then(json => {
      dispatch({
        type: SET_LIGHT_GPS
      });
    });
  };
};

//for updating the light mode of light
export async function  updateMode(siteId, modeType, immediate) {

  let data = await ModeUpdate(LIGHT_API + '/' + siteId + '/command', modeType, immediate);
  return data;
};

//for updating threshold and level of light
export async function updateThresholdAndLevels(lightToUpdate) {

  let data = await ThresholdAndLevelUpdate(SITES_API + '/' + lightToUpdate.siteId + '/levels', lightToUpdate);
  return data;
}

//for updating the gps status of light
export const updateGroupGps = (siteId, gpsStatus, immediate) => {
  return dispatch => {
    GpsUpdate(GROUP_API + '/' + siteId + '/command', gpsStatus, immediate).then(json => {
      dispatch({
        type: SET_LIGHT_GPS
      });
    });
  };
};

//for updating the light mode of light
export async function  updateGroupMode(siteId, modeType, immediate) {
  let data = await ModeUpdate(GROUP_API + '/' + siteId + '/command', modeType, immediate);
  return data;
};

//for updating threshold and level of light
export async function updateGroupThresholdAndLevels(lightToUpdate) {
  let data = await ThresholdAndLevelUpdate(GROUP_API + '/' + lightToUpdate.groupId + '/levels', lightToUpdate);
  return data;
}

//for setting schedule for light
export const setLightSchedule = (mac, deviceType, selectedDays, lightMode, timeInSeconds) => {
  return dispatch => {
    ModeUpdate(LIGHT_API, mac, deviceType, selectedDays, lightMode, timeInSeconds).then(json => {
      dispatch({
        type: SET_LIGHT_MODE
      });
    });
  };
};












