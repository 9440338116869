import React, { Component } from 'react';
import IssuesTable from '../issues/issues-table';
import RowResizer from '../issues/row-resizer';
import Tabs from '../tabs/tabs.js';
import Button from '../button/button.js';
import CloseIssue from '../issues/close-issue';
import InfoBox from '../infobox/infobox.js';
import infoTickIcon from '../../assets/green-tick.svg';
import ColumnEditor from '../issues/column-editor';
import NewIssue from '../issues/new-issue';
import { tabs } from '../constants/tabs';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { css } from 'emotion';
import { toast } from 'react-toastify';
import { checkLoginTimeout, clearLoginTimeout } from '../../modules/user.js';
import { getWorkOrderListFromDb, updateWorkOrderIssuesInDb} from '../../modules/work-order';
import { getDeviceListFromDb } from '../../modules/inventory.js';
import {
  selectAll,
  search,
  reset,
  select,
  sort,
  paginateNext,
  paginatePrev,
  changeDataLimit,
  closeSelectedIssues,
  showColumns,
  dimissNotification,
  showNotification,
  createNewWorkOrder,
  postComment,
  addToWorkOrder,
  filterSubColumnTypes,
  PRIORITY,
  ISSUE,
  DEVICETYPE,
  STATUS
} from '../../modules/issues';
import AddComment from '../issues/add-comment';
import AddWorkOrder from '../issues/add-work-order';
import NotificationModal from '../modal/notification-modal';

import { getIssuesListFromDb, getIssueDetailsFromDb , updateIssueInDb} from '../../modules/issues';
import CreateWorkOrder from '../work-order/create-work-order.js';
import Modal from 'react-responsive-modal';
import Confirmation from '../confirmation/confirmation.js';
import Messages from '../constants/messages';
import AppLoader from '../Loader/loader';
import { relativeTimeThreshold } from 'moment';

class Issues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewIssueModal: false,
      showModal: false,
      workOrderText: '',
      rowType: 'medium',
      isRowSelected: true,
      selectedRow: [],
      shown: false,
      selectedTableRows: [],
      showCreateWorkOrderModal: false,
      showCloseConfirmation: false,
      showIssueCloseConfirmation: false,
      showCloseIssuesConfirmation:false,
      showAssignIssuesConfirmation: false,
      tableData: [],
      showLoader: true,
      workOrderList: [],
      selectedIssues:[],
      deviceList:[],
      queryString: (window.location.search)? window.location.search : '',
      isFormDirty: false,
      isIssueCloseFormDirty: false,
      isCloseIssueFormDirty: false
    };
  }

  componentDidMount() {
    if(this.state.queryString) {
      let currentVal = this.state.queryString.replace('?','');
      this.setState({queryString:currentVal});
    }
    else{
      this.setState({queryString:''});
    }

    this.getDevicesList();
    this.getIssuesList();
    this.getWorkOrderList();

  }

  showMenuOnMobile = () => {
    this.setState({ shown: !this.state.shown });
  }

  getWorkOrderList = async () => {
    let data = await getWorkOrderListFromDb();//api call for getting the work order list from database
    if(data){
      this.setState({ workOrderList: data });
    }
  }

  updateWorkOrderForIssues = async (workOrderId, selectedIssues) =>{
    let issues=[];
    for (let i =0  ; i < selectedIssues.length ; i++ ){
      issues.push({
        issue_id: selectedIssues[i]
      })
    }
     let  selectedIssue ={
      issueId: issues,
      id : workOrderId
     }
     let response = await updateWorkOrderIssuesInDb(selectedIssue);
     if (response !== null && response !== undefined  ) {
       if(selectedIssues.length>1)
       {
        toast.success(Messages.UpdateWorkIdWithIssues);
       }
       else
       {
        toast.success(Messages.UpdateWorkIdWithIssue);
       }
      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
    }
  }

  getIssuesList = async () => {
    let data = await getIssuesListFromDb(); //api call for getting the issue list from database
    if (data) {

      if (this.state.queryString != "") {
        let filterData = data.filter(item => {
          return (
            item.work_order_id == this.state.queryString
          )
        });
        this.setState({ tableData: filterData, showLoader: false });
      }
      else {
        this.setState({ tableData: data, showLoader: false });
      }

    }
  }

  componentWillMount() {
    this.props.checkLoginTimeout(this.props.user);
  }
  componentWillUnmount() {
    this.props.clearLoginTimeout(this.props.user);
  }
  createNewIssue = () => {
    this.setState({
      showNewIssueModal: true
    });
  }

  clearIssueFilter= () =>{
    this.setState({queryString:''});
    window.location.href="/issues";
  }

  onCancelNewIssue = () => {
    this.setState({ showIssueCloseConfirmation: this.state.isIssueCloseFormDirty });

    if (!this.state.isIssueCloseFormDirty)
      this.setState({showNewIssueModal: false});
  }

  onNewIssue = (issue) => {
    this.setState({
      showNewIssueModal: false
    });
    const message = 'Issue has been successfully created';
    setTimeout(() => {
      this.props.showNotification(message);
    }, 100);
  }

  onEditColumns = (id, show) => {
    this.props.showColumns(id, show);
  }

  onRowSizeChanged = (type) => {
    this.setState({
      rowType: type
    });
  }

  onCloseIssues =  () => {
    if(!this.state.isRowSelected){
      this.setState({showCloseIssuesConfirmation:true});
     }
  }

GetIssuesDetails = async (i)=>{
  let response = await getIssueDetailsFromDb(i);
  if (response !== undefined && response !== null){
    let issueToUpdate = {
      id: response.issue_id,
      siteId: response.site_id,
      priority: response.priority,
      type: response.issue_type,
      location: response.location,
      comments: response.comment== undefined ?"":response.comment,
      status: "CLOSED"
    };

    this.updateIssueClose(issueToUpdate);
  }
}

updateIssueClose = async (data)=>{
  let updateResponse = await updateIssueInDb(data);

}

  onInteraction() {
    if (this.props.message !== null) {
      this.props.dimissNotification();
    }
  }

  renderNotification(message) {
    if (message !== null) {
      const style = css({
        border: '1px solid #34B37E'
      });
      return (
        <InfoBox className={`${centerAlign} ${style}`} icon={infoTickIcon}>
          {message}
        </InfoBox>
      );
    }
    return null;
  }
  renderCreateNewWorkOrder = () => {
    let heading = 'Create New Work Order';
    let title =
      'Enter the name for the new work order you would like to create';
    let applyChangesButtonTitle = 'Create';
    let cancelButtonTitle = 'Cancel';
    if (this.state.showModal) {
      return (
        <NotificationModal>
          <div className={notificationContainer}>
            <div className={headingClass}>{heading}</div>
            <div className={notitficationTitle}>{title}</div>
            <input
              className={inputClass}
              onChange={e =>
                this.setState({
                  workOrderText: e.target.value
                })}
            />
            <div className={notificationButtons}>
              <Button
                btnClassName={notificationCancelButton}
                buttonTextStyle={cancelButtonText}
                text={cancelButtonTitle}
                buttonContainerStyle={buttonContainerStyle}
                buttonClickCallback={this.onCancelChanges}
              />
              <Button
                btnClassName={notificationButton}
                buttonTextStyle={notificationText}
                text={applyChangesButtonTitle}
                buttonContainerStyle={buttonContainerStyle}
                buttonClickCallback={this.onApplyChangesConfirmed}
              />
            </div>
          </div>
        </NotificationModal>
      );
    }
    return null;
  };
  onCancelChanges = () => {
    this.setState({
      showModal: false,
      workOrderText: ''
    });
  };
  onApplyChangesConfirmed = () => {
    if (this.state.workOrderText)
      this.props.createNewWorkOrder(this.state.workOrderText);
    this.setState({
      showModal: false,
      workOrderText: ''
    });
  };

  showCreateNewOrder = () => {
    this.setState({
      showCreateWorkOrderModal: true
      //showModal: true
    });
  };

  addworkorder = e => {
    this.props.addToWorkOrder(e);
    let selectedIssues = this.state.selectedIssues;
    if (selectedIssues.length > 0) {
      this.updateWorkOrderForIssues(e, selectedIssues);
    }
  };

  checkButtonDisabled = () => {
    let state = true;
    if (this.props.issues.data.length) {
      this.props.issues.data.forEach(e => {
        if (e.isSelected) {
          state = false;
        }
      });
    }
    return state;
  }

  onRowSelect = (row, isSelected , selectedIssues) => {
    if (isSelected) {
      this.state.selectedTableRows.push(1);
    } else {
      this.state.selectedTableRows.pop();
    }

    if (this.state.selectedTableRows.length > 0) {
      isSelected = true;
    } else {
      isSelected = false;
    }

    this.setState({ isRowSelected: !isSelected, selectedRow: row , selectedIssues : selectedIssues});
  }

  createNewWorkOrder = () => {
    this.setState({ showCreateWorkOrderModal: true });
  }

  closeWorkModal = () => {
    this.setState({ showCloseConfirmation: this.state.isFormDirty });

    if (!this.state.isFormDirty)
      this.setState({showCreateWorkOrderModal: false});
  }

  closeWorkAddModel =()=>{
    setTimeout(() => {
      this.getWorkOrderList();
      this.setState({ showCreateWorkOrderModal: false });
    }, 2000);
  }

  closeCloseConfirmation = () => {
    this.setState({ showCloseConfirmation: false });
  }

  closeIssueConfirmation=()=>{
    this.setState({showCloseIssuesConfirmation:false});
  }

  onCloseIssuesConfirm = () => {
    this.setState({ showLoader: true , showCloseIssuesConfirmation : false}, () => {
      this.setFormDirty(false);
      const selectedIssues = this.state.selectedIssues;
      if (selectedIssues.length > 0) {
        for (let i = 0; selectedIssues.length > i; i++) {
          this.GetIssuesDetails(selectedIssues[i]);
        }
        this.props.closeSelectedIssues();
        this.getIssuesList();
        if (selectedIssues.length > 1) {
          toast.success(Messages.UpdateCloseIssues);
        }
        else {
          toast.success(Messages.UpdateCloseIssue);
        }
        setTimeout(() => {
          window.location.reload(false);
        }, 2000);
      }
    });
  }

  onCloseConfirm = () => {
    this.setState({ showCloseConfirmation: false });
    this.setState({ showCreateWorkOrderModal: false });
    this.setFormDirty(false);
  }

  issueCloseConfirmation = () => {
    this.setState({ showIssueCloseConfirmation: false });
  }

  onIssueCloseConfirm = () => {
    this.setState({ showIssueCloseConfirmation: false });
    this.setState({ showNewIssueModal: false });
    this.setIssueCloseFormDirty(false);
  }

  getDevicesList = async () => {
    let data = await getDeviceListFromDb(); //api call for getting the device list from database
    if (data) {
      data = data.filter(l => {
        if (l.device_type !== undefined) {
          return l.device_type.toLowerCase() === "fybrlyte";
        }
      });
      let device = data.map(k => {
        return { key: k.site_id, label: k.site_name + ' (' + k.site_id + ')' }
      });
      this.setState({ deviceList: device })
    }
  }

  onAddAndUpdate=()=>{
    this.getIssuesList();
    this.setState({showNewIssueModal:false})
  }

  setFormDirty = (isDirty) => {
    this.setState({isFormDirty: isDirty})
  }

  setIssueCloseFormDirty = (isDirty) => {
    this.setState({isIssueCloseFormDirty: isDirty})
  }

  setCloseIssueFormDirty = (isDirty) => {
    this.setState({isCloseIssueFormDirty: isDirty})
  }

  render() {
    let button;
      if(this.state.queryString !=''){
        button=  <Button
        btnClassName="clearButton"
      text="CLEAR FILTER"
      buttonClickCallback={this.clearIssueFilter}
      />
      }

    const {
      columns,
      data,
      message
    } = this.props.issues;
    let buttonDisabledState = this.state.isRowSelected;
    const objectToArray = item => {
      return Object.keys(item).map(key => {
        return { key: key, label: item[key] };
      });
    };
    const statusTypes = objectToArray(STATUS);
    const deviceIssues = objectToArray(ISSUE);
    const priorityTypes = objectToArray(PRIORITY);

    return (
      <div className="issues customTab" onClick={this.onInteraction.bind(this)}>

        <div id="createNewIssue">
          <Modal classNames={{ modal: "create-issue-modal" }} open={this.state.showNewIssueModal} onClose={this.onCancelNewIssue} right closeOnOverlayClick={false}>
            <NewIssue deviceList={this.state.deviceList} statusTypes={statusTypes} issueType={deviceIssues} priorityTypes={priorityTypes} onNewIssue={this.onNewIssue} onCancel={this.onCancelNewIssue} onAddIssue={this.onAddAndUpdate} isFormDirty={this.setIssueCloseFormDirty} isDirty={this.state.isIssueCloseFormDirty} />
          </Modal>
        </div>
        {
          this.state.showIssueCloseConfirmation &&
          (
            <Confirmation confirmationText={Messages.IssueCloseConfirmation} confirmButtonText="Confirm" rejectButtonText="Cancel" openConfirmation={true} closeConfirmationModal={this.issueCloseConfirmation} onConfirmClicked={this.onIssueCloseConfirm} />
          )
        }
        {this.renderNotification(message)}
        <div className="desktopViewForTabs">
          <Tabs tabs={tabs} activeTab="issues" />
        </div>
        <div className="mobileViewForTabs">
          <div onClick={() => this.showMenuOnMobile()}>
            <i className="fa fa-bars mobile-menu-icon" aria-hidden="true"></i>
          </div>
          <div className={this.state.shown ? "show" : "hide"}>
            <Tabs tabs={tabs} activeTab="issues" />
          </div>
        </div>
        {/* <Tabs tabs={tabs} activeTab="issues" /> */}
        <div className={container}>
          <div className="issuestoprow">
            <div className="lhs">

              <ColumnEditor onEdit={this.onEditColumns} columns={columns} className="paddingButton" />
              {button}
            </div>
            <div className="middle">

              <CloseIssue className="box-btn"
                onClose={this.onCloseIssues}
                disabled={buttonDisabledState}
              />
              <AddWorkOrder className="box-btn"
                workOrder={this.state.workOrderList}
                submit={this.addworkorder}
                onCreateNew={this.showCreateNewOrder}
                disabled={buttonDisabledState}
              />

            </div>
            <div className="rhs">
              <Button
                buttonTextStyle={btnStyle}
                text="CREATE NEW ISSUE"
                buttonClickCallback={this.createNewIssue}
              />
            </div>
          </div>
          <div className={row}>
            <div className="table-responsive">
              <IssuesTable
                columns={columns.filter(col => col.isRequired)}
                data={this.state.tableData}
                rowType={this.state.rowType}
                onRowSelect={this.onRowSelect}
                deviceList={this.state.deviceList}
                OnUpdate={this.onAddAndUpdate}
                isFormDirty={this.setFormDirty}
                isDirty={this.state.isFormDirty}
              />
            </div>
          </div>
          <div className={rowResizerStyle}>
            <RowResizer onRowSizeChanged={this.onRowSizeChanged} />
          </div>
        </div>
        <div id="createNewWorkOrder">
          <Modal classNames={{ modal: "create-work-order-modal" }} open={this.state.showCreateWorkOrderModal} onClose={this.closeWorkModal} right closeOnOverlayClick={false}>
            <CreateWorkOrder onClose={this.closeWorkModal}  onAddWork={this.closeWorkAddModel} isFormDirty={this.setFormDirty} isDirty={this.state.isFormDirty}  />
          </Modal>
        </div>
        {
          this.state.showCloseConfirmation &&
          (
            <Confirmation confirmationText={Messages.WorkOrderCloseConfirmation} confirmButtonText="Confirm" rejectButtonText="Cancel" openConfirmation={true} closeConfirmationModal={this.closeCloseConfirmation} onConfirmClicked={this.onCloseConfirm} />
          )
        }

        {
          this.state.showCloseIssuesConfirmation &&
          (
            <Confirmation confirmationText={Messages.CloseIssuesConfirmation} confirmButtonText="Confirm" rejectButtonText="Cancel" openConfirmation={true} closeConfirmationModal={this.closeIssueConfirmation} onConfirmClicked={this.onCloseIssuesConfirm} />
          )
        }

        {
          this.state.showLoader &&
          (
            <div className="overlay">
              <div className="application-loader">
                <AppLoader />
              </div>
            </div>
          )
        }
      </div>
    );
  }
}

const notificationContainer = css({
  display: 'flex',
  flexDirection: 'column'
});

const btnStyle = css({
  fontFamily: 'OpenSans',
  fontSize: '14px',
  fontWeight: 'bold',
  fontStyle: 'normal',
  fontStretch: 'normal',
  letterSpacing: '1px'
});

const inputClass = css({
  width: '350px',
  borderRadius: '2px',
  marginLeft: '20px',
  border: '1.5px solid #0151cb !important',
  backgroundColor: '#ffffff',
  padding: '17px 0 18px 16px',
  fontSize: '14px'
});

const notitficationTitle = css({
  display: 'flex',
  fontFamily: 'OpenSans',
  fontSize: '14px',
  fontWeight: 'normal',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  textAlign: 'left',
  color: '#5e6c84',
  padding: '20px'
});
const headingClass = css({
  fontFamily: 'OpenSans',
  fontSize: '18px',
  fontWeight: 600,
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  color: '#112138',
  padding: '24px 0px 0px 24px'
});

const notificationButtons = css({
  display: 'flex',
  justifyContent: 'flex-end',
  paddingRight: '15px'
});

const notificationButton = css({
  height: '48px'
});

const notificationCancelButton = css({
  height: '48px',
  borderRadius: '2px',
  backgroundColor: '#ffffff',
  border: 'solid 1px #c4cdd5',
  ':hover': {
    backgroundColor: 'rgba(23, 44, 76, 0.2)',
    border: 'solid 1px rgba(23, 44, 76, 0.2)'
  }
});

const notificationText = css({
  fontFamily: 'OpenSans',
  fontSize: '14px',
  fontWeight: 'bold',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: '1px',
  textAlign: 'center',
  color: '#ffffff'
});

const cancelButtonText = css({
  fontFamily: 'OpenSans',
  fontSize: '14px',
  fontWeight: 'bold',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: '1px',
  textAlign: 'center',
  color: '#5e6c84'
});

const buttonContainerStyle = {
  padding: '15px 0 15px 15px'
};
const centerAlign = css({
  position: 'absolute',
  margin: '75px 0 0 33%'
});

const container = css({
  display: 'flex',
  flexDirection: 'column'
});

const row = css({
  display: 'flex'
});

const rowResizerStyle = css({
  display: 'flex',
  marginLeft: '5px'
});

const mapStateToProps = state => ({
  issues: state.issues,
  user: state.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      selectAll,
      select,
      search,
      reset,
      sort,
      paginateNext,
      paginatePrev,
      changeDataLimit,
      // createIssue,
      postComment,
      closeSelectedIssues,
      showColumns,
      dimissNotification,
      addToWorkOrder,
      createNewWorkOrder,
      showNotification,
      filterSubColumnTypes,
      checkLoginTimeout,
      clearLoginTimeout
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Issues);
