export function get2xBgStyle(id, sourceId, minZoom, maxZoom) {
  return {
    id: id,
    type: 'circle',
    source: sourceId,
    paint: {
      'circle-color': '#8E8E8E',
      'circle-radius': 10,
      'circle-opacity': 0.6
    },
    minzoom: minZoom,
    maxzoom: maxZoom,
      filter: ['all', ['==', 'selected', false],
          ['in', 'mode', 0, 3],
          ['!has', 'point_count']]
  };
}

export function get2xTurnedOnBgStyle(id, sourceId, minZoom, maxZoom) {
  return {
    id: id,
    type: 'circle',
    source: sourceId,
    paint: {
      'circle-color': '#ffc402',
      'circle-radius': 10,
      'circle-opacity': 0.4
    },
    minzoom: minZoom,
    maxzoom: maxZoom,
    filter: ['all', ['!=', 'selected', true],
                    ['any', ['==', 'mode', 1],
                    ['==', 'mode', 2]],
                    ['!has', 'point_count']]
  };
}

/*
export function get2xTurnedOnBgTwoStyle(id, sourceId, minZoom, maxZoom) {
  return {
    id: id,
    type: 'circle',
    source: sourceId,
    paint: {
      'circle-color': '#ffc402',
      'circle-radius': 10,
      'circle-opacity': 0.4
    },
    minzoom: minZoom,
    maxzoom: maxZoom,
    filter: ['==', 'mode', 2]
  };
}
 */

export function get2xBgStyleSite(id, sourceId, minZoom, maxZoom) {
    return {
        id: id,
        type: 'circle',
        source: sourceId,
        paint: {
            'circle-color': '#2584ff',
            'circle-radius': 10,
            'circle-opacity': 0.6
        },
        minzoom: minZoom,
        maxzoom: maxZoom,
        filter: ['all', ['!=', 'selected', true],
            ['!in', 'mode', 0, 1, 2, 3],
            ['!has', 'point_count']]
    };
}

export function get2xSelectedOutlineSiteStyle(id, sourceId, minZoom, maxZoom) {
    return {
        id: id,
        type: 'circle',
        source: sourceId,
        paint: {
            'circle-color': '#0151cb',
            'circle-radius': 14,
            'circle-opacity': 1
        },
        minzoom: minZoom,
        maxzoom: maxZoom,
        filter: ['all', ['==', 'selected', true],
                        ['!in', 'mode', 0, 1, 2, 3],
                        ['!has', 'point_count']]
    };
}

export function get2xSelectedBgSiteStyle(id, sourceId, minZoom, maxZoom) {
    return {
        id: id,
        type: 'circle',
        source: sourceId,
        paint: {
            'circle-color': '#2584ff',
            'circle-radius': 10,
            'circle-opacity': 1
        },
        minzoom: minZoom,
        maxzoom: maxZoom,
        filter: ['all', ['==', 'selected', true],
            ['!in', 'mode', 0, 1, 2, 3],
            ['!has', 'point_count']]
    };
}

export function get2xSelectedOutlineStyle(id, sourceId, minZoom, maxZoom) {
  return {
    id: id,
    type: 'circle',
    source: sourceId,
    paint: {
      'circle-color': '#ff8a00',
      'circle-radius': 14
    },
    minzoom: minZoom,
    maxzoom: maxZoom,
      filter: ['all', ['==', 'selected', true],
          ['in', 'mode', 1, 2],
          ['!has', 'point_count']]
  };
}

export function get2xSelectedBgStyle(id, sourceId, minZoom, maxZoom) {
  return {
    id: id,
    type: 'circle',
    source: sourceId,
    paint: {
      'circle-color': '#ffc402',
      'circle-radius': 10,
    },
    minzoom: minZoom,
    maxzoom: maxZoom,
      filter: ['all', ['==', 'selected', true],
          ['in', 'mode', 1, 2],
          ['!has', 'point_count']]
  };
}

export function get2xSelectedOutlineOffStyle(id, sourceId, minZoom, maxZoom) {
    return {
        id: id,
        type: 'circle',
        source: sourceId,
        paint: {
            'circle-color': '#4A4A4A',
            'circle-radius': 14,
            'circle-opacity': 1
        },
        minzoom: minZoom,
        maxzoom: maxZoom,
        filter: ['all', ['==', 'selected', true],
            ['in', 'mode', 0, 3],
            ['!has', 'point_count']]
    };
}

export function get2xSelectedBgOffStyle(id, sourceId, minZoom, maxZoom) {
    return {
        id: id,
        type: 'circle',
        source: sourceId,
        paint: {
            'circle-color': '#8E8E8E',
            'circle-radius': 10,
            'circle-opacity': 1
        },
        minzoom: minZoom,
        maxzoom: maxZoom,
        filter: ['all', ['==', 'selected', true],
            ['in', 'mode', 0, 3],
            ['!has', 'point_count']]
    };
}

export function get2xCircleStyle(
    id,
    sourceId,
    minZoom,
    maxZoom,
    fillColor,
    fillOutlineColor = fillColor,
    radius
) {
    return {
        id: id,
        type: 'circle',
        source: sourceId,
        paint: {
            'circle-color': fillColor,
            'circle-opacity': 0.2,
            'circle-stroke-color': fillOutlineColor,
            'circle-radius': {
                base: 1.75,
                stops: [[minZoom, 10], [maxZoom, radius]]
            },
            'circle-stroke-width': 1
        },
        minzoom: minZoom,
        maxzoom: maxZoom
    };
}
