import React, { Component } from 'react';
import SchedulingTable from '../scheduling/scheduling-table';
import RowResizer from '../scheduling/row-resizer';
import Tabs from '../tabs/tabs.js';
import Button from '../button/button.js';
import ColumnEditor from '../scheduling/column-editor';
import { tabs } from '../constants/tabs';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { css } from 'emotion';
import { checkLoginTimeout, clearLoginTimeout } from '../../modules/user.js';
import {
  selectAll,
  search,
  reset,
  select,
  sort,
  paginateNext,
  paginatePrev,
  changeDataLimit,
  createScheduling,
  closeSelectedScheduling,
  showColumns,
  dimissNotification,
  showNotification,
  createNewWorkOrder,
  postComment,
  addToWorkOrder,
  filterSubColumnTypes
} from '../../modules/scheduling';
import Modal from 'react-responsive-modal';
import CreateSchedule from '../scheduling/create-schedule';
import Confirmation from '../confirmation/confirmation.js';
import { getScheduleListFromDb } from '../../modules/scheduling';
import AppLoader from '../Loader/loader';
import Messages from '../constants/messages.js';
import GroupTable from "../group/group-table";
import CreateGroup from "../group/create-group";


class Scheduling extends Component {
  constructor(props) {
    super(props);

    this.state = {
      setScheduleModal: false,
      createScheduleModal: false,
      showCloseConfirmation: false,
      shown: false,
      tableData: [],
      showLoader : true,
      isFormDirty: false
    };
  }

  showMenuOnMobile = () => {
    this.setState({ shown: !this.state.shown });
  }

  componentDidMount() {
    this.getScheduleList();
  }

  getScheduleList = async () => {
    let data = await getScheduleListFromDb(); //api call for getting the schedule list from database
    if(data != null && data != undefined)
    {
    	this.setState({ tableData: data, showLoader : false });
  	}

  }

  componentWillMount() {
    this.props.checkLoginTimeout(this.props.user);
  }
  componentWillUnmount() {
    this.props.clearLoginTimeout(this.props.user);

  }
  onEditColumns = (id, show) => {
    this.props.showColumns(id, show);
  }
  onRowSizeChanged = (type) => {
    this.setState({
      rowType: type
    });
  }
  onInteraction() {
    if (this.props.message !== null) {
      this.props.dimissNotification();
    }
  }
  //for opening the set scheduling popup
  openSchedulePopup = () => {
    this.setState({ setScheduleModal: true });
  }
  setScheduleClose = () => {
    this.setState({ setScheduleModal: false });
  }

  //for creating schedule
  createNewSchedule = () => {
    this.setState({ createScheduleModal: true });
  }

  closeCreateScheduleModal = () => {
    this.setState({ showCloseConfirmation: this.state.isFormDirty });

    if (!this.state.isFormDirty)
      this.setState({createScheduleModal: false});
  }

  closeCloseConfirmation = () => {
    this.setState({ showCloseConfirmation: false });
  }

  onCloseConfirm = () => {
    this.setState({ showCloseConfirmation: false, createScheduleModal: false }, () => {
     // window.location.reload(false);
      this.setFormDirty(false);
    });
  }

  setFormDirty = (isDirty) => {
    this.setState({isFormDirty: isDirty})
  }

  createScheduling = () =>{
      this.getScheduleList();
  }

  render() {
    const {
      isFormDirty,
      isDirty
    } = this.props;

    const {
      columns,
      data
    } = this.props.scheduling;

    return (
      <div className="scheduling customTab" onClick={this.onInteraction.bind(this)}>
        <div className="desktopViewForTabs">
          <Tabs tabs={tabs} activeTab="scheduling" />
        </div>
        <div className="mobileViewForTabs">
          <div onClick={() => this.showMenuOnMobile()}>
            <i className="fa fa-bars mobile-menu-icon" aria-hidden="true"></i>
          </div>
          <div className={this.state.shown ? "show" : "hide"}>
            <Tabs tabs={tabs} activeTab="scheduling" />
          </div>
        </div>
        {/* <Tabs tabs={tabs} activeTab="scheduling" /> */}
        <div>
          <div className={container}>
            <div className="toprow">
              <div className={lhs}>
                <ColumnEditor onEdit={this.onEditColumns} columns={columns} />
              </div>
              <div className="midbox"></div>
              <div className={rhs}>
                <Button
                  buttonTextStyle={btnStyle}
                  text="CREATE NEW SCHEDULE"
                  buttonClickCallback={this.createNewSchedule}
                  btnClassName="create-new-schedule-btn"
                />
              </div>
            </div>
            <div className={row}>
              <SchedulingTable
                columns={columns.filter(col => col.isRequired)}
                data={this.state.tableData}
                rowType={this.state.rowType}
                createSchedule={this.createScheduling}
                isFormDirty={this.setFormDirty}
                isDirty={this.state.isFormDirty}
              />
            </div>
            <div className={rowResizerStyle}>
              <RowResizer onRowSizeChanged={this.onRowSizeChanged} />
            </div>
          </div>

          <div id="createScheduleModalDiv">
            <Modal classNames={{ modal: "create-schedule-modal" }} open={this.state.createScheduleModal} onClose={this.closeCreateScheduleModal} center closeOnOverlayClick={false}>
              <div>
                <CreateSchedule onConfirmClicked={this.onCloseConfirm} createSchedule={this.createScheduling} isFormDirty={this.setFormDirty} isDirty={this.state.isFormDirty} />
              </div>
            </Modal>
          </div>
          {
            this.state.showCloseConfirmation &&
            (
              <Confirmation confirmationText={Messages.ScheduleCloseConfirmation} confirmButtonText="Confirm" rejectButtonText="Cancel" openConfirmation={true} closeConfirmationModal={this.closeCloseConfirmation} onConfirmClicked={this.onCloseConfirm} />
            )
          }
          {
            this.state.showLoader &&
            (
              <div className="overlay">
                <div className="application-loader">
                  <AppLoader />
                </div>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

const rhs = css({});

const btnStyle = css({
  fontFamily: 'OpenSans',
  fontSize: '13px',
  fontWeight: 'bold',
  fontStyle: 'normal',
  fontStretch: 'normal',
  letterSpacing: '1px'
});

const container = css({
  display: 'flex',
  flexDirection: 'column',
  padding: '10px'
});

const lhs = css({
  alignSelf: 'center',
});

const row = css({
  display: 'flex',
});

const rowResizerStyle = css({
  display: 'flex',
  marginLeft: '5px'
});

const mapStateToProps = state => ({
  scheduling: state.scheduling,
  user: state.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      selectAll,
      select,
      search,
      reset,
      sort,
      paginateNext,
      paginatePrev,
      changeDataLimit,
      createScheduling,
      postComment,
      closeSelectedScheduling,
      showColumns,
      dimissNotification,
      addToWorkOrder,
      createNewWorkOrder,
      showNotification,
      filterSubColumnTypes,
      checkLoginTimeout,
      clearLoginTimeout
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Scheduling);
