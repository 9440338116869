import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/app/app';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import registerServiceWorker from './registerServiceWorker';
import store, { history } from './store';
import { persistStore, getStoredState } from 'redux-persist';
import { version } from '../package.json';

const persistor = persistStore(store);

getStoredState({}, (err, restoredState) => {
  if(restoredState) {
    let storedAppVersion = restoredState.app ? restoredState.app.version : null;
    // Purge the persisted state if the version has changed
    if(version !== storedAppVersion) {
      persistor.purge();
    }
  }
})

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

registerServiceWorker();

export default persistor;
