import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '../button/button.js';
import BootstrapTable from 'react-bootstrap-table-next';
import Modal from 'react-responsive-modal';
import DeviceMap from '../deviceMap/deviceMap';
import Confirmation from '../confirmation/confirmation.js';
import Messages from '../constants/messages.js';
import {
    MODETYPE,
    createGroupInDb,
    getGroupFromDb,
    updateGroupInDb,
    deleteDeviceFromGroupInDb,
    getDevicesInsideGroupById,
    addSubgroupToSites, assignDevicesToGroupInDb
} from '../../modules/grouping';
import { toast } from 'react-toastify';
import AppLoader from '../Loader/loader';
import {css} from "emotion";
import SideBar from "../sidebar/sidebar";
import {LAYER_1X, LAYER_2X, LAYER_3X} from "../constants/map";
import crossIcon from "../../assets/cross.svg";
import SingleSelectDropDown from "../single-select-dropdown/single-select-dropdown";
import {removeUnderscoreCapitalize} from "../../utils/utils";
import {
    toggleDeviceSiteDisplay
} from '../../modules/map.js';
import {selectedBlocks, selectedLights} from "../../modules/map";


class DevicesListMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageRefresh: false,
            errors: [],
            title: 'Map',
            subGroupValue: '',
            subGroupValues: [1],
            selectedDeviceList: [],
            groupLevelSubgroupValues: [],
            addedGroupLevelMaskCount: 0,
            selectedDeviceForAdd: [],
            createdGroupId: 0,
            editedGroupId: 0
        }
    }

    componentDidMount() {
        if (this.props.createdGroupId !== undefined && this.props.createdGroupId !== null && this.props.editedGroupId !== undefined && this.props.editedGroupId !== null) {
            this.setState({ createdGroupId: this.props.createdGroupId, editedGroupId: this.props.editedGroupId, groupLevelSubgroupValues: this.props.groupLevelSubgroupValues })
        }
    }

    checkSideBar = () => {
        let showSideBar = true;
        /*
        switch (this.props.map.layerType) {
            case LAYER_1X:
                if (this.props.map.selectedLights.length === 0) {
                    showSideBar = false;
                }
                break;
            default:
                if (this.props.map.selectedLights.length === 0) {
                    showSideBar = false;
                }
                break;
        }
         */
        return showSideBar;
    };

    onAddDevicesClicked = () => {
        let devices = [...this.props.map.selectedLights.map((light) => ({...light, sub_group: this.state.subGroupValues}))];
        let statuses = [];
        this.setState({selectedDeviceList: devices}, async () => {
            debugger;
            if (!this.handleValidation()) {
                return;
            } else {
                debugger
                debugger;
            debugger
            debugger;
                this.setState({ showLoader: true }, async () => {
                    if (this.state.selectedDeviceList.length > 0) {
                        for (let i = 0; i < this.state.selectedDeviceList.length; i++) {
                            if (await this.createSubgroupForGroupLevel(this.state.selectedDeviceList[i].site_id)) {
                                statuses.push(true);
                            } else {
                                statuses.push(false);
                            }
                        }
                    }
                    if (this.state.selectedDeviceForAdd.length === this.state.selectedDeviceList.length) {
                        if (await this.assignDevicesToGroup(this.state.selectedDeviceForAdd)) {
                            statuses.push(true);
                        } else {
                            statuses.push(false);
                        }
                    }

                    debugger;
                debugger;
                debugger;
                    if (statuses.indexOf(false) === -1)
                        toast.success(Messages.UpdateGroupSuccessText);
                    else
                        toast.error(Messages.UpdateGroupFailureText);
                });
            }
        });
    }

    onClearSelections = () => {
        this.setState({selectedDeviceList: []}, () => {
            this.props.selectedLights([...this.state.selectedDeviceList]);
        });
    }

    deselectLights = mac_address => {
        let lights = this.props.map.selectedLights
            .filter(e => {
                return e.mac_address !== mac_address;
            })
            .map(e => {
                return e.mac_address;
            });
        this.props.selectedLights(lights);
    };

    setSubGroupId = (e) => {
        let errors = [];
        let {isFormDirty} = this.props;
        if (Number(e.target.value) > 80) {
            errors["noSubGroupAdded"] = Messages.MaxSubgroupValueForAdd;
            this.setState({ errors: errors });
        } else {
            if (Number(e.target.value) < 0) {
                errors["noSubGroupAdded"] = Messages.NegativeValue;
                this.setState({ errors: errors });
            } else if ((Number(e.target.value) - Math.floor(Number(e.target.value))) !== 0) {
                errors["noSubGroupAdded"] = Messages.DecimalValue;
                this.setState({ errors: errors });
            } else {
                this.setState({ subGroupValue: e.target.value, errors: errors});
                isFormDirty(true);
            }
        }
    }

    addSubgroupValues = () => {
        let errors = [];
        if (this.state.subGroupValues.length > 80) {
            errors["noSubGroupAdded"] = Messages.MaxSubgroupLengthForAdd;
            this.setState({ errors: errors });
        }
        // else
        // if (this.state.subGroupValues.length === 1 && (Number(this.state.subGroupValues[0]) === 1)) {
        //     let val = Number(this.state.subGroupValue);
        //     if (val !== '' && val !== 0) {
        //         this.setState({ subGroupValues: [val], subGroupValue: '', errors: [] });
        //     } else {
        //         errors["noSubGroupAdded"] = Messages.NoSubgroupInputForAdd;
        //         this.setState({ errors: errors });
        //     }
        // }
        else {
            let val = Number(this.state.subGroupValue);
            debugger;
            debugger;
            let duplicatVal = this.state.subGroupValues.filter(function (item) {
                return item == val
            });
            if (duplicatVal.length > 0) {
                errors["noSubGroupAdded"] = Messages.DuplicateSubGroupValue;
                this.setState({ errors: errors });
            }
            else {
                if (val !== '' && val !== 0) {
                    this.setState({ subGroupValues: [...this.state.subGroupValues, val], subGroupValue: '', errors: [],  groupLevelSubgroupValues: [...this.state.subGroupValues, val]});
                } else {
                    errors["noSubGroupAdded"] = Messages.NoSubgroupInputForAdd;
                    this.setState({ errors: errors });
                }
            }
        }
    }

    createSubgroupForGroupLevel = async (siteId) => {
        let subGroupArray = [];
        let groupId = this.state.createdGroupId;
        debugger;
        if (this.state.editedGroupId !== undefined && this.state.editedGroupId !== null && this.state.editedGroupId !== 0) {
            groupId = this.state.editedGroupId;
        }
        let subGroupMask = this.state.groupLevelSubgroupValues.toString();
        subGroupArray.push(parseInt(subGroupMask));
        debugger;
        let subgroupToAdd = {
            groupId: groupId,
            siteId: siteId,
            groupMask: subGroupArray
        }
        debugger;
        let response = await addSubgroupToSites(subgroupToAdd);
        debugger;
        if (response !== undefined && response !== null) {
            this.setState({
                addedGroupLevelMaskCount: Number(this.state.addedGroupLevelMaskCount) + 1,
                showLoader: false
            }, () => {
                let deviceToAdd = {
                    site_id: siteId,
                    group_mask: subGroupArray
                }
                this.state.selectedDeviceForAdd = [...this.state.selectedDeviceForAdd, deviceToAdd];
                debugger;
                /*if (this.state.selectedDeviceForAdd.length === this.state.selectedDeviceList.length) {
                    this.assignDevicesToGroup(this.state.selectedDeviceForAdd);
                }*/
            });
            return true;
        }

        debugger;
        return false;
    }

    assignDevicesToGroup = async (selectedDevice) => {

        let groupId = this.state.createdGroupId;
        debugger;
        if (this.state.editedGroupId !== undefined && this.state.editedGroupId !== null && this.state.editedGroupId !== 0) {
            groupId = this.state.editedGroupId;
        }
        debugger;
        let devicesToAttach = {
            groupId: groupId,
            deviceList: selectedDevice
        }
        debugger;
        let response = await assignDevicesToGroupInDb(devicesToAttach);
        debugger;
        if (response !== undefined && response !== null) {
            this.setState({ showLoader: false }, () => {
                this.props.handlerFromParant(this.state.selectedDeviceList);
                debugger;
            });
            return true;
        }

        debugger;
        return false;
    }

    handleValidation = () => {
        let isValid = true;
        let errors = [];
        let state = this.state;

        debugger;
        if (state["selectedDeviceList"].length === 0) {
            errors["noRecordSelected"] = Messages.DeviceListNoSelection;
            isValid = false;
        }

        debugger;
        if (this.state.selectedDeviceList.length > 0) {
            let selectedArray = [...this.state.selectedDeviceList];
            for (let i = 0; i < selectedArray.length; i++) {
                if ((selectedArray[i].sub_group === undefined || selectedArray[i].sub_group === null || selectedArray[i].sub_group === "") && (this.state.groupLevelSubgroupValues === "" || this.state.groupLevelSubgroupValues === null || this.state.groupLevelSubgroupValues.length <= 0)) {
                    errors["noRecordSelected"] = Messages.DeviceWithoutSubgroup;
                    isValid = false;
                    break;
                } else {
                    isValid = true;
                }
            }
        }

        debugger;
        this.setState({ errors: errors });
        return isValid;
    }

    removeSubgroupValues = () => {
        let {isFormDirty} = this.props;
        let val = Number(this.state.subGroupValue);
        if (this.state.subGroupValues.length === 1) {
            this.setState({ subGroupValues: [1], subGroupValue: '', errors: [] });
        }
        else

        if (val !== '' && val !== 0) {
            let index = this.state.subGroupValues.indexOf(val);
            if (index !== -1) {
                this.state.subGroupValues.splice(index, 1);
                this.setState({ subGroupValues: this.state.subGroupValues, subGroupValue: '', errors: [], groupLevelSubgroupValues: this.state.subGroupValues});
                isFormDirty(true);
            }
        } else {
            let errors = [];
            errors["noSubGroupAdded"] = Messages.NoSubgroupInputForRemove;
            this.setState({ errors: errors });
        }
    }

    render() {
        let buttonText = this.props.map.selectedLights.length > 0 ? "Add (" + this.props.map.selectedLights.length + ")" : "Add";
        let clearText = "Clear Selections"

        return (
            <div>
                <h4>{this.state.title}</h4>
                <div className="create-group-parent-div">
                    <div>
                        <DeviceMap className={mapClass}>
                        </DeviceMap>
                        {this.checkSideBar() && (
                            <SideBar sidebarClassCustom={sideBarClass}>
                                <div style={{display: 'block', width: '100%'}}>
                                    <div className={rowClass}>
                                        <Button
                                            text={buttonText}
                                            buttonClickCallback={this.onAddDevicesClicked}
                                            btnClassName={applyChangesControlStyle}
                                            style={buttonCssAssignSchedule}
                                        />
                                    </div>
                                </div>

                                <div style={{display: 'block', width: '100%'}}>
                                    <div className={rowClass}>
                                        <Button
                                            text={clearText}
                                            buttonClickCallback={this.onClearSelections}
                                            btnClassName={applyChangesControlStyle}
                                            style={buttonCssAssignSchedule}
                                        />
                                    </div>
                                </div>

                                <div className="col-sm-6 margin-top-4">
                                    <div className="div-sub-group-control-body" style={{padding: '0px'}}>
                                        <div>
                                            <div>Add Subgroup </div>
                                        </div>
                                        <span className="display-inline-flex">
                                        <span><input className="inputSubGroupValue" type="number" value={this.state.subGroupValue} onChange={(e) => this.setSubGroupId(e)} step="any" /></span>
                                        <span className="margin-top-negative-4"><i title="Add" onClick={() => this.addSubgroupValues()} className="fa fa-plus-circle" style={{ color: 'green', fontSize: '36px', marginLeft: '30px' }}></i></span>
                                        <span className="margin-top-negative-4"><i title="Remove" onClick={() => this.removeSubgroupValues()} className="fa fa-times-circle" style={{ color: 'red', fontSize: '36px', marginLeft: '10px' }}></i></span>
                                    </span>
                                        <div id="divSubgroupValues" className="margin-top-10 subgroup-array">Subgroups : [{this.state.subGroupValues.toString()}]</div>
                                        <div className="application-error-messages margin-top-10">{this.state.errors["noSubGroupAdded"]}</div>
                                    </div>
                                </div>
                            </SideBar>
                        )}
                    </div>
                </div>
                {
                    this.state.showLoader &&
                    (
                        <div className="overlay">
                            <div className="application-loader">
                                <AppLoader />
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    map: state.map,
    user: state.user
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            toggleDeviceSiteDisplay,
            selectedLights
        },
        dispatch
    );

/*
const mapClass = css({
    position: 'fixed',
    width: '100%',
    float: 'left',
    height: 'calc(100vh - 110px)'
});
 */

const sideBarClass = css({
    position: 'absolute',
    top: '50px',
    right: '18px',
    width: '300px',
    backgroundColor: '#ffffff',
    boxShadow: '2px 2px 4px 2px rgba(0, 0, 0, 0.1)',
    overflowY: 'auto', //added on 20th sept 2019 for scroll in the sidebar for more values
    zIndex: 1000
});

const mapClass = css({
    position: 'absolute',
    top: 50,
    bottom: 0,
    width: '97%',
    height: '92%'
    /*float: 'left',*/
    /*
    position: 'relative',
    width: '800px',
    height: '800px'
     */
});

const filterClass = css({
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: '#f4f5f7',
    padding: '8px 16px 16px 8px'
});

const filterTab = css({
    borderRadius: '2px',
    backgroundColor: '#0151cb',
    fontFamily: 'OpenSans',
    fontSize: '12px',
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '18px',
    letterSpacing: 'normal',
    color: '#ffffff',
    padding: '8px 7px',
    marginLeft: '8px',
    marginTop: '8px',
    display: 'inline-flex',
    border: '0 solid rgba(0,0,0,0.01)'
});

const filterCross = css({
    padding: '0px 0px 0 4px',
    float: 'right',
    cursor: 'pointer'
});

const rowClass = css({
    padding: '16px'
});

const applyChangesStyle = css({
    width: '100%',
});
const applyChangesControlStyle = css({
    width: '100%'
});

const applyChangesButtons = css({
    display: 'flex',
    alignSelf: 'center'
});

const buttonCssAssignSchedule = css({
    padding: '5px 5px 5px 5px !important',
    marginTop: '10px'
})

export default connect(mapStateToProps, mapDispatchToProps)(DevicesListMap);
