import { REHYDRATE } from 'redux-persist/constants';
import { CHECK_USER_TIMEOUT } from './user';

import {getDeviceList, getSiteSuggestionList} from '../components/api/inventory';
import { API_ROOT } from '../components/constants/api';
import {getMacSuggestionList} from "../components/api/site";

let inventory = [];

export const KEYS = {
  INSTALLDATE: 'installation_date',
  MODE: 'mode',
  MAC: 'mac_address',
  SITEID: 'site_id',
  SITENAME: 'site_name',
  STATUS: 'site_status'
};

export const MODETYPE = {
  0: 'AUTO',
  1: 'MODE 1',
  2: 'MODE 2',
  3: 'OFF',
  4: 'FLASH'
};

export const COLUMNS = [
  { label: 'SITE ID', key: KEYS.SITEID, isRequired: true },
  { label: 'SITE NAME', key: KEYS.SITENAME, isRequired: true },
  { label: 'SITE STATUS', key: KEYS.STATUS, isRequired: true },
  { label: 'MAC', key: KEYS.MAC, isRequired: true },
  { label: 'MODE', key: KEYS.MODE, isRequired: true },
  { label: 'INSTALLATION DATE', key: KEYS.INSTALLDATE, isRequired: true }
];

const initialState = {
  data: inventory.slice(0),
  columns: COLUMNS
};

const TYPES = {
  SHOW_COLUMNS: 'SHOW_COLUMNS'
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REHYDRATE:
      const newState = { ...initialState };
      return newState;
    case TYPES.SHOW_COLUMNS:
      const columnIdentifier = action.payload.identifier;
      const show = action.payload.show;
      return filterColumns(state, columnIdentifier, show, COLUMNS);
    default:
      return state;
  }
};

function filterColumns(state, columnId, show, columns = []) {
  const filteredColumns = state.columns.map(column => {
    let isRequired = column.key === columnId ? show : column.isRequired;
    return { ...column, isRequired };
  });
  return { ...state, columns: filteredColumns };
}

export function showColumns(identifier, show) {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TYPES.SHOW_COLUMNS,
      payload: {
        identifier,
        show
      }
    });
  };
}

//for getting the list of devices from database
export async function getDeviceListFromDb(){
  let data = await getDeviceList(API_ROOT + 'devices');
  return data;
};

export async function getSiteSuggestionListFromDb(mac_address){
  let data = await getSiteSuggestionList(API_ROOT + 'sites/names?filter=' + mac_address);
  return data;
};
