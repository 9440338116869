import {applyStyles, getSource, getSourceLayer} from './1x-features';
import mapboxgl from 'mapbox-gl';
import center from '@turf/center';
import polygon from 'turf-extent';
import markerIcon from '../../../assets/bulb.svg';
import * as MapboxDraw from  '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.js';

import {getLayerTypeForZoom, LAYER_1X, LAYER_1X_ZOOM_LEVEL, LAYERS} from '../../constants/map';
import {getDistrictsList, getMacDeviceList} from "../../../modules/map";
import {addLayer, removeLayer} from "../utils";
import {getClusteredSourceLayer, getGroupSourceLayer} from "../../map/layers/3x-features";

const defaultOptions = {
  minZoom: LAYER_1X_ZOOM_LEVEL.START,
  maxZoom: LAYER_1X_ZOOM_LEVEL.END
};

export default class OneXLayer {
  constructor(map, features = [], props, options = defaultOptions) {
    this.map = map;
    this.options = options;
    this.markers = [];
    this.map.on('zoomend', () => this.onZoomChange());
    this.map.on('dragend', () => this.onDrag());
    //this.map.on('click', e => this.onFeatureClicked(e));
    this.markersAdded = false;
    this.selections = new Set();
    this.props = props;
    this.pointFeatures = features;
    this.draw = null;
    //this.previousZoom = this.map.getZoom();
  }

  _addToSelections = (id) => {
    if (this.selections.has(id)) {
      this.selections.delete(id);
    } else {
      this.selections.add(id);
    }
    this.props.selectedDistricts([...this.selections]);
    return this.selections;
  }

  updateFeatureData = async (props = this.props) => {
    let bbox = this.map.getBounds();
    let centerPoint = null;

    let data = await getDistrictsList();

    if (data && data.features && data.features.length > 0) {
      //let coordinates = data.features.map((item) => item.geometry.coordinates).filter((item) => item[0] && item[1]);
      let coordinates = data.features.filter((item) => item.geometry.coordinates[0]);
      //data.features = coordinates;

      this.pointFeatures = data;
      this.initLayer();

      //if button click, marker, then pass center.  Do not pass if using gestures
      //zoom in/zoom out. Take the center of the first feature that came back
      //this.map.flyTo({ zoom: props.map.zoomLevel, center: centerPoint.geometry.coordinates });
      //this.map.flyTo({ zoom: props.map.zoomLevel });
      //this.props.setLightsDataInStore(data); //called for setting the values redux store
    }
  }

  static calculateCenterPoint = async () => {
    let centerPoint = null;
    let data = await getDistrictsList();

    if (data && data.features && data.features.length > 0) {
      //let coordinates = data.features.map((item) => item.geometry.coordinates).filter((item) => item[0] && item[1]);
      let coordinates = data.features.filter((item) => item.geometry.coordinates[0]);
      centerPoint = center(coordinates[0]);
    }

    return centerPoint;
  }

  updateSelections = (districts) => {
    const zoom = this.map.getZoom();
    if (zoom >= this.options.minZoom && zoom <= this.options.maxZoom) {
      const selectedDistricts = districts.map(district => {
        return district.id;
      });
      this.selections = new Set(selectedDistricts);

      this.initLayer();
    }
    //const filter = ['in', 'id', ...this.selections.values()];
    //this.map.setFilter('1x-selected-bg-style', filter);
  }

  onFeatureClicked = (e) => {
    const features = this.map.queryRenderedFeatures(e.point, {
      layers: ['1x-selected-bg-style', '1x-bg-style']
    });
    if (features && features.length > 0) {
      const feature = features[0];
      const layerId = feature.layer.id;
      const layerIds = layerId.split('-');
      if (layerIds && layerIds.length > 0) {
        if (layerIds[0] === '1x') {
          // 1x feature clicked
          const districtId = feature.properties.id;
          this._addToSelections(districtId);

        }
      }
    }
  }

  initLayer = () => {
    let source = getSource(this.pointFeatures.features);
    let sourceLayer = getSourceLayer(source, 'districts', this.options);
    addLayer(sourceLayer, this.map);

    this.markers = this.constructMarkers(this.pointFeatures.features);
    this.addMarkers();
    this.map.resize();
  }

  /*
  initLayer = () => {
    let source = getSource(this.pointFeatures.features);

    debugger;
    debugger;
    debugger;
    let sourceLayer = getSourceLayer(source, '1x-layer', this.options);
    addLayer(sourceLayer, this.map);
  }
   */

  drawFeatures = (features = this.pointFeatures) => {
    let source = getSource(this.pointFeatures.features);
    let sourceLayer = getSourceLayer(source, 'districts', this.options);
    addLayer(sourceLayer, this.map);

    this.markers = this.constructMarkers(this.pointFeatures.features);
    this.addMarkers();
  }

  constructMarkers = (features = []) => {
    return features.map(feature => {
      const centerPoint = center(feature);
      let el = document.createElement('div');
      el.setAttribute('data-district_id', feature.properties.id);
      el.setAttribute('data-marker_center', centerPoint.geometry.coordinates);
      el.className = 'district_markers';

      const numLights = feature.properties.site_count || 0;
      const name = feature.properties.name;
      const text = `${numLights}`;

      el.innerHTML =
          `<div class='marker' title="${name}">
        <img src=${markerIcon} alt="-"/>
        <span style="font-size: 12px; width: 100%">${name.length > 10 ? name.substring(0, 10) + '...' : name}(${text})</span>
      </div>`;
      el.addEventListener('click', async e => {
        e.preventDefault();
        e.stopPropagation();

        let coordsSplit = e.currentTarget.dataset.marker_center.split(',');
        let coords = [Number.parseFloat(coordsSplit[0]), Number.parseFloat(coordsSplit[1])];

        this.props.zoom('IN', coords);
        this._addToSelections(e.currentTarget.dataset.district_id);
      });

      return new mapboxgl.Marker(el).setLngLat(
          centerPoint.geometry.coordinates
      );
    });
  }

  onDrag = async () => {
    const zoom = this.map.getZoom();
    if(zoom >= this.options.minZoom && zoom <= this.options.maxZoom) {
      this.clearMarkers();
      await this.updateFeatureData();
    }
  }

  onZoomChange = async () => {
    const zoom = this.map.getZoom();
    if (zoom > this.options.maxZoom || zoom < this.options.minZoom) {
      this.hideCurrentMarkersToLayer();
    }

    if (zoom >= this.options.minZoom && zoom <= this.options.maxZoom) {
      await this.displayCurrentMarkersToLayer();
    }
  }

  addMarkers = (markers = this.markers) => {
    markers.forEach(marker => {
      marker.addTo(this.map);
    });
  }

  clearMarkers = (markers = this.markers) => {
    markers.forEach(marker => {
      marker.remove();
    });
  }

  clear = () => {
    this.clearMarkers();
    //removeLayer('1X_LAYER', this.map);
    //this.map.off('zoom', () => this.onZoomChange());
  }

  setProps = (props) => {
    this.props = props;
  }

  setMarkersAdded = (markersAdded) => {
    this.markersAdded = markersAdded;
  }

  toggleMarkersHide = (display) => {
    let markers = document.getElementsByClassName("district_markers");
    for (let i = 0; i < markers.length; i++) {
      markers[i].style.visibility = !display ? "hidden" : "visible";
    }
  }

  hideCurrentMarkersToLayer = () => {
    this.toggleMarkersHide(false);
    this.markersAdded = false;
  }

  displayCurrentMarkersToLayer = async () => {
    if (!this.markersAdded) {
      //this.drawBackground(true);
      //await this.updateFeatureData();
      //this.addMarkers();
      this.toggleMarkersHide(true);
      this.markersAdded = true;
      //this.props.updateLayer(true);
    }
  }
}
