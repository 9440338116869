import React from 'react';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

 export default class AppLoader extends React.Component {  
    render() {
     return(
      <Loader type="Triangle" color="#0647a6" height={100} width={100} />
     );
    }
 }