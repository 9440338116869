import React, { Component } from 'react';
import { SplitButton } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import filterFactory, { textFilter, dateFilter, Comparator } from 'react-bootstrap-table2-filter';
import Select from "react-select";
import Modal from 'react-responsive-modal';
import Messages from '../constants/messages.js';
import { deleteIssueFromDb } from '../../modules/issues';
import { toast } from 'react-toastify';
import AppLoader from '../Loader/loader';
import { PRIORITY, ISSUE, STATUS } from '../../modules/issues';
import Confirmation from '../confirmation/confirmation.js';
import NewIssue from '../issues/new-issue';
import moment from 'moment';
import { getDeviceListFromDb } from '../../modules/inventory.js';
import { lightReadings } from '../api/LightReading.js';



var filterIssueTypeQuery = [], filterDeviceTypeQuery = [], filterPriorityTypeQuery = [], filterStatusQuery = [];

export default class IssuesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      priorityTypeOptions: [
        { label: 'High', value: 'High' },
        { label: 'Medium', value: 'Medium' }
      ],
      options: [
        { label: 'Light Controller', value: 'Light Controller' },
        { label: 'Gateway', value: 'Gateway' },
        { label: 'Motion Sensor', value: 'Motion Sensor' }
      ],
      issueTypeOptions: [
        { label: 'Replacement', value: 'Replacement' },
        { label: 'Maintenance', value: 'Maintenance' }
      ],
      currentStatusOptions: [
        { label: 'Cancelled', value: 'Cancelled' },
        { label: 'Reported', value: 'Reported' },
        { label: 'Pending', value: 'Pending' }
      ],
      currentStatus: {
        Cancelled: 'Cancelled',
        Reported: 'Reported',
        Pending: 'Pending'
      },
      data: this.props.data,
      beforeFilterData: this.props.data,
      openDeleteModal: false,
      selectedIssueId: '',
      showLoader: false,
      showNewIssueModal : false,
      showIssueCloseConfirmation : false,
      isIssueCloseFormDirty: false,
      selectedIssues:[],
      deviceList:{}
    };
  }

  componentDidMount() {

    this.getDevicesList();
  }

  onDeleteClicked(cell, row) {
    let selectedIssue = row.issue_id;
    this.setState({ openDeleteModal: !this.state.openDeleteModal, selectedIssueId: selectedIssue });
  }

  closeDeleteModal = () => {
    this.setState({ openDeleteModal: false });
  }

  getDevicesList = async () => {
    let data = await getDeviceListFromDb(); //api call for getting the device list from database
    if (data !== undefined && data !== null) {
      data = data.filter(l => {
        if (l.device_type !== undefined) {
          return l.device_type.toLowerCase() === "fybrlyte";
        }
      });
      let device = data.map(k => {
        return { key: k.site_id, label: k.site_name + ' (' + k.site_id + ')' }
      });
      this.setState({ deviceList: device })
    }
  }

  //for device type filtering
  handleDeviceTypeChange = selectedOption => {
    filterDeviceTypeQuery = [];
    if (selectedOption != null && selectedOption.length > 0) {
      const query1 = selectedOption[0] !== undefined ? selectedOption[0].value.toLowerCase() : 'null';
      const query2 = selectedOption[1] !== undefined ? selectedOption[1].value.toLowerCase() : 'null';
      const query3 = selectedOption[2] !== undefined ? selectedOption[2].value.toLowerCase() : 'null';
      const query4 = selectedOption[3] !== undefined ? selectedOption[3].value.toLowerCase() : 'null';
      if (query1 !== 'null') {
        if (filterDeviceTypeQuery.indexOf(query1) === -1)
          filterDeviceTypeQuery.push(query1);
      }
      if (query2 !== 'null') {
        if (filterDeviceTypeQuery.indexOf(query2) === -1)
          filterDeviceTypeQuery.push(query2);
      }
      if (query3 !== 'null') {
        if (filterDeviceTypeQuery.indexOf(query3) === -1)
          filterDeviceTypeQuery.push(query3);
      }
      if (query4 !== 'null') {
        if (filterDeviceTypeQuery.indexOf(query4) === -1)
          filterDeviceTypeQuery.push(query4);
      }
    }
    this.filterTableData();
  }

  //for issue type filtering
  handleIssueTypeChange = selectedOption => {
    filterIssueTypeQuery = [];
    if (selectedOption != null && selectedOption.length > 0) {
      const query1 = selectedOption[0] !== undefined ? selectedOption[0].value.toLowerCase() : 'null';
      const query2 = selectedOption[1] !== undefined ? selectedOption[1].value.toLowerCase() : 'null';
      if (query1 !== 'null') {
        if (filterIssueTypeQuery.indexOf(query1) === -1)
          filterIssueTypeQuery.push(query1);
      }
      if (query2 !== 'null') {
        if (filterIssueTypeQuery.indexOf(query2) === -1)
          filterIssueTypeQuery.push(query2);
      }
    }
    this.filterTableData();
  }

  //for priority type filtering
  handlePriorityTypeChange = selectedOption => {
    filterPriorityTypeQuery = [];
    if (selectedOption != null && selectedOption.length > 0) {
      const query1 = selectedOption[0] !== undefined ? selectedOption[0].value.toLowerCase() : 'null';
      const query2 = selectedOption[1] !== undefined ? selectedOption[1].value.toLowerCase() : 'null';
      if (query1 !== 'null') {
        if (filterPriorityTypeQuery.indexOf(query1) === -1)
          filterPriorityTypeQuery.push(query1);
      }
      if (query2 !== 'null') {
        if (filterPriorityTypeQuery.indexOf(query2) === -1)
          filterPriorityTypeQuery.push(query2);
      }
    }
    this.filterTableData();
  }

  //for priority type filtering
  handleStatusChange = selectedOption => {
    filterStatusQuery = [];
    if (selectedOption != null && selectedOption.length > 0) {
      const query1 = selectedOption[0] !== undefined ? selectedOption[0].value.toLowerCase() : 'null';
      const query2 = selectedOption[1] !== undefined ? selectedOption[1].value.toLowerCase() : 'null';
      const query3 = selectedOption[2] !== undefined ? selectedOption[2].value.toLowerCase() : 'null';
      if (query1 !== 'null') {
        if (filterStatusQuery.indexOf(query1) === -1)
          filterStatusQuery.push(query1);
      }
      if (query2 !== 'null') {
        if (filterStatusQuery.indexOf(query2) === -1)
          filterStatusQuery.push(query2);
      }
      if (query3 !== 'null') {
        if (filterStatusQuery.indexOf(query3) === -1)
          filterStatusQuery.push(query3);
      }
    }
    this.filterTableData();
  }

  //for filtering data according to various items selected
  filterTableData() {
    let data = this.state.beforeFilterData;
    let filteredData = data;
    //for device type filtering
    if (filterDeviceTypeQuery.length > 0) {
      filteredData = data.filter(item => {
        return (
          filterDeviceTypeQuery.includes(item.deviceType.toLowerCase())
        )
      });
    }
    //for issue type filtering
    if (filterIssueTypeQuery.length > 0) {
      if (filteredData.length > 0) {
        filteredData = filteredData.filter(item => {
          return (
            filterIssueTypeQuery.includes(item.type.toLowerCase())
          )
        })
      } else {
        if (filterDeviceTypeQuery.length <= 0 && filterPriorityTypeQuery.length <= 0 && filterStatusQuery.length <= 0) {
          filteredData = data.filter(item => {
            return (
              filterIssueTypeQuery.includes(item.type.toLowerCase())
            )
          });
        }
      }
    }
    //for priority type filtering
    if (filterPriorityTypeQuery.length > 0) {
      if (filteredData.length > 0) {
        filteredData = filteredData.filter(item => {
          return (
            filterPriorityTypeQuery.includes(item.priority.toLowerCase())
          )
        })
      } else {
        if (filterDeviceTypeQuery.length <= 0 && filterIssueTypeQuery.length <= 0 && filterStatusQuery.length <= 0) {
          filteredData = data.filter(item => {
            return (
              filterPriorityTypeQuery.includes(item.priority.toLowerCase())
            )
          });
        }
      }
    }

    //for status filtering
    if (filterStatusQuery.length > 0) {
      if (filteredData.length > 0) {
        filteredData = filteredData.filter(item => {
          return (
            filterStatusQuery.includes(item.status.toLowerCase())
          )
        })
      } else {
        if (filterDeviceTypeQuery.length <= 0 && filterIssueTypeQuery.length <= 0 && filterPriorityTypeQuery.length <= 0) {
          filteredData = data.filter(item => {
            return (
              filterStatusQuery.includes(item.status.toLowerCase())
            )
          });
        }
      }
    }
    //for setting data after all filtering
    this.setState({ data: filteredData });
  }

  arrayRemove(arr, value) {
    return arr.filter(function (ele) {
      return ele !== value;
    });
  }

  cellButton = (cell, row) => {
    return (
      <SplitButton drop="down" title="VIEW">
        <div className="issue-actions-ddl">
          <div className="action-items" onClick={() => this.onEditClicked(cell, row)}><i className="fa fa-edit" aria-hidden="true"></i> Edit </div>
          <div className="action-items" onClick={() => this.onDeleteClicked(cell, row)}><i className="fa fa-trash" aria-hidden="true"></i> Delete </div>
        </div>
      </SplitButton>
    );
  };

  onEditClicked(cell, row) {
    let selectedIssue = row.issue_id;
    this.setState({ showNewIssueModal: true, selectedIssueId: selectedIssue });
  }

  enumFormatter(cell, row, enumObject) {
    return enumObject[cell];
  }

  renderComments(cell, row) {
    let newVal = cell;
    if (cell !== "" && cell !== null ) {
      if (cell.length > 15) {
        newVal = newVal.substr(0, 15) + "...";
      }
    }
    return (
      <div className="pointer-class" title={cell}>{newVal}</div>
    );
  }

  onRowSelect = (val, row, e) => {
    let isSelected = e.target.checked;
    if(isSelected){
      this.setState({selectedIssues: [...this.state.selectedIssues,row.issue_id]},() => {
        this.props.onRowSelect(row, isSelected, this.state.selectedIssues);
      });
    }
    else{
      let index = this.state.selectedIssues.indexOf(row.issue_id);
      let newArray = this.state.selectedIssues.splice(index,1);
      this.setState({selectedIssues: newArray},() => {
        this.props.onRowSelect(row, isSelected, this.state.selectedIssues);
      });
    }

  }

  renderCheckboxes = (cell, row) => {
    return (
      <input type="checkbox" onChange={(e) => this.onRowSelect(cell, row, e)} />
    );
  }

  onDeleteIssue = async () => {
    this.setState({ showLoader: true }, () => {
      this.deleteIssue();
    })
  }

  deleteIssue = async () => {
    let response = await deleteIssueFromDb(this.state.selectedIssueId); //api call for deleting issue details from database
    if (response !== undefined && response !== null) {
      this.setState({ openDeleteModal: false }, () => {
        this.props.OnUpdate();
        toast.success(Messages.DeleteIssueSuccessText);
        setTimeout(() => {
          this.setState({showLoader: false});
         // window.location.reload(false);
        }, 2000);
      });
    }
  }

  renderCreatedDates = (cell) => {
    let date = new Date(cell);
    let localDate = date.toLocaleDateString();
    let localeTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    let timezone =  moment.tz(moment.tz.guess()).zoneAbbr();
    return (
      <span>
        <span>{localDate}</span><span>&nbsp;&nbsp;&nbsp;</span><span>{localeTime}</span>&nbsp;&nbsp;&nbsp;<span>{timezone}</span>
      </span>
    );
  }

  onCancelNewIssue = () => {
    this.setState({ showIssueCloseConfirmation: this.state.isIssueCloseFormDirty });

    if (!this.state.isIssueCloseFormDirty)
      this.setState({showNewIssueModal: false});
  }

  issueCloseConfirmation = () => {
    this.setState({ showIssueCloseConfirmation: false });
  }

  onIssueCloseConfirm = () => {
    this.setState({ showIssueCloseConfirmation: false });
    this.setState({ showNewIssueModal: false });
    this.setFormDirty(false);
  }

  setFormDirty = (isDirty) => {
    this.setState({isIssueCloseFormDirty: isDirty})
  }

  OnUpdate=()=>{
    this.props.OnUpdate();
    this.setState({showNewIssueModal:false})
  }

  render() {
    const objectToArray = item => {
      return Object.keys(item).map(key => {
        return { key: key, label: item[key] };
      });
    };
    const statusTypes = objectToArray(STATUS);
    const deviceIssues = objectToArray(ISSUE);
    const priorityTypes = objectToArray(PRIORITY);


    const { columns } = this.props;
    const { data } = this.props;

    //for column filtering
    let showSiteId = "column-hide", showPriority = "column-hide", showDeviceType = "column-hide", showType = "column-hide", showLocation = "column-hide", showDateCreated = "column-hide", showComment = "column-hide", showStatus = "column-hide";
    for (let i = 0; i < columns.length; i++) {
      let keyVal = columns[i].key;
      switch (keyVal) {
        case "site_id":
          showSiteId = "column-show";
          break;
        case "priority":
          showPriority = "column-show";
          break;
        // case "device_type":
        //   showDeviceType = "column-show";
        //   break;
        case "issue_type":
          showType = "column-show";
          break;
        case "location":
          showLocation = "column-show";
          break;
        case "date_created":
          showDateCreated = "column-show";
          break;
        case "comments":
          showComment = "column-show";
          break;
        case "status":
          showStatus = "column-show";
          break;
        default:
          break;
      }
    }

    //for resizing functionality
    const { rowType } = this.props;
    let rowDisplayClass = "issue-medium-row-size";
    if (rowType === 'thick') {
      rowDisplayClass = "issue-thick-row-size";
    } else if (rowType === 'medium') {
      rowDisplayClass = "issue-medium-row-size";
    } else if (rowType === 'thin') {
      rowDisplayClass = "issue-thin-row-size";
    }

    const dataColumns = [{
      dataField: 'issue_id',
      text: 'ID',
      hidden: true
    }, {
      dataField: 'checkbox',
      text: 'SELECT',
      formatter: this.renderCheckboxes
    }, {
      dataField: 'site_id',
      text: 'SITE ID',
      sort: true,
      classes: showSiteId,
      headerClasses: showSiteId,
      filter: textFilter()
    }, {
      dataField: 'priority',
      text: 'PRIORITY',
      sort: true,
      classes: showPriority,
      headerClasses: showPriority,
      filter: textFilter()
    },
    {
      dataField: 'issue_type',
      text: 'ISSUE TYPE',
      sort: true,
      classes: showType,
      headerClasses: showType,
      filter: textFilter()
    },
    {
      dataField: 'work_order_name',
      text: 'WORK ORDER',
      sort: true,
      classes: showType,
      headerClasses: showType,
      defaultValue:'test Order 1',
      filter: textFilter(),
    },
    {
      dataField: 'location',
      text: 'LOCATION',
      sort: true,
      classes: showLocation,
      headerClasses: showLocation,
      filter: textFilter()
    }, {
      dataField: 'date_created',
      text: 'DATE CREATED',
      sort: true,
      classes: showDateCreated,
      headerClasses: showDateCreated,
      filter: dateFilter({
        withoutEmptyComparatorOption: true,
        comparators: [Comparator.EQ],
      }),
      formatter: this.renderCreatedDates
    }, {
      dataField: 'comments',
      text: 'COMMENTS',
      sort: true,
      classes: showComment,
      headerClasses: showComment,
      formatter: this.renderComments
    }, {
      dataField: 'status',
      text: 'STATUS',
      sort: true,
      classes: showStatus,
      headerClasses: showStatus,
      filter: textFilter()
    }, {
      dataField: 'button',
      text: 'ACTIONS',
      formatter: this.cellButton
    }];

    const defaultSorted = [{
      dataField: 'date_created',
      order: 'desc'
    }];

    return (
      <div className="table-container fixed-table-footer">
        <BootstrapTable  wrapperClasses="table-common table-issue-mob" rowClasses={rowDisplayClass} ref="refissuetable" keyField='issue_id' data={data} columns={dataColumns} pagination={paginationFactory()} filter={filterFactory()} />
        <div id="deleteModalDiv">
          <Modal classNames={{ modal: "delete-modal-dialog-div" }} open={this.state.openDeleteModal} onClose={this.closeDeleteModal} center closeOnOverlayClick={false}>
            <div>
              <h4>Delete this issue ? </h4>
              <p className="delete-p-text">{Messages.DeleteIssueText}</p>
              <div className="delete-buttons-div">
                <button onClick={this.onDeleteIssue} className="delete-confirm-button">DELETE ISSUE</button>
                <button onClick={this.closeDeleteModal} className="confirmation-cancel-btn">CANCEL</button>
              </div>
            </div>
          </Modal>
        </div>

        <div id="editNewIssue">
          <Modal classNames={{ modal: "create-issue-modal" }} open={this.state.showNewIssueModal} onClose={this.onCancelNewIssue} right closeOnOverlayClick={false}>
            <NewIssue selectedIssueId={this.state.selectedIssueId} deviceList={this.state.deviceList} statusTypes={statusTypes} issueType={deviceIssues} priorityTypes={priorityTypes} onNewIssue={this.onNewIssue} onCancel={this.onCancelNewIssue} onUpdate={this.OnUpdate} isFormDirty={this.setFormDirty} isDirty={this.state.isIssueCloseFormDirty} />
          </Modal>
        </div>
        {
          this.state.showIssueCloseConfirmation &&
          (
            <Confirmation confirmationText={Messages.IssueCloseConfirmation} confirmButtonText="Confirm" rejectButtonText="Cancel" openConfirmation={true} closeConfirmationModal={this.issueCloseConfirmation} onConfirmClicked={this.onIssueCloseConfirm} />
          )
        }

        {
          this.state.showLoader &&
          (
            <div className="overlay">
              <div className="application-loader">
                <AppLoader />
              </div>
            </div>
          )
        }
      </div>
    );
  }
}
