import React, { Component } from 'react';
import { css } from 'emotion';
export default class RowResizer extends Component {

  constructor(props) {
    super(props);
    this.onThickBarSelected = this.onThickBarSelected.bind(this);
    this.onMediumBarSelected = this.onMediumBarSelected.bind(this);
    this.onThinBarSelected = this.onThinBarSelected.bind(this);
    this.state = {
      selected: 'medium'
    }
  }

  onThickBarSelected() {
    this.setState({
      selected: 'thick'
    });
    this.props.onRowSizeChanged('thick');
  }

  onMediumBarSelected() {
    this.setState({
      selected: 'medium'
    });
    this.props.onRowSizeChanged('medium');
  }

  onThinBarSelected() {
    this.setState({
      selected: 'thin'
    });
    this.props.onRowSizeChanged('thin');
  }

  getBarStyle(selected, barSize) {
    return css({
      padding: '1rem',
      backgroundColor: `${selected ? '#F4F5F7' : 'white'}`,
      background: `linear-gradient(
        to bottom,
        #5E6C84,
        #5E6C84 50%,
        ${selected ? '#F4F5F7' : 'white'} 50%,
        ${selected ? '#F4F5F7' : 'white'}
      )`,
      backgroundSize: `100% ${barSize}px`,
      backgroundPosition: 'top 2px right 0px'
    });
  }

  render() {
    const container = css({
      display: 'flex',
      flexDirection: 'row',
      padding: '2px',
      backgroundColor: '#DFE1E5',
      borderRadius: '3px',
      height: '42px',
      alignItems: 'center',
      alignSelf: 'center'
    });
    // THICK BAR
    const isThickBarSelected = this.state.selected === 'thick';
    const barThickContainerStyle = css({
      backgroundColor: `${isThickBarSelected ? '#F4F5F7' : 'white'}`,
      padding: '5px',
      borderRadius: '2px 0px 0px 2px',
      marginRight: '2px'
    });
    const barThickStyle = this.getBarStyle(isThickBarSelected, 11);
    // MEDIUM BAR
    const isMediumBarSelected = this.state.selected === 'medium';
    const barMediumContainerStyle = css({
      backgroundColor: `${isMediumBarSelected ? '#F4F5F7' : 'white'}`,
      padding: '5px'
    });
    const barMediumStyle = this.getBarStyle(isMediumBarSelected, 8);
    // THIN BAR
    const isThinBarSelected = this.state.selected === 'thin';
    const barThinContainerStyle = css({
      backgroundColor: `${isThinBarSelected ? '#F4F5F7' : 'white'}`,
      padding: '5px',
      borderRadius: '0px 2px 2px 0px',
      marginLeft: '2px'
    });
    const barThinStyle = this.getBarStyle(isThinBarSelected, 5);

    return (
      <div className={container}>
        <div className={barThickContainerStyle} onClick={this.onThickBarSelected}>
          <div className={barThickStyle}/>
        </div>
        <div className={barMediumContainerStyle} onClick={this.onMediumBarSelected}>
          <div className={barMediumStyle}/>
        </div>
        <div className={barThinContainerStyle} onClick={this.onThinBarSelected}>
          <div className={barThinStyle}/>
        </div>
      </div>
    )
  }

}
