export const tabs = [
  {
    name: 'OVERVIEW',
    id: 'overview',
    href: '/'
  },
  {
    name: 'MAP',
    id: 'controls',
    href: '/map'
  },
  {
    name: 'SITES',
    id: 'site',
    href: '/site'
  },
  {
    name: 'INVENTORY',
    id: 'inventory',
    href: '/inventory'
  },
  {
    name: 'GROUPS',
    id: 'group',
    href: '/group'
  },
  {
    name: 'SCHEDULING',
    id: 'scheduling',
    href: '/scheduling'
  },
  {
    name: 'WORK ORDERS',
    id: 'workorder',
    href: '/workorder'
  },
  {
    name: 'ISSUES',
    id: 'issues',
    href: '/issues'
  }
];
