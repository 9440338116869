export function get3xBgStyle(id, sourceId, minZoom, maxZoom) {
  return {
    id: id,
    type: 'circle',
    source: sourceId,
    paint: {
      'circle-color': '#8E8E8E',
      'circle-radius': 10,
      'circle-opacity': 0.6
    },
    minzoom: minZoom,
    maxzoom: maxZoom,
      filter: ['all', ['==', 'selected', false],
          ['in', 'mode', 0, 3],
          ['!has', 'point_count']]
  };
}

export function get3xTurnedOnBgStyle(id, sourceId, minZoom, maxZoom) {
  return {
    id: id,
    type: 'circle',
    source: sourceId,
    paint: {
      'circle-color': '#ffc402',
      'circle-radius': 10,
      'circle-opacity': 0.4
    },
    minzoom: minZoom,
    maxzoom: maxZoom,
    filter: ['all', ['!=', 'selected', true],
                    ['any', ['==', 'mode', 1],
                    ['==', 'mode', 2]],
                    ['!has', 'point_count']]
  };
}

/*
export function get3xTurnedOnBgTwoStyle(id, sourceId, minZoom, maxZoom) {
  return {
    id: id,
    type: 'circle',
    source: sourceId,
    paint: {
      'circle-color': '#ffc402',
      'circle-radius': 10,
      'circle-opacity': 0.4
    },
    minzoom: minZoom,
    maxzoom: maxZoom,
    filter: ['==', 'mode', 2]
  };
}
 */

export function get3xBgStyleSite(id, sourceId, minZoom, maxZoom) {
    return {
        id: id,
        type: 'circle',
        source: sourceId,
        paint: {
            'circle-color': '#2584ff',
            'circle-radius': 10,
            'circle-opacity': 0.6
        },
        minzoom: minZoom,
        maxzoom: maxZoom,
        filter: ['all', ['!=', 'selected', true],
            ['!in', 'mode', 0, 1, 2, 3],
            ['!has', 'point_count']]
    };
}

export function get3xSelectedOutlineSiteStyle(id, sourceId, minZoom, maxZoom) {
    return {
        id: id,
        type: 'circle',
        source: sourceId,
        paint: {
            'circle-color': '#0151cb',
            'circle-radius': 14,
            'circle-opacity': 1
        },
        minzoom: minZoom,
        maxzoom: maxZoom,
        filter: ['all', ['==', 'selected', true],
                        ['!in', 'mode', 0, 1, 2, 3],
                        ['!has', 'point_count']]
    };
}

export function get3xSelectedBgSiteStyle(id, sourceId, minZoom, maxZoom) {
    return {
        id: id,
        type: 'circle',
        source: sourceId,
        paint: {
            'circle-color': '#2584ff',
            'circle-radius': 10,
            'circle-opacity': 1
        },
        minzoom: minZoom,
        maxzoom: maxZoom,
        filter: ['all', ['==', 'selected', true],
            ['!in', 'mode', 0, 1, 2, 3],
            ['!has', 'point_count']]
    };
}

export function get3xSelectedOutlineStyle(id, sourceId, minZoom, maxZoom) {
  return {
    id: id,
    type: 'circle',
    source: sourceId,
    paint: {
      'circle-color': '#ff8a00',
      'circle-radius': 14
    },
    minzoom: minZoom,
    maxzoom: maxZoom,
      filter: ['all', ['==', 'selected', true],
          ['in', 'mode', 1, 2],
          ['!has', 'point_count']]
  };
}

export function get3xSelectedBgStyle(id, sourceId, minZoom, maxZoom) {
  return {
    id: id,
    type: 'circle',
    source: sourceId,
    paint: {
      'circle-color': '#ffc402',
      'circle-radius': 10,
    },
    minzoom: minZoom,
    maxzoom: maxZoom,
      filter: ['all', ['==', 'selected', true],
          ['in', 'mode', 1, 2],
          ['!has', 'point_count']]
  };
}

export function get3xSelectedOutlineOffStyle(id, sourceId, minZoom, maxZoom) {
    return {
        id: id,
        type: 'circle',
        source: sourceId,
        paint: {
            'circle-color': '#4A4A4A',
            'circle-radius': 14,
            'circle-opacity': 1
        },
        minzoom: minZoom,
        maxzoom: maxZoom,
        filter: ['all', ['==', 'selected', true],
            ['in', 'mode', 0, 3],
            ['!has', 'point_count']]
    };
}

export function get3xSelectedBgOffStyle(id, sourceId, minZoom, maxZoom) {
    return {
        id: id,
        type: 'circle',
        source: sourceId,
        paint: {
            'circle-color': '#8E8E8E',
            'circle-radius': 10,
            'circle-opacity': 1
        },
        minzoom: minZoom,
        maxzoom: maxZoom,
        filter: ['all', ['==', 'selected', true],
            ['in', 'mode', 0, 3],
            ['!has', 'point_count']]
    };
}
