import React, { Component } from 'react';
import Button from '../button/button.js';
import { css } from 'emotion';
import Select from "react-select";
import {
    SITE,
    STATUS,
    PRIORITY,
    createSiteInDb,
    getSiteDetailsFromDb,
    updateSiteInDb,
    allocateDeviceInDb,
    allocateSiteInDb,
    allocateCoordinatesInDb,
    updateSiteCoordinatesInDb,
    getSiteCoordinatesInDb,
    getDeviceCoordinatesInDb
} from '../../modules/site';
import Messages from '../constants/messages.js';
import { toast } from 'react-toastify';
import AppLoader from '../Loader/loader';
import Autosuggest from 'react-autosuggest';
import {allocateSite} from "../api/site";

export default class Allocate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pairId: '',
            deviceId: '',
            siteId: '',
            macAddress: '',
            longitude: '',
            latitude: '',
            allocateDevice: false,
            errors: [],
            showLoader: false,
            suggestions: []
        };
    }

    handleValidation = () => {
        let {allocateDevice} = this.props;
        let isValid = true;
        let errors = [];
        let state = this.state;

        if (!state["pairId"]) {
            errors["pairId"] = allocateDevice ? Messages.DeviceIdRequiredText : Messages.SiteIdRequiredText;
            isValid = false;
        }

        return isValid
    }

    onSubmit = () => {

        let {submit} = this.props;
        if (this.handleValidation()) {

            this.setState({showLoader: true}, () => {

                submit(this.state.pairId, this.state.allocateDevice);
            });
        }
    }

    getSuggestionValue = (suggestion) => {
        let {isFormDirty} = this.props;

        isFormDirty(true);
        return suggestion.name;
    }

    renderSuggestion = suggestion => {
        return (
            <div className={autoSuggestDropDown}>
                {suggestion.name}
            </div>
        )
    };

    onChange = (event, { newValue }) => {
        this.setState({
            macAddress: newValue
        });
    };

    getAutoSuggestInput = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        let {onAutoSuggestChange, isFormDirty} = this.props;
        this.setState({
            pairId: suggestion.value,
            longitude: suggestion.longitude ? suggestion.longitude : null,
            latitude: suggestion.latitude ? suggestion.latitude : null,
        });

        if (onAutoSuggestChange) {
            onAutoSuggestChange(suggestion.value);
        }
    };

    onSuggestionsFetchRequested = ({ value }) => {
        let {getSuggestions} = this.props;

        getSuggestions(value).then((list) => {
            this.setState({
                suggestions: list
            });
        })
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    render() {
        let {allocateDevice} = this.props;
        let {isFormDirty} = this.props;

        const inputProps = {
            placeholder: allocateDevice ? 'Type a mac address' : 'Type a site name',
            value: this.state.macAddress,
            onChange: this.onChange
        };

        let createUpdateBtn = <Button buttonTextStyle={btnStyle} text="ALLOCATE" buttonClickCallback={this.onSubmit.bind(this)} />;

        let title = "Allocate Site";
        if (allocateDevice) {
            title = "Allocate Device";
        }
        return (
            <div>
                <h4 className="class-bold">{title}</h4>
                <div className={table}>
                    <div className={wrapper}>

                        <div className={item} style={{zIndex: 100}}>
                            {
                                (allocateDevice) ?
                                <div className="class-bold">Device Mac Address <span className="important-fields">*</span></div> :
                                <div className="class-bold">Site Name <span className="important-fields">*</span></div>
                            }
                            <Autosuggest
                                suggestions={this.state.suggestions}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested.bind(this)}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequested.bind(this)}
                                getSuggestionValue={this.getSuggestionValue.bind(this)}
                                renderSuggestion={this.renderSuggestion.bind(this)}
                                inputProps={inputProps}
                                onSuggestionSelected={this.getAutoSuggestInput.bind(this)}
                                theme={theme}
                            />
                            <div className="application-error-messages">{this.state.errors["pairId"]}</div>
                        </div>

                        <div className={item}>
                            <div className="class-bold" style={{marginTop: 5}}>Longitude</div>
                            <input readonly className="text-box"  value={this.state.longitude} onChange={(event) => {this.setState({ longitude: event.target.value, errors: [] }); isFormDirty(true);}} type="text" maxLength="30"></input>
                            <div className="application-error-messages">{this.state.errors["longitude"]}</div>
                        </div>

                        <div className={item} style={{zIndex: 1}}>
                            <div className="class-bold">Latitude</div>
                            <input readonly className="text-box" value={this.state.latitude} onChange={(event) => {this.setState({ latitude: event.target.value, errors: [] }); isFormDirty(true);}} type="text" maxLength="30"></input>
                            <div className="application-error-messages">{this.state.errors["longitude"]}</div>
                        </div>

                    </div>
                </div>
                <div className="application-error-messages error-messages-center">{this.state.errors["generalErrors"]}</div>
                <div className={footer} style={{justifyContent: 'left', marginLeft: '20px'}}>
                    <input name="allocateDevice" type="checkbox" defaultChecked={this.state.allocateDevice} onChange={(event) => { this.setState({ allocateDevice: event.target.value, errors: [] }); isFormDirty(true);}} />&nbsp;<span>Apply the device coordinates</span>
                </div>
                <div className={footer} style={{marginTop: '10px'}}>
                    <div className={cancelButtonContainer} onClick={this.props.onCancel}>
                        <div className={cancelButton}>
                            <div>CANCEL</div>
                        </div>
                    </div>
                    <div>
                        {createUpdateBtn}
                    </div>
                </div>
                {
                    this.state.showLoader &&
                    (
                        <div className="overlay">
                            <div className="application-loader">
                                <AppLoader />
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }

}

const autoSuggestDropDown = css({
    backgroundColor: '#ffffff',
    listStyleType: 'none'
})

const btnStyle = css({
    fontFamily: 'OpenSans',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: '1px',
});

const footer = css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '70px'
});


const table = css({
});

const wrapper = css({
    display: 'grid',
    gridTemplateColumns: '300px 300px',
    gridTemplateRows: '80px 80px 80px 80px',
    gridGap: '10px',
});

const item = css({
    padding: '20px 20px 5px 20px'
});

const rightItem = css({
    gridColumn: '2 / 2',
    gridRow: '2 / 5',
    padding: '28px 20px 15px 20px'
});

const textAreaStyle = css({
    height: '100%',
    width: '96%',
    borderRadius: '2px',
    resize: 'none',
    backgroundColor: '#ffffff',
    border: 'solid 1px #dbdbdb',
    '&:focus': {
        outline: 'none',
        border: '1.5px solid #0151cb'
    },
    boxSizing: 'border-box',
    padding: '10px'
});

const cancelButton = css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderRadius: '2px',
    backgroundColor: '#FFFFFF',
    width: '153px',
    height: '100%',
    fontFamily: 'OpenSans',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: '1px',
    color: '#5e6c84',
    border: '1px solid #C4CDD5',
    '&:hover': {
        backgroundColor: '#F4F5F7',
    },
});

const cancelButtonContainer = css({
    display: 'flex',
    flexGrow: '0.1'
});

const theme = {
    input: {
        width: '250px',
        height: '38px'
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    suggestion: {
        cursor: 'pointer',
        padding: '0px 0px 0px 0px'
    }
};
