import { REHYDRATE } from 'redux-persist/constants';
import orderby from 'lodash.orderby';
import {
  arrayToObject,
  getLimits,
  addItemsToArray,
  removeItemsFromArray
} from '../utils/utils';
import {CHECK_USER_TIMEOUT} from './user';
import flow from 'lodash.flow';

import { getIssuesList, deleteIssueData, createIssue , getIssueDetails, updateIssue } from '../components/api/issues';
import { API_ROOT } from '../components/constants/api';


export const PRIORITY = {
  LOW: 'Low',
  MEDIUM: 'Medium',
  HIGH: 'High'
};

export const ISSUE = {
  REPLACEMENT: 'Replacement',
  MAINTENANCE: 'Maintenance'
};

export const STATUS = {
  CLOSED: 'Closed',
  REPORTED: 'Reported',
  PENDING: 'Pending',
  
};

export const DEVICETYPE = {
  LIGHTCONTROLLER: 'Light Controller',
  GATEWAY: 'Gateway' 
};
let WORK_ORDER = {
  WORK_ORDER_1: 'Work Order No. 1',
  WORK_ORDER_2: 'Work Order No. 2',
  WORK_ORDER_3: 'Work Order No. 3',
  WORK_ORDER_4: 'Work Order No. 4'
};

let ISSUES = [];

export const KEYS = {
  SITEID: 'site_id',
  PRIORITY: 'priority',
  ISSUETYPE: 'issue_type',
  LOCATION: 'location',
  DATECREATED: 'date_created',
  COMMENTS: 'comments',
  STATUS: 'status'
};

//modified on 12th sept 2019
export const COLUMNS = [
  { label: 'Site Id', key: KEYS.SITEID, isRequired: true },
  { label: 'Priority', key: KEYS.PRIORITY, isRequired: true },
  { label: 'Issue Type', key: KEYS.ISSUETYPE, isRequired: true },
  { label: 'Location', key: KEYS.LOCATION, isRequired: true },
  { label: 'Date Created', key: KEYS.DATECREATED, isRequired: true },
  { label: 'Current Status', key: KEYS.STATUS, isRequired: true },
  { label: 'Comments', key: KEYS.COMMENTS, isRequired: true } 
];

export const initialFilters = {
  deviceId: null,
  priority: [PRIORITY.MEDIUM, PRIORITY.HIGH],
  deviceType: [DEVICETYPE.LIGHTCONTROLLER, DEVICETYPE.GATEWAY],
  type: [ISSUE.REPLACEMENT, ISSUE.MAINTENANCE],
  location: null,
  dateCreated: null,
  status: [STATUS.CANCELLED, STATUS.REPORTED, STATUS.PENDING],
  selected: null
};

const CURRENT_PAGE = 1;
const LIMIT = Math.min(ISSUES.length, 10);
const defaultLimits = getLimits(ISSUES, CURRENT_PAGE, LIMIT);

const END = defaultLimits.END;
const START = defaultLimits.START;

const initialState = {
  data: ISSUES.slice(0),
  workOrder: WORK_ORDER,
  filters: initialFilters,
  sortBy: null,
  allSelected: false,
  columns: COLUMNS,
  currentPage: CURRENT_PAGE,
  showingStart: START,
  showingEnd: END,
  maxPages: defaultLimits.MAXPAGES,
  columnInSort: null,
  message: null,
  limit: LIMIT,
  defaultLimit: LIMIT
};

const TYPES = {
  FILTER: 'FILTER',
  SELECT_ALL: 'SELECT_ALL',
  SELECT: 'SELECT',
  SEARCH_STRING: 'SEARCH_STRING',
  RESET: 'RESET',
  SORT: 'SORT',
  PAGINATE_RIGHT: 'PAGINATE_RIGHT',
  PAGINATE_LEFT: 'PAGINATE_LEFT',
  CHANGE_LIMIT: 'CHANGE_LIMIT',
  NEW_ISSUE: 'NEW_ISSUE',
  CLOSE_SELECTED_ISSUES: 'CLOSE_SELECTED_ISSUES',
  SHOW_COLUMNS: 'SHOW_COLUMNS',
  FILTER_TYPES_IN_COLUMN: 'FILTER_TYPES_IN_COLUMN',
  DISMISS_NOTIFICATION: 'DISMISS_NOTIFICATION',
  SHOW_NOTIFICATION: 'SHOW_NOTIFICATION',
  POST_COMMENT: 'POST_COMMENT',
  ADD_TO_WORK_ORDER: 'ADD_TO_WORK_ORDER',
  NEW_WORK_ORDER: 'NEW_WORK_ORDER'
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REHYDRATE:
      const newState = { ...initialState };
      return newState;
    case TYPES.SELECT_ALL:
      return performMultiSelection(state, action.payload.select);
    case TYPES.SELECT:
      const id = action.payload.id;
      const checked = action.payload.select;
      return performSelection(state, checked, id);
    case TYPES.SEARCH_STRING:
      const searchString = action.payload.searchString;
      const columnId = action.payload.columnId;
      const trimmedString = searchString.trim();
      const verifiedString = trimmedString.length === 0 ? null : trimmedString;
      const filters = { ...state.filters, [columnId]: verifiedString };
      return recomputePages(applyFilters(state, ISSUES, filters));
    case TYPES.SORT:
      const order = action.payload.order;
      const key = action.payload.key;
      return performSort(state, order, key);
    case TYPES.PAGINATE_RIGHT:
      return paginateRight(state);
    case TYPES.PAGINATE_LEFT:
      return paginateLeft(state);
    case TYPES.CHANGE_LIMIT:
      const changeLimitForData = flow([changeLimit, recomputePages]);
      return changeLimitForData(
        state,
        action.payload.limit,
        action.payload.defaultLimit
      );
    case TYPES.RESET:
      return { ...initialState };
    case TYPES.NEW_ISSUE:
      const issue = action.payload.issue;
      return addNewIssue(state, issue, ISSUES);
    case TYPES.CLOSE_SELECTED_ISSUES:
      ISSUES = ISSUES.filter(filterDeselectedIssues);
      return removeIssues(state);
    case TYPES.SHOW_COLUMNS:
      const columnIdentifier = action.payload.identifier;
      const show = action.payload.show;
      return filterColumns(state, columnIdentifier, show, COLUMNS);
    case TYPES.FILTER_TYPES_IN_COLUMN:
      const stateAfterSubTypeFilter = filterSubColumns(state, action.payload);
      return recomputePages(
        applyFilters(
          stateAfterSubTypeFilter,
          ISSUES,
          stateAfterSubTypeFilter.filters
        )
      );
    case TYPES.SHOW_NOTIFICATION:
      let message = action.payload.message;
      return { ...state, message: message };
    case TYPES.DISMISS_NOTIFICATION:
      return { ...state, message: null };
    case TYPES.POST_COMMENT:
      return postComments(state, action.payload.comment);
    case TYPES.ADD_TO_WORK_ORDER:
      return addWorkOrder(state, action.payload.order);
    case TYPES.NEW_WORK_ORDER:
      return newWorkOrder(state, action.payload.order);
    default:
      return state;
  }
};

function changeLimit(state, limit, defaultLimit) {
  return {
    ...state,
    limit: limit || state.limit,
    defaultLimit: defaultLimit || state.defaultLimit
  };
}

function recomputePages(state) {
  const curPage = 1;
  const limits = getLimits(state.data, curPage, state.limit);
  const stateWithPagesRecomputed = {
    ...state,
    currentPage: curPage,
    showingEnd: limits.END,
    showingStart: limits.START,
    maxPages: limits.MAXPAGES
  };
  return stateWithPagesRecomputed;
}

function applyFilters(state, initialData, filters) {
  const deviceId = filters.deviceId;
  const priorities = filters.priority;
  const deviceTypes = filters.deviceType;
  const issueTypes = filters.type;
  const selected = filters.selected;
  const location = filters.location;
  const statusTypes = filters.status;
  const dateCreated = filters.dateCreated;

  const issues = arrayToObject(state.data, 'id');

  const filteredData = initialData.filter(issue => {
    const deviceFilter =
      deviceId === null
        ? true
        : issue.deviceId.toLowerCase().indexOf(deviceId.toLowerCase()) !== -1;
    const locationFilter =
      location === null
        ? true
        : issue.location.toLowerCase().indexOf(location.toLowerCase()) !== -1;
    const priorityFilter = priorities.indexOf(issue.priority) !== -1;
    const deviceTypeFilter = deviceTypes.indexOf(issue.deviceType) !== -1;
    const issueTypeFilter = issueTypes.indexOf(issue.type) !== -1;
    const statusTypeFilter = statusTypes.indexOf(issue.status) !== -1;
    const selectionFilter =
      selected === null ? true : issue.isSelected === selected;
    const dateFilter =
      dateCreated === null
        ? true
        : issue.dateCreated.toLowerCase().indexOf(dateCreated.toLowerCase()) !==
          -1;

    const isFiltered =
      deviceFilter &&
      priorityFilter &&
      deviceTypeFilter &&
      issueTypeFilter &&
      statusTypeFilter &&
      selectionFilter &&
      locationFilter &&
      dateFilter;
    return isFiltered;
  });

  const filteredDataWithMergedState = filteredData.map(data => {
    if (issues[data.id]) return issues[data.id];
    return data;
  });

  return { ...state, data: filteredDataWithMergedState, filters: filters };
}

function addNewIssue(state, issue, initialData) {
  const identifier =
    initialData.reduce((prev, next) => {
      const max = Math.max(prev, next.id);
      return max;
    }, 0) + 1;
  const newIssue = { id: identifier, ...issue };
  initialData.push(newIssue);
  return { ...state, data: [...state.data, newIssue] };
}

const filterDeselectedIssues = issue => {
  return !issue.isSelected;
};

function removeIssues(state) {
  const deselectedIssues = state.data.filter(filterDeselectedIssues);
  const extras = { allSelected: false };
  return { ...state, data: deselectedIssues, ...extras };
}

function filterColumns(state, columnId, show, columns = []) {
  const filteredColumns = state.columns.map(column => {
    let isRequired = column.key === columnId ? show : column.isRequired;
    return { ...column, isRequired };
  });
  return { ...state, columns: filteredColumns };
}

function filterSubColumns(state, payload) {
  const { columnId, types, show } = payload;
  // E.g., columnId = 'priority', type: 'Medium', show: false
  const filters = state.filters;
  const currentTypeFiltersForCoumn = filters[columnId];
  const newTypeFilter = show
    ? addItemsToArray(currentTypeFiltersForCoumn, types)
    : removeItemsFromArray(currentTypeFiltersForCoumn, types);
  const newFilter = { ...filters, [columnId]: newTypeFilter };
  return { ...state, filters: newFilter };
}

function performMultiSelection(state, select) {
  const shouldBeChecked = select;
  const newData = state.data.map(data => {
    const issue = { ...data, isSelected: shouldBeChecked };
    return issue;
  });
  return { ...state, data: newData, allSelected: select };
}

function postComments(state, comment) {
  let newData = state.data.map(i => {
    if (i.isSelected) {
      return { ...i, comments: comment };
    }
    return i;
  });
  return { ...state, data: newData };
}

export function postComment(comment) {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TYPES.POST_COMMENT,
      payload: {
        comment
      }
    });
  };
}

function addWorkOrder(state, order) {
  let newData = state.data.map(i => {
    if (i.isSelected) {
      return { ...i, status: state.workOrder[order] };
    }
    return i;
  });
  return { ...state, data: newData };
}

export function addToWorkOrder(order) {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TYPES.ADD_TO_WORK_ORDER,
      payload: {
        order
      }
    });
  };
}

function newWorkOrder(state, order) {
  let count = Object.keys(state.workOrder).length + 1;
  let nextWorkOrder = Object.assign({}, state.workOrder);
  nextWorkOrder['WORK_ORDER_' + count] = order;
  return { ...state, workOrder: nextWorkOrder };
}

export function createNewWorkOrder(order) {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TYPES.NEW_WORK_ORDER,
      payload: {
        order
      }
    });
  };
}

export function selectAll(select) {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TYPES.SELECT_ALL,
      payload: {
        select
      }
    });
  };
}

function performSelection(state, select, id) {
  const shouldBeChecked = select;
  const newData = state.data.map(data => {
    if (parseInt(data.id, 10) === parseInt(id, 10)) {
      const issue = { ...data, isSelected: shouldBeChecked };
      return issue;
    }
    return data;
  });
  return { ...state, data: newData, allSelected: false };
}

export function select(id, select) {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TYPES.SELECT,
      payload: {
        select,
        id
      }
    });
  };
}

export function search(searchString, columnId) {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TYPES.SEARCH_STRING,
      payload: {
        searchString,
        columnId
      }
    });
  };
}

function performSort(state, order, key) {
  const issues = state.data.slice(0);
  const sortState = { columnInSort: key, sortBy: order };
  if (order === 'asc' || order === 'desc') {
    return { ...state, data: orderby(issues, [key], [order]), ...sortState };
  } else {
    return { ...state, data: orderby(issues, ['id'], ['asc']), ...sortState };
  }
}

function paginateRight(state) {
  const currentPage = state.currentPage;
  const numItems = state.data.length;
  const adjustedLimit = Math.min(numItems, state.limit);
  const maxPages = Math.ceil(numItems / adjustedLimit);
  const nextPage = Math.min(currentPage + 1, maxPages);

  const limits = getLimits(state.data, nextPage, state.limit);
  if (currentPage === limits.MAXPAGES) {
    return state;
  }

  return {
    ...state,
    currentPage: nextPage,
    showingEnd: limits.END,
    showingStart: limits.START,
    maxPages: limits.MAXPAGES
  };
}

function paginateLeft(state) {
  const currentPage = state.currentPage;
  if (currentPage === 1) {
    return state;
  }
  const nextPage = Math.max(currentPage - 1, 1);
  const limits = getLimits(state.data, nextPage, state.limit);

  return {
    ...state,
    currentPage: nextPage,
    showingEnd: limits.END,
    showingStart: limits.START,
    maxPages: limits.MAXPAGES
  };
}

export function changeDataLimit(limit, defaultLimit) {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TYPES.CHANGE_LIMIT,
      payload: {
        limit,
        defaultLimit
      }
    });
  };
}

export function paginateNext() {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TYPES.PAGINATE_RIGHT
    });
  };
}

export function paginatePrev() {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TYPES.PAGINATE_LEFT
    });
  };
}

export function sort(key, order) {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TYPES.SORT,
      payload: {
        key,
        order
      }
    });
  };
}

export function reset() {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TYPES.RESET
    });
  };
}

// export function createIssue(issue) {
//   return dispatch => {
//     dispatch({
//       type: CHECK_USER_TIMEOUT
//     });
//     dispatch({
//       type: TYPES.NEW_ISSUE,
//       payload: {
//         issue
//       }
//     });
//   };
// }

export function closeSelectedIssues() {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TYPES.CLOSE_SELECTED_ISSUES
    });
  };
}

export function showColumns(identifier, show) {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TYPES.SHOW_COLUMNS,
      payload: {
        identifier,
        show
      }
    });
  };
}

export function filterSubColumnTypes(columnId, types, show) {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TYPES.FILTER_TYPES_IN_COLUMN,
      payload: {
        columnId,
        types,
        show
      }
    });
  };
}

export function showNotification(message) {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TYPES.SHOW_NOTIFICATION,
      payload: {
        message
      }
    });
  };
}

export function dimissNotification() {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TYPES.DISMISS_NOTIFICATION
    });
  };
}

//for getting the list of issues from database
export async function getIssuesListFromDb(){ 
  let data = await getIssuesList(API_ROOT + 'issues');
  return data; 
};

//for deleting the group details from database
export async function deleteIssueFromDb(issueId) {  
  let data = await deleteIssueData(API_ROOT + 'issues/' + issueId);
  return data; 
};

//for creating new issue in database
export async function createIssueInDb(issueToAdd) {  
  let data = await createIssue(API_ROOT + 'issues' , issueToAdd);
  return data; 
};

//for getting issue details from database using id
export async function getIssueDetailsFromDb(issueId) { 
  let data = await getIssueDetails(API_ROOT + 'issues/' + issueId);
  return data; 
};

//for updating issue in database
export async function updateIssueInDb(issueToUpdate) { 
  let data = await updateIssue(API_ROOT + 'issues/' + issueToUpdate.id , issueToUpdate);  
  return data; 
};



