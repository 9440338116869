import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { css } from 'emotion';

export default class Tabs extends React.Component {
  renderHeader() {
    let { tabs, activeTab } = this.props;

    let tabsHtml = tabs.map(
      tab =>
        tab.href !== '' ? (
          <Link
            to={{pathname: tab.href, state: { navigatedTo: tab.id === 'controls'}}}
            key={tab.id}
            className={tab.id === activeTab ? activeTabName : tabName}
          >
            {tab.name}
          </Link>
        ) : (
          <div
            key={tab.id}
            className={tab.id === activeTab ? activeTabName : tabName}
          >
            {tab.name}
          </div>
        )
    );

    return <div className={tabHeader}>{tabsHtml}</div>;
  }

  render() {
    return (
      <section className={tabsWrapper}>
        {this.renderHeader()}
        <div className={tabsContent}>{this.props.children}</div>
      </section>
    );
  }
}

Tabs.propTypes = {
  children: PropTypes.any,
  tabs: PropTypes.array,
  activeTab: PropTypes.string
};
const tabsWrapper = css({
  height: '56px'
});
const tabHeader = css({
  position: 'fixed',
  height: '56px',
  zIndex: 1,
  width: '100%',
  padding: '0 30px',
  backgroundColor: '#ffffff',
  boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)'
});

const tabName = css({
  display: 'inline-block',
  padding: '22px 10px 13px',
  marginRight: '15px',
  fontSize: '14px',
  letterSpacing: '0.7px',
  textDecoration: 'none',
  textAlign: 'center',
  color: '#757575'
});

const activeTabName = css([
  tabName,
  {
    color: '#212121',
    borderBottom: 'solid 5px #0151cb'
  }
]);

const tabsContent = css({
  position: 'relative',
  paddingTop: '50px',
});
