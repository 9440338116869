import 'isomorphic-fetch';
import { Cookies } from 'react-cookie';
import {getApiToken} from "../../utils/user";
const cookies = new Cookies();

//for getting the list of schedules from database
export async function getWorkOrderList(url) {
    let response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getApiToken()
        }
    });
    let status = response.status;
    let data = status >= 400 ? null : await response.json();
    return data;
}

//for deleting the work order details from work id
export async function deleteWorkOrderData(url) {
    let response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getApiToken()
      }
    });
    let status = response.status;
    let data = status >= 400 ? null : await response;
    return data;
  }

 //for creating new work order in database
 export async function createWorkOrder(url,orderToAdd) {
  let response = await fetch(url, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': getApiToken()
      },
      body : JSON.stringify({
        work_order_name : orderToAdd.workOrderName,
        status : orderToAdd.status,
        priority : orderToAdd.priority,
        date_assigned : orderToAdd.assignedDate,
        assigned_to : orderToAdd.assignedTo,
        comments : orderToAdd.comment,
        //issue_count: orderToAdd.issueCount
      })
  });
  let status = response.status;
  let data = status >= 400 ? null : await response;
  return data;
}

//for getting the work order details from work id
export async function getWorkOrderDetails(url) {
  let response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    }
  });
  let status = response.status;
  let data = status >= 400 ? null : await response.json();
  return data;
}

//for creating new work order in database
export async function updateWorkOrder(url,orderToUpdate) {
  let response = await fetch(url, {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': getApiToken()
      },
      body : JSON.stringify({
        work_order_name : orderToUpdate.workOrderName,
        status : orderToUpdate.status,
        priority : orderToUpdate.priority,
        date_assigned : orderToUpdate.assignedDate,
        assigned_to : orderToUpdate.assignedTo,
        comments : orderToUpdate.comment
      })
  });
  let status = response.status;
  let data = status >= 400 ? null : await response;
  return data;
}



//for add one and more issues to a work order
export async function updateWorkOrderWithIssues(url, selectedIssue){
  let response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    },
    body: JSON.stringify(selectedIssue.issueId)
  });
  let status = response.status;
  let data = status >= 400 ? null : await response;
  return data;
}
