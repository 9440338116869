export default {
    // Group Tab
    GroupNameRequiredText : 'Please enter a name for the group.',
    GroupDevicesRequiredText : 'Please enter devices for creating group.',
    DeleteGroupText: 'Deleting this will permanently remove all the settings associated with the devices in this group.This action cannot be undone.',
    GroupCloseConfirmation : 'All of your unsaved data will be lost. Are you sure you want to close ?',
    DeviceListNoSelection : 'Please select any device for adding in group.',
    GroupExtraRecordsText : 'You cannot add more than 8 entries.',
    DeleteGroupSuccessText : 'Group deleted successfully !',
    CreateGroupSuccessText : 'A new group is created with specified name, you can now add devices to the group !',
    UpdateGroupSuccessText : 'Group updated successfully !',
    UpdateGroupFailureText : 'Group update failures occurred !',
    DeleteDevicesFromGroupSuccess : 'Device deleted successfully from group !',
    NoSubgroupAddedToSite : 'Please add any subgroup id.',
    NoSubgroupInputForAdd : 'Please enter a value to add in subgroup list.',
    NoSubgroupInputForRemove : 'Please enter a value to remove from subgroup list.',
    DeviceWithoutSubgroup : 'You have selected a device with no subgroup, please fill subgroup field on the previous screen or add it by using "Edit Subgroup" action for the selected device.',
    ScheduleAssignToGroupsuccess : 'Schedule successfully assigned to the group !',
    ScheduleAssignToGroupfailure : 'An error occurred while assigning a schedule to the group !',
    MaxSubgroupLengthForAdd : 'You can only add 80 values to a subgroup.',
    MaxSubgroupValueForAdd : 'You cannot add a value more than 80 to a subgroup.',
    DuplicateSubGroupValue: 'This value already exists.',
    NegativeValue : 'Negative values are not allowed.',
    DecimalValue : 'Decimal values are not allowed.',


    // Work Order Tab
    WorkOrderNameRequiredText : 'Please enter a name.',
    WorkOrderStatusRequiredText : 'Please select status.',
    WorkOrderPriorityRequiredText : 'Please select priority.',
    WorkOrderAssignDateRequiredText : 'Please select a date.',
    WorkOrderAssignToRequiredText : 'Please enter a name to whom you want to assign the work order.',
    DeleteWorkOrderText : 'Deleting this will permanently remove all the settings associated with this work order.This action cannot be undone.',
    WorkOrderCloseConfirmation : 'All of your unsaved data will be lost. Are you sure you want to close ?',
    DeleteWorkOrderSuccessText : 'Work order deleted successfully !',
    CreateWorkOrderSuccessText : 'Work order created successfully !',
    UpdateWorkOrderSuccessText : 'Work order updated successfully !',
    LargeSiteIdValue : 'You can only input maximum 15 digits.',



    // Schedule Tab
    ScheduleNameRequiredText : 'Please enter a name for the schedule.',
    ScheduleListRequiredText : 'Please enter records for creating schedule.',
    ScheduleCloseConfirmation : 'All of your unsaved data will be lost. Are you sure you want to close ?',
    DeleteScheduleText : 'Deleting this will permanently remove all the settings associated with the entries in this schedule.This action cannot be undone.',
    ScheduleExtraRecordsText : 'You cannot add more than 8 entries.',
    CreateScheduleSuccessText : "A new schedule is created with specified name, you can now add entries for the schedule !",
    DeleteScheduleSuccessText : 'Schedule deleted successfully !',
    CreateScheduleEntrySuccess : 'Created entry is attached with schedule successfully !',
    DeleteScheduleEntry : 'Are you sure you want to delete this entry ?',
    UpdateScheduleEntrySuccess : 'Entry updated successfully !',
    UpdateScheduleSuccessText : "Schedule updated successfully !",
    DeleteScheduleEntrySuccess : 'Schedule entry deleted successfully !',

    // Inventory Tab
    DeleteInventoryText : 'Deleting this will permanently remove all the settings associated with this inventory.This action cannot be undone.',

    // Issue Tab
    IssueSiteIdRequiredText : 'Please select site where issue occurred.',
    IssueLocationRequiredText : 'Please enter location for issue.',
    IssuePriorityRequiredText : 'Please select priority of issue.',
    IssueTypeRequiredText : 'Please select issue type.',
    IssueStatusRequiredText : 'Please select status of issue.',
    DeleteIssueText : 'Deleting this will permanently remove all the settings associated with this Issue.This action cannot be undone.',
    IssueCloseConfirmation : 'All of your unsaved data will be lost. Are you sure you want to close ?',
    DeleteIssueSuccessText : 'Issue deleted successfully !',
    CreateIssueSuccessText : 'Issue created successfully !',
    UpdateIssueSuccessText : 'Issue updated successfully !',
    UpdateWorkIdWithIssue: 'Issue has been successfully added to work order.',
    UpdateWorkIdWithIssues:'Issues has been successfully added to work order.',
    UpdateCloseIssue:'Issue has been successfully closed',
    UpdateCloseIssues:'Issues has been successfully closed',
    CloseIssuesConfirmation:'Are you sure you want to close selected issues ?',
    AssignIssuesConfirmation :'Are you sure you want to assign issues on selected work order ?',

    /*
    siteId: response.siteId, clientsName: response.clientsName, address1: response.address1, address2: response.address2,
                              poleType: response.poleType, fixtureType: response.fixtureType, lampType: response.lampType, powerFeed: response.powerFeed,
                              poleId: response.poleId, longitude: response.longitude, latitude: response.latitude
     */
    // Site Tab
    SiteIdRequiredText : 'Please select a site.',
    DeviceIdRequiredText : 'Please select a device.',
    SiteClientsNameRequiredText : 'Please enter site name of site.',
    SiteAddressRequiredText : 'Please enter address of site.',
    SiteCityStateZipCodeRequiredText : 'Please enter city, state and zip code of site.',
    SitePoleTypeRequiredText : 'Please select pole type for site.',
    SitePoleConditionRequiredText : 'Please select pole condition for site.',
    SiteArmLengthRequiredText : 'Please select arm length for site.',
    SiteArmConditionRequiredText : 'Please select arm condition for site.',
    SiteAreaServedRequiredText : 'Please select area served for site.',
    SiteFixtureTypeRequiredText : 'Please select fixture type for site.',
    SiteLampTypeRequiredText : 'Please select lamp type for site.',
    SitePowerFeedRequiredText : 'Please select power feed for site.',
    SitePoleIdRequiredText : 'Please select pole id for site.',
    SiteLongitudeRequiredText : 'Please select longitude for site.',
    SiteLatitudeRequiredText : 'Please select latitude for site.',
    DeleteSiteText : 'Deleting this will permanently remove all the settings associated with this Site.  This action cannot be undone.',
    SiteCloseConfirmation : 'All of your unsaved data will be lost. Are you sure you want to close ?',
    DeleteSiteSuccessText : 'Site deleted successfully !',
    CreateSiteSuccessText : 'Site created successfully !',
    UpdateSiteSuccessText : 'Site updated successfully !',
    UpdateWorkIdWithSite: 'Site has been successfully added to work order.',
    UpdateWorkIdWithSites:'Sites has been successfully added to work order.',
    UpdateCloseSite:'Site has been successfully closed',
    UpdateCloseSites:'Sites has been successfully closed',
    CloseSitesConfirmation:'Are you sure you want to close selected issues ?',
    SiteAllocationSuccessText : 'Site allocated successfully !',
    DeviceAllocationSuccessText : 'Device allocated successfully !',

    //Control Tab
    GPSStatusChangedSuccess : "Device's GPS status changed successfully !",
    GPSStatusChangedFailure : "An error occurred while assigning the device's GPS status !",
    ModeChangedSuccess : "Device's Mode changed successfully !",
    ModeChangedFailure : "An error occurred while updating the device's mode !",
    DevicePropertiesUpdatedSuccess : "Device's properties updated successfully !",
    DevicePropertiesUpdatedFailure : "An error occurred while updating the device's properties !",
    ScheduleAssignToDevicesuccess : 'Schedule successfully assigned to selected device !',
    ScheduleAssignToDevicefailure : 'An error occurred while assigning the schedule to the device !',

    //General Messages
    FirstCharacterAsSpaceErrorText : "You can't enter space at very first position.",
    AllocateCoordinatesSuccessText : 'Coordinates allocated successfully !',
    NoDeviceSelectedText : 'You must specify a valid mac address.',
    MissingLatLongText : 'The supplied mac address does not have coordinates assigned.'
};
