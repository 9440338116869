export function addLayer(layer, map) {
  let existingSource = map.getSource(layer.source.id);
  if (existingSource) {
    map.getSource(layer.source.id).setData(layer.source.sourceObj.data);
  } else {
    map.addSource(layer.source.id, layer.source.sourceObj);
  }
  layer.styles.forEach(style => {
   let styleExists = map.getLayer(style.id);
    if (!styleExists) {
      //map.addLayer(style.id);
      map.addLayer(style);
    }
  });
}

export function removeLayer(layerId, map) {
  let styleExists = map.getLayer(layerId);

  if (styleExists) {
    map.removeLayer(layerId);
  }
}

export function setFeatureAsSelected(id, features) {
  return features.map(feature => {
    let sourceId = feature.properties.name;
    let selection = id === sourceId;
    let featureWithSelection = Object.assign({}, feature);
    featureWithSelection.properties.selected = selection.toString();
    return featureWithSelection;
  });
}

export const removeEmptyGeometryObjects = (collection) => ({
  type: 'FeatureCollection',
  features: collection.features.filter((x => x.geometry))
})
