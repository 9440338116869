import React, { Component } from 'react';
import Login from './login';
import LoginError from './loginerror';
import AppHeader from '../app-header/app-header';
import LoginModal from '../modal/modal';
import { css } from 'emotion';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Modal from 'react-responsive-modal';

import { loginWithCreds, USER_LOG_IN_REQESTED, USER_LOG_IN_ERROR } from '../../modules/user';

class LoginContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      termsModal: false,
      privacyModal: false
    }
    this.openTermsModal = this.openTermsModal.bind(this);
    this.closeTermsModal = this.closeTermsModal.bind(this);
    this.openPrivacyModal = this.openPrivacyModal.bind(this);
    this.closePrivacyModal = this.closePrivacyModal.bind(this);
  }

  onLoginSubmit = (user, pass) => {
    this.props.loginWithCreds(user, pass);
  };

  componentWillReceiveProps(props) {
    let isLoggedIn = props.user.isLoggedIn;
    if (isLoggedIn) {
      this.props.goToFieldPage();
    }
  }

  openTermsModal() {
    this.setState({ termsModal: true });
  }
  closeTermsModal() {
    this.setState({ termsModal: false });
  }
  openPrivacyModal() {
    this.setState({ privacyModal: true });
  }
  closePrivacyModal() {
    this.setState({ privacyModal: false });
  }

  render() {
    let homeBtn = {
      name: 'Home',
      func: this.props.goToFieldPage
    };
    let loginStatus = this.props.user.status === USER_LOG_IN_REQESTED;
    let loginError = this.props.user.status === USER_LOG_IN_ERROR;
    return (
      <div className={loginContainer}>
        <AppHeader button={homeBtn} />
        <LoginModal title="Welcome" subtitle="Login to your Fybr Account">
          <LoginError show={loginError} />
          <Login
            onSubmit={this.onLoginSubmit}
            isLoggedIn={this.props.user.isLoggedIn}
            loading={loginStatus}
          />
          <div className={(loginError === false) ? forgetLabelWithoutError : forgetLabelWithError}>
            <label>If you have forgotten your password, contact our sales team <span className={boldText}>sales@fybr.com</span></label>
          </div>
        </LoginModal>
        <div className={footerContainer}>
          <div className={footerClass}>
            <span title="Terms of Services" className={hoverPointer} onClick={this.openTermsModal}>Terms of Services</span> | <span title="Privacy Policy" className={hoverPointer} onClick={this.openPrivacyModal}>Privacy Policy</span> | <span>Copyright &copy; 2019 Fybr. All rights reserved.</span>
          </div>
        </div>
        <Modal classNames={{ modal: "terms-of-services-modal-dialog"}} open={this.state.termsModal} onClose={this.closeTermsModal} center closeOnOverlayClick={false}>
          <div>
            <h3>Terms of Services</h3>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
          </div>
        </Modal>
        <Modal classNames={{ modal: "privacy-policy-modal-dialog"}} open={this.state.privacyModal} onClose={this.closePrivacyModal} center closeOnOverlayClick={false}>
          <div>
            <h3>Privacy Policy</h3>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loginWithCreds,
      goToFieldPage: () => push('/')
    },
    dispatch
  );

const loginContainer = css({
  backgroundColor: '#202e38',
  minHeight: '100vh',
  position: 'relative'
});

const footerClass = css({
  position: 'absolute',
  left: 0,
  bottom: '24px',
  fontFamily: 'OpenSans',
  fontSize: '14px',
  color: '#BDBDBD',
  letterSpacing: '0.5px',
  lineHeight: '21px',
  width: '100%',
  textAlign: 'center'
});
const footerContainer = css({
  position: 'relative',
  minHeight: '75px',
  marginTop: '25px',
});

const forgetLabelWithoutError = css({
  fontFamily: 'OpenSans',
  fontSize: '13px',
  lineHeight: '1.5',
  letterSpacing: '0.5px',
  color: '#757575',
  display: 'block',
  marginTop: '70px',
  marginBottom: '40px'
});

const forgetLabelWithError = css({
  fontFamily: 'OpenSans',
  fontSize: '13px',
  lineHeight: '1.5',
  letterSpacing: '0.5px',
  color: '#757575',
  display: 'block',
  marginTop: '15px',
  marginBottom: '40px'
});

const boldText = css({
  fontWeight: 'bold'
});

const hoverPointer = css({
  cursor: 'pointer'
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
