import {
  getClusteredSourceLayer,
  getGroupSourceLayer,
  getSource,
  getSourceCountLayer,
  getSourceLayer
} from './2x-features';
import { addLayer } from '../utils';
import {getLayerTypeForZoom, LAYER_2X_ZOOM_LEVEL, LAYER_2X_ZOOM_LEVEL_DEVICE, LAYERS} from '../../constants/map';
import {getDistrictsList, getSelectedSitesByViewPort} from "../../../modules/map";
import center from '@turf/center';
import mapboxgl from "mapbox-gl";

const defaultOptions = {
  minZoom: LAYER_2X_ZOOM_LEVEL_DEVICE.START,
  maxZoom: LAYER_2X_ZOOM_LEVEL_DEVICE.END
};

export default class OneXLayer {

  constructor(map, features = [], props, options = defaultOptions) {
    this.map = map;
    this.features = features.map((feature) => {
      let featureWithSelectionData = Object.assign({}, feature);
      featureWithSelectionData.properties.selected = false;
      return featureWithSelectionData;
    });

    this.start = [0, 0];
    this.current = 0;
    this.box = 0;
    this.options = options;
    this.map.on('click', e => this.onFeatureClicked(e));
    this.map.on('zoomend', () => this.onZoomChange());
    this.map.on('dragend', () => this.onDrag());
    this.selections = new Set();
    this.props = props;
    this.oneXLayer = null;
    this.addSecondLevelMarkers = null;
    this.canvas = this.map.getCanvasContainer();
    this.canvas.addEventListener('mousedown', this.mouseDown, true);
  }

  _addToSelections = (id) => {
    if(this.selections.has(id)) {
      this.selections.delete(id);
    } else {
      //this.selections.clear();
      this.selections.add(id);
    }

    //this.props.selectedLights([...this.selections]);
    this.props.selectedLights([...this.selections]);
  }

  _clearSelecions = () => {
    this.props.selectedLights([]);
  }

  _getFeaturesWithSelectionData = () => {
    return this.features.map((feature) => {
      let featureWithSelectionData = Object.assign({}, feature);
      //let lightId = feature.properties.id;
      let lightId = feature.properties.mac_address;
      featureWithSelectionData.properties.selected = this.selections.has(lightId);
      return featureWithSelectionData;
    });
  }

  updateFeatureData = async (props = this.props) => {
    this.props = props;
    let { selectedLights } = props;

    let bbox = this.map.getBounds();
    let sitePointFeaturesNew = await getSelectedSitesByViewPort(bbox, 'DEVICES', true);

    if (sitePointFeaturesNew && sitePointFeaturesNew.features && sitePointFeaturesNew.features.length > 0) {
      let coordinates = sitePointFeaturesNew.features.filter((item) => item.geometry.coordinates[0]);
      let centerPoint = center(coordinates[0]);
      //sitePointFeaturesNew.features = coordinates;

      this.features = coordinates;
      this.updateLayer(props);
      //this.features = sitePointFeaturesNew;
      //this.initLayer();
    }
  }

  static calculateCenterPoint = async (bbox, currentDeviceCategory = 'DEVICES') => {
    let centerPoint = null;

    let sitePointFeaturesNew = await getSelectedSitesByViewPort(bbox, currentDeviceCategory);

    if (sitePointFeaturesNew && sitePointFeaturesNew.features && sitePointFeaturesNew.features.length > 0) {
      let coordinates = sitePointFeaturesNew.features.filter((item) => item.geometry.coordinates[0]);
      centerPoint = center(coordinates[0]);
      //let centerPoint = center(coordinates[0]);
    }

    return centerPoint;
  }

  onDrag = async () => {
      /*
    const zoom = this.map.getZoom();
    if(zoom >= this.options.minZoom && zoom <= this.options.maxZoom) {
      this._clearSelecions();
      this.clearMarkers();
      await this.updateFeatureData();

      if (this.addSecondLevelMarkers)
        await this.addSecondLevelMarkers();
    }
       */
  }

  onZoomChange = async () => {
    const zoom = this.map.getZoom();
    if(zoom > this.options.maxZoom || zoom < this.options.minZoom) {
      if(this.markersAdded) {
        this.clearMarkers();
        this.markersAdded = false;
      }
    }

    if(zoom >= this.options.minZoom && zoom <= this.options.maxZoom) {
      if(!this.markersAdded) {
        this.markersAdded = true;
        await this.updateFeatureData();
        //this.props.updateLayer(true);
      }
    }
  }

  updateLayer = (props) => {
    let source = getSource(this.features);

    let clusteredSourceLayer = getClusteredSourceLayer(source, '2x-layer-clustered', this.options);
    let clusteredCountTextLayer = getSourceCountLayer(source, '2x-layer-clustered-count', this.options);
    let groupTextLayer = getGroupSourceLayer(source, '2x-layer-group-layer', this.options);
    addLayer(clusteredSourceLayer, this.map);
    addLayer(clusteredCountTextLayer, this.map);
    addLayer(groupTextLayer, this.map);

    let sourceLayer = getSourceLayer(source, '2x-layer', this.options);
    addLayer(sourceLayer, this.map);
  }

  updateSelections = (lights) => {
    let lightIndices = lights.map((light) => {
      return light.mac_address;
    });
    this.selections = new Set(lightIndices);
    this.features = this.features.map(feature => {
      let featureWithSelectionData = Object.assign({}, feature);
      let id = feature.properties.mac_address;
      featureWithSelectionData.properties.selected = lightIndices.indexOf(id) !== -1;
      return featureWithSelectionData;
    });
    // Update features
    let source = getSource(this.features);

    let clusteredSourceLayer = getClusteredSourceLayer(source, '2x-layer', this.options);
    addLayer(clusteredSourceLayer, this.map);
    let sourceLayer = getSourceLayer(source, '2x-layer-clustered', this.options);
    addLayer(sourceLayer, this.map);
    let groupTextLayer = getGroupSourceLayer(source, '2x-layer-group-layer', this.options);
    addLayer(groupTextLayer, this.map);
  }

  /*
  onFeatureClicked = (e) => {
    debugger;
    debugger;

    let features = this.map.queryRenderedFeatures(e.point);
    if (features && features.length > 0) {
      let feature = features[0];

      let layerId = feature.layer.id;
      let layerIds = layerId.split('-');
      if (layerIds && layerIds.length > 0) {
        if (layerIds[0] === '1x') {
          // 2x feature clicked

          let lightId = feature.properties.mac_address;
          this._addToSelections(lightId);
          this.features = this._getFeaturesWithSelectionData();
          this.drawFeatures(this.features);
        }
      }
    }
  }
   */

  onFeatureClicked = (e) => {
    //this.onFeatureClickedClustered(e);
    this.onFeatureClickedNonClustered(e);
  }

  onFeatureClickedNonClustered = (e) => {
    let features = e && e.point ? this.map.queryRenderedFeatures(e.point, {
      layers: ['2x-bg-style','2x-turned-on-one-style', '2x-selected-bg-style', '2x-selected-outline-style', '2x-selected-outline-bg-site-style', '2x-selected-outline-site-style',
              '2x-selected-outline-bg-site-off-style', '2x-selected-outline-site-off-style', '2x-bg-style-site']
    }) : this.map.queryRenderedFeatures(e, {
      layers: ['2x-bg-style','2x-turned-on-one-style', '2x-selected-bg-style', '2x-selected-outline-style', '2x-selected-outline-bg-site-style', '2x-selected-outline-site-style',
              '2x-selected-outline-bg-site-off-style', '2x-selected-outline-site-off-style', '2x-bg-style-site']
    });
    if (features && features.length > 0) {
      if (e.point) {
        let feature = features[0];

        let lightId = feature.properties.mac_address;
        this._addToSelections(lightId);
        this.features = this._getFeaturesWithSelectionData();
        this.drawFeatures(this.features);
      } else {
        if (features.length > 0) {
          let featureList = [...new Set(features.map((item) => item.properties.mac_address))];
          featureList.map((feature) => {
            this._addToSelections(feature);
          })
          this.features = this._getFeaturesWithSelectionData();
          this.drawFeatures(this.features);
        }
      }
    }
  }

  onFeatureClickedClustered = (e) => {
    //let testFeatures = this.map.queryRenderedFeatures(e);
    let features = this.map.queryRenderedFeatures(e, { layers: ['2x-selected-bg-clustered-style', '2x-bg-clustered-style', '2x-selected-bg-style', '2x-bg-style']});
    //let features = this.map.queryRenderedFeatures(e.point, {
     // layers: ['1x-bg-clustered-style', '1x-turned-on-one-clustered-style', '1x-selected-bg-clustered-style', '1x-selected-outline-clustered-style', '1x-selected-outline-bg-site-clustered-style',
     //         '1x-selected-outline-site-clustered-style', '1x-selected-outline-bg-site-off-clustered-style', '1x-selected-outline-site-off-clustered-style', '1x-bg-style-clustered-site']
    //});
    if (features && features.length > 0) {
      //let clusterId = features[0].properties.cluster_id;
      //let features = map.queryRenderedFeatures(e.point, { layers: ['clusters'] });
      if (e.point) {
        let clusterId = features[0].properties.cluster_id,
            point_count = features[0].properties.point_count,
            clusterSource = this.map.getSource('2x-layer-clustered');

        // Get Next level cluster Children
        //
        clusterSource.getClusterChildren(clusterId, (err, aFeatures) => {
          console.log('getClusterChildren', err, aFeatures);
        });

        // Get all points under a cluster
        clusterSource.getClusterLeaves(clusterId, point_count, 0, (err, aFeatures) => {
          console.log('getClusterLeaves', err, aFeatures);
        })
      } else {
        features.map((feature) => {
          let clusterId = feature.properties.cluster_id,
              point_count = feature.properties.point_count,
              clusterSource = this.map.getSource('2x-layer-clustered');

          let lightId = feature.properties.mac_address;
          this._addToSelections(lightId);
        })
        this.features = this._getFeaturesWithSelectionData();
        this.drawFeatures(this.features);
      }
      /*
      this.map.getSource('1x-layer-clustered').getClusterExpansionZoom(
          clusterId,
          function (err, zoom) {
            if (err) return;
          debugger;
          debugger;

            this.map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom
            });
          }
      );
      */
    }
  }

  clearMarkers = () => {
    return;
  }

  drawFeatures = (features = this.features) => {
    let source = getSource(this.features);

    let clusteredSourceLayer = getClusteredSourceLayer(source, '2x-layer-clustered', this.options);
    addLayer(clusteredSourceLayer, this.map);
    let sourceLayer = getSourceLayer(source, '2x-layer', this.options);
    addLayer(sourceLayer, this.map);
    let groupTextLayer = getGroupSourceLayer(source, '2x-layer-group-layer', this.options);
    addLayer(groupTextLayer, this.map);
  }

  initLayer = () => {
    let source = getSource(this.features);

    let clusteredSourceLayer = getClusteredSourceLayer(source, '2x-layer-clustered', this.options);
    addLayer(clusteredSourceLayer, this.map);
    let sourceLayer = getSourceLayer(source, '2x-layer', this.options);
    addLayer(sourceLayer, this.map);
    let groupTextLayer = getGroupSourceLayer(source, '2x-layer-group-layer', this.options);
    addLayer(groupTextLayer, this.map);
  }

  clear = () => {
    this.map.off('zoom', () => this.onZoomChange());
  }

  setProps = (props) => {
    this.props = props;
  }

  setMarkersAdded = (markersAdded) => {
    this.markersAdded = markersAdded;
  }

  addLayerThreeMarkers = async () => {
    await this.updateFeatureData();
  }

  setSecondLevelMarkers = (addSecondLevelMarkers) => {
    this.addSecondLevelMarkers = addSecondLevelMarkers;
  }

  mousePos = (e) => {
    let canvas = this.map.getCanvasContainer();
    let rect = this.map.getCanvasContainer().getBoundingClientRect();
    return new mapboxgl.Point(
        e.clientX - rect.left - canvas.clientLeft,
        e.clientY - rect.top - canvas.clientTop
        //e.layerX - rect.left - canvas.clientLeft,
        //e.layerY - rect.top - canvas.clientTop
    );
  }

  mouseDown = (e) => {
// Continue the rest of the function if the shiftkey is pressed.
    if (!(e.shiftKey && e.button === 0)) return;

// Disable default drag zooming when the shift key is held down.
    this.map.dragPan.disable();

// Call functions for the following events
    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('mouseup', this.onMouseUp);
    document.addEventListener('keydown', this.onKeyDown);

// Capture the first xy coordinates
    this.start = this.mousePos(e);
  }

  onMouseMove = (e) => {
// Capture the ongoing xy coordinates
    this.current = this.mousePos(e);

// Append the box element if it doesnt exist
    if (!this.box) {
      this.box = document.createElement('div');
      this.box.classList.add('boxDraw');
      this.canvas.appendChild(this.box);
    }

    let minX = Math.min(this.start.x, this.current.x),
        maxX = Math.max(this.start.x, this.current.x),
        minY = Math.min(this.start.y, this.current.y),
        maxY = Math.max(this.start.y, this.current.y);

// Adjust width and xy position of the box element ongoing
    let pos = 'translate(' + minX + 'px,' + minY + 'px)';
    this.box.style.transform = pos;
    this.box.style.WebkitTransform = pos;
    this.box.style.width = maxX - minX + 'px';
    this.box.style.height = maxY - minY + 'px';
  }

  onMouseUp = (e) =>  {
// Capture xy coordinates
    let dragEnd = this.mousePos(e);
    let start = [];
    let end = [];
    start.push(this.start.x);
    start.push(this.start.y);
    end.push(dragEnd.x);
    end.push(dragEnd.y);
    //this.finish([this.start, dragEnd]);
    this.finish([start, end]);
  }

  onKeyDown = (e) => {
// If the ESC key is pressed
    if (e.keyCode === 27) this.finish();
  }

  finish = (bbox) => {
// Remove these events now that finish has been called.
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('keydown', this.onKeyDown);
    document.removeEventListener('mouseup', this.onMouseUp);

    if (this.box) {
      this.box.parentNode.removeChild(this.box);
      this.box = null;
    }

// If bbox exists. use this value as the argument for `queryRenderedFeatures`
    if (bbox) {
      this.onFeatureClicked(bbox);

      if (this.features.length >= 1000) {
        return window.alert('Select a smaller number of features');
      }
    }

    this.map.dragPan.enable();
  }
}
