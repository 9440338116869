import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { connectedReduxRedirect } from 'redux-auth-wrapper/history3/redirect';
import StreetLightPage from '../pages/street-light';
import OverviewPageComponent from '../pages/overview';
import PageNotFound from '../pages/page-not-found';
import InventoryPage from '../pages/inventory';
import IssuesPage from '../pages/issues';
import SchedulingPage from '../pages/scheduling';
import ReportsPage from '../pages/reports';
import LoginContainer from '../login/logincontainer';
import { withRouter } from 'react-router';
import AppHeader from '../app-header/app-header';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { logAppVersion } from '../../modules/app';
import { logout } from '../../modules/user';
import { shouldRefreshToken } from '../../utils/user';
import { version } from '../../../package.json';
import './app.css';
import GroupPage from '../pages/group';
import SitePage from '../pages/site';
import WorkOrderPage from '../pages/work-order';

const userIsAuthenticated = connectedReduxRedirect({
  redirectPath: '/',
  authenticatedSelector: state =>
    typeof state.user.isLoggedIn !== 'undefined' &&
    state.user.isLoggedIn !== false &&
    !shouldRefreshToken(state.user.expiresAt),
  wrapperDisplayName: 'UserIsAuthenticated',
  // This should be a redux action creator
  redirectAction: path => () => {
    window.location.href = '/login';
  }
});

//const StreetLightPage = userIsAuthenticated(StreetLightPageComponent);
const OverviewPage = userIsAuthenticated(OverviewPageComponent);

class App extends Component {
  componentWillMount() {
    this.props.logAppVersion(version);
  }

  render() {
    return (
      <div>
        <header>
          <AppHeader
            logoutAction={this.props.logout}
            goToLoginPage={this.props.goToLoginPage}
            user={this.props.user}
          />
        </header>
        <main>
          <Switch>
            <Route exact path="/" component={OverviewPage} />
            <Route exact path="/map" component={StreetLightPage} />
            <Route exact path="/login" component={LoginContainer} />
            <Route exact path="/inventory" component={InventoryPage} />
            <Route exact path="/issues" component={IssuesPage} />
            <Route exact path="/scheduling" component={SchedulingPage} />
            <Route exact path="/reports" component={ReportsPage} />
            <Route exact path="/group" component={GroupPage} />
            <Route exact path="/workorder" component={WorkOrderPage} />
            <Route exact path="/site" component={SitePage} />
            <Route path="*" exact={true} component={PageNotFound} />
          </Switch>
        </main>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  app: state.app
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logout,
      logAppVersion,
      goToLoginPage: () => push('login')
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
