import React from 'react';
import { css } from 'emotion';
import Button from '../button/button.js';
import { zoom } from '../../modules/map.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {updateLayer} from '../../modules/map';

class MapLegend extends React.Component {
    /*
                <div className={legendHeaderClassRight}>
                    <a href={"#"} onClick={() => {toggleVisible()}}>{displayText}</a>
                </div>
                 */
    render() {
        let {headerText, visible, toggleVisible } = this.props;
        visible = visible ? visible : false;
        //let legendContainer = visible ? legendContainerClass : legendContainerHideClass;
        let bodyContainer = visible ? legendBodyClass : legendBodyClassHidden;
        let displayText = visible ? 'hide' : 'show';
        return (
            <a href={"#"} onClick={() => {toggleVisible()}}>
            <div className={legendContainerClass}>
                <div className={legendHeaderClassLeft}>
                    {headerText}
                </div>
                <div className={bodyContainer}>
                    <div className={legendElementHeader}>
                        Lamp
                    </div>
                    <div className={legendElement}>
                        <div className={legendColorItem} style={{backgroundColor: '#ffc402'}}>&nbsp;</div>&nbsp;&nbsp;On
                    </div>
                    <div className={legendElement}>
                        <div className={legendColorItem} style={{backgroundColor: '#8E8E8E'}}>&nbsp;</div>&nbsp;&nbsp;Off
                    </div>
                </div>
                <div className={bodyContainer}>
                    <div className={legendElementHeader}>
                        Site
                    </div>
                    <div className={legendElement}>
                        <div className={legendColorItem} style={{backgroundColor: '#2584ff'}}>&nbsp;</div>&nbsp;&nbsp;Site
                    </div>
                </div>
            </div>
            </a>
        );
    }
}

const mapStateToProps = state => ({
    layerType: state.map.layerType,
    map: state.map
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            zoom,
            updateLayer
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(MapLegend);

const legendContainerClass = css({
    position: 'absolute',
    width: '140px',
    float: 'left',
    backgroundColor: '#ffffff',
    marginTop: '12px',
    fontFamily: 'OpenSans',
    left: '10px',
    minHeight: '42px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    fontSize: '14px',
    fontWeight: '600',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.5',
    letterSpacing: '1px',
    textAlign: 'center',
    color: '#112138',
    marginRight: '16px',
    border: 'none',
    paddingTop: '13px'
});

const legendContainerHideClass = css({
    position: 'absolute',
    width: '220px',
    float: 'left',
    backgroundColor: '#ffffff'
});

const legendHeaderClassLeft = css({
    height: '30px',
    marginBottom: '5px',
    fontFamily: 'OpenSans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontStretch: 'normal',
    textAlign: 'center',
    letterSpacing: '1px',
    lineHeight: '21px',
    color: '#112138',
    display: 'block',
    paddingLeft: '0px',
    textTransform: 'uppercase'
});

const legendHeaderClassRight = css({
    float: 'left',
    marginBottom: '5px',
    width: '100px',
    height: '30px',
    textAlign: 'left',
    paddingLeft: '50px'
});

const legendBodyClass = css({
    width: '220px',
    height: '40px'
});

const legendBodyClassHidden = css({
    width: '220px',
    height: '40px',
    display: 'none'
});

const legendElementHeader = css({
    float: 'left',
    height: '30px',
    width:  '140px',
    textAlign: 'left',
    letterSpacing: '1px',
    lineHeight: '21px',
    marginBottom: '5px',
    paddingTop: '5px',
    fontFamily: 'OpenSans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontStretch: 'normal',
    color: '#112138',
    display: 'block',
    paddingLeft: '10px',
    textTransform: 'uppercase',
    backgroundColor: '#d6eaf8'
});

const legendElement = css({
    marginTop: '10px',
    float: 'left',
    height: '30px',
    width:  '140px',
    marginBottom: '5px',
    fontFamily: 'OpenSans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#112138',
    fontWeight: 'normal',
    textAlign: 'left'
});

const legendColorItem = css({
    float: 'left',
    width: '25px',
    height: '20px',
    marginLeft: '10px'
});
