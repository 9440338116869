import React, { Component } from 'react';
import { css } from 'emotion';
import upArrow from '../../assets/sort-asc-arrow.svg';
import downArrow from '../../assets/sort-desc-arrow.svg';

export default class SingleSelectDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      title: this.props.title
    };
    this.toggleList = this.toggleList.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.node = null;
  }

  toggleList() {
    this.setState(prevState => ({
      isExpanded: !prevState.isExpanded
    }));
  }

  selectOption = (label, key) => {
    this.setState({
      title: label,
      isExpanded: false
    });
    this.props.onSelect(key);
  }

  handleClickOutside() {
    this.setState({
      isExpanded: false
    })
  }

  handleClick = (e) => {
    if(this.node !== null && this.node.contains(e.target)) {
      return;
    }
    this.handleClickOutside();
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  renderListItems(options) {
    const itemsContainer = css({
      display: 'flex',
      flexDirection: 'column',
      zIndex: 10,
      position: 'absolute',
      marginTop: '35px',
      backgroundColor: '#ffffff',
      borderRadius: '4px',
      boxShadow: '0 3px 10px 0 rgba(0, 0, 0, 0.09)',
      width: '100%',
      border: '1px solid #dfdfdf',
      left: '0px'
    });

    const itemStyle = css({
      display: 'flex',
      alignItems: 'center',
      minHeight: '48px',
      textOverflow: 'ellipsis',
      cursor: 'default',
      width: '250px',// padding + 220 + padding
      paddingLeft: '15px'
    });
    const items = options.map((option) => {
      return (
        <div
          className={itemStyle}
          key={option.key}
          onClick={() => this.selectOption(option.label, option.key)}>
          {option.label}
        </div>
      );
    });
    const showList = this.state.isExpanded;
    if(!showList) {
      return null;
    }
    return (
      <div className={itemsContainer}>
        {items}
      </div>
    );
  }

  render() {
    const {containerStyle, headerStyle, arrowContainerStyle, titleContainerStyle} = this.props;
    const { title, isExpanded } = this.state;
    const { options } = this.props;

    const container = css({
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      padding: '10px',
      borderRadius: '2px',
      fontSize: '14px',
      // color: 'rgba(0,0,0,0.27)',
      color:'#000',
      backgroundColor: '#FFFFFF',
      '&:hover': {
        backgroundColor: '#F4F5F7',
        cursor: 'pointer',
      },
      border: this.props.showErrorState ? '1px solid #ff5630' : '1px solid #C4CDD5',
      minWidth: '100px',
      height: '40px',
      ...containerStyle
    });

    const header = css({
      display: 'flex',
      flexDirection: 'row',
      ...headerStyle
    });
    const titleStyle = css({
      display: 'flex',
      flexGrow: '0.9',
      ...titleContainerStyle
    });
    const arrowStyle = css({
      display: 'flex',
      flexGrow: '0.1',
      ...arrowContainerStyle
    });

    const arrowIcon = isExpanded ? upArrow : downArrow;
    return (
      <div className={container} ref={node => this.node = node}>
        <div className={header} onClick={this.toggleList}>
          <div className={titleStyle}>
            {title}
          </div>
          <div className={arrowStyle}>
            <img src={arrowIcon} alt=""/>
          </div>
        </div>
        {this.renderListItems(options || [])}
      </div>
    );
  }

}
