import React, { Component } from 'react';
import Button from '../button/button.js';
import { css } from 'emotion';
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import {
  SITE,
  STATUS,
  PRIORITY,
  createSiteInDb,
  getSiteDetailsFromDb,
  updateSiteInDb,
  saveSiteInDb
} from '../../modules/site';
import Messages from '../constants/messages.js';
import { toast } from 'react-toastify';
import AppLoader from '../Loader/loader';
import Autosuggest from 'react-autosuggest';


export default class NewSite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      siteId: '',
      siteName: '',
      address1: '',
      address2: '',
      poleType: '',
      poleCondition: '',
      armLength: '',
      armCondition: '',
      fixtureType: '',
      fixtureCondition: '',
      fixtureQuantity: '',
      fixtureModel: '',
      lightHeight: '',
      lightingArea: '',
      lampType: '',
      lampWattage: '',
      metered: '',
      areaServed: '',
      powerFeed: '',
      poleId: '',
      longitude: '',
      latitude: '',
      errors: [],
      showLoader: false,
      editedSiteId: 0,
      suggestions: []
    };
  }

  componentDidMount() {
    if (this.props.selectedSiteId !== undefined && this.props.selectedSiteId !== null) {
      this.setState({ editedSiteId: this.props.selectedSiteId, showLoader: true }, () => {
        this.getSiteDetailsById().then(() => {});
      });
    }
  }

  getSiteDetailsById = async () => {

    if (this.state.editedSiteId !== undefined && this.state.editedSiteId !== null && this.state.editedSiteId !== 0) {
      let response = await getSiteDetailsFromDb(this.state.editedSiteId);

      if (response !== undefined && response !== null) {

        this.setState({ siteName: response.site_name, address1: response.street1_name, address2: response.street2_name, longitude: response.longitude, latitude: response.latitude,
          poleType: response.site_details.pole_type, poleCondition: response.site_details.pole_condition, armLength: response.site_details.arm_length,
          armCondition: response.site_details.arm_condition, lightHeight: response.site_details.light_height, lightingArea: response.site_details.lighting_area,
          fixtureType: response.site_details.fixture_type, fixtureCondition: response.site_details.fixture_condition, fixtureQuantity: response.site_details.fixture_quantity,
          lampType: response.site_details.lamp_type, lampWattage: response.site_details.lamp_wattage, metered: response.site_details.metered, powerFeed: response.site_details.power_feed,
          fixtureModel: response.site_details.fixture_model, poleId: response.site_details.pole_id, showLoader: false })


      }
    }
  }

  addNewOptionToList = (value, list) => {
    if (value && !value.label) {
      let found = list.findIndex((item) => item.label === value);
      if (found !== -1) {
        return list;
      } else {
        return [...list, {label: value, value: value}];
      }
    } else if (value && value.label) {
      let found = list.findIndex((item) => item.label === value.label);
      if (found !== -1) {
        return list;
      } else {
        return [...list, {label: value.label, value: value.label}];
      }
    } else {
      return list;
    }
  }

  onMeteredSelected = (metered) => {
    const {isFormDirty} = this.props;
    this.setState({ metered: { key: metered.key ? metered.key : metered.value, label: metered.label }, errors: [] });
    isFormDirty(true);
  }

  onFixtureModelSelected = (fixtureModel) => {
    const {isFormDirty} = this.props;
    this.setState({ fixtureModel: { key: fixtureModel.key ? fixtureModel.key : fixtureModel.value, label: fixtureModel.label }, errors: [] });
    isFormDirty(true);
  }

  onLightingAreaSelected = (lightingArea) => {
    const {isFormDirty} = this.props;
    this.setState({ lightingArea: { key: lightingArea.key ? lightingArea.key : lightingArea.value, label: lightingArea.label }, errors: [] });
    isFormDirty(true);
  }

  onPoleTypeSelected = (poleType) => {
    const {isFormDirty} = this.props;
    this.setState({ poleType: { key: poleType.key ? poleType.key : poleType.value, label: poleType.label }, errors: [] });
    isFormDirty(true);
  }

  onPoleConditionSelected = (poleCondition) => {
    const {isFormDirty} = this.props;
    this.setState({ poleCondition: { key: poleCondition.key ? poleCondition.key : poleCondition.value, label: poleCondition.label }, errors: [] });
    isFormDirty(true);
  }

  onArmLengthSelected = (armLength) => {
    const {isFormDirty} = this.props;
    this.setState({ armLength: { key: armLength.key ? armLength.key : armLength.value, label: armLength.label }, errors: [] });
    isFormDirty(true);
  }

  onArmConditionSelected = (armCondition) => {
    const {isFormDirty} = this.props;
    this.setState({ armCondition: { key: armCondition.key ? armCondition.key : armCondition.value, label: armCondition.label }, errors: [] });
    isFormDirty(true);
  }

  onFixtureTypeSelected = (fixtureType) => {
    const {isFormDirty} = this.props;
    this.setState({ fixtureType: { key: fixtureType.key ? fixtureType.key : fixtureType.value, label: fixtureType.label }, errors: [] });
    isFormDirty(true);
  }

  onFixtureConditionSelected = (fixtureCondition) => {
    const {isFormDirty} = this.props;
    this.setState({ fixtureCondition: { key: fixtureCondition.key ? fixtureCondition.key : fixtureCondition.value, label: fixtureCondition.label }, errors: [] });
    isFormDirty(true);
  }

  onLampTypeSelected = (lampType) => {
    const {isFormDirty} = this.props;
    this.setState({ lampType: { key: lampType.key ? lampType.key : lampType.value, label: lampType.label }, errors: [] });
    isFormDirty(true);
  }

  onPowerFeedSelected = (powerFeed) => {
    const {isFormDirty} = this.props;
    this.setState({ powerFeed: { key: powerFeed.key ? powerFeed.key : powerFeed.value, label: powerFeed.label }, errors: [] });
    isFormDirty(true);
  }

  convertValueToListItem = (value, list) => {
    let results = {};

    list = this.addNewOptionToList(value, list);
    if (value && !value.label)
      results = list.filter((item) => item.label === value);
    else if (value && value.label)
      results = list.filter((item) => item.label === value.label);

    return results;
  }

  handleValidation = () => {
    let isValid = true;
    let errors = [];
    let state = this.state;


    /*
    if (!state["siteName"]) {
      errors["siteName"] = Messages.SiteAddressRequiredText;
      isValid = false;
    }
    if (!state["address1"]) {
      errors["address1"] = Messages.SiteAddressRequiredText;
      isValid = false;
    }
    if (!state["address2"]) {
      errors["address2"] = Messages.SiteCityStateZipCodeRequiredText;
      isValid = false;
    }
    if (!state["poleType"]) {
      errors["poleType"] = Messages.SitePoleTypeRequiredText;
      isValid = false;
    }
    if (!state["poleType"]) {
      errors["poleType"] = Messages.SitePoleTypeRequiredText;
      isValid = false;
    }
    if (!state["fixtureType"]) {
      errors["fixtureType"] = Messages.SiteFixtureTypeRequiredText;
      isValid = false;
    }
    if (!state["lampType"]) {
      errors["lampType"] = Messages.SiteLampTypeRequiredText;
      isValid = false;
    }
    if (!state["powerFeed"]) {
      errors["powerFeed"] = Messages.SitePowerFeedRequiredText;
      isValid = false;
    }
    if (!state["longitude"]) {
      errors["longitude"] = Messages.SiteLongitudeRequiredText;
      isValid = false;
    }
    if (!state["latitude"]) {
      errors["latitude"] = Messages.SiteLatitudeRequiredText;
      isValid = false;
    }
    this.setState({ errors: errors });
    return isValid;

     */
    return isValid
  }

  onSaveClick = () => {
    if (this.handleValidation()) {
      this.setState({showLoader: true}, async () => {
        await this.saveSite();
      })
    }
  }

  onUpdateClick = () => {
    if (this.handleValidation()) {
      this.setState({ showLoader: true }, async () => {
        this.updateSite();
      });
    }
  }

  saveSite = async () => {
    let { siteName, address1, address2, poleType, poleCondition, armLength, armCondition, lightingArea,
      fixtureType, fixtureCondition, fixtureQuantity, lightHeight, lampType, lampWattage, metered, fixtureModel,
      powerFeed, poleId, longitude, latitude } = this.state;
    let {isFormDirty} = this.props;


    let siteToCreate = {
      site_name: siteName,
      street1_name: address1,
      street2_name: address2,
      lighting_area: lightingArea && lightingArea.label ? lightingArea.label : lightingArea,
      pole_type: poleType && poleType.label ? poleType.label : poleType,
      pole_condition: poleCondition && poleCondition.label ? poleCondition.label : poleCondition,
      arm_length: armLength && armLength.label ? armLength.label : armLength,
      arm_condition: armCondition && armCondition.label ? armCondition.label : armCondition,
      fixture_type: fixtureType && fixtureType.label ? fixtureType.label : fixtureType,
      fixture_condition: fixtureCondition && fixtureCondition.label ? fixtureCondition.label : fixtureCondition,
      fixture_quantity: fixtureQuantity,
      fixture_model: fixtureModel && fixtureModel.label ? fixtureModel.label : fixtureModel,
      light_height: lightHeight,
      lamp_type: lampType && lampType.label ? lampType.label : lampType,
      lamp_wattage: lampWattage,
      metered: metered && metered.label ? metered.label : metered,
      power_feed: powerFeed && powerFeed.label ? powerFeed.label : powerFeed,
      pole_id: poleId,
      longitude: longitude,
      latitude: latitude
    };

    saveSiteInDb(siteToCreate).then((response) => {
      if (response) {
        toast.success(Messages.CreateSiteSuccessText);
        setTimeout(() => {
          this.setState({showLoader:false});
          this.props.onUpdate();
          isFormDirty(false);
        }, 2000);
      }
    })

    /*
    let response = await updateSiteInDb(siteToUpdate);
    if (response !== undefined && response !== null) {
      toast.success(Messages.UpdateSiteSuccessText);
      setTimeout(() => {
        this.setState({showLoader:false});
        this.props.onUpdate();
      }, 2000);
    }
     */
  }

  updateSite = async () => {
    let { editedSiteId, siteName, address1, address2, poleType, poleCondition, armLength, armCondition, lightingArea,
      fixtureType, fixtureCondition, fixtureQuantity, lightHeight, lampType, lampWattage, metered, fixtureModel,
      powerFeed, poleId, longitude, latitude } = this.state;
    let {isFormDirty} = this.props;


    let siteToUpdate = {
      id: editedSiteId,
      site_name: siteName,
      street1_name: address1,
      street2_name: address2,
      lighting_area: lightingArea && lightingArea.label ? lightingArea.label : lightingArea,
      pole_type: poleType && poleType.label ? poleType.label : poleType,
      pole_condition: poleCondition && poleCondition.label ? poleCondition.label : poleCondition,
      arm_length: armLength && armLength.label ? armLength.label : armLength,
      arm_condition: armCondition && armCondition.label ? armCondition.label : armCondition,
      fixture_type: fixtureType && fixtureType.label ? fixtureType.label : fixtureType,
      fixture_condition: fixtureCondition && fixtureCondition.label ? fixtureCondition.label : fixtureCondition,
      fixture_quantity: fixtureQuantity,
      fixture_model: fixtureModel && fixtureModel.label ? fixtureModel.label : fixtureModel,
      light_height: lightHeight,
      lamp_type: lampType && lampType.label ? lampType.label : lampType,
      lamp_wattage: lampWattage,
      metered: metered && metered.label ? metered.label : metered,
      power_feed: powerFeed && powerFeed.label ? powerFeed.label : powerFeed,
      pole_id: poleId,
      longitude: longitude,
      latitude: latitude
    };

    updateSiteInDb(siteToUpdate).then((response) => {
      if (response) {
        toast.success(Messages.UpdateSiteSuccessText);
        setTimeout(() => {
          this.setState({showLoader:false});
          this.props.onUpdate();
          isFormDirty(false);
        }, 2000);
      }
    })

    /*
    let response = await updateSiteInDb(siteToUpdate);
    if (response !== undefined && response !== null) {
      toast.success(Messages.UpdateSiteSuccessText);
      setTimeout(() => {
        this.setState({showLoader:false});
        this.props.onUpdate();
      }, 2000);
    }
     */
  }

  checkForSpace = (e) => {
    let errors = {};
    let formIsValid = true;
    e = e || window.event;
    if (e.charCode == 32 && e.target.value.length === 0) {
      errors["generalErrors"] = Messages.FirstCharacterAsSpaceErrorText;
      formIsValid = false;
    }
    if (!formIsValid) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  setSiteId = (e) => {
    let errors = [];
    if (e.target.value.length > 15) {
      errors["siteId"] = Messages.LargeSiteIdValue;
      this.setState({ errors: errors });
    } else if (Number(e.target.value) < 0) {
      errors["siteId"] = Messages.NegativeValue;
      this.setState({ errors: errors });
    } else if ((Number(e.target.value) - Math.floor(Number(e.target.value))) !== 0) {
      errors["siteId"] = Messages.DecimalValue;
      this.setState({ errors: errors });
    } else {
      this.setState({ siteId: e.target.value, errors: errors });
    }
  }

  render() {
    let {isDirty, isFormDirty} = this.props;
    let {suggestions, onSuggestionsFetchRequested, onSuggestionsClearRequested, getSuggestionValue, renderSuggestion, inputProps} = this.props;
    let {poleTypes, conditions, armLength, lampTypes, powerFeedTypes, fixtureModels, fixtureTypes, lightingArea} = this.props;

    let createUpdateBtn = <Button buttonTextStyle={btnStyle} text="CREATE" buttonClickCallback={this.onSaveClick} />;
    let title = "Create new site";
    if (this.props.selectedSiteId !== undefined && this.props.selectedSiteId !== null) {
      title = "Edit Site";
      createUpdateBtn = <Button buttonTextStyle={btnStyle} text="UPDATE" buttonClickCallback={this.onUpdateClick} />
    }

    poleTypes = this.addNewOptionToList(this.state.poleType, poleTypes);
    conditions = this.addNewOptionToList(this.state.poleCondition, conditions);
    armLength = this.addNewOptionToList(this.state.armLength, armLength);
    conditions = this.addNewOptionToList(this.state.armCondition, conditions);
    fixtureTypes = this.addNewOptionToList(this.state.fixtureType, fixtureTypes);
    fixtureModels = this.addNewOptionToList(this.state.fixtureModel, fixtureModels);
    lampTypes = this.addNewOptionToList(this.state.lampType, lampTypes);
    lightingArea = this.addNewOptionToList(this.state.lightingArea, lightingArea);
    powerFeedTypes = this.addNewOptionToList(this.state.powerFeed, powerFeedTypes);

    return (
      <div>
        <h4 className="class-bold">{title}</h4>
        <div className={table}>
          <div className={wrapper}>

            <div className={item}>
              <div className="class-bold">Site Name</div>
              <input className="text-box" value={this.state.siteName} onChange={(event) => {this.setState({ siteName: event.target.value, errors: [] }); isFormDirty(true)}} type="text" maxLength="100"></input>
              <div className="application-error-messages">{this.state.errors["siteName"]}</div>
            </div>

            <div className={item}>
              <div className="class-bold">Pole Id</div>
              <input className="text-box" value={this.state.poleId} onChange={(event) => {this.setState({ poleId: event.target.value, errors: [] }); isFormDirty(true)}} type="text" maxLength="15"></input>
              <div className="application-error-messages">{this.state.errors["poleId"]}</div>
            </div>

            <div className={item}>
              <div className="class-bold">Address 1</div>
              <input className="text-box" value={this.state.address1} onChange={(event) => {this.setState({ address1: event.target.value, errors: [] }); isFormDirty(true)}} type="text" maxLength="100"></input>
              <div className="application-error-messages">{this.state.errors["address1"]}</div>
            </div>

            <div className={item}>
              <div className="class-bold">Address 2</div>
              <input className="text-box" value={this.state.address2} onChange={(event) => {this.setState({ address2: event.target.value, errors: [] }); isFormDirty(true)}} type="text" maxLength="100"></input>
              <div className="application-error-messages">{this.state.errors["address2"]}</div>
            </div>

            <div className={item}>
              <div className="class-bold">Pole Type</div>
              <div className="dropdown-select">
                <CreatableSelect value={this.convertValueToListItem(this.state.poleType, poleTypes)} onChange={this.onPoleTypeSelected} options={poleTypes}></CreatableSelect>
              </div>
              <div className="application-error-messages">{this.state.errors["poleType"]}</div>
            </div>

            <div className={item}>
              <div className="class-bold">Pole Condition</div>
              <div className="dropdown-select">
                <CreatableSelect value={this.convertValueToListItem(this.state.poleCondition, conditions)} onChange={this.onPoleConditionSelected} options={conditions}></CreatableSelect>
              </div>
              <div className="application-error-messages">{this.state.errors["poleCondition"]}</div>
            </div>

            <div className={item}>
              <div className="class-bold">Arm Length</div>
              <div className="dropdown-select">
                <CreatableSelect value={this.convertValueToListItem(this.state.armLength, armLength)} onChange={this.onArmLengthSelected} options={armLength}></CreatableSelect>
              </div>
              <div className="application-error-messages">{this.state.errors["armLength"]}</div>
            </div>

            <div className={item}>
              <div className="class-bold">Arm Condition</div>
              <div className="dropdown-select">
                <CreatableSelect value={this.convertValueToListItem(this.state.armCondition, conditions)} onChange={this.onArmConditionSelected} options={conditions}></CreatableSelect>
              </div>
              <div className="application-error-messages">{this.state.errors["armCondition"]}</div>
            </div>

            <div className={item}>
              <div className="class-bold">Fixture Type</div>
              <div className="dropdown-select">
                <CreatableSelect value={this.convertValueToListItem(this.state.fixtureType, fixtureTypes)} onChange={this.onFixtureTypeSelected} options={fixtureTypes}></CreatableSelect>
              </div>
              <div className="application-error-messages">{this.state.errors["fixtureType"]}</div>
            </div>

            <div className={item}>
              <div className="class-bold">Fixture Quantity</div>
              <div className="dropdown-select">
                <input className="text-box" value={this.state.fixtureQuantity} onChange={(event) => {this.setState({ fixtureQuantity: event.target.value, errors: [] }); isFormDirty(true)}} type="text" maxLength="50"></input>
              </div>
              <div className="application-error-messages">{this.state.errors["fixtureQuantity"]}</div>
            </div>

            <div className={item}>
              <div className="class-bold">Fixture Model</div>
              <div className="dropdown-select">
                <CreatableSelect value={this.convertValueToListItem(this.state.fixtureModel, fixtureModels)} onChange={this.onFixtureModelSelected} options={fixtureModels}></CreatableSelect>
              </div>
              <div className="application-error-messages">{this.state.errors["fixtureModel"]}</div>
            </div>

            <div className={item}>
              <div className="class-bold">Light Height</div>
              <div className="dropdown-select">
                <input className="text-box" value={this.state.lightHeight} onChange={(event) => {this.setState({ lightHeight: event.target.value, errors: [] }); isFormDirty(true)}} type="text" maxLength="50"></input>
              </div>
              <div className="application-error-messages">{this.state.errors["lightHeight"]}</div>
            </div>

            <div className={item}>
              <div className="class-bold">Lamp Type</div>
              <div className="dropdown-select">
                <CreatableSelect value={this.convertValueToListItem(this.state.lampType, lampTypes)} onChange={this.onLampTypeSelected} options={lampTypes}></CreatableSelect>
              </div>
              <div className="application-error-messages">{this.state.errors["lampType"]}</div>
            </div>

            <div className={item}>
              <div className="class-bold">Lamp Wattage</div>
              <div className="dropdown-select">
                <input className="text-box" value={this.state.lampWattage} onChange={(event) => {this.setState({ lampWattage: event.target.value, errors: [] }); isFormDirty(true)}} type="text" maxLength="50"></input>
              </div>
              <div className="application-error-messages">{this.state.errors["lampWattage"]}</div>
            </div>

            <div className={item}>
              <div className="class-bold">Metered</div>
              <div className="dropdown-select">
                <CreatableSelect value={this.convertValueToListItem(this.state.metered, [{label: "Yes", value: "Yes"}, {label: "No", value: "No"}])} onChange={this.onMeteredSelected} options={[{label: "Yes", value: "Yes"}, {label: "No", value: "No"}]}></CreatableSelect>
              </div>
              <div className="application-error-messages">{this.state.errors["metered"]}</div>
            </div>

            <div className={item}>
              <div className="class-bold">Lighting Area</div>
              <div className="dropdown-select">
                <CreatableSelect value={this.convertValueToListItem(this.state.lightingArea, lightingArea)} onChange={this.onLightingAreaSelected} options={lightingArea}></CreatableSelect>
              </div>
              <div className="application-error-messages">{this.state.errors["poleType"]}</div>
            </div>

            <div className={item}>
              <div className="class-bold">Power Feed</div>
              <div className="dropdown-select">
                <CreatableSelect value={this.convertValueToListItem(this.state.powerFeed, powerFeedTypes)} onChange={this.onPowerFeedSelected} options={powerFeedTypes}></CreatableSelect>
              </div>
              <div className="application-error-messages">{this.state.errors["device"]}</div>
            </div>

            <div className={item}>
              &nbsp;
            </div>

            <div className={item}>
              <div className="class-bold">Longitude</div>
              <input className="text-box" value={this.state.longitude} onChange={(event) => {this.setState({ longitude: event.target.value, errors: [] }); isFormDirty(true)}} type="text" maxLength="30"></input>
              <div className="application-error-messages">{this.state.errors["longitude"]}</div>
            </div>

            <div className={item}>
              <div className="class-bold">Latitude</div>
              <input className="text-box" value={this.state.latitude} onChange={(event) => {this.setState({ latitude: event.target.value, errors: [] }); isFormDirty(true)}} type="text" maxLength="30"></input>
              <div className="application-error-messages">{this.state.errors["longitude"]}</div>
            </div>

          </div>
        </div>
        <div className="application-error-messages error-messages-center">{this.state.errors["generalErrors"]}</div>
        <div className={footer}>
          <div className={cancelButtonContainer} onClick={this.props.onCancel}>
            <div className={cancelButton}>
              <div>CANCEL</div>
            </div>
          </div>
          <div>
            {createUpdateBtn}
          </div>
        </div>
        {
          this.state.showLoader &&
          (
            <div className="overlay">
              <div className="application-loader">
                <AppLoader />
              </div>
            </div>
          )
        }
      </div>
    );
  }

}

const btnStyle = css({
  fontFamily: 'OpenSans',
  fontSize: '14px',
  fontWeight: 'bold',
  fontStyle: 'normal',
  fontStretch: 'normal',
  letterSpacing: '1px',
});

const footer = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  marginTop: '70px'
});


const table = css({
});

const wrapper = css({
  display: 'grid',
  gridTemplateColumns: '300px 300px',
  gridTemplateRows: '80px 80px 80px 80px',
  gridGap: '10px',
});

const item = css({
  padding: '20px 20px 5px 20px'
});

const rightItem = css({
  gridColumn: '2 / 2',
  gridRow: '2 / 5',
  padding: '28px 20px 15px 20px'
});

const textAreaStyle = css({
  height: '100%',
  width: '96%',
  borderRadius: '2px',
  resize: 'none',
  backgroundColor: '#ffffff',
  border: 'solid 1px #dbdbdb',
  '&:focus': {
    outline: 'none',
    border: '1.5px solid #0151cb'
  },
  boxSizing: 'border-box',
  padding: '10px'
});

const cancelButton = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
  borderRadius: '2px',
  backgroundColor: '#FFFFFF',
  width: '153px',
  height: '100%',
  fontFamily: 'OpenSans',
  fontSize: '14px',
  fontWeight: 'bold',
  fontStyle: 'normal',
  fontStretch: 'normal',
  letterSpacing: '1px',
  color: '#5e6c84',
  border: '1px solid #C4CDD5',
  '&:hover': {
    backgroundColor: '#F4F5F7',
  },
});

const cancelButtonContainer = css({
  display: 'flex',
  flexGrow: '0.1'
});
