import { REHYDRATE } from 'redux-persist/constants';
import { CHECK_USER_TIMEOUT } from './user';
import { API_ROOT } from '../components/constants/api';
import { getWorkOrderList, deleteWorkOrderData, createWorkOrder, getWorkOrderDetails, updateWorkOrder, updateWorkOrderWithIssues } from '../components/api/work-order';

let workOrders = [];

export const KEYS = {
    NAME: 'work_order_name',
    CREATEDDATE: 'date_created',
    STATUS: 'status',
    PRIORITY: 'priority',
    DATEASSIGNED: 'date_assigned',
    ASSIGNEDTO: 'assigned_to',
    COMMENTS: 'comments'   
  };
  
  export const COLUMNS = [
    { label: 'WORK ORDER NAME', key: KEYS.NAME, isRequired: true },
    { label: 'CREATED DATE', key: KEYS.CREATEDDATE, isRequired: true },
    { label: 'STATUS', key: KEYS.STATUS, isRequired: true },
    { label: 'PRIORITY', key: KEYS.PRIORITY, isRequired: true },
    { label: 'DATE ASSIGNED', key: KEYS.DATEASSIGNED, isRequired: true },
    { label: 'ASSIGNED TO', key: KEYS.ASSIGNEDTO, isRequired: true },
    { label: 'COMMENTS', key: KEYS.COMMENTS, isRequired: true }   
  ];

  export const STATUS = {
    CANCELLED: 'Cancelled',
    REPORTED: 'Reported',
    PENDING: 'Pending'
  };

  export const PRIORITY = {
    MEDIUM: 'Medium',
    HIGH: 'High'
  };

  const initialState = { 
    data: workOrders.slice(0),   
    columns: COLUMNS
  };  

  const TYPES = {
    SHOW_COLUMNS: 'SHOW_COLUMNS'   
  };
  
  export default (state = initialState, action) => {    
    
    switch (action.type) {
      case REHYDRATE:
        const newState = { ...initialState };
        return newState;
      case TYPES.SHOW_COLUMNS:
        const columnIdentifier = action.payload.identifier;
        const show = action.payload.show;
        return filterColumns(state, columnIdentifier, show, COLUMNS);     
      default:
        return state;
    }
  };

  function filterColumns(state, columnId, show, columns = []) {
    const filteredColumns = state.columns.map(column => {
      let isRequired = column.key === columnId ? show : column.isRequired;
      return { ...column, isRequired };
    });
    return { ...state, columns: filteredColumns };
  }

  export function showColumns(identifier, show) {    
    return dispatch => {
      dispatch({
        type: CHECK_USER_TIMEOUT
      });
      dispatch({
        type: TYPES.SHOW_COLUMNS,
        payload: {
          identifier,
          show
        }
      });
    };
  }

//for getting the list of schedules from database
export async function getWorkOrderListFromDb(){ 
  let data = await getWorkOrderList(API_ROOT + 'work_orders');
  return data; 
};

//for deleting the work order details from database
export async function deleteWorkOrderFromDb(workOrderId) {  
  let data = await deleteWorkOrderData(API_ROOT + 'work_orders/' + workOrderId);
  return data;  
};

//for creating work order in database
export async function createWorkOrderInDb(orderToAdd) { 
   let data = await createWorkOrder(API_ROOT + 'work_orders', orderToAdd);
  return data;  
};

//for getting work order details from database using id
export async function getWorkOrderDetailsFromDb(workOrderId) {  
  let data = await getWorkOrderDetails(API_ROOT + 'work_orders/' + workOrderId);
  return data;  
};

//for updating work order in database
export async function updateWorkOrderInDb(orderToUpdate) { 
  let data = await updateWorkOrder(API_ROOT + 'work_orders/' + orderToUpdate.id, orderToUpdate);
  return data;  
};

//for updating work order in database
export async function updateWorkOrderIssuesInDb(selectedIssue) {  
  let data = await updateWorkOrderWithIssues(API_ROOT + 'work_orders/' + selectedIssue.id  + '/issues' , selectedIssue);
  return data;  
};



