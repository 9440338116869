import { REHYDRATE } from 'redux-persist/constants';
import { CHECK_USER_TIMEOUT } from './user';

import { getGroupList, deleteGroupData, createGroup, updateGroup, getGroupData, assignDevices, deleteDevicesfromGroup, addSubgroups, getDevicesInsideGroup, AssignSchedule } from '../components/api/group';
import { API_ROOT } from '../components/constants/api';

export const KEYS = {
  GROUPMAC: 'group_mac',
  GROUPNAME: 'group_name',
  NOOFDEVICES: 'device_count',
  ASSIGNEDSCHEDULE : 'schedule_name'
};

export const MODETYPE = {
  0: 'AUTO',
  1: 'MODE 1',
  2: 'MODE 2',
  3: 'OFF',
  4: 'FLASH'
};

export const COLUMNS = [
  { label: 'GROUP MAC', key: KEYS.GROUPMAC, isRequired: true },
  { label: 'GROUP NAME', key: KEYS.GROUPNAME, isRequired: true },
  { label: 'NO. OF DEVICES', key: KEYS.NOOFDEVICES, isRequired: true },
  { label: 'ASSIGNED SCHEDULE', key: KEYS.ASSIGNEDSCHEDULE, isRequired: true }
];

const initialState = {
  data : [],
  columns: COLUMNS
};

const TYPES = {
  SHOW_COLUMNS: 'SHOW_COLUMNS',
  SET_GROUP_LIST: 'SET_GROUP_LIST',
  SET_GROUP_DATA: 'SET_GROUP_DATA'
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REHYDRATE:
      const newState = { ...initialState };
      return newState;
    case TYPES.SHOW_COLUMNS:
      const columnIdentifier = action.payload.identifier;
      const show = action.payload.show;
      return filterColumns(state, columnIdentifier, show, COLUMNS);
    case TYPES.SET_GROUP_LIST:
      return state;
    case TYPES.SET_GROUP_DATA:
      return state;
    default:
      return state;
  }
};

function filterColumns(state, columnId, show, columns = []) {
  const filteredColumns = state.columns.map(column => {
    let isRequired = column.key === columnId ? show : column.isRequired;
    return { ...column, isRequired };
  });
  return { ...state, columns: filteredColumns };
}

export function showColumns(identifier, show) {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
    dispatch({
      type: TYPES.SHOW_COLUMNS,
      payload: {
        identifier,
        show
      }
    });
  };
}

//for getting the list of groups from database
export async function getGroupListFromDb(){
    let data = await getGroupList( API_ROOT + 'groups');
    return data;
};

export function setGroupListInStore() {
  return dispatch => {
    dispatch({
      type: TYPES.SET_GROUP_LIST
    });
  };
}

export function setGroupDetailsInStore() {
  return dispatch => {
    dispatch({
      type: TYPES.SET_GROUP_DATA
    });
  };
}

//for deleting the group details from database
export async function deleteGroupFromDb(groupId) {
    let data = await deleteGroupData(API_ROOT + 'groups/' + groupId);
    return data;
};

//for creating a group in database
export async function createGroupInDb(groupName){
    let data = await createGroup(API_ROOT + 'groups', groupName);
    return data;
};

//for updating the details of a single group in database
export async function updateGroupInDb(groupToUpdate) {
    let data = await updateGroup(API_ROOT + 'groups/' + groupToUpdate.groupId, groupToUpdate);
    return data;
};

//for getting the group details from database
export async function getGroupFromDb(groupId) {
  let data = await getGroupData(API_ROOT + 'groups/' + groupId);
  return data;
};

//for attaching devices to group in database
export async function assignDevicesToGroupInDb(devicesToAttach) {
  let data = await assignDevices(API_ROOT + 'groups/' + devicesToAttach.groupId + '/sites', devicesToAttach);
  return data;
};

//for deleting devices from group in database
export async function deleteDeviceFromGroupInDb(deviceToDelete) {
  let data = await deleteDevicesfromGroup(API_ROOT + 'groups/' + deviceToDelete.groupId + '/sites/' + deviceToDelete.siteId);
  return data;
};

//for adding group mask to devices/sites
export async function addSubgroupToSites(subgroupToAdd) {
  let data = await addSubgroups(API_ROOT + 'sites/' + subgroupToAdd.siteId , subgroupToAdd);
  return data;
};

//for getting devices attached to a particular group from database
export async function getDevicesInsideGroupById(groupId) {
  let data = await getDevicesInsideGroup(API_ROOT + 'groups/' + groupId + '/sites');
  return data;
};

//for assigning schedule to selected group
export async function AssignScheduleToGroup(scheduleToAssign){
  let data = await AssignSchedule(API_ROOT + 'groups/' + scheduleToAssign.group_id + '/schedule', scheduleToAssign);
  return data;
};

//
