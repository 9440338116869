import React from 'react';
import { css } from 'emotion';
import Button from '../button/button.js';
import { zoom } from '../../modules/map.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {LAYER_1X, LAYER_2X, LAYER_3X} from '../constants/map';
import {updateLayer} from '../../modules/map';
import OneXLayer from '../../../src/components/map/layers/1x-layer';
import TwoXLayer from '../../../src/components/map/layers/2x-layer';
import ThreeXLayer from '../../../src/components/map/layers/3x-layer';

class BottomButtons extends React.Component {
  zoomIn = () => {

    //this.props.zoom('IN', null);
      this.getCoordinates().then((coordinates) => {
          if (coordinates)
            this.props.zoom('IN', coordinates.geometry.coordinates);
          else
            this.props.zoom('IN', null);
      });
  };

  zoomOut = () => {
    //this.props.zoom('OUT', null);
      this.getCoordinates().then((coordinates) => {
        if (coordinates)
          this.props.zoom('OUT', coordinates.geometry.coordinates);
        else
          this.props.zoom('OUT', null);
      });
  };

  getCoordinates = async (props = this.props) => {
      switch (props.map.layerType) {
          case LAYER_1X: return await OneXLayer.calculateCenterPoint();
          case LAYER_2X: return await TwoXLayer.calculateCenterPoint(props.map.viewPort);
          case LAYER_3X: return await ThreeXLayer.calculateCenterPoint(props.map.viewPort, props.map.currentDeviceCategory);
          default: break;
      }
  }

  renderZoomInButton() {
    return (
      <Button
        text="ZOOM IN"
        btnClassName={zoomButtonStyle}
        buttonTextStyle={btnTextClass}
        buttonClickCallback={this.zoomIn}
        key="zoom_in_button"
      />
    );
  }

  renderZoomOutButton() {
    return (
      <Button
        text="ZOOM OUT"
        btnClassName={zoomButtonStyle}
        buttonTextStyle={btnTextClass}
        buttonClickCallback={this.zoomOut}
        key="zoom_out_button"
      />
    );
  }

  renderZoomControlsForLayer(layer) {
    if (layer === LAYER_1X) {
      return this.renderZoomInButton();
    } else if (layer === LAYER_3X) {
      return this.renderZoomOutButton();
    } else {
      return [this.renderZoomInButton(), this.renderZoomOutButton()];
    }
  }

  render() {
    let layerType = this.props.layerType;
    let buttons = this.renderZoomControlsForLayer(layerType);
    return (
      <div className={buttonBottomClass}>
        {buttons}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  zoomLevel: state.map.zoomLevel,
  layerType: state.map.layerType,
  map: state.map
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      zoom,
      updateLayer
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BottomButtons);

const buttonBottomClass = css({
  position: 'absolute',
  left: '32px',
  bottom: '32px',
  width: 'auto',
  display: 'flex',
  height: '42px',
  alignItems: 'center',
  '& div': {
    display: 'block'
  }
});

const zoomButtonStyle = css({
  //borderRadius: '2px',
  backgroundColor: '#ffffff !important',
  boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
  fontFamily: 'OpenSans',
  fontSize: '16px',
  fontWeight: 600,
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: '1px',
  textAlign: 'center',
  color: '#112138',
  marginRight: '16px',
  border: 'none',
  '&:hover': {
    backgroundColor: '#dfe1e5 !important',
    border: 'none !important',
  },
  '&:hover > span': {
    color: '#112138 !important'
  },
  '&:focus': {
    //border: 'solid 2px #0151cb'
  },
  '&:active': {
    border: 'none !important'
  }
});

const btnTextClass = css({
  color: '#112138'
});
