import 'isomorphic-fetch';
import { Cookies } from 'react-cookie';
import {getApiToken} from "../../utils/user";
const cookies = new Cookies();

//for getting the list of sites from database
export async function getSitesList(url) {
    let response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getApiToken()
        }
    });
    let status = response.status;
    let data = status >= 400 ? null : await response.json();
    return data;
}

export async function getSiteListValues(url) {
    let response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getApiToken()
        }
    });
    let status = response.status;
    let data = status >= 400 ? null : await response.json();
    return data;
}

export async function getMacSuggestionList(url) {
    let response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getApiToken()
        }
    });
    let status = response.status;
    let data = status >= 400 ? null : await response.json();
    return data;
}

//for deleting the site details from site id
export async function deleteSiteData(url) {
    let response = await fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getApiToken()
        }
    });
    let status = response.status;
    let data = status >= 400 ? null : await response;
    return data;
}

//for creating new site in database
export async function allocateDevice(url, allocation) {
    let response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getApiToken()
        },//site name, address information, pole type, fixture type, lamp type, power feed, pole_id, longitude, latitude
        body: JSON.stringify({
            ...allocation
        })
    });
    let status = response.status;

    let data = status >= 400 ? null : await response;
    return data;
}

//for creating new site in database
export async function allocateSite(url, allocation) {
    let response = await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getApiToken()
        },//site name, address information, pole type, fixture type, lamp type, power feed, pole_id, longitude, latitude
        body: JSON.stringify({
            ...allocation,
            id: undefined
        })
    });
    let status = response.status;

    let data = status >= 400 ? null : await response;
    return data;
}

//for fetching device coordinates database
export async function getCoordinates(url) {
    let response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getApiToken()
        }
    });
    let status = response.status;
    let data = status >= 400 ? null : await response.json();
    return data;
}

//for creating new site in database
export async function createSite(url, siteToAdd) {
    let response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getApiToken()
        },//site name, address information, pole type, fixture type, lamp type, power feed, pole_id, longitude, latitude
        body: JSON.stringify({
            site_name: siteToAdd.site_name,
            street1_name: siteToAdd.street1_name,
            street2_name: siteToAdd.street2_name,
            longitude: siteToAdd.longitude && siteToAdd.longitude > 0 ? siteToAdd.longitude : undefined,
            latitude: siteToAdd.latitude && siteToAdd.latitude > 0 ? siteToAdd.latitude : undefined,
            site_details: {
                pole_type: siteToAdd.pole_type,
                fixture_type: siteToAdd.fixture_type,
                lamp_type: siteToAdd.lamp_type,
                power_feed: siteToAdd.power_feed,
                pole_id: siteToAdd.pole_id,
                pole_condition: siteToAdd.pole_condition,
                light_height: siteToAdd.light_height,
                arm_length: siteToAdd.arm_length,
                arm_condition: siteToAdd.arm_condition,
                fixture_quantity: siteToAdd.fixture_quantity,
                object_id: siteToAdd.object_id,
                lamp_wattage: siteToAdd.lamp_wattage,
                metered: siteToAdd.metered,
                lighting_area: siteToAdd.lighting_area,
                fixture_model: siteToAdd.fixture_model,
                model_number: siteToAdd.model_number,
                new_fixture_type: siteToAdd.new_fixture_type
            }
        })
    });
    let status = response.status;
    let data = status >= 400 ? null : await response;
    return data;
}

//for getting site details from database using site id
export async function getSiteDetails(url) {
    let response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getApiToken()
        }
    });

    let status = response.status;
    let data = status >= 400 ? null : await response.json();
    return data;
}


//for updating site in database
export async function updateSite(url, siteToUpdate) {

    let response = await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getApiToken()
        },
        body: JSON.stringify({
            site_name: siteToUpdate.site_name,
            street1_name: siteToUpdate.street1_name,
            street2_name: siteToUpdate.street2_name,
            longitude: siteToUpdate.longitude,
            latitude: siteToUpdate.latitude,
            site_details: {
                pole_type: siteToUpdate.pole_type,
                fixture_type: siteToUpdate.fixture_type,
                lamp_type: siteToUpdate.lamp_type,
                power_feed: siteToUpdate.power_feed,
                pole_id: siteToUpdate.pole_id,
                pole_condition: siteToUpdate.pole_condition,
                light_height: siteToUpdate.light_height,
                arm_length: siteToUpdate.arm_length,
                arm_condition: siteToUpdate.arm_condition,
                fixture_quantity: siteToUpdate.fixture_quantity,
                fixture_condition: siteToUpdate.fixture_condition,
                object_id: siteToUpdate.object_id,
                lamp_wattage: siteToUpdate.lamp_wattage,
                metered: siteToUpdate.metered,
                lighting_area: siteToUpdate.lighting_area,
                fixture_model: siteToUpdate.fixture_model,
                model_number: siteToUpdate.model_number,
                new_fixture_type: siteToUpdate.new_fixture_type
            }
        })
    });

    let status = response.status;
    let data = status >= 400 ? null : await response;
    return data;

}

//for updating site in database
export async function updateSiteCoordinates(url, siteToUpdate) {

    let response = await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getApiToken()
        },
        body: JSON.stringify({
            site_id: siteToUpdate.site_id,
            device_id: siteToUpdate.device_id,
            apply_device_coordinates: siteToUpdate.allocateDevice
        })
    });

    let status = response.status;
    let data = status >= 400 ? null : await response;
    return data;

}

//for updating site in database
export async function updateDeviceCoordinates(url, deviceToUpdate) {

    let response = await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getApiToken()
        },
        body: JSON.stringify({
            longitude: deviceToUpdate.longitude,
            latitude: deviceToUpdate.latitude
        })
    });

    let status = response.status;
    let data = status >= 400 ? null : await response;
    return data;

}
