import 'isomorphic-fetch';

export async function login(url, username, password) {
  let response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      username: username,
      password: password,
    })
  });
  let status = response.status;
  let data = status >= 400 ? null : response.json();
  return data;
}
