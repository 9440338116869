import moment from 'moment';

export function shouldRefreshToken(expiryTime) {
  if (Date.now() > new Date(expiryTime).getTime() * 1000) {
    return true;
  }
  return false;
}

export function getToken() {
  let user = JSON.parse(window.localStorage.getItem('reduxPersist:user'))
  return user && user.token ? user.token : null;
}
export function getUser() {
  let user = JSON.parse(window.localStorage.getItem('reduxPersist:user'))
  return user && user.loggedusername ? user.loggedusername : '';
}
export function getName() {
  let user = JSON.parse(window.localStorage.getItem('reduxPersist:user'))
  return user && user.name ? user.name : '';
}
export function isTokenValid(tokenExpiry = null) {
  if (tokenExpiry === null) {
    return false;
  }
  let expiryTime = moment.utc(tokenExpiry, 'YYYY-MM-DDTHH:mm:ss').local();
  let currentTimeInUTC = moment.utc().local();
  let isValid = expiryTime.isAfter(currentTimeInUTC);
  return isValid;
}

export function isTokenInLocalStorageValid(state) {
  let reduxUser = JSON.parse(window.localStorage.getItem('reduxPersist:user'));
  let stateUser = state;
  let user = reduxUser && reduxUser.expiresAt ? reduxUser : stateUser;

  if (user !== null && user.expiresAt !== null && user.token !== null) {
    let tokenExpiry = user.expiresAt;
    if (!tokenExpiry) {
      return false;
    }

    let d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(tokenExpiry);
    let expiryTime = moment.utc(d, 'YYYY-MM-DDTHH:mm:ss').local();
    let currentTimeInUTC = moment.utc().local();
    let isValid = expiryTime.isAfter(currentTimeInUTC);

    return isValid;
  }

  return false;
}

export const setApiToken = token => {
  localStorage.setItem('apitoken', token);
};

export const clearApiToken = () => {
  localStorage.removeItem('apitoken');
}

export function getApiToken() {
  let token = localStorage.getItem('apitoken');
  if (token) {
    return token;
  } else {
    return null;
  }
}
