import React, { Component } from 'react';
import { css } from 'emotion';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { checkLoginTimeout, clearLoginTimeout } from '../../modules/user.js';
import Toggle from '../toggle/toggle.js';
import Tabs from '../tabs/tabs.js';
import { tabs } from '../constants/tabs';
import inputCheckedSVG from '../../assets/input_checked.svg';
import SiteTable from '../site/site-table';
import RowResizer from '../site/row-resizer';
import ColumnEditor from '../site/column-editor';
import { showColumns, getSitesListFromDb } from '../../modules/site.js';
import AppLoader from '../Loader/loader';
import {getMacSuggestionListFromDb, getSiteListValuesFromDb} from "../../modules/site";
import Button from "../button/button";
import Confirmation from "../confirmation/confirmation";
import Messages from "../constants/messages";
import Modal from "react-responsive-modal";
import CreateGroup from "../group/create-group";
import NewSite from "../site/new-site";
import GroupTable from "../group/group-table";

class Site extends Component {
    constructor(props) {
        super(props);
        this.state = {
            map: props.map,
            selectAll: false,
            createSiteModal: false,
            shown: false,
            tableData: [],
            showLoader: true,
            armLength: [],
            conditions: [],
            fixtureModels: [],
            fixtureTypes: [],
            lampTypes: [],
            lightingAreas: [],
            poleTypes: [],
            powerFeedTypes: [],
            showCloseConfirmation: false,
            isFormDirty: false
        };
    }

    componentDidMount() {
        if (this.state.map.sites !== undefined && this.state.map.sites !== null && this.state.map.sites.length > 0) {
            for (var key in this.state.map.sites) {    //for changing data coming from database to normal data array for binding in table.
                if (this.state.map.sites.hasOwnProperty(key)) {
                    this.state.tableData.push(this.state.map.sites[key]);
                }
            }
        } else {
            this.getListValuesList().then((item) => {
            });
            this.getSitesList().then(() => {
            });
        }
    }

    getSitesList = async () => {
        let data = await getSitesListFromDb(); //api call for getting the device list from database

        if (data !== undefined && data !== null) {
            /*
            data = data.filter(l => {
                if (l.device_type !== undefined) {
                    return l.device_type.toLowerCase() === "fybrlyte";
                }
            });
             */
            this.setState({ tableData: data, showLoader: false });
        }
    }

    onAddAndUpdate=()=>{
        this.getSitesList();
    }

    getListValuesList = async () => {
        let data = await getSiteListValuesFromDb(); //api call for getting the device list from database

        if (data !== undefined && data !== null) {
            /*
            data = data.filter(l => {
                if (l.device_type !== undefined) {
                    return l.device_type.toLowerCase() === "fybrlyte";
                }
            });
             */
            this.setState({armLength: data.arm_length_options.map((item) => ({
                    value: item.option,
                    label: item.option
                }))});
            this.setState({conditions: data.conditions.map((item) => ({
                    value: item.condition,
                    label: item.condition
                }))});
            this.setState({fixtureModels: data.fixture_models.map((item) => ({
                    value: item.model,
                    label: item.model
                }))});
            this.setState({fixtureTypes: data.fixture_types.map((item) => ({
                    value: item.type,
                    label: item.type
                }))});
            this.setState({lampTypes: data.lamp_types.map((item) => ({
                    value: item.type,
                    label: item.type
                }))});
            this.setState({lightingAreas: data.lighting_area_options.map((item) => ({
                    value: item.option,
                    label: item.option
                }))});
            this.setState({poleTypes: data.pole_types.map((item) => ({
                    value: item.type,
                    label: item.type
                }))});
            this.setState({powerFeedTypes: data.power_feed_types.map((item) => ({
                    value: item.type,
                    label: item.type
                }))});
        }
    }

    getMacAddressValuesList = async (mac_address) => {
        let data = await getMacSuggestionListFromDb(mac_address); //api call for getting the device list from database
        if (data !== undefined && data !== null) {
            return data;
        }
    }

    componentWillMount() {
        this.props.checkLoginTimeout(this.props.user);
    }

    showMenuOnMobile = () => {
        this.setState({ shown: !this.state.shown });
    }

    componentWillUnmount() {
        this.props.clearLoginTimeout(this.props.user);
    }

    onRowSizeChanged = (type) => {
        this.setState({
            rowType: type
        });
    }

    onCancelAllocateDevice = (site) => {
        const message = 'Device has been allocated';
        setTimeout(() => {
            this.props.showNotification(message);
        }, 100);
    }

    onNewSite = (site) => {
        const message = 'Site has been successfully created';
        setTimeout(() => {
            this.props.showNotification(message);
        }, 100);
    }

    onEditColumns = (id, show) => {
        this.props.showColumns(id, show);
    }

    createNewSite = () => {
        this.setState({ createSiteModal: true });
    }

    closeCreateSiteModal = () => {
        this.setState({ showCloseConfirmation: this.state.isFormDirty });

        if (!this.state.isFormDirty)
            this.setState({createSiteModal: false});
    }

    closeCloseConfirmation = () => {
        this.setState({ showCloseConfirmation: false });
    }

    onCloseConfirm = () => {
        this.setState({ showCloseConfirmation: false , createSiteModal: false}, () => {
            // window.location.reload(false);
        });

        this.setFormDirty(false);
    }

    selectAll = (e) => {
        let state = this.state;
        for (let key in state.map.sites) {
            state.map.sites[key].selected = e.target.checked;
        }
        this.setState({
            selectAll: e.target.checked,
            map: state.map
        });
    }

    setFormDirty = (isDirty) => {
        this.setState({isFormDirty: isDirty})
    }

    render() {
        const { columns } = this.props.site;

        return (
            <div className="customTab">
                <div className="desktopViewForTabs">
                    <Tabs tabs={tabs} activeTab="site" />
                </div>
                <div className="mobileViewForTabs">
                    <div onClick={() => this.showMenuOnMobile()}>
                        <i className="fa fa-bars mobile-menu-icon" aria-hidden="true"></i>
                    </div>
                    <div className={this.state.shown ? "show" : "hide"}>
                        <Tabs tabs={tabs} activeTab="site" />
                    </div>
                </div>

                <div className={container}>
                    <div className="toprow">
                        <div className={lhs}>
                            <ColumnEditor onEdit={this.onEditColumns} columns={columns} />
                        </div>
                        <div className="midbox"></div>
                        <div className={rhs}>
                            <Button
                                buttonTextStyle={btnStyle}
                                text="CREATE NEW SITE"
                                buttonClickCallback={this.createNewSite}
                                btnClassName="create-new-schedule-btn"
                            />
                        </div>
                    </div>

                    <div className={row}>
                        <div className="table-responsive">
                            <SiteTable
                                columns={columns.filter(col => col.isRequired)}
                                data={this.state.tableData}
                                rowType={this.state.rowType}
                                armLength={this.state.armLength}
                                fixtureModels={this.state.fixtureModels}
                                conditions={this.state.conditions}
                                poleTypes={this.state.poleTypes}
                                lampTypes={this.state.lampTypes}
                                powerFeedTypes={this.state.powerFeedTypes}
                                fixtureTypes={this.state.fixtureTypes}
                                lightingAreas={this.state.lightingAreas}
                                siteSuggestion={this.getMacAddressValuesList}
                                onUpdate={this.onAddAndUpdate}
                                onCancelAllocateDevice={this.onCancelAllocateDevice}
                                onNewSite={this.onNewSite}
                                isFormDirty={this.setFormDirty}
                                isDirty={this.state.isFormDirty}
                            />
                        </div>
                    </div>
                    <div className={rowResizerStyle}>
                        <RowResizer onRowSizeChanged={this.onRowSizeChanged} />
                    </div>
                </div>
                {
                    this.state.showCloseConfirmation &&
                    (
                        <Confirmation confirmationText={Messages.SiteCloseConfirmation} confirmButtonText="Confirm" rejectButtonText="Cancel" openConfirmation={true} closeConfirmationModal={this.closeCloseConfirmation} onConfirmClicked={this.onCloseConfirm} />
                    )
                }
                <div id="createSiteModalDiv">
                    <Modal classNames={{ modal: "create-site-modal" }} open={this.state.createSiteModal} onClose={this.closeCreateSiteModal} right closeOnOverlayClick={false}>
                        <NewSite
                            selectedSiteId={this.state.selectedSiteId}
                            deviceList={this.state.deviceList}
                            poleTypes={this.state.poleTypes}
                            conditions={this.state.conditions}
                            armLength={this.state.armLength}
                            lampTypes={this.state.lampTypes}
                            powerFeedTypes={this.state.powerFeedTypes}
                            fixtureModels={this.state.fixtureModels}
                            fixtureTypes={this.state.fixtureTypes}
                            lightingArea={this.state.lightingAreas}
                            //onNewSite={onNewSite}
                            onCancel={this.closeCreateSiteModal}
                            onUpdate={this.onUpdate}
                            isFormDirty={this.setFormDirty}
                            isDirty={this.state.isFormDirty}
                        />
                    </Modal>
                </div>
                {
                    this.state.showLoader &&
                    (
                        <div className="overlay">
                            <div className="application-loader">
                                <AppLoader />
                            </div>
                        </div>
                    )
                }
            </div>);
    }
}

const mapStateToProps = state => ({
    map: state.map,
    user: state.user,
    site: state.site
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            checkLoginTimeout,
            clearLoginTimeout,
            showColumns
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(Site);

const lhs = css({
    alignSelf: 'center',
});

const row = css({
    display: 'flex',
});

const rowResizerStyle = css({
    display: 'flex',
    marginLeft: '5px'
});

const rhs = css({});

const btnStyle = css({
    fontFamily: 'OpenSans',
    fontSize: '13px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: '1px'
});

const itemStyle = css({
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0',
    height: '63px',
    boxSizing: 'border-box',
});

const rowStyle = css({
    display: 'contents',
    '&:nth-child(2n+1)': {
        '& .backgroundStyle': {
            backgroundColor: '#fafbfc',
        }
    }
});

const container = css({
    display: 'flex',
    flexDirection: 'column',
    padding: '10px'
});

const editButtonStyle = css({
    borderRadius: '2px',
    border: 'solid 1px #dfe1e5',
    fontFamily: 'OpenSans',
    fontSize: '14px',
    fontWeight: 600,
    color: '#5e6c84',
    textAlign: 'center',
    lineHeight: 1.5,
    marginLeft: '5px',
    padding: '10px',
    width: '50px',
});

const checkbox = css({
    display: 'inline-block',
    width: '12px',
    height: '12px',
    position: 'relative',
    '& input[type=checkbox]': {
        visibility: 'hidden',
    },
    '& label': {
        display: 'inline-block',
        cursor: 'pointer',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '14px',
        height: '14px',
        boxSizing: 'border-box',
        marginLeft: '15px',
        marginTop: '3px',
        border: 'solid 1px #c1c7d0',
        borderRadius: '2px',
    },
    '& input[type=checkbox]:checked + label': {
        content: `url(${inputCheckedSVG})`,
        transition: 'all .5s ease',
        border: 0,
    }
});
