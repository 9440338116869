import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import app from './app';
import user from './user';
import map from './map';
import issues from './issues';
import scheduling from './scheduling';
import grouping from './grouping';
import inventory from './inventory';
import workorder from './work-order';
import site from './site';

export default combineReducers({
  routing: routerReducer,
  app,
  user,
  map,
  issues,
  scheduling,
  grouping,
  inventory,
  workorder,
  site
})
