import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { css } from 'emotion';
import PropTypes from 'prop-types';
import FybrLogo from '../../fybr-logo.svg';
import { Cookies } from 'react-cookie'; //added on 18th september
import { ToastContainer } from 'react-toastify'; //for showing toaster messages
import 'react-toastify/dist/ReactToastify.css';
import {getName, getUser} from '../../utils/user';

const cookies = new Cookies();

const navLinks = [
  {
    name: 'STREET LIGHT MANAGEMENT',
    id: 'lighting',
    title : 'STREET LIGHT MANAGEMENT',
    href: '/'
  }
];

export default class AppHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserMenus: false
    };
    this.toggleUserMenus = this.toggleUserMenus.bind(this);
  }

  logout() {
    this.props.logoutAction();
    this.props.goToLoginPage();
    this.setState({ showUserMenus: false });
  }

  renderLogoutButton() {
    let isUserLoggedIn = this.props.user.isLoggedIn;
    if (isUserLoggedIn) {
      return (
        <div className={rightAligned} onClick={this.logout.bind(this)}>
          LOGOUT
        </div>
      );
    }
    return null;
  }

  toggleUserMenus() {
    this.setState({ showUserMenus: !this.state.showUserMenus });
  }

  render() {

    let btn = this.props.button.name ? (
      <div className={rightAligned} onClick={this.props.button.func}>
        {this.props.button.name === 'Home' ? '' : this.props.button.name}
      </div>
    ) : (
        <div onClick={this.toggleUserMenus} onBlur={this.toggleUserMenus} className={rightMenu}>
          <div className={rightLink}> <i className="fa fa-user-circle"></i> <span className="username">{getName()}</span>
            <i className="fa fa-caret-down user-profile-icon"></i>
            {this.state.showUserMenus &&
              <div className="user-profile-menus">
                <div id="div-username-mob">{getName()}</div>
                <div onClick={this.logout.bind(this)} title="Logout"><i className="fa fa-sign-out action-items" aria-hidden="true"></i>Logout</div>
              </div>
            }
          </div>
        </div>
      );

    return (
      <div>
        <ToastContainer />
        <div className="appHeaderClass">
          <div className={navBar}>
            <Link to="/">
              <img className={logo} alt="Fybr Logo" src={FybrLogo} />
            </Link>
            <hr className={verticalSeperator} />
            {navLinks.map(link => (
              <li className="headerLink" key={link.id}>
                <Link title={link.title} to={link.href}>{link.name}</Link>
              </li>
            ))}
            {btn}
          </div>
        </div>
        <div className={marginBar} />
      </div>
    );
  }
}

const navBar = css({
  display: 'flex',
  width: '100%',
  margin: '0px',
  alignItems: 'center'
});

const logo = css({
  display: 'block',
  marginRight: '20px'
});

const verticalSeperator = css({
  margin: '0',
  width: '0',
  border: 'solid 1px #979797',
  height: '20px',
  marginTop: '-2.5px',
  marginRight: '20px'
});

const marginBar = css({
  height: '60px',
  width: '100%'
});

const rightAligned = css({
  textTransform: 'uppercase',
  marginLeft: 'auto',
  cursor: 'pointer'
});

const rightMenu = css({
  marginLeft: 'auto',
});

const rightLink = css({
  display: 'inline-block',
  cursor: 'pointer',
  fontSize: '1rem',
  borderLeft: '1px solid #6c798f',
  padding: '0 1.5rem',
  lineHeight: '60px',
});

AppHeader.propTypes = {
  button: PropTypes.object
};
AppHeader.defaultProps = {
  button: {}
};
