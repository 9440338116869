import {
  get1xCircleSelectedStyle,
  get1xCircleStyle
} from '../styles/1x-styles';
import {get1xBgStyle, get1xOutlineStyle, get1xSelectedBgStyle} from "../../map/styles/1x-styles";

const defaultOptions = {
  minZoom: 1,
  maxZoom: 10
};

export function getSource(features) {
  return {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: features
    }
  };
}

export function applyStyles(sourceId, options = defaultOptions) {
  let bgStyle = get1xBgStyle(
      '1x-bg-style',
      sourceId,
      options.minZoom,
      options.maxZoom
  );
  let bgSelectedStyle = get1xSelectedBgStyle(
      '1x-selected-bg-style',
      sourceId,
      options.minZoom,
      options.maxZoom
  );
  let outlineStyle = get1xOutlineStyle(
      '1x-outline-style',
      sourceId,
      options.minZoom,
      options.maxZoom
  );

  return [bgStyle, bgSelectedStyle, outlineStyle];
}

export function getSourceLayer(source, sourceId, options = defaultOptions) {
  return {
    source: { id: sourceId, sourceObj: source },
    styles: applyStyles(sourceId, options)
  };
}
