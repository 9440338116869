import React from 'react';
import { css } from 'emotion';

const maxWidth = 268;
const minWidth = 35;

export default class BrightnessBar extends React.Component {

  constructor(props) {
    super(props);
    this.gridCol = '';
  }

  componentWillMount(){
    this.calculateGrid();
  }
  componentWillReceiveProps(){
    this.calculateGrid();
  }

  calculateGrid = ()=>{
    this.gridCol = '';
    let rangeBuckets = this.getRangeBuckets(this.props.buckets); 
    Object.keys(rangeBuckets).sort().forEach((value) => {
      let gridWidth = rangeBuckets[value]/this.props.totalLights * maxWidth;
      this.gridCol += `${gridWidth}px `;
    }); 
  
  }
  getRangeBuckets = (buckets) => {
    let rangeEnds = [0, 1023, 2047, 3071];  //approx [0,25%,50%,75%]
    let newRangeBuckets = {0: 0, 1024: 0, 2048: 0, 3072: 0, 4095: 0};
    Object.keys(buckets).sort().forEach((value) => {
      value = parseInt(value,10);

      if(value === rangeEnds[0]){
        newRangeBuckets[0]=newRangeBuckets[0]+buckets[value];
      }else if(value >= rangeEnds[3]){
        newRangeBuckets[4095]=newRangeBuckets[4095]+buckets[value];
      }else if(value >= rangeEnds[2]){
        newRangeBuckets[3072]=newRangeBuckets[3072]+buckets[value];        
      }else if(value >= rangeEnds[1]){
        newRangeBuckets[2048]=newRangeBuckets[2048]+buckets[value];        
      }else if(value > rangeEnds[0]){
        newRangeBuckets[1024]=newRangeBuckets[1024]+buckets[value];        
      }
    });
    Object.keys(newRangeBuckets).forEach(k=> {
        if (newRangeBuckets[k]===0 )
        delete newRangeBuckets[k];
      });
    return newRangeBuckets;
  }
  renderBuckets() {
    let buckets = this.getRangeBuckets(this.props.buckets);
    let totalLights = this.props.totalLights;
    let values = Object.keys(buckets).map(val=>{return parseInt(val,10)}).sort(function(a, b){return a-b});
    return values.map((value, i) => {
      let brightness = value;
      let isSwitchedOff = parseInt(value,10) === 0;
      let numOfLights = buckets[value];
      let width = numOfLights/totalLights * maxWidth;
      let bgColors = ['#ffe89b','#ffdc68', '#ffd035','#ffc402'];
      let maxBrightness = 4095;
      let index = Math.floor((brightness/maxBrightness)/0.25);
      let bgColor = parseInt(brightness,10) === 0 ? '#c1c7d0' : bgColors[index - 1];
      let brightnessPercentage = ['01-25', '26-50', '51-75', '76-100'];
      let colWidth = width*1.15 > maxWidth ? maxWidth : width*1.15;
      if(colWidth<minWidth){
        colWidth = minWidth;
      }
      let styleObj = {
        width: `${width}px`,
        backgroundColor: `${bgColor}`,
        ':hover': {
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          height: '56px',
          width: `${colWidth}px`,
          boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.18)',
          marginTop: '-8px',
          zIndex: 1,
          '& span': {
            display: 'inline-block',
            fontFamily: 'OpenSans',
            fontSize: '10px',
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.5,
            letterSpacing: 'normal',
            color: '#5e6c84'
          }
        }
      };
      if(i===0){
        //first element
        styleObj.borderRadius = '2px';
        styleObj.borderTopRightRadius = '0px';
        styleObj.borderBottomRightRadius = '0px';
        styleObj[':hover'].marginLeft = '-5px';
      }
      if(i === values.length-1){
        styleObj.borderRadius = '2px';
        styleObj.borderTopLeftRadius = '0px';
        styleObj.borderBottomLeftRadius = '0px';
        styleObj[':hover'].marginLeft = '-8px';
      }

      let style = css(styleObj);
      if(isSwitchedOff) {
        return (
          <div className={barBlockClass + ' ' + style} key={i}>
            <span className={hiddenSpan}>OFF</span>
            <span className={displayOffSpan}>
              {numOfLights} <span className={hiddenSpan}> lights</span>
            </span>
          </div>
        );
      } else {
        return (
          <div className={barBlockClass + ' ' + style} key={i}>
            <span className={hiddenSpan}>{brightnessPercentage[index-1]}%</span>
            <span className={displaySpan}>
              {numOfLights} <span className={hiddenSpan}> lights</span>
            </span>
          </div>
        );
      }
    });
  }

  render() {
    this.calculateGrid();
    let gridStyle = css({
      gridTemplateColumns: `${this.gridCol}`
    });
    return (
      <div className={selectionBar + ' ' + gridStyle}>
        {this.renderBuckets()}
      </div>
    );
  }

}

const selectionBar = css({
  padding: '24px 16px 24px 16px',
  whiteSpace: 'unset',
  overflow: 'hidden',
  display: 'grid',
  maxWidth: '300px',
  gridTemplateRows: '40px',
  maxHeight: '40px'
});

const barBlockClass = css({
  height: '40px',
  width: '30px',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  transitionProperty: 'width, height',
  transitionDuration: '0.05s',
  transitionTimingFunction: 'linear'
});

const hiddenSpan = css({
  display: 'none',
  fontFamily: 'OpenSans',
  fontSize: '12px',
  fontWeight: 600,
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  color: '#5e6c84'
});

const displayOffSpan = css({
  fontFamily: 'OpenSans',
  fontSize: '10px',
  fontWeight: 600,
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  textAlign: 'center',
  color: '#5e6c84'
});

const displaySpan = css({
  fontFamily: 'OpenSans',
  fontSize: '10px',
  fontWeight: 600,
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  textAlign: 'center',
  color: '#ce9e00'
});
