export const TOKEN =
  'pk.eyJ1IjoiYXJ1bmFicmFoYW0iLCJhIjoiODBJTV9WUSJ9.m5tbZ5XYg8VhD-8Qu7d_SA';
export const DEFAULT_ZOOM = 1.99999999999999;
export const DEFAULT_ZOOM_MODAL = 1;
 //export const DEFAULT_ZOOM = 16.7737153468216;
//export const DEFAULT_CENTER = [-73.93694848247401, 40.70766035719308];
export const DEFAULT_CENTER = [-90.631591796875  , 38.66868896484375 ];

export const LAYER_1X_ZOOM_LEVEL = {
  START: 1,
  END: 10.99999999999999,
  DEFAULT: 4
};

export const LAYER_1X_ZOOM_LEVEL_DEVICE = {
  START: 1,
  END: 10.99999999999999,
  DEFAULT: 2
};

export const LAYER_2X_ZOOM_LEVEL = {
  START: 11,
  END: 15.99999999999999,
  DEFAULT: 14.5
};

export const LAYER_2X_ZOOM_LEVEL_DEVICE = {
  START: 11,
  END: 21.99999999999999,
  DEFAULT: 14.5
};

export const LAYER_3X_ZOOM_LEVEL = {
  START: 16,
  END: 22,
  DEFAULT: 16
};

export const LAYER_1X = 'LAYER_1X';
export const LAYER_2X = 'LAYER_2X';
export const LAYER_3X = 'LAYER_3X';
export const ON = 'on';
export const OFF = 'off';

export const ZOOM_LEVELS = [
  LAYER_1X_ZOOM_LEVEL,
  LAYER_2X_ZOOM_LEVEL,
  LAYER_3X_ZOOM_LEVEL
];
export const LAYERS = [LAYER_1X, LAYER_2X, LAYER_3X];

export function getLayerTypeForZoom(zoom) {
  if (zoom >= LAYER_1X_ZOOM_LEVEL.START && zoom < LAYER_1X_ZOOM_LEVEL.END) {
    return LAYER_1X;
  }
  if (zoom >= LAYER_2X_ZOOM_LEVEL.START && zoom < LAYER_2X_ZOOM_LEVEL.END) {
    return LAYER_2X;
  }
  if (zoom >= LAYER_3X_ZOOM_LEVEL.START && zoom < LAYER_3X_ZOOM_LEVEL.END) {
    return LAYER_3X;
  }
  return null;
}
