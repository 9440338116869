import React, { Component } from 'react';
import WorkOrderTable from '../work-order/work-order-table';
import RowResizer from '../group/row-resizer';
import Tabs from '../tabs/tabs.js';
import ColumnEditor from '../group/column-editor';
import { tabs } from '../constants/tabs';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { css } from 'emotion';
import { checkLoginTimeout, clearLoginTimeout, checkUserTimeoutAndLogout , logout} from '../../modules/user.js';
import Button from '../button/button.js';
import { showColumns } from '../../modules/work-order.js';
import CreateWorkOrder from '../work-order/create-work-order.js';
import Modal from 'react-responsive-modal';
import Confirmation from '../confirmation/confirmation.js';
import Messages from '../constants/messages';
import { getWorkOrderListFromDb } from '../../modules/work-order';
import AppLoader from '../Loader/loader';
import CreateGroup from "../group/create-group";


class WorkOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shown: false,
      tabledata: [],
      showCreateWorkOrderModal: false,
      showCloseConfirmation: false,
      showLoader: true,
      isFormDirty: false
    };
  }

  componentDidMount() {
    this.getWorkOrderList();
  }

  getWorkOrderList = async () => {
    let data = await getWorkOrderListFromDb();//api call for getting the work order list from database
    if(data)
    {
    this.setState({ tabledata: data, showLoader: false });
  }

  }

  showMenuOnMobile = () => {
    this.setState({ shown: !this.state.shown });
  }

  componentWillMount() {
    checkLoginTimeout(this.props.user);
  }

  componentWillUnmount() {
    this.props.clearLoginTimeout(this.props.user);
  }

  onEditColumns = (id, show) => {
    this.props.showColumns(id, show);
  }

  onRowSizeChanged = (type) => {
    this.setState({
      rowType: type
    });
  }

  createNewWorkOrder = () => {
    this.setState({ showCreateWorkOrderModal: true });
  }

  closeWorkModal = () => {
    this.setState({ showCloseConfirmation: this.state.isFormDirty });

    if (!this.state.isFormDirty)
      this.setState({showCreateWorkOrderModal: false});
  }

  closeCloseConfirmation = () => {
    this.setState({ showCloseConfirmation: false });
  }

  onCloseConfirm = () => {
    this.setState({ showCloseConfirmation: false });
    this.setState({ showCreateWorkOrderModal: false });
    this.setFormDirty(false);
  }

  setFormDirty = (isDirty) => {
    this.setState({isFormDirty: isDirty})
  }

  onAddAndUpdate =()=>{
    this.getWorkOrderList();
    setTimeout(() => {
        this.setState({showCreateWorkOrderModal: false})
  }, 2000);
  }

  render() {
    const { columns } = this.props.workorder;
    const { data } = this.state;
    return (
      <div className="workorder customTab">
        <div className="desktopViewForTabs">
          <Tabs tabs={tabs} activeTab="workorder" />
        </div>
        <div className="mobileViewForTabs">
          <div onClick={() => this.showMenuOnMobile()}>
            <i className="fa fa-bars mobile-menu-icon" aria-hidden="true"></i>
          </div>
          <div className={this.state.shown ? "show" : "hide"}>
            <Tabs tabs={tabs} activeTab="workorder" />
          </div>
        </div>

        <div className={container}>
          <div className="toprow">
            <div className={lhs}>
              <ColumnEditor onEdit={this.onEditColumns} columns={columns} />
            </div>
            <div className="midbox"></div>
            <div className={rhs}>
              <Button
                buttonTextStyle={btnStyle}
                text="CREATE WORK ORDER"
                buttonClickCallback={this.createNewWorkOrder}
                btnClassName="create-new-schedule-btn"
              />
            </div>
          </div>

          <div id="createNewWorkOrder">
            <Modal classNames={{ modal: "create-work-order-modal" }} open={this.state.showCreateWorkOrderModal} onClose={this.closeWorkModal} right closeOnOverlayClick={false}>
              <CreateWorkOrder onClose={this.closeWorkModal} onAddWork={this.onAddAndUpdate} isFormDirty={this.setFormDirty} isDirty={this.state.isFormDirty}></CreateWorkOrder>
            </Modal>
          </div>
          {
            this.state.showCloseConfirmation &&
            (
              <Confirmation confirmationText={Messages.WorkOrderCloseConfirmation} confirmButtonText="Confirm" rejectButtonText="Cancel" openConfirmation={true} closeConfirmationModal={this.closeCloseConfirmation} onConfirmClicked={this.onCloseConfirm} />
            )
          }
          <div className={row}>
            <div className="table-responsive">
              <WorkOrderTable
                columns={columns.filter(col => col.isRequired)}
                data={this.state.tabledata}
                rowType={this.state.rowType}
                onUpdate={this.onAddAndUpdate}
              />
            </div>
          </div>
          <div className={rowResizerStyle}>
            <RowResizer onRowSizeChanged={this.onRowSizeChanged} />
          </div>
        </div>
        {
          this.state.showLoader &&
          (
            <div className="overlay">
              <div className="application-loader">
                <AppLoader />
              </div>
            </div>
          )
        }
      </div>
    );
  }
}

const row = css({
  display: 'flex',
});

const lhs = css({
  alignSelf: 'center',
});

const rhs = css({});

const btnStyle = css({
  fontFamily: 'OpenSans',
  fontSize: '13px',
  fontWeight: 'bold',
  fontStyle: 'normal',
  fontStretch: 'normal',
  letterSpacing: '1px'
});

const container = css({
  display: 'flex',
  flexDirection: 'column',
  padding: '10px'
});

const rowResizerStyle = css({
  display: 'flex',
  marginLeft: '5px'
});

const mapStateToProps = state => ({
  workorder: state.workorder,
  user: state.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      checkLoginTimeout,
      clearLoginTimeout,
      showColumns
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrder);
