import React, { Component } from 'react';
import Option from './option';
import colIcon from '../../assets/col-icon.svg';
import upArrow from '../../assets/sort-asc-arrow.svg';
import downArrow from '../../assets/sort-desc-arrow.svg';
import { css } from 'emotion';

class MultiSelectDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      title: this.props.title
    };
    this.onOptionSelected = this.onOptionSelected.bind(this);
    this.toggleList = this.toggleList.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.node = null;
  }

  onOptionSelected(optionId, show) {
    this.props.onOptionSelected(optionId, show);
  }

  toggleList() {
    this.setState(prevState => ({
      isExpanded: !prevState.isExpanded
    }));
  }

  handleClickOutside() {
    this.setState({
      isExpanded: false
    })
  }

  handleClick = (e) => {
    if(this.node !== null && this.node.contains(e.target)) {
      return;
    }
    this.handleClickOutside();
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  renderListItems(columns) {
    const items = columns.map((column) => {
      return (
        <Option
          label={column.label}
          isSelected={column.isRequired}
          keyId={column.key}
          onSelect={this.onOptionSelected}
          key={column.key}
        />
      );
    });
    const showList = this.state.isExpanded;
    if(!showList) {
      return null;
    }
    return items;
  }

  render() {
    const { title, isExpanded } = this.state;
    const { columns } = this.props;
    const container = css({
      padding: '15px',
      borderRadius: '2px',
      fontFamily: 'OpenSans',
      color: '#5e6c84',
      fontSize: '14px',
      fontWeight: 'bold',
      fontStyle: 'normal',
      fontStretch: 'normal',
      letterSpacing: '1px',
      backgroundColor: '#FFFFFF',
      width: '174px',
      '&:hover': {
        backgroundColor: '#F4F5F7',
      },
      border: '1px solid #C4CDD5',
    });
    const header = css({
      display: 'flex',
      flexDirection: 'row'
    });
    const iconStyle = css({
      display: 'flex',
      flex: '0.1',
      justifyContent: 'center',
    });
    const titleStyle = css({
      display: 'flex',
      flex: '0.8',
      justifyContent: 'center'
    });
    const arrowStyle = css({
      display: 'flex',
      flex: '0.1',
      justifyContent: 'center'
    });
    const items = css({
      zIndex: 10,
      width: '200px',
      marginTop: '25px',
      left: '5px',
      position: 'absolute',
      backgroundColor: '#ffffff',
      borderRadius: '4px',
      boxShadow: '0 3px 10px 0 rgba(0, 0, 0, 0.09)'
    });
    const arrowIcon = isExpanded ? upArrow : downArrow;
    return (
      <div className={container} ref={node => this.node = node}>
        <div onClick={this.toggleList} className={header}>
          <div className={iconStyle}>
            <img src={colIcon} alt=""/>
          </div>
          <div className={titleStyle}>
            {title}
          </div>
          <div className={arrowStyle}>
            <img src={arrowIcon} alt=""/>
          </div>
        </div>
        <div className={items}>
          {this.renderListItems(columns)}
        </div>
      </div>
    );
  }
}

export default class ColumnEditor extends Component {
  render() {
    return (
      <MultiSelectDropDown
        title={'COLUMNS'}
        columns={this.props.columns}
        onOptionSelected={this.props.onEdit}
      />
    );
  }
}
