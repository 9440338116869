import { REHYDRATE } from 'redux-persist/constants';
import orderby from 'lodash.orderby';
import {
    arrayToObject,
    getLimits,
    addItemsToArray,
    removeItemsFromArray
} from '../utils/utils';
import {CHECK_USER_TIMEOUT} from './user';
import flow from 'lodash.flow';

import {
    getSitesList,
    deleteSiteData,
    createSite,
    getSiteDetails,
    updateSite,
    getSiteListValues,
    getMacSuggestionList,
    allocateDevice,
    allocateSite,
    getCoordinates,
    updateSiteCoordinates,
    updateDeviceCoordinates
} from '../components/api/site';
import { API_ROOT } from '../components/constants/api';
import site from "../components/pages/site";

let SITES = [];
export const KEYS = {
    SITENAME: 'site_name',
    ADDRESS: 'street1_name',
    LOCATION: 'street2_name',
    POLETYPE: 'pole_type',
    FIXTURETYPE: 'fixture_type',
    LAMPTYPE: 'lamp_type',
    POWERFEED: 'power_feed',
    POLEID: 'pole_id',
    LONGITUDE: 'longitude',
    LATITUDE: 'latitude'
};

export const MODETYPE = {
    0: 'AUTO',
    1: 'MODE 1',
    2: 'MODE 2',
    3: 'OFF',
    4: 'FLASH'
};
export const COLUMNS = [
    { label: 'CLIENTS NAME', key: KEYS.SITENAME, isRequired: true },
    { label: 'ADDRESS', key: KEYS.ADDRESS, isRequired: true },
    { label: 'CITY,STATE,ZIP', key: KEYS.LOCATION, isRequired: true },
    { label: 'POLETYPE', key: KEYS.POLETYPE, isRequired: true },
    { label: 'FIXTURE TYPE', key: KEYS.FIXTURETYPE, isRequired: true },
    { label: 'LAMP TYPE', key: KEYS.LAMPTYPE, isRequired: true },
    { label: 'POWER FEED', key: KEYS.POWERFEED, isRequired: true },
    { label: 'POLE ID', key: KEYS.POLEID, isRequired: true },
    { label: 'LONGITUDE', key: KEYS.LONGITUDE, isRequired: true },
    { label: 'LATITUDE', key: KEYS.LATITUDE, isRequired: true },
];

export const initialFilters = {
    /*
    deviceId: null,
    priority: [PRIORITY.MEDIUM, PRIORITY.HIGH],
    deviceType: [DEVICETYPE.LIGHTCONTROLLER, DEVICETYPE.GATEWAY],
    type: [SITE.REPLACEMENT, SITE.MAINTENANCE],
    location: null,
    dateCreated: null,
    status: [STATUS.CANCELLED, STATUS.REPORTED, STATUS.PENDING],
    selected: null

     */
};

const CURRENT_PAGE = 1;
const LIMIT = Math.min(SITES.length, 10);
const defaultLimits = getLimits(SITES, CURRENT_PAGE, LIMIT);

const END = defaultLimits.END;
const START = defaultLimits.START;

const initialState = {
    data: SITES.slice(0),
    filters: initialFilters,
    sortBy: null,
    allSelected: false,
    columns: COLUMNS,
    currentPage: CURRENT_PAGE,
    showingStart: START,
    showingEnd: END,
    maxPages: defaultLimits.MAXPAGES,
    columnInSort: null,
    message: null,
    limit: LIMIT,
    defaultLimit: LIMIT
};

const TYPES = {
    FILTER: 'FILTER',
    SELECT_ALL: 'SELECT_ALL',
    SELECT: 'SELECT',
    SEARCH_STRING: 'SEARCH_STRING',
    RESET: 'RESET',
    SORT: 'SORT',
    PAGINATE_RIGHT: 'PAGINATE_RIGHT',
    PAGINATE_LEFT: 'PAGINATE_LEFT',
    CHANGE_LIMIT: 'CHANGE_LIMIT',
    NEW_SITE: 'NEW_SITE',
    CLOSE_SELECTED_SITES: 'CLOSE_SELECTED_SITES',
    SHOW_COLUMNS: 'SHOW_COLUMNS',
    FILTER_TYPES_IN_COLUMN: 'FILTER_TYPES_IN_COLUMN',
    DISMISS_NOTIFICATION: 'DISMISS_NOTIFICATION',
    SHOW_NOTIFICATION: 'SHOW_NOTIFICATION',
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REHYDRATE:
            const newState = { ...initialState };
            return newState;
        case TYPES.SELECT_ALL:
            return performMultiSelection(state, action.payload.select);
        case TYPES.SELECT:
            const id = action.payload.id;
            const checked = action.payload.select;
            return performSelection(state, checked, id);
        case TYPES.SEARCH_STRING:
            const searchString = action.payload.searchString;
            const columnId = action.payload.columnId;
            const trimmedString = searchString.trim();
            const verifiedString = trimmedString.length === 0 ? null : trimmedString;
            const filters = { ...state.filters, [columnId]: verifiedString };
            return recomputePages(applyFilters(state, SITES, filters));
        case TYPES.SORT:
            const order = action.payload.order;
            const key = action.payload.key;
            return performSort(state, order, key);
        case TYPES.PAGINATE_RIGHT:
            return paginateRight(state);
        case TYPES.PAGINATE_LEFT:
            return paginateLeft(state);
        case TYPES.CHANGE_LIMIT:
            const changeLimitForData = flow([changeLimit, recomputePages]);
            return changeLimitForData(
                state,
                action.payload.limit,
                action.payload.defaultLimit
            );
        case TYPES.RESET:
            return { ...initialState };
        case TYPES.NEW_SITE:
            const site = action.payload.site;
            return addNewSite(state, site, SITES);
        case TYPES.CLOSE_SELECTED_SITES:
            SITES = SITES.filter(filterDeselectedSites);
            return removeSites(state);
        case TYPES.SHOW_COLUMNS:
            const columnIdentifier = action.payload.identifier;
            const show = action.payload.show;
            return filterColumns(state, columnIdentifier, show, COLUMNS);
        case TYPES.FILTER_TYPES_IN_COLUMN:
            const stateAfterSubTypeFilter = filterSubColumns(state, action.payload);
            return recomputePages(
                applyFilters(
                    stateAfterSubTypeFilter,
                    SITES,
                    stateAfterSubTypeFilter.filters
                )
            );
        case TYPES.SHOW_NOTIFICATION:
            let message = action.payload.message;
            return { ...state, message: message };
        case TYPES.DISMISS_NOTIFICATION:
            return { ...state, message: null };
        default:
            return state;
    }
};

function changeLimit(state, limit, defaultLimit) {
    return {
        ...state,
        limit: limit || state.limit,
        defaultLimit: defaultLimit || state.defaultLimit
    };
}

function recomputePages(state) {
    const curPage = 1;
    const limits = getLimits(state.data, curPage, state.limit);
    const stateWithPagesRecomputed = {
        ...state,
        currentPage: curPage,
        showingEnd: limits.END,
        showingStart: limits.START,
        maxPages: limits.MAXPAGES
    };
    return stateWithPagesRecomputed;
}

function applyFilters(state, initialData, filters) {
    const deviceId = filters.deviceId;
    const priorities = filters.priority;
    const deviceTypes = filters.deviceType;
    const siteTypes = filters.type;
    const selected = filters.selected;
    const location = filters.location;
    const statusTypes = filters.status;
    const dateCreated = filters.dateCreated;

    const sites = arrayToObject(state.data, 'id');

    const filteredData = initialData.filter(site => {
        const deviceFilter =
            deviceId === null
                ? true
                : site.deviceId.toLowerCase().indexOf(deviceId.toLowerCase()) !== -1;
        const locationFilter =
            location === null
                ? true
                : site.location.toLowerCase().indexOf(location.toLowerCase()) !== -1;
        const priorityFilter = priorities.indexOf(site.priority) !== -1;
        const deviceTypeFilter = deviceTypes.indexOf(site.deviceType) !== -1;
        const siteTypeFilter = siteTypes.indexOf(site.type) !== -1;
        const statusTypeFilter = statusTypes.indexOf(site.status) !== -1;
        const selectionFilter =
            selected === null ? true : site.isSelected === selected;
        const dateFilter =
            dateCreated === null
                ? true
                : site.dateCreated.toLowerCase().indexOf(dateCreated.toLowerCase()) !==
                -1;

        const isFiltered =
            deviceFilter &&
            priorityFilter &&
            deviceTypeFilter &&
            siteTypeFilter &&
            statusTypeFilter &&
            selectionFilter &&
            locationFilter &&
            dateFilter;
        return isFiltered;
    });

    const filteredDataWithMergedState = filteredData.map(data => {
        if (sites[data.id]) return sites[data.id];
        return data;
    });

    return { ...state, data: filteredDataWithMergedState, filters: filters };
}

function addNewSite(state, site, initialData) {
    const identifier =
        initialData.reduce((prev, next) => {
            const max = Math.max(prev, next.id);
            return max;
        }, 0) + 1;
    const newSite = { id: identifier, ...site };
    initialData.push(newSite);
    return { ...state, data: [...state.data, newSite] };
}

const filterDeselectedSites = site => {
    return !site.isSelected;
};

function removeSites(state) {
    const deselectedSites = state.data.filter(filterDeselectedSites);
    const extras = { allSelected: false };
    return { ...state, data: deselectedSites, ...extras };
}

function filterColumns(state, columnId, show, columns = []) {
    const filteredColumns = state.columns.map(column => {
        let isRequired = column.key === columnId ? show : column.isRequired;
        return { ...column, isRequired };
    });
    return { ...state, columns: filteredColumns };
}

function filterSubColumns(state, payload) {
    const { columnId, types, show } = payload;
    // E.g., columnId = 'priority', type: 'Medium', show: false
    const filters = state.filters;
    const currentTypeFiltersForCoumn = filters[columnId];
    const newTypeFilter = show
        ? addItemsToArray(currentTypeFiltersForCoumn, types)
        : removeItemsFromArray(currentTypeFiltersForCoumn, types);
    const newFilter = { ...filters, [columnId]: newTypeFilter };
    return { ...state, filters: newFilter };
}

function performMultiSelection(state, select) {
    const shouldBeChecked = select;
    const newData = state.data.map(data => {
        const site = { ...data, isSelected: shouldBeChecked };
        return site;
    });
    return { ...state, data: newData, allSelected: select };
}

export function selectAll(select) {
    return dispatch => {
        dispatch({
            type: CHECK_USER_TIMEOUT
        });
        dispatch({
            type: TYPES.SELECT_ALL,
            payload: {
                select
            }
        });
    };
}

function performSelection(state, select, id) {
    const shouldBeChecked = select;
    const newData = state.data.map(data => {
        if (parseInt(data.id, 10) === parseInt(id, 10)) {
            const site = { ...data, isSelected: shouldBeChecked };
            return site;
        }
        return data;
    });
    return { ...state, data: newData, allSelected: false };
}

export function select(id, select) {
    return dispatch => {
        dispatch({
            type: CHECK_USER_TIMEOUT
        });
        dispatch({
            type: TYPES.SELECT,
            payload: {
                select,
                id
            }
        });
    };
}

export function search(searchString, columnId) {
    return dispatch => {
        dispatch({
            type: CHECK_USER_TIMEOUT
        });
        dispatch({
            type: TYPES.SEARCH_STRING,
            payload: {
                searchString,
                columnId
            }
        });
    };
}

function performSort(state, order, key) {
    const sites = state.data.slice(0);
    const sortState = { columnInSort: key, sortBy: order };
    if (order === 'asc' || order === 'desc') {
        return { ...state, data: orderby(sites, [key], [order]), ...sortState };
    } else {
        return { ...state, data: orderby(sites, ['id'], ['asc']), ...sortState };
    }
}

function paginateRight(state) {
    const currentPage = state.currentPage;
    const numItems = state.data.length;
    const adjustedLimit = Math.min(numItems, state.limit);
    const maxPages = Math.ceil(numItems / adjustedLimit);
    const nextPage = Math.min(currentPage + 1, maxPages);

    const limits = getLimits(state.data, nextPage, state.limit);
    if (currentPage === limits.MAXPAGES) {
        return state;
    }

    return {
        ...state,
        currentPage: nextPage,
        showingEnd: limits.END,
        showingStart: limits.START,
        maxPages: limits.MAXPAGES
    };
}

function paginateLeft(state) {
    const currentPage = state.currentPage;
    if (currentPage === 1) {
        return state;
    }
    const nextPage = Math.max(currentPage - 1, 1);
    const limits = getLimits(state.data, nextPage, state.limit);

    return {
        ...state,
        currentPage: nextPage,
        showingEnd: limits.END,
        showingStart: limits.START,
        maxPages: limits.MAXPAGES
    };
}

export function changeDataLimit(limit, defaultLimit) {
    return dispatch => {
        dispatch({
            type: CHECK_USER_TIMEOUT
        });
        dispatch({
            type: TYPES.CHANGE_LIMIT,
            payload: {
                limit,
                defaultLimit
            }
        });
    };
}

export function paginateNext() {
    return dispatch => {
        dispatch({
            type: CHECK_USER_TIMEOUT
        });
        dispatch({
            type: TYPES.PAGINATE_RIGHT
        });
    };
}

export function paginatePrev() {
    return dispatch => {
        dispatch({
            type: CHECK_USER_TIMEOUT
        });
        dispatch({
            type: TYPES.PAGINATE_LEFT
        });
    };
}

export function sort(key, order) {
    return dispatch => {
        dispatch({
            type: CHECK_USER_TIMEOUT
        });
        dispatch({
            type: TYPES.SORT,
            payload: {
                key,
                order
            }
        });
    };
}

export function reset() {
    return dispatch => {
        dispatch({
            type: CHECK_USER_TIMEOUT
        });
        dispatch({
            type: TYPES.RESET
        });
    };
}

// export function createSite(site) {
//   return dispatch => {
//     dispatch({
//       type: CHECK_USER_TIMEOUT
//     });
//     dispatch({
//       type: TYPES.NEW_SITE,
//       payload: {
//         site
//       }
//     });
//   };
// }

export function closeSelectedSites() {
    return dispatch => {
        dispatch({
            type: CHECK_USER_TIMEOUT
        });
        dispatch({
            type: TYPES.CLOSE_SELECTED_SITES
        });
    };
}

export function showColumns(identifier, show) {
    return dispatch => {
        dispatch({
            type: CHECK_USER_TIMEOUT
        });
        dispatch({
            type: TYPES.SHOW_COLUMNS,
            payload: {
                identifier,
                show
            }
        });
    };
}

export function filterSubColumnTypes(columnId, types, show) {
    return dispatch => {
        dispatch({
            type: CHECK_USER_TIMEOUT
        });
        dispatch({
            type: TYPES.FILTER_TYPES_IN_COLUMN,
            payload: {
                columnId,
                types,
                show
            }
        });
    };
}

export function showNotification(message) {
    return dispatch => {
        dispatch({
            type: CHECK_USER_TIMEOUT
        });
        dispatch({
            type: TYPES.SHOW_NOTIFICATION,
            payload: {
                message
            }
        });
    };
}

export function dimissNotification() {
    return dispatch => {
        dispatch({
            type: CHECK_USER_TIMEOUT
        });
        dispatch({
            type: TYPES.DISMISS_NOTIFICATION
        });
    };
}

//for getting the list of sites from database
export async function getSitesListFromDb(){
    let data = await getSitesList(API_ROOT + 'sites');
    return data;
};

export async function getSiteListValuesFromDb(){
    let data = await getSiteListValues(API_ROOT + 'list_values');
    return data;
};

export async function getMacSuggestionListFromDb(mac_address){
    let data = await getMacSuggestionList(API_ROOT + 'devices/fybrlyte_macs?filter=' + mac_address);
    return data;
};


//for deleting the group details from database
export async function deleteSiteFromDb(siteId) {
    let data = await deleteSiteData(API_ROOT + 'sites/' + siteId);
    return data;
};

//for creating new site in database
export async function createSiteInDb(siteToAdd) {
    let data = await createSite(API_ROOT + 'sites' , siteToAdd);
    return data;
};

//for allocating a device with site
export async function allocateDeviceInDb(siteToUpdate) {
    let data = await allocateDevice(API_ROOT + 'devices/' + siteToUpdate.id, siteToUpdate);
    return data;
};

//for allocating a device with site
export async function getDeviceCoordinatesInDb(deviceId) {
    let data = await getCoordinates(API_ROOT + 'devices/' + deviceId);
    return data;
};

//for allocating a device with site
export async function allocateSiteInDb(siteToAdd) {
    let data = await allocateSite(API_ROOT + 'sites/' + siteToAdd.id, siteToAdd);
    return data;
};

export async function updateDeviceCoordinatesInDb(deviceToUpdate) {
    let data = await updateDeviceCoordinates(API_ROOT + 'device/' + deviceToUpdate.id, deviceToUpdate);
    return data;
}

//for allocating a device with site
export async function getSiteCoordinatesInDb(siteId) {
    let data = await getCoordinates(API_ROOT + 'sites/' + siteId);
    return data;
};

//for getting site details from database using id
export async function getSiteDetailsFromDb(siteId) {
    let data = await getSiteDetails(API_ROOT + 'sites/' + siteId);
    return data;
};

//for updating site in database
export async function updateSiteInDb(siteToUpdate) {
    let data = await updateSite(API_ROOT + 'sites/' + siteToUpdate.id , siteToUpdate);
    return data;
};

export async function saveSiteInDb(siteToUpdate) {
    let data = await createSite(API_ROOT + 'sites/' , siteToUpdate);
    return data;
};

export async function updateSiteCoordinatesInDb(siteToUpdate) {
    let data = await updateSiteCoordinates(API_ROOT + 'sites/allocate_device', siteToUpdate);
    return data;
}



