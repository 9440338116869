import { getLightsForDistricts, getLightsForBlocks } from '../modules/map.js';
import { STATUS } from '../modules/issues';
import { ON } from '../components/constants/map.js';

export function getBrightnessRangeForDistricts(selectedDistricts, props) {
  let districtIds = selectedDistricts.map(district => district.id);
  let lights = Object.keys(props.map.lights).map(id => props.map.lights[id]);
  let blocks = Object.keys(props.map.blocks).map(id => props.map.blocks[id]);
  let districts = Object.keys(props.map.districts).map(
    id => props.map.districts[id]
  );

  let lightsForDistricts = getLightsForDistricts(
    districtIds,
    lights,
    blocks,
    districts
  );
  if (lightsForDistricts.length > 0) {
    let min = Math.min.apply(Math, lightsForDistricts.map(e => e.brightness));
    let max = Math.max.apply(Math, lightsForDistricts.map(e => e.brightness));
    return [min, max];
  }
  return [0, 0];
}

export function getBrightnessRangeForBlocks(selectedBlocks, props) {
  let blockIds = selectedBlocks.map(block => block.id);
  let lights = Object.keys(props.map.lights).map(id => props.map.lights[id]);

  let lightsForBlocks = getLightsForBlocks(blockIds, lights);
  if (lightsForBlocks.length > 0) {
    let min = Math.min.apply(Math, lightsForBlocks.map(e => e.brightness));
    let max = Math.max.apply(Math, lightsForBlocks.map(e => e.brightness));
    return [min, max];
  }
  return [0, 0];
}

export function getBrightnessRangeForLights(selectedLights) {
  // if (selectedLights.length > 0) {
  //   let min = Math.min.apply(Math, selectedLights.map(e => e.brightness));
  //   let max = Math.max.apply(Math, selectedLights.map(e => e.brightness));
  //   return [min, max];
  // }
  return [0, 0];
}

export function getLampSwitchStateForDistricts(selectedDistricts, props) {
  let districtIds = selectedDistricts.map(district => district.id);
  let lights = Object.keys(props.map.lights).map(id => props.map.lights[id]);
  let blocks = Object.keys(props.map.blocks).map(id => props.map.blocks[id]);
  let districts = Object.keys(props.map.districts).map(
    id => props.map.districts[id]
  );

  let lightsForDistricts = getLightsForDistricts(
    districtIds,
    lights,
    blocks,
    districts
  );
  let numOfLights = lightsForDistricts.length;

  let numLightsTurnedOn = lightsForDistricts.filter(light => {
    return light.lampStatus === ON;
  }).length;

  if (numLightsTurnedOn > 0) {
    if (numOfLights === numLightsTurnedOn) {
      return 1;
    } else {
      return 2;
    }
  }
  return 0;
}

export function getLampSwitchStateForBlocks(selectedBlocks, props) {
  let blockIds = selectedBlocks.map(block => block.id);
  let lights = Object.keys(props.map.lights).map(id => props.map.lights[id]);

  let lightsForBlocks = getLightsForBlocks(blockIds, lights);
  let numOfLights = lightsForBlocks.length;

  let numLightsTurnedOn = lightsForBlocks.filter(light => {
    return light.lampStatus === ON;
  }).length;

  if (numLightsTurnedOn > 0) {
    if (numOfLights === numLightsTurnedOn) {
      return 1;
    } else {
      return 2;
    }
  }
  return 0;
}

export function getLampSwitchStateForLights(lights) {
  let numOfLights = lights.length;
   let numLightsTurnedOn = lights.filter(light => {
     return light.lampStatus === ON;
   }).length;

   if (numLightsTurnedOn > 0) {
     if (numOfLights === numLightsTurnedOn) {
       return 1;
     } else {
       return 2;
     }
   }
  return 0;
}

export function getTotalLightsInDistricts(selectedDistricts, props) {
  let districtIds = selectedDistricts.map(district => district.id);
  let lights = Object.keys(props.map.lights).map(id => props.map.lights[id]);
  let blocks = Object.keys(props.map.blocks).map(id => props.map.blocks[id]);
  let districts = Object.keys(props.map.districts).map(
    id => props.map.districts[id]
  );

  let lightsForDistricts = getLightsForDistricts(
    districtIds,
    lights,
    blocks,
    districts
  );
  return lightsForDistricts.length;
}

export function getTotalLightsInBlocks(selectedBlocks, props) {
  let blockIds = selectedBlocks.map(block => block.id);
  let lights = Object.keys(props.map.lights).map(id => props.map.lights[id]);

  let lightsForBlocks = getLightsForBlocks(blockIds, lights);
  return lightsForBlocks.length;
}

export function getBrightnessBucketsForDistricts(selectedDistricts, props) {
  let districtIds = selectedDistricts.map(district => district.id);
  let lights = Object.keys(props.map.lights).map(id => props.map.lights[id]);
  let blocks = Object.keys(props.map.blocks).map(id => props.map.blocks[id]);
  let districts = Object.keys(props.map.districts).map(
    id => props.map.districts[id]
  );

  let lightsForDistricts = getLightsForDistricts(
    districtIds,
    lights,
    blocks,
    districts
  );
  let groups = lightsForDistricts.reduce((acc, light) => {
    if (!acc[light.brightness]) {
      acc[light.brightness] = 1;
    } else {
      acc[light.brightness]++;
    }
    return acc;
  }, {});
  return groups;
}

export function getBrightnessBucketsForBlocks(selectedBlocks, props) {
  let blockIds = selectedBlocks.map(block => block.id);
  let lights = Object.keys(props.map.lights).map(id => props.map.lights[id]);

  let lightsForBlocks = getLightsForBlocks(blockIds, lights);
  let groups = lightsForBlocks.reduce((acc, light) => {
    if (!acc[light.brightness]) {
      acc[light.brightness] = 1;
    } else {
      acc[light.brightness]++;
    }
    return acc;
  }, {});
  return groups;
}

export const arrayToObject = (array, keyField) =>
  array.reduce((obj, item) => {
    obj[item[keyField]] = item;
    return obj;
  }, {});

export function getColorForStatus(status) {
  switch (status) {
    case STATUS.CANCELLED:
      return '#ff5630';
    case STATUS.REPORTED:
      return '#ff8f72';
    case STATUS.PENDINNG:
      return '#f0b800';
    default:
      return '#0265ff';
  }
}

export function getLimits(items, currentPage, limit) {
  let numOfItems = items.length;
  let adjustedLimit = Math.min(numOfItems, limit);
  let end = currentPage * adjustedLimit;
  let start = end - adjustedLimit + 1;
  let maxPages = Math.ceil(numOfItems / adjustedLimit);
  return {
    LIMIT: adjustedLimit,
    START: Math.min(start, numOfItems),
    END: Math.min(end, numOfItems),
    MAXPAGES: maxPages
  };
}

export const addItemsToArray = (array, items) => {
  return items.reduce((acc, item) => {
    const itemExists = acc.indexOf(item) !== -1;
    return itemExists ? acc : [...acc, item];
  }, array);
};

const isItemPresentIn = (item, items) => {
  return (
    items.filter(obj => {
      return obj === item;
    }).length > 0
  );
};

export const removeItemsFromArray = (array, items) => {
  return array.filter(obj => {
    return !isItemPresentIn(obj, items);
  });
};

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const removeUnderscoreCapitalize = (str) => {
  return str.split('_').join(' ');
}
