export function get2xBgClusteredStyle(id, sourceId, minZoom, maxZoom) {
  return {
    id: id,
    type: 'circle',
    source: sourceId,
    paint: {
        'circle-color': [
            'step',
            ['get', 'point_count'],
            '#8E8E8E',
            100,
            '#8E8E8E',
            750,
            '#8E8E8E'
        ],
        'circle-radius': [
            'step',
            ['get', 'point_count'],
            10,
            100,
            20,
            750,
            30
        ],
        'circle-opacity': 0.6
    },
    minzoom: minZoom,
    maxzoom: maxZoom,
      filter: ['all', ['==', 'selected', false],
          ['in', 'mode', 0, 3],
          ['has', 'point_count']]
  };
}

export function get2xTurnedOnBgClusteredStyle(id, sourceId, minZoom, maxZoom) {
  return {
    id: id,
    type: 'circle',
    source: sourceId,
    paint: {
        'circle-color': [
            'step',
            ['get', 'point_count'],
            '#ffc402',
            100,
            '#ffc402',
            750,
            '#ffc402'
        ],
        'circle-radius': [
            'step',
            ['get', 'point_count'],
            10,
            100,
            20,
            750,
            30
        ],
        'circle-opacity': 0.4
    },
    minzoom: minZoom,
    maxzoom: maxZoom,
    filter: ['all', ['!=', 'selected', true],
                    ['any', ['==', 'mode', 1],
                    ['==', 'mode', 2]],
                    ['has', 'point_count']]
  };
}

/*
export function get2xTurnedOnBgTwoClusteredStyle(id, sourceId, minZoom, maxZoom) {
  return {
    id: id,
    type: 'circle',
    source: sourceId,
    paint: {
      'circle-color': '#ffc402',
      'circle-radius': 10,
      'circle-opacity': 0.4
    },
    minzoom: minZoom,
    maxzoom: maxZoom,
    filter: ['==', 'mode', 2]
  };
}
 */

export function get2xFgCountStyleSite(id, sourceId, minZoom, maxZoom) {
    return {
        id: id,
        type: 'symbol',
        source: sourceId,
        layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 12
        },
        minzoom: minZoom,
        maxzoom: maxZoom,
        filter: ['all', ['!=', 'selected', true],
            ['!in', 'mode', 0, 1, 2, 3],
            ['has', 'point_count']]
    }
}

export function get2xBgClusteredStyleSite(id, sourceId, minZoom, maxZoom) {
    return {
        id: id,
        type: 'circle',
        source: sourceId,
        paint: {
            'circle-color': [
                'step',
                ['get', 'point_count'],
                '#2584ff',
                100,
                '#2584ff',
                750,
                '#2584ff'
            ],
            'circle-radius': [
                'step',
                ['get', 'point_count'],
                10,
                100,
                20,
                750,
                30
            ],
            'circle-opacity': 0.6
        },
        minzoom: minZoom,
        maxzoom: maxZoom,
        filter: ['all', ['!=', 'selected', true],
            ['!in', 'mode', 0, 1, 2, 3],
            ['has', 'point_count']]
    };
}

export function get2xSelectedOutlineSiteClusteredStyle(id, sourceId, minZoom, maxZoom) {
    return {
        id: id,
        type: 'circle',
        source: sourceId,
        paint: {
            'circle-color': [
                'step',
                ['get', 'point_count'],
                '#0151cb',
                100,
                '#0151cb',
                750,
                '#0151cb'
            ],
            'circle-radius': [
                'step',
                ['get', 'point_count'],
                14,
                100,
                24,
                750,
                34
            ],
            'circle-opacity': 1
        },
        minzoom: minZoom,
        maxzoom: maxZoom,
        filter: ['all', ['==', 'selected', true],
                        ['!in', 'mode', 0, 1, 2, 3],
                        ['has', 'point_count']]
    };
}

export function get2xSelectedBgSiteClusteredStyle(id, sourceId, minZoom, maxZoom) {
    return {
        id: id,
        type: 'circle',
        source: sourceId,
        paint: {
            'circle-color': [
                'step',
                ['get', 'point_count'],
                '#2584ff',
                100,
                '#2584ff',
                750,
                '#2584ff'
            ],
            'circle-radius': [
                'step',
                ['get', 'point_count'],
                10,
                100,
                20,
                750,
                30
            ],
            'circle-opacity': 1
        },
        minzoom: minZoom,
        maxzoom: maxZoom,
        filter: ['all', ['==', 'selected', true],
            ['!in', 'mode', 0, 1, 2, 3],
            ['has', 'point_count']]
    };
}

export function get2xSelectedOutlineClusteredStyle(id, sourceId, minZoom, maxZoom) {
  return {
    id: id,
    type: 'circle',
    source: sourceId,
    paint: {
        'circle-color': [
            'step',
            ['get', 'point_count'],
            '#ff8a00',
            100,
            '#ff8a00',
            750,
            '#ff8a00'
        ],
        'circle-radius': [
            'step',
            ['get', 'point_count'],
            14,
            100,
            24,
            750,
            34
        ]
    },
    minzoom: minZoom,
    maxzoom: maxZoom,
      filter: ['all', ['==', 'selected', true],
          ['in', 'mode', 1, 2],
          ['has', 'point_count']]
  };
}

export function get2xSelectedBgClusteredStyle(id, sourceId, minZoom, maxZoom) {
  return {
    id: id,
    type: 'circle',
    source: sourceId,
    paint: {
        'circle-color': [
            'step',
            ['get', 'point_count'],
            '#ffc402',
            100,
            '#ffc402',
            750,
            '#ffc402'
        ],
        'circle-radius': [
            'step',
            ['get', 'point_count'],
            10,
            100,
            20,
            750,
            30
        ]
    },
    minzoom: minZoom,
    maxzoom: maxZoom,
      filter: ['all', ['==', 'selected', true],
          ['in', 'mode', 1, 2],
          ['has', 'point_count']]
  };
}

export function get2xSelectedOutlineOffClusteredStyle(id, sourceId, minZoom, maxZoom) {
    return {
        id: id,
        type: 'circle',
        source: sourceId,
        paint: {
            'circle-color': [
                'step',
                ['get', 'point_count'],
                '#4A4A4A',
                100,
                '#4A4A4A',
                750,
                '#4A4A4A'
            ],
            'circle-radius': [
                'step',
                ['get', 'point_count'],
                14,
                100,
                24,
                750,
                34
            ],
            'circle-opacity': 1
        },
        minzoom: minZoom,
        maxzoom: maxZoom,
        filter: ['all', ['==', 'selected', true],
            ['in', 'mode', 0, 3],
            ['has', 'point_count']]
    };
}

export function get2xSelectedBgOffClusteredStyle(id, sourceId, minZoom, maxZoom) {
    return {
        id: id,
        type: 'circle',
        source: sourceId,
        paint: {
            'circle-color': [
                'step',
                ['get', 'point_count'],
                '#8E8E8E',
                100,
                '#8E8E8E',
                750,
                '#8E8E8E'
            ],
            'circle-radius': [
                'step',
                ['get', 'point_count'],
                10,
                100,
                20,
                750,
                30
            ],
            'circle-opacity': 1
        },
        minzoom: minZoom,
        maxzoom: maxZoom,
        filter: ['all', ['==', 'selected', true],
            ['in', 'mode', 0, 3],
            ['has', 'point_count']]
    };
}
