import React, { Component } from 'react';
import { SplitButton } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import Messages from '../constants/messages.js';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import NewSite from "../site/new-site";
import Autosuggest from "react-autosuggest";
import Allocate from "./allocate";
import {allocateDeviceInDb, allocateSiteInDb, updateSiteCoordinatesInDb} from "../../modules/site";
import {toast} from "react-toastify";
import Confirmation from "../confirmation/confirmation";
import LampControls from "../lamp-controls";
import AssignSchedule from "../scheduling/assign-schedule";

export default class SiteTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSiteId : '',
      selectedDeviceId : '',
      data: this.props.data,
      beforeFilterData: this.props.data,
      openDeleteModal: false,
      showLoader: false,
      showNewSiteModal : false,
      showSiteCloseConfirmation : false,
      selectedSites:[],
      selectedName: '',
      editSiteModal: false,
      allocateDeviceModal: false,
      setScheduleModal: false,
      value: '',
      showCloseConfirmation: false,
      controlsModal: false
    };
  }

  cellButton = (cell, row) => {
    return (
        <div>
          <SplitButton className="remove-outline" drop="down" title="ACTIONS" style={{float: 'left'}}>
            <div className="actions-ddl" style={{height: 'auto'}}>
              <div title="Edit" className="action-items" onClick={() => this.onEditClicked(cell, row)}><i className="fa fa-edit action-items" aria-hidden="true"></i> Edit </div>
              <div title="Allocate Device" className="action-items" onClick={() => this.onAllocateClicked(cell, row)}><i className="fa fa-plus-circle action-items" aria-hidden="true"></i> Allocate Device </div>
              <div title="Apply Device Coordinates" className="action-items" onClick={() => this.onAssignCoordinatesClicked(cell, row)}><i className="fa fa-plus-circle action-items" aria-hidden="true"></i> Apply Device Coordinates </div>
            </div>
          </SplitButton>&nbsp;
            <button onClick={() => this.onControlsClicked(cell, row)} style={{width: '100px', height: '37px', textAlign: 'center', color: '#fff', backgroundColor: '#0151cb',
                borderColor: '#0151cb'}}>CONTROLS</button>
        </div>
    );
  };

  cellSingleButton = (cell, row) => {
    return (
        <div>
          {row.device_id && (
              <button onClick={() => this.onControlsClicked(cell, row)}>CONTROLS</button>
          )}
        </div>
    );
  };

  onAddClicked = (cell, row) => {
    let selectedSite = 0;
    this.setState({ editSiteModal: true, selectedSiteId: selectedSite});
  }

  onControlsClicked = (cell, row) => {
    let selectedDevice = row.device_id;
    let selectedSite = row.site_id;
    this.setState({ controlsModal: true, selectedSiteId: selectedSite, selectedDeviceId: selectedDevice});
  }

  onEditClicked = (cell, row) => {
    let selectedSite = row.site_id;
    this.setState({ editSiteModal: true, selectedSiteId: selectedSite});
  }

  onAssignCoordinatesClicked = (cell, row) => {
    let selectedSite = row.site_id;
    let selectedDevice = row.device_id;
    this.setState({ selectedSiteId: selectedSite, selectedDeviceId: selectedDevice});
  }

  onAllocateClicked = (cell, row) => {
    let selectedSite = row.site_id;
    let selectedDevice = row.device_id;
    this.setState({ allocateDeviceModal: true, selectedSiteId: selectedSite, selectedDeviceId: selectedDevice});
  }

  enumFormatter(cell, row, enumObject) {
    return enumObject[cell];
  }

  renderGroupColor(cell, row) {
    return (
      <div style={{ backgroundColor: cell, width: '20px', height: '20px' }}></div>
    );
  }

  onCancelNewSite = () => {
    this.setState({ editSiteModal: false });
  }

  closeCreateSiteModal = () => {
    const {isDirty, isFormDirty} = this.props;
    this.setState({ showCloseConfirmation: isDirty });

    if (!isDirty)
      this.setState({editSiteModal: false});
  }

  closeCloseConfirmation = () => {
    this.setState({ showCloseConfirmation: false });
  }

  closeControlsModal = () => {
    this.setState({controlsModal: false});
  }

  closeScheduleModal = () => {
    this.setState({ openScheduleModal: false })
  }

  onCloseConfirm = () => {
    const {isFormDirty} = this.props;
    this.setState({ showCloseConfirmation: false , editSiteModal: false, allocateDeviceModal: false}, () => {
      // window.location.reload(false);
    });

    isFormDirty(false);
  }

  onCancelAllocateDevice = () => {
    const {isDirty, isFormDirty} = this.props;
    this.setState({ showCloseConfirmation: isDirty });

    if (!isDirty)
      this.setState({allocateDeviceModal: false});
  }

  onUpdate=()=>{
    const {isFormDirty} = this.props;
    this.props.onUpdate();
    this.setState({editSiteModal:false});
    isFormDirty(false);
  }

  getSuggestions = async value => {
    let {siteSuggestion} = this.props;
    let suggestionList = [];
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (inputLength > 4) {
      let suggestions = await siteSuggestion(inputValue);
      if (suggestions) {
        suggestionList = suggestions.map((item) => ({
          name: item.mac_address,
          value: item.device_id
        }));
      } else {
        suggestionList = [];
      }
    } else {
      suggestionList = [];
    }

    return suggestionList;
  };

  pairDevice = async (deviceId, allocateDevice) => {
    let {isFormDirty} = this.props;
    let allocation = {
      site_id: this.state.selectedSiteId,
      device_id: deviceId,
      allocateDevice
    };

    updateSiteCoordinatesInDb(allocation).then((response) => {
      if (response) {
        toast.success(Messages.DeviceAllocationSuccessText);
        setTimeout(() => {
          this.setState({showLoader: false});
          this.onCancelAllocateDevice();
          isFormDirty(false);
        }, 2000);
      }
    });
  }

  render() {
    const { data, value } = this.props;
    const { columns /*onNewSite*/ } = this.props;
    const { poleTypes, armLength, conditions, lampTypes, powerFeedTypes, fixtureModels, fixtureTypes, lightingAreas } = this.props;
    const {isDirty, isFormDirty} = this.props;

    //for column filtering
    let showSiteId = "column-hide", showSiteName = "column-hide", showAddress = "column-hide", showPoleType = "column-hide",
        showFixtureType = "column-hide", showLampType = "column-hide", showPowerFeed = "column-hide", showPoleId = "column-hide",
        showLongitude = "column-hide", showLatitude = "column-hide";
    for (let i = 0; i < columns.length; i++) {
      let keyVal = columns[i].key;
      switch (keyVal) {
        case "site_id":
          showSiteId = "column-show";
          break;
        case "site_name":
          showSiteName = "column-show";
          break;
        case "address":
          showAddress = "column-show";
          break;
        case "pole_type":
          showPoleType = "column-show";
          break;
        case "fixture_type":
          showFixtureType = "column-show";
          break;
        case "lamp_type":
          showLampType = "column-show";
          break;
        case "power_feed":
          showPowerFeed = "column-show";
          break;
        case "pole_id":
          showPoleId = "column-show";
          break;
        case "longitude":
          showLongitude = "column-show";
          break;
        case "latitude":
          showLatitude = "column-show";
          break;
        default:
          break;
      }
    }

    //for resizing functionality
    const { rowType } = this.props;
    let rowDisplayClass = "site-thin-row-size";
    if (rowType === 'thick') {
      rowDisplayClass = "site-thick-row-size";
    } else if (rowType === 'medium') {
      rowDisplayClass = "site-medium-row-size";
    } else if (rowType === 'thin') {
      rowDisplayClass = "site-thin-row-size";
    }

    const dataColumns = [{
      dataField: 'site_id',
      text: 'SITE ID',
      sort: false,
      classes: showSiteId,
      headerClasses: showSiteId
    }, {
      dataField: 'site_name',
      text: 'SITE NAME',
      sort: true,
      classes: showSiteName,
      headerClasses: showSiteName,
      filter: textFilter()
    }, {
        dataField: 'site_details.pole_id',
        text: 'POLE ID',
        sort: true,
        classes: showPoleId,
        headerClasses: showPoleId
      }, {
      dataField: 'street1_name',
      text: 'ADDRESS',
      sort: true,
      classes: showAddress,
      headerClasses: showAddress
    },
     {
      dataField: 'site_details.pole_type',
      text: 'POLE TYPE',
      sort: true,
      classes: showPoleType,
      headerClasses: showPoleType
    },
     {
      dataField: 'site_details.fixture_type',
      text: 'FIXTURE TYPE',
      sort: true,
      classes: showFixtureType,
      headerClasses: showFixtureType
    },
    {
      dataField: 'site_details.lamp_type',
      text: 'LAMP TYPE',
      sort: true,
      classes: showLampType,
      headerClasses: showLampType
    },
    {
      dataField: 'site_details.power_feed',
      text: 'POWER FEED',
      sort: false,
      classes: showPowerFeed,
      headerClasses: showPowerFeed
    },
    {
      dataField: 'longitude',
      text: 'LONGITUDE',
      sort: false,
      classes: showLongitude,
      headerClasses: showLongitude
    },
    {
      dataField: 'latitude',
      text: 'LATITUDE',
      sort: false,
      classes: showLatitude,
      headerClasses: showLatitude
    }, {
        dataField: 'button',
        text: 'ACTIONS',
        formatter: this.cellButton
      }];

    const defaultSorted = [{
      dataField: 'site_name',
      order: 'asc'
    }];

    let tableClasses = "table-container fixed-table-footer";
    tableClasses = tableClasses.trim();

    return (
      <div className={tableClasses}>
        <BootstrapTable wrapperClasses="table-common table-site-mob" rowClasses={rowDisplayClass} keyField='site_id' data={data} columns={dataColumns} defaultSorted={defaultSorted} pagination={paginationFactory()} filter={filterFactory()}  />
        <div id="ControlsModalDiv">
          <Modal classNames={{ modal: "controls-modal" }} open={this.state.controlsModal} onClose={this.closeControlsModal} center closeOnOverlayClick={false}>
            <div>
              <LampControls id={this.state.selectedDeviceId} siteId={this.state.selectedSiteId} openAssignModal={() => this.setState({openScheduleModal: true})} closeAssignModal={this.closeScheduleModal} useGroup={false}></LampControls>
            </div>
          </Modal>
        </div>
        {
          this.state.showCloseConfirmation &&
          (
              <Confirmation confirmationText={Messages.SiteCloseConfirmation} confirmButtonText="Confirm" rejectButtonText="Cancel" openConfirmation={true} closeConfirmationModal={this.closeCloseConfirmation} onConfirmClicked={this.onCloseConfirm} />
          )
        }
        <div id="editNewSite">
          <Modal classNames={{ modal: "edit-site-modal" }} open={this.state.editSiteModal} onClose={this.closeCreateSiteModal} right closeOnOverlayClick={false}>
            <NewSite
                selectedSiteId={this.state.selectedSiteId}
                deviceList={this.state.deviceList}
                poleTypes={poleTypes}
                conditions={conditions}
                armLength={armLength}
                lampTypes={lampTypes}
                powerFeedTypes={powerFeedTypes}
                fixtureModels={fixtureModels}
                fixtureTypes={fixtureTypes}
                lightingArea={lightingAreas}
                //onNewSite={onNewSite}
                onCancel={this.closeCreateSiteModal}
                onUpdate={this.onUpdate}
                isFormDirty={isFormDirty}
                isDirty={isDirty}
            />
          </Modal>
        </div>
        <div id="allocateDevice">
          <Modal classNames={{ modal: "alllocate-device-modal" }} open={this.state.allocateDeviceModal} onClose={this.onCancelAllocateDevice} right closeOnOverlayClick={false}>
            <Allocate
                allocateDevice={true}
                //onNewSite={onNewSite}
                onCancel={this.onCancelAllocateDevice}
                onUpdate={this.onCancelAllocateDevice}
                getSuggestions={this.getSuggestions}
                submit={this.pairDevice}
                siteId={this.state.selectedSiteId}
                isFormDirty={isFormDirty}
                isDirty={isDirty}
            />
          </Modal>
        </div>
        <div id="assignScheduleModalDiv">
          <Modal classNames={{ modal: "assign-schedule-modal-dialog" }} open={this.state.openScheduleModal} onClose={this.closeScheduleModal} center closeOnOverlayClick={false}>
            <div>
              <AssignSchedule siteId={this.state.selectedSiteId} closeAssignModal={this.closeScheduleModal}/>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}
