import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '../textfield/textfield';
import Button from '../button/button';
import { css } from 'emotion';
import loading from '../../assets/loading.gif';
import Modal from 'react-responsive-modal';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      cookieModal: true,
      isCookieAccepted: false
    };
    this.cookieAccepted = this.cookieAccepted.bind(this);
    this.cookieDeclined = this.cookieDeclined.bind(this);
    
    if(cookies.get("IsCookiesPolicyAccepted")){
      this.state.isCookieAccepted = true;
      this.state.cookieModal = false;
    }

  }

  cookieAccepted() {
    cookies.set('IsCookiesPolicyAccepted',"true");
    this.setState({ isCookieAccepted: true });
    this.setState({ cookieModal: false });
  }

  cookieDeclined() {
    this.setState({ cookieModal: false });
    this.setState({ isCookieAccepted: false });
    setTimeout(() => {
      this.setState({ cookieModal: true });
    }, 5000);
  }

  handleKeyPress = (target) => {     
    if (target.charCode === 13) {
      this.props.onSubmit(
        this.state.username.toLowerCase(),
        this.state.password //removed tolowercase for making the password validation correct.
      );
    }
  }

  render() {
    let disabledLogin = true;
    if (this.state.isCookieAccepted === true) {
      disabledLogin = false;
    }
    let loginJSX = this.props.loading ? (
      <img src={loading} alt="loading" className={loadingClass} />
    ) : (
        <Button
          label="Submit"
          className={buttonStyle}
          btnClassName={supplyButtonStyle}
          buttonClickCallback={() =>
            this.props.onSubmit(
              this.state.username.toLowerCase(),
              this.state.password
            )}
          text="LOGIN"
          disabled={disabledLogin}
        />
      );
    return (
      <div>
        <div className={divStyle}>
          <TextField
            labelType="floating"
            label="Enter Username"
            value=""
            onChange={(event, newValue) =>
              this.setState({ username: newValue })}
          />
          <br />
          <TextField
            type="password"
            labelType="floating"
            label="Enter Password"
            value=""
            onChange={(event, newValue) =>
              this.setState({ password: newValue })}
            onKeyPress={this.handleKeyPress}
          />
          <br />
          {loginJSX}
          <Modal classNames={{ modal: "cookies-acceptance-modal"}} open={this.state.cookieModal} onClose={this.cookieDeclined} center closeOnOverlayClick={false}>
            <div>
              <h3>This site uses cookies</h3>
              <p>This website stores cookies on your computer. These cookies are used to collect information about how you interact with our website and allow us to remember you. We use this information in order to improve and customize your browsing experience and for analytics and metrics about our visitors both on this website and other media. To find out more about the cookies we use, see our Privacy Policy.</p>
              <button className="accept-cookie" onClick={this.cookieAccepted}>Accept</button>
              <button className="reject-cookie" onClick={this.cookieDeclined}>Decline</button>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

const buttonStyle = css({
  margin: 15
});
const supplyButtonStyle = css({
  width: '100%'
});
const divStyle = css({
  textAlign: 'center',
  margin: '0 auto'
});

const loadingClass = css({
  height: '50px',
  width: '50px'
});

Login.propTypes = {
  onSubmit: PropTypes.func,
  loading: PropTypes.bool
};
export default Login;
