import {applyStyles, getSourceLayer} from './2x-features';
import mapboxgl from 'mapbox-gl';
import center from '@turf/center';
import markerIcon from '../../../assets/bulb.svg';
import {getLayerTypeForZoom, LAYER_1X, LAYER_2X, LAYER_2X_ZOOM_LEVEL, LAYERS} from '../../constants/map';
import {getDistrictsList, getSelectedBlocksListByViewPort} from "../../../modules/map";
import polygon from "turf-extent";
import * as MapboxDraw from  '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.js';
import {addLayer, removeLayer} from "../utils";
import {getSource} from "./1x-features";

const defaultOptions = {
  minZoom: LAYER_2X_ZOOM_LEVEL.START,
  maxZoom: LAYER_2X_ZOOM_LEVEL.END
};

export default class TwoXLayer {

  constructor(map, pointFeatures = [], props, options = defaultOptions) {
    this.map = map;
    this.options = options;
    this.markers = [];
    this.map.on('zoomend', () => this.onZoomChange());
    this.map.on('dragend', () => this.onDrag());
    //this.map.on('click', e => this.onFeatureClicked(e));
    this.markersAdded = false;
    this.selections = new Set();
    this.props = props;
    this.pointFeatures = pointFeatures;
    this.addThirdLevelMarkers = null;
    this.addFirstLevelMarkers = null;
  }

  _addToSelections = (id) => {
    if(this.selections.has(id)) {
      this.selections.delete(id);
    } else {
      this.selections.add(id);
    }
    this.props.selectedBlocks([...this.selections]);
  }

  _getFeaturesWithSelectionData = () => {
    return this.features.map((feature) => {
      const featureWithSelectionData = Object.assign({}, feature);
      const blockId = feature.properties.id;
      featureWithSelectionData.properties.selected = this.selections.has(blockId);
      return featureWithSelectionData;
    });
  }

  updateSelections = (blocks) => {
    const selectedBlocks = blocks.map(block => {
      return block.id;
    });
    this.selections = new Set(selectedBlocks);

    //const filter = ['in', 'id', ...this.selections.values()];
    //this.map.setFilter('2x-selected-bg-style', filter);
  }

  updateFeatureData = async (props = this.props) => {
    let {selectedBlocks} = this.props;
    let bbox = this.map.getBounds();

    let blockPointFeaturesNew = await getSelectedBlocksListByViewPort(bbox);
    if (blockPointFeaturesNew && blockPointFeaturesNew.features && blockPointFeaturesNew.features.length > 0) {

      let coordinates = blockPointFeaturesNew.features.filter((item) => item.geometry.coordinates[0]);
      let centerPoint = center(coordinates[0]);
      blockPointFeaturesNew.features = coordinates;

      this.pointFeatures = blockPointFeaturesNew;
      this.initLayer();
      //if button click, marker, then pass center.  Do not pass if using gestures
      //zoom in/zoom out. Take the center of the first feature that came back
      //this.map.flyTo({ zoom: props.map.zoomLevel, center: centerPoint.geometry.coordinates });
      //this.map.flyTo({ zoom: props.map.zoomLevel });
    }
  }

  static calculateCenterPoint = async (bbox) => {
    let centerPoint = null;

    let blockPointFeaturesNew = await getSelectedBlocksListByViewPort(bbox);
    if (blockPointFeaturesNew && blockPointFeaturesNew.features && blockPointFeaturesNew.features.length > 0) {

      let coordinates = blockPointFeaturesNew.features.filter((item) => item.geometry.coordinates[0]);
      centerPoint = center(coordinates[0]);
    }

    return centerPoint;
  }

  onFeatureClicked = (e) => {
    const features = this.map.queryRenderedFeatures(e.point, {
      layers: ['2x-bg-style', '2x-selected-bg-style']
    });
    if (features && features.length > 0) {
      const feature = features[0];
      const layerId = feature.layer.id;
      const layerIds = layerId.split('-');
      if (layerIds && layerIds.length > 0) {
        if (layerIds[0] === '2x') {
          // 1x feature clicked
          const blockId = feature.properties.id;
          this._addToSelections(blockId);
        }
      }
    }
  }

  initLayer = () => {

    let source = getSource(this.pointFeatures.features);
    let sourceLayer = getSourceLayer(source, 'blocks', this.options);
    addLayer(sourceLayer, this.map);

    this.markers = this.constructMarkers(this.pointFeatures.features);
    this.addMarkers();
  }

  constructMarkers = (features = []) => {
    return features.map((feature) => {
      const centerPoint = center(feature);
      let el = document.createElement('div');
      el.setAttribute('data-block_id', feature.properties.id);
      el.setAttribute('data-marker_center', centerPoint.geometry.coordinates);
      el.className = 'block_markers';

      const numLights = feature.properties.site_count || 0;
      const text = `${numLights}`;
      const name = feature.properties.name;
      el.innerHTML =
          `<div class='marker' alt="${name}">
        <img src=${markerIcon} alt="-"/>
        <span style="font-size: 12px; width: 100%">${name.length > 10 ? name.substring(0, 10) + '...' : name} (${text})</span>
      </div>`;

      el.addEventListener('click', async e => {
        e.preventDefault();
        e.stopPropagation();

        let coordsSplit = e.currentTarget.dataset.marker_center.split(',');
        let coords = [Number.parseFloat(coordsSplit[0]), Number.parseFloat(coordsSplit[1])];
        this.props.zoom('IN', coords);
        this._addToSelections(e.currentTarget.dataset.block_id);
      });

      return new mapboxgl.Marker(el)
          .setLngLat(centerPoint.geometry.coordinates);
    });
  }

  onDrag = async () => {
    const zoom = this.map.getZoom();
    if(zoom >= this.options.minZoom && zoom <= this.options.maxZoom) {
      this.clearMarkers();
      this.addMarkers();

      if (this.addThirdLevelMarkers)
        this.addThirdLevelMarkers();

      if (this.addFirstLevelMarkers)
        this.addFirstLevelMarkers();

      await this.updateFeatureData();
    }
  }

  onZoomChange = async () => {
    debugger;
    debugger;
    const zoom = this.map.getZoom();
    if(zoom > this.options.maxZoom || zoom < this.options.minZoom) {
      this.hideCurrentMarkersToLayer();
    }

    if(zoom >= this.options.minZoom && zoom <= this.options.maxZoom) {
      await this.displayCurrentMarkersToLayer();
    }
  }

  addMarkers = (markers = this.markers) => {
    markers.forEach(marker => {
      marker.addTo(this.map);
    });
  }

  clearMarkers = (markers = this.markers) => {
    markers.forEach(marker => {
      marker.remove();
    });
  }

  clear() {
    this.clearMarkers();
    //removeLayer('2X_LAYER', this.map);
    this.map.off('zoom', () => this.onZoomChange());
  }

  setProps = (props) => {
    this.props = props;
  }

  setMarkersAdded = (markersAdded) => {
    this.markersAdded = markersAdded;
  }

  setThirdLevelMarkers = (addThirdLevelMarkers) => {
    this.addThirdLevelMarkers = addThirdLevelMarkers;
  }

  setFirstLevelMarkers = (addFirstLevelMarkers) => {
    this.addFirstLevelMarkers = addFirstLevelMarkers;
  }

  addLayerTwoMarkers = async () => {
    await this.updateFeatureData();
    this.addMarkers();
  }

  toggleMarkersHide = (display) => {
    let markers = document.getElementsByClassName("block_markers");
    for (let i = 0; i < markers.length; i++) {
      markers[i].style.visibility = !display ? "hidden" : "visible";
    }
  }

  hideCurrentMarkersToLayer = () => {
    if(this.markersAdded) {
      //this.drawBackground(false);
      //this.clearMarkers();
      this.toggleMarkersHide(false);
      this.markersAdded = false;
    }
  }

  displayCurrentMarkersToLayer = async () => {
    if(!this.markersAdded) {
      //await this.updateFeatureData();
      //this.addMarkers();
      this.toggleMarkersHide(true);
      if (this.addThirdLevelMarkers)
        await this.addThirdLevelMarkers();

      this.markersAdded = true;
      //this.props.updateLayer(true);
    }
  }
}
