import React, { Component } from 'react';
import { css } from 'emotion';
import comment from '../../assets/comment.svg';
import classNames from 'classnames';

class AddComment extends Component {
  state = {
    show: false,
    comment: ''
  };
  handleClickOutside() {
    this.setState({
        show: false
    })
  }

  handleClick = (e) => {
      if(this.node !== null && this.node.contains(e.target)) {
          return;
      }
      this.handleClickOutside();
  }

  componentWillMount() {
      document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClick, false);
  }

  toggle = () => {
    if(this.props.disabled) return;
    this.setState({
      show: !this.state.show
    });
  };
  post = () => {
    this.setState({
      show: false
    });
    this.props.submit(this.state.comment);
  };

  render() {
    let containerClass = this.props.className
      ? this.props.className
      : defaultContainerClass;
    let btnContainerClass = classNames({
      [`${btnContainer}`]: true,
      [`${btnDisabledContainer}`]: this.props.disabled
    });
    return (
      <div className={containerClass} ref={node => this.node = node}>
        <div className={btnContainerClass} onClick={this.toggle}>
          <img src={comment} alt="" className="add-comment-btn-icons-mob" />
          <div className={btnText}>ADD COMMENT</div>
        </div>
        {this.state.show && (
          <div className={boxContainer}>
            <div className={titleClass}>Add Comment</div>
            <textarea
              className={textInput}
              onChange={e => {
                this.setState({ comment: e.target.value });
              }}
            />
            <div className={submitClass} onClick={this.post}>
              Post Comment
            </div>
          </div>
        )}
      </div>
    );
  }
}
const btnContainer = css({
  width: '184px',
  height: '52px',
  borderRadius: '2px',
  backgroundColor: '#ffffff',
  display: 'flex',
  justifyContent: 'space-around',
  border: '1px solid #c4cdd5',
  alignItems: 'center',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#F4F5F7'
  }
});
const btnDisabledContainer = css({
  cursor: 'default!important',
  backgroundColor: '#ebecf0!important',
  '&:hover': {
    backgroundColor: '#ebecf0!important'
  },
  '& div':{
    color: '#c1c7d0!important'
  }
});
const btnText = css({
  fontFamily: 'OpenSans',
  fontSize: '14px',
  fontWeight: 'bold',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.71,
  letterSpacing: '1px',
  textAlign: 'center',
  color: '#5e6c84',
  '&::-moz-selection': {
    background: 'none'
  },
  '&::selection': {
    background: 'none'
  }
});
const boxContainer = css({
  width: '184px',
  height: '188px',
  borderRadius: '4px',
  boxShadow: '0 3px 10px 0 rgba(0, 0, 0, 0.09)',
  backgroundColor: '#ffffff',
  position: 'absolute',
  marginTop: '8px',
  padding: '16px 8px',
  zIndex: 1
});
const titleClass = css({
  fontFamily: 'OpenSans',
  fontSize: '14px',
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  color: '#112138',
  '&::-moz-selection': {
    background: 'none'
  },
  '&::selection': {
    background: 'none'
  }
});
const textInput = css({
  width: '177px',
  height: '124px',
  borderRadius: '2px',
  border: '1.5px solid #0151cb',
  margin: '8px 0px',
  resize: 'none',
  backgroundColor: '#ffffff'
});
const submitClass = css({
  fontFamily: 'OpenSans',
  fontSize: '14px',
  fontWeight: 600,
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  color: '#0151cb',
  textAlign: 'right',
  cursor: 'pointer',
  '&::-moz-selection': {
    background: 'none'
  },
  '&::selection': {
    background: 'none'
  }
});
const defaultContainerClass = css({
  width: '200px',
  padding: '0px 20px 0px 16px'
});
export default AddComment;
