import React, { Component } from 'react';
import { SplitButton } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ShowGroupDetails from '../scheduling/show-group-details';
import Modal from 'react-responsive-modal';
import AssignSchedule from '../scheduling/assign-schedule';
import CreateGroup from '../group/create-group';
import Confirmation from '../confirmation/confirmation.js';
import Messages from '../constants/messages.js';
import { deleteGroupFromDb } from '../../modules/grouping';
import { toast } from 'react-toastify';
import AppLoader from '../Loader/loader';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import LampControls from "../lamp-controls";
import Button from "../button/button";

export default class GroupTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showGroupData: false,
      selectedGroup: [],
      openDeleteModal: false,
      setScheduleModal: false,
      editGroupModal: false,
      controlsModal: false,
      showCloseConfirmation: false,
      selectedGroupId: '',
      showLoader: false,
      openScheduleModal: false,
      selectedGroupName : '',
      selectedSiteId: ''
    };
  }

  cellButton = (cell, row) => {
    return (
      <div>
        <SplitButton className="remove-outline" drop="down" title="ACTIONS" style={{float: 'left'}}>
          <div className="actions-ddl">
            <div title="Edit" className="action-items" onClick={() => this.onEditClicked(cell, row)}><i className="fa fa-edit action-items" aria-hidden="true"></i> Edit </div>
            <div title="Assign Schedule" className="action-items" onClick={() => this.onSetScheduleClicked(cell, row)}><i className="fa fa-calendar-check-o action-items" aria-hidden="true"></i> Assign schedule </div>
            <div title="Delete" className="action-items" onClick={() => this.onDeleteClicked(cell, row)}><i className="fa fa-trash action-items" aria-hidden="true"></i> Delete </div>
          </div>
        </SplitButton>&nbsp;
        <button onClick={() => this.onControlsClicked(cell, row)} style={{width: '100px', height: '37px', textAlign: 'center', color: '#fff', backgroundColor: '#0151cb',
            borderColor: '#0151cb'}}>CONTROLS</button>
      </div>
    );
  };

  cellSingleButton = (cell, row) => {
    return (
        <div>
          <button onClick={() => this.onControlsClicked(cell, row)}>CONTROLS</button>
        </div>
    );
  };

  onEditClicked = (cell, row) => {
    let selectedGroup = row.group_id;
    this.setState({ editGroupModal: true, selectedGroupId: selectedGroup});
  }

  onControlsClicked = (cell, row) => {
    let selectedGroup = row.group_id;
    this.setState({ controlsModal: true, selectedGroupId: selectedGroup});
  }

  onDeleteClicked(cell, row) {
    let selectedGroup = row.group_id;
    this.setState({ openDeleteModal: !this.state.openDeleteModal, selectedGroupId: selectedGroup });
  }

  onSetScheduleClicked = (cell, row) => {
    let selectedGroup = row.group_id;
    let selectedGroupName = row.group_name;
    this.setState({ openScheduleModal: true , selectedGroupId: selectedGroup, selectedGroupName : selectedGroupName});
  }

  closeScheduleModal = () => {
    this.setState({ openScheduleModal: false })
  }

  enumFormatter(cell, row, enumObject) {
    return enumObject[cell];
  }

  renderGroupName(cell, row) {
    return (
      <button title={cell} className="group-name-link">{cell}</button>
    );
  }

  openGroupModal = (e, column, columnIndex, row, rowIndex) => {
    let selectedGroupID = row.group_id;
    this.setState({ showGroupData: true, selectedGroupId: selectedGroupID, selectedGroup: row });
  }

  closeGroupModal = () => {
    this.setState({ showGroupData: false });
  }

  closeDeleteModal = () => {
    this.setState({ openDeleteModal: false });
  }

  closeCloseConfirmation = () => {
    this.setState({ showCloseConfirmation: false });
  }

  closeEditGroupModal = () => {
    const {isDirty, isFormDirty} = this.props;
    this.setState({ showCloseConfirmation: isDirty });


    if (!isDirty)
      this.setState({editGroupModal: false});
  }

  closeControlsModal = () => {
    this.setState({controlsModal: false});
  }

  closeScheduleModal = () => {
    this.setState({ openScheduleModal: false })
  }

  onCloseConfirm = () => {
    const {isFormDirty} = this.props;
    this.setState({ showCloseConfirmation: false, editGroupModal: false },() => {
      this.props.onUpdate();
    });

    isFormDirty(false);
  }

  onDeleteGroup = async () => {
    this.setState({ showLoader: true }, () => {
      this.deleteGroup();
    });
  }

  deleteGroup = async () => {
    let response = await deleteGroupFromDb(this.state.selectedGroupId); //api call for deleting group details from database
    if (response !== undefined && response !== null) {
      this.setState({ openDeleteModal: false }, () => {
        this.props.onUpdate();
        toast.success(Messages.DeleteGroupSuccessText);
        setTimeout(() => {
          this.setState({showLoader:false})
        }, 2000);
      });
    }
  }

  OnUpdate=()=>{
    this.props.onUpdate();
    setTimeout(() => {
      this.setState({editGroupModal:false})
    }, 2000);
  }

  render() {
    const { data } = this.props;
    const { columns } = this.props;
    const { isDirty, isFormDirty } =  this.props;

    //for column filtering
    let showGroupName = "column-hide", showNoOfDevices = "column-hide", showGroupMac = "column-hide", showScheduleName = "column-hide";
    for (let i = 0; i < columns.length; i++) {
      let keyVal = columns[i].key;
      switch (keyVal) {
        case "group_name":
          showGroupName = "column-show";
          break;
        case "device_count":
          showNoOfDevices = "column-show";
          break;
        case "group_mac":
          showGroupMac = "column-show";
          break;
        case "schedule_name":
          showScheduleName = "column-show";
          break;
        default:
          break;
      }
    }

    //for resizing functionality
    const { rowType } = this.props;
    let rowDisplayClass = "group-thin-row-size";
    if (rowType === 'thick') {
      rowDisplayClass = "group-thick-row-size";
    } else if (rowType === 'medium') {
      rowDisplayClass = "group-medium-row-size";
    } else if (rowType === 'thin') {
      rowDisplayClass = "group-thin-row-size";
    }

    const dataColumns = [{
      dataField: 'group_id',
      text: 'ID',
      hidden: true,
      sort: true
    }, {
      dataField: 'group_mac',
      text: 'GROUP MAC',
      sort: true,
      classes: showGroupMac,
      headerClasses: showGroupMac
    }, {
      dataField: 'group_name',
      text: 'GROUP NAME',
      sort: true,
      classes: showGroupName,
      headerClasses: showGroupName,
      formatter: this.renderGroupName,
      events: {
        onClick: this.openGroupModal,
      },
      filter: textFilter()
    }, {
      dataField: 'device_count',
      text: 'NO. OF DEVICES',
      sort: true,
      classes: showNoOfDevices,
      headerClasses: showNoOfDevices
    },{
      dataField: 'schedule_name',
      text: 'ASSIGNED SCHEDULE',
      sort: true,
      classes: showScheduleName,
      headerClasses: showScheduleName
    }, {
      dataField: 'button',
      text: 'ACTIONS',
      formatter: this.cellButton
    }];

    const defaultSorted = [{
      dataField: 'group_name',
      order: 'desc'
    }];

    let tableClasses = "table-container fixed-table-footer";
    tableClasses = tableClasses.trim();
    return (
      <div className={tableClasses}>

        <div id="viewDevicesInsideGroup">
          <Modal classNames={{ modal: "show-group-details-modal-dialog" }} open={this.state.showGroupData} onClose={this.closeGroupModal} right closeOnOverlayClick={false}>
            <div>
               <ShowGroupDetails selectedGroupId={this.state.selectedGroupId} selectedGroupData={this.state.selectedGroup} ></ShowGroupDetails>
            </div>
          </Modal>
        </div>

        <BootstrapTable wrapperClasses="table-common table-group-mob" rowClasses={rowDisplayClass} keyField='group_id' data={data} columns={dataColumns}  pagination={paginationFactory()}  filter={filterFactory()} />
        <div id="deleteModalDiv">
          <Modal classNames={{ modal: "delete-modal-dialog-div" }} open={this.state.openDeleteModal} onClose={this.closeDeleteModal} center closeOnOverlayClick={false}>
            <div>
              <h4>Delete this group ? </h4>
              <p className="delete-p-text">{Messages.DeleteGroupText}</p>
              <div className="delete-buttons-div">
                <button onClick={this.onDeleteGroup} className="delete-confirm-button">DELETE GROUP</button>
                <button onClick={this.closeDeleteModal} className="confirmation-cancel-btn">CANCEL</button>
              </div>
            </div>
          </Modal>
        </div>

        <div id="editGroupModalDiv">
          <Modal classNames={{ modal: "edit-group-modal" }} open={this.state.editGroupModal} onClose={this.closeEditGroupModal} center closeOnOverlayClick={false}>
            <div>
              <CreateGroup selectedGroupId={this.state.selectedGroupId} isFormDirty={isFormDirty} isDirty={isDirty} OnUpdateData={this.OnUpdate}></CreateGroup>
            </div>
          </Modal>
        </div>

        <div id="ControlsModalDiv">
          <Modal classNames={{ modal: "controls-modal" }} open={this.state.controlsModal} onClose={this.closeControlsModal} center closeOnOverlayClick={false}>
            <div>
              <LampControls id={this.state.selectedGroupId} useGroup={true}></LampControls>
            </div>
          </Modal>
        </div>

        <div id="assignScheduleModalDiv">
          <Modal classNames={{ modal: "assign-schedule-modal-dialog" }} open={this.state.openScheduleModal} onClose={this.closeScheduleModal} center closeOnOverlayClick={false}>
              <div>
                  <AssignSchedule groupId={this.state.selectedGroupId} closeAssignModal={this.closeScheduleModal}/>
              </div>
          </Modal>
        </div>
        {
          this.state.showCloseConfirmation &&
          (
            <Confirmation confirmationText={Messages.GroupCloseConfirmation} confirmButtonText="Confirm" rejectButtonText="Cancel" openConfirmation={true} closeConfirmationModal={this.closeCloseConfirmation} onConfirmClicked={this.onCloseConfirm}></Confirmation>
          )
        }
        {
          this.state.showLoader &&
          (
            <div className="overlay">
              <div className="application-loader">
                <AppLoader />
              </div>
            </div>
          )
        }
      </div>
    );
  }
}
