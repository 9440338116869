import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Button from '../button/button.js';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Messages from '../constants/messages.js';
import AppLoader from '../Loader/loader';
import Modal from 'react-responsive-modal';
import { SplitButton } from "react-bootstrap";
import { MODETYPE, getDeviceListFromDb } from '../../modules/inventory.js';
import { assignDevicesToGroupInDb, addSubgroupToSites } from '../../modules/grouping';
import filterFactory, {textFilter, dateFilter} from "react-bootstrap-table2-filter";

const { SearchBar, ClearSearchButton } = Search;

class DevicesList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            selectedDeviceList: [],
            errors: [],
            showLoader: true,
            createdGroupId: 0,
            editedGroupId: 0,
            subGroupId: '',
            openSubgroupModal: false,
            subGroupValue: '',
            subGroupValues: [],
            editedSiteId: 0,
            editedSiteName: '',
            groupLevelSubgroupValues: [],
            addedGroupLevelMaskCount: 0,
            selectedDeviceForAdd: []
        };
    }

    componentDidMount() {
        if (this.props.createdGroupId !== undefined && this.props.createdGroupId !== null && this.props.editedGroupId !== undefined && this.props.editedGroupId !== null) {
            this.setState({ createdGroupId: this.props.createdGroupId, editedGroupId: this.props.editedGroupId, groupLevelSubgroupValues: this.props.groupLevelSubgroupValues }, async () => {
                await this.getDevicesList();
            })
        }
    }

    getDevicesList = async () => {
        let data = await getDeviceListFromDb(); //api call for getting the device list from database

        if (data !== undefined && data !== null) {
            data = data.filter(l => {
                if (l.device_type !== undefined) {
                    return l.device_type.toLowerCase() === "fybrlyte";
                }
            });
            this.setState({ tableData: data, showLoader: false });
        }
    }

    renderSiteName = (cell, row) => {
        return (
            <button title={cell} className="group-name-link">{cell}</button>
        );
    }

    handleValidation = () => {
        let isValid = true;
        let errors = [];
        let state = this.state;

        if (state["selectedDeviceList"].length === 0) {
            errors["noRecordSelected"] = Messages.DeviceListNoSelection;
            isValid = false;
        }

        if (this.state.selectedDeviceList.length > 0) {
            let selectedArray = [...this.state.selectedDeviceList];
            for (var i = 0; i < selectedArray.length; i++) {
                if ((selectedArray[i].group_mask === undefined || selectedArray[i].group_mask === null || selectedArray[i].group_mask === "") && (this.state.groupLevelSubgroupValues === "" || this.state.groupLevelSubgroupValues === null || this.state.groupLevelSubgroupValues.length <= 0)) {
                    errors["noRecordSelected"] = Messages.DeviceWithoutSubgroup;
                    isValid = false;
                    break;
                } else {
                    isValid = true;
                }
            }
        }

        this.setState({ errors: errors });
        return isValid;
    }

    renderCheckboxes = (cell, row) => {
        let dataFromParent = this.props.dataFromParent;
        let isSelected = false;
        if (dataFromParent !== undefined && dataFromParent.length > 0) {
            for (var i in dataFromParent) {
                if (dataFromParent[i].site_id === row.site_id) {
                    isSelected = true;
                    break;
                }
            }
        }
        if (isSelected) {
            return (
                <input type="checkbox" disabled="disabled" checked={true} onChange={(e) => this.selectedDevice(cell, row, e)} />
            );
        } else {
            return (
                <input type="checkbox" onChange={(e) => this.selectedDevice(cell, row, e)} />
            );
        }
    }

    selectAllDevices = (isSelected, rows) => {
        if (isSelected) {
            this.setState({selectedDeviceList: []});
            this.setState({selectedDeviceList: rows.map((item) => item)});
        } else {
            this.setState({selectedDeviceList: []});
        }
    }

    selectedDevice = (row, isSelected, e) => {
        if (isSelected) {
            this.state.selectedDeviceList.push(row);
        } else {
            let val = row.site_id;
            let index = this.state.selectedDeviceList.findIndex(function (item, i) {
                return item.site_id === val
            });
            this.state.selectedDeviceList.splice(index, 1);
        }
        /*
        if (e.target.checked) {
            this.state.selectedDeviceList.push(row);
        } else {
            let val = row.site_id;
            let index = this.state.selectedDeviceList.findIndex(function (item, i) {
                return item.site_id === val
            });
            this.state.selectedDeviceList.splice(index, 1);
        }
         */
    }

    selectRowProp = {
        mode: 'checkbox',
        clickToSelect: true,  // enable click to select
        onSelect: this.selectedDevice,
        onSelectAll: this.selectAllDevices
    };

    onAddDevicesClicked = () => {

        if (!this.handleValidation()) {
            return;
        } else {
            this.setState({ showLoader: true }, async () => {
                if (this.state.selectedDeviceList.length > 0) {
                    for (let i = 0; i < this.state.selectedDeviceList.length; i++) {
                        await this.createSubgroupForGroupLevel(this.state.selectedDeviceList[i].site_id);
                    }
                }
                if (this.state.selectedDeviceForAdd.length === this.state.selectedDeviceList.length) {
                    await this.assignDevicesToGroup(this.state.selectedDeviceForAdd);
                }
            });
        }
    }

    createSubgroupForGroupLevel = async (siteId) => {
        let subGroupArray = [];
        let groupId = this.state.createdGroupId;
        if (this.state.editedGroupId !== undefined && this.state.editedGroupId !== null && this.state.editedGroupId !== 0) {
            groupId = this.state.editedGroupId;
        }
        let subGroupMask = this.state.groupLevelSubgroupValues.toString();
        subGroupArray.push(parseInt(subGroupMask));
        let subgroupToAdd = {
            groupId: groupId,
            siteId: siteId,
            groupMask: subGroupArray
        }
        let response = await addSubgroupToSites(subgroupToAdd);
        if (response !== undefined && response !== null) {
            this.setState({
                addedGroupLevelMaskCount: Number(this.state.addedGroupLevelMaskCount) + 1,
                showLoader: false
            }, () => {
                let deviceToAdd = {
                    site_id: siteId,
                    group_mask: subGroupArray
                }
                this.state.selectedDeviceForAdd = [...this.state.selectedDeviceForAdd, deviceToAdd];
                /*if (this.state.selectedDeviceForAdd.length === this.state.selectedDeviceList.length) {
                    this.assignDevicesToGroup(this.state.selectedDeviceForAdd);
                }*/
            });
        }
    }

    assignDevicesToGroup = async (selectedDevice) => {

        let groupId = this.state.createdGroupId;
        if (this.state.editedGroupId !== undefined && this.state.editedGroupId !== null && this.state.editedGroupId !== 0) {
            groupId = this.state.editedGroupId;
        }
        let devicesToAttach = {
            groupId: groupId,
            deviceList: selectedDevice
        }
        let response = await assignDevicesToGroupInDb(devicesToAttach);
        if (response !== undefined && response !== null) {
            this.setState({ showLoader: false }, () => {
                this.props.handlerFromParant(this.state.selectedDeviceList);
            });
        }
    }

    renderInstallDate = (cell) => {
        let date = new Date(cell);
        //let localDate = date.toLocaleDateString();

        //date.setDate(date.getDate() + 20);

        let localDate = ('0' + (date.getUTCMonth()+1)).slice(-2) + '/'
            + ('0' + (date.getUTCDate())).slice(-2) + '/'
            + date.getUTCFullYear();
        return (
            <span>{localDate}</span>
        );
    }

    renderModeText = (cell) => {
        if (cell !== undefined && cell !== null && cell !== "") {
            return MODETYPE[cell];
        } else {
            return "";
        }
    }

    renderSubgroupButton = (cell, row) => {
        return (
            <div>
                <SplitButton className="remove-outline" drop="down" title="ACTIONS">
                    <div className="actions-ddl">
                        <div title="Edit Subgroup" className="action-items" onClick={() => this.onAddEditSubGroupClicked(cell, row)}><i className="fa fa-edit action-items" aria-hidden="true"></i>Edit Subgroup</div>
                    </div>
                </SplitButton>
            </div>
        );
    }

    onAddEditSubGroupClicked = (cell, row) => {
        this.setState({ openSubgroupModal: true, editedSiteId: row.site_id, editedSiteName: row.site_name, subGroupValues: [1], errors: [] });
    }

    closeSubgroupModal = () => {
        this.setState({ openSubgroupModal: false });
    }

    onAddSubgroupClicked = () => {
        if (this.state.subGroupValues.length <= 0) {
            let errors = [];
            errors["noSubGroupAdded"] = Messages.NoSubgroupAddedToSite;
            this.setState({ errors: errors });
        } else {
            this.setState({ errors: [], showLoader: true }, async () => {
                await this.createSubgroupForSite();
            });
        }
    }

    createSubgroupForSite = async () => {
        let groupId = this.state.createdGroupId;
        if (this.state.editedGroupId !== undefined && this.state.editedGroupId !== null && this.state.editedGroupId !== 0) {
            groupId = this.state.editedGroupId;
        }
        let subGroupMask = this.state.subGroupValues.toString();
        let subgroupToAdd = {
            groupId: groupId,
            siteId: this.state.editedSiteId,
            groupMask: "[" + subGroupMask + "]"
        }
        let response = await addSubgroupToSites(subgroupToAdd);
        if (response !== null && response !== undefined) {
            this.setState({ openSubgroupModal: false }, () => {
                this.getDevicesList();
            })
        }
    }

    setSubGroupId = (e) => {
        let errors = [];
        let {isFormDirty} = this.props;
        if (Number(e.target.value) > 80) {
            errors["noSubGroupAdded"] = Messages.MaxSubgroupValueForAdd;
            this.setState({ errors: errors });
        } else {
            if(Number(e.target.value) < 0){
                errors["noSubGroupAdded"] = Messages.NegativeValue;
                this.setState({ errors: errors });
            }else if((Number(e.target.value) - Math.floor(Number(e.target.value))) !== 0){
                errors["noSubGroupAdded"] = Messages.DecimalValue;
                this.setState({ errors: errors });
            }else{
                this.setState({ subGroupValue: e.target.value, errors: errors });
                isFormDirty(true);
            }
        }
    }

    addSubgroupValues = () => {
        let {isFormDirty} = this.props;
        let errors = [];
        if (this.state.subGroupValues.length > 80) {
            errors["noSubGroupAdded"] = Messages.MaxSubgroupLengthForAdd;
            this.setState({ errors: errors });
        }
        else if (this.state.subGroupValues.length === 1 && (Number(this.state.subGroupValues[0]) === 1)) {
            let val = Number(this.state.subGroupValue);
            if (val !== '' && val !== 0) {
                this.setState({ subGroupValues: [val], subGroupValue: '', errors: [] });
                isFormDirty(true);
            } else {
                errors["noSubGroupAdded"] = Messages.NoSubgroupInputForAdd;
                this.setState({ errors: errors });
            }
        }
        else {
            let val = Number(this.state.subGroupValue);
            let duplicatVal = this.state.subGroupValues.filter(function (item) {
                return item == val
            });
            if (duplicatVal.length > 0) {
                errors["noSubGroupAdded"] = Messages.DuplicateSubGroupValue;
                this.setState({ errors: errors });
            }
            else {
                if (val !== '' && val !== 0) {
                    this.setState({ subGroupValues: [...this.state.subGroupValues, val], subGroupValue: '', errors: [] });
                    isFormDirty(true);
                } else {
                    errors["noSubGroupAdded"] = Messages.NoSubgroupInputForAdd;
                    this.setState({ errors: errors });
                }
            }
        }
    }

    removeSubgroupValues = () => {
        let {isFormDirty} = this.props;
        let val = Number(this.state.subGroupValue);
        if (this.state.subGroupValues.length === 1) {
            this.setState({ subGroupValues: 1, subGroupValue: '', errors: [] });
        }
        else if (val !== '' && val !== 0) {
            let index = this.state.subGroupValues.indexOf(val);
            if (index !== -1) {
                this.state.subGroupValues.splice(index, 1);
                this.setState({ subGroupValues: this.state.subGroupValues, subGroupValue: '', errors: [] });
                isFormDirty(true);
            }
        } else {
            let errors = [];
            errors["noSubGroupAdded"] = Messages.NoSubgroupInputForRemove;
            this.setState({ errors: errors });
        }
    }

    render() {
        const dataColumns = [/*{
            dataField: 'checkbox',
            text: 'SELECT',
            formatter: this.renderCheckboxes,

        },*/ {
            dataField: 'site_id',
            text: 'SITE ID',
            sort: true
        }, {
            dataField: 'site_name',
            text: 'SITE NAME',
            sort: true,
            formatter: this.renderSiteName,
            filter: textFilter()
        }, {
            dataField: 'mac_address',
            text: 'MAC',
            sort: true
        },
         {
            dataField: 'installation_date',
            text: 'INSTALL DATE',
            sort: true,
            formatter: this.renderInstallDate
        },
         {
            dataField: 'mode',
            text: 'MODE',
            sort: true,
            formatter: this.renderModeText
        }, {
            dataField: 'group_name',
            text: 'GROUP NAME',
            sort: true,
            style: {width: '14%'}
        }, {
            dataField: 'group_mask',
            text: 'SUBGROUPS',
            sort: true
        }, {
            dataField: 'site_status',
            text: 'SITE STATUS',
            sort: true
        }, {
            dataField: 'street1_name',
            text: 'ADDRESS',
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'street2_name',
            text: 'CITY, STATE, ZIP',
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'button',
            text: 'ACTIONS',
            formatter: this.renderSubgroupButton
        }];

        const defaultSorted = [{
            dataField: 'mac_address',
            order: 'desc'
        }];

        const searchProps = {
            delay: 1000
        };

        return (
            <div>
                <h5 className="class-bold">Assign Devices</h5>
                <ToolkitProvider
                    keyField="site_id"
                    data={this.state.tableData}
                    columns={dataColumns}
                    search={searchProps}
                >
                    {
                        props => (
                            <div>
                                <div className="device-list-search-parent-div row">
                                    <div className="device-list-search-input-div col-sm-8">
                                        <SearchBar {...props.searchProps} />
                                    </div>
                                    <div className="device-list-search-button-div col-sm-4">
                                        <ClearSearchButton className="table-search-btn" {...props.searchProps} />
                                    </div>
                                </div>

                                <div className="padding-10 table-responsive fixed-table-footer-mobile">
                                    <BootstrapTable wrapperClasses="table-common table-assign-device-mob" {...props.baseProps} keyField='site_id' defaultSorted={defaultSorted} data={this.state.tableData} columns={dataColumns} pagination={paginationFactory()} filter={filterFactory()} selectRow={ this.selectRowProp } />
                                </div>
                                <div className="application-error-messages center-aligned">{this.state.errors["noRecordSelected"]}</div>
                                <div className="add-devices-btn">
                                    <Button text="ADD" buttonClickCallback={this.onAddDevicesClicked} buttonTextStyle="application-button" btnClassName="" />
                                </div>
                            </div>
                        )
                    }
                </ToolkitProvider>

                <div id="addSubGroupModalDiv">
                    <Modal classNames={{ modal: "add-subgroup-modal-dialog-div" }} open={this.state.openSubgroupModal} onClose={this.closeSubgroupModal} center closeOnOverlayClick={false}>
                        <div>
                            <h4>Update Subgroups</h4>
                            <div className="div-sub-group-control-body">
                                <div id="siteIdOnAddSubgroup">
                                    <span className="class-bold">Site ID : </span>
                                    <span>{this.state.editedSiteId}</span>
                                </div>
                                <div id="siteNameOnAddSubgroup">
                                    <span className="class-bold">Site Name : </span>
                                    <span>{this.state.editedSiteName}</span>
                                </div>
                                <div className="addSubgroupControl">
                                    <span className="class-bold">Add Subgroup : </span>
                                    <span className="display-inline-flex">
                                        <span><input className="inputSubGroupValue" type="number" value={this.state.subGroupValue} onChange={(e) => this.setSubGroupId(e)} step="any" /></span>
                                        <span className="margin-top-negative-4"><i title="Add" onClick={() => this.addSubgroupValues()} className="fa fa-plus-circle" style={{ color: 'green', fontSize: '36px', marginLeft: '30px' }}></i></span>
                                        <span className="margin-top-negative-4"><i title="Remove" onClick={() => this.removeSubgroupValues()} className="fa fa-times-circle" style={{ color: 'red', fontSize: '36px', marginLeft: '10px' }}></i></span>
                                    </span>
                                </div>
                                <div id="divSubgroupValues" className="margin-top-10 subgroup-array">Subgroups : [{this.state.subGroupValues.toString()}]</div>
                                <div className="application-error-messages margin-top-10">{this.state.errors["noSubGroupAdded"]}</div>
                            </div>
                            <div className="delete-buttons-div">
                                <button onClick={() => this.onAddSubgroupClicked()} className="delete-confirm-button">UPDATE</button>
                                <button onClick={this.closeSubgroupModal} className="confirmation-cancel-btn">CANCEL</button>
                            </div>
                        </div>
                    </Modal>
                </div>

                {
                    this.state.showLoader &&
                    (
                        <div className="overlay">
                            <div className="application-loader">
                                <AppLoader />
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    sensors: state.sensors,
    map: state.map,
    user: state.user
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(DevicesList);
