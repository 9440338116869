import React, { Component } from 'react';
import { css } from 'emotion';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { checkLoginTimeout, clearLoginTimeout } from '../../modules/user.js';
import { showColumns, getSitesListFromDb } from '../../modules/site.js';
import Button from "../button/button";
import Messages from "../constants/messages";
import SingleSelectDropDown from "../single-select-dropdown/single-select-dropdown";
import {toast} from "react-toastify";
import {
    DELIVERY,
    MODELEVELS,
    MODETYPE,
    updateGps,
    updateGroupGps,
    updateGroupMode,
    updateMode, updateThresholdAndLevels,
    applyChanges, updateGroupThresholdAndLevels
} from "../../modules/map";
import NotificationModal from "../modal/notification-modal";
import {OFF, ON} from "../constants/map";

class LampControls extends Component {
    constructor(props) {
        super(props);
        this.state = {
            map: props.map,
            showApplyChangesPrompt: false,
            buttonState: false,
            brightness: 0,
            toggleLampState: 0,
            totalLights: 0,
            toggleGpsState: 0,
            modeType: '0',
            validModeType: true,
            modeLevel1: '0',
            validModeLevel1: true,
            modeLevel2: '0',
            validModeLevel2: true,
            ambientThreshold: 0,
            immediate: true,
            openScheduleModal: false,
            shown: false,
            editSiteModal: false,
            allocateDeviceModal: false,
            visible: false
        };
    }

    onTurnOffGps = async e => {
        let {id} = this.props;
        let update = {
            toggleGpsState: 0
        };

        let lightImmediate = this.state.immediate;
        await this.updateGPSinDb(id, update.toggleGpsState, lightImmediate, update);
    };

    onTurnOnGps = async e => {
        let {id} = this.props;
        let update = {
            toggleGpsState: 1
        };

        let lightImmediate = this.state.immediate;
        await this.updateGPSinDb(id, update.toggleGpsState, lightImmediate, update);
    };

    updateGPSinDb = async (site_id, gpsValue, immediate, update) => {
        let {useGroup} = this.props;
        //let response = await updateGps(site_id, gpsValue, immediate);
        //let {updateGps: updateCurrentGps} = this.props;
        //let response = await updateGps(site_id, gpsValue, immediate);

        let response = useGroup ? await updateGroupGps(site_id, gpsValue, immediate) : await updateGps(site_id, gpsValue, immediate);
        if (response !== undefined && immediate !== null) {
            this.setState(update);
            toast.success(Messages.GPSStatusChangedSuccess)
        } else {
            toast.error(Messages.GPSStatusChangedFailure);
        }
    }

    onModeTypeSelected = async (modeType) => {
        let {id} = this.props;
        let immediate = this.state.immediate;

        await this.setMode(id, modeType, immediate);
    }

    async setMode(siteId, modeType, immediate) {
        let {useGroup} = this.props;
        let response = useGroup ? await updateGroupMode(siteId, modeType, immediate) : await updateMode(siteId, modeType, immediate);
        if (response !== undefined && response !== null) {
            this.setState({ modeType: modeType, validModeType: true }, () => {
                toast.success(Messages.ModeChangedSuccess);
            });
        } else {
            toast.error(Messages.ModeChangedFailure);
        }
    }

    onModeLevel1Selected = (level) => {
        this.setState({
            modeLevel1: level,
            validModeLevel1: true,
            buttonState: true,
        });
    }

    onModeLevel2Selected = (level) => {
        this.setState({
            modeLevel2: level,
            validModeLevel2: true,
            buttonState: true,
        });
    }

    setThresholdValue = e => {
        let val = parseInt(e.target.value);
        if (!Number.isInteger(val) || val < 0 || val > 4000) {
            e.target.value = '';
            return;
        } else {
            this.setState({
                buttonState: true,
                ambientThreshold: val
            });
        }
    }

    setImmediateValue = e => {
        let val = e;
        this.setState({
            buttonState: true,
            immediate: val === 'true' || val === null || val === undefined
        });
    }

    applyChangesButtonClicked = () => {

        if (this.state.buttonState === true) {
            this.setState({
                showApplyChangesPrompt: true
            });
        }
    }

    onCancelChanges = () => {
        this.setState({
            showApplyChangesPrompt: false
        });
    };

    onSetScheduleClicked = () => {
        const {openAssignModal} = this.props;
        openAssignModal();
    }

    onApplyChangesConfirmed = async () => {
        let {siteId, id, useGroup} = this.props;
        let brightness = this.state.brightness[1];
        let lampStatus = this.state.toggleLampState === 0 ? OFF : ON;

        let lightToUpdate = !useGroup ? {
            lightModeLevel1: this.state.modeLevel1 ? parseInt(this.state.modeLevel1) : 0,
            lightModeLevel2: this.state.modeLevel2 ? parseInt(this.state.modeLevel2) : 0,
            lightAmbientThreshold: this.state.ambientThreshold ? parseInt(this.state.ambientThreshold) : 0,
            siteId: siteId
        } : {
            lightModeLevel1: this.state.modeLevel1 ? parseInt(this.state.modeLevel1) : 0,
            lightModeLevel2: this.state.modeLevel2 ? parseInt(this.state.modeLevel2) : 0,
            lightAmbientThreshold: this.state.ambientThreshold ? parseInt(this.state.ambientThreshold) : 0,
            groupId: id
        }

        await this.updateThresholdLevels(lightToUpdate);

        this.props.applyChanges({
            brightness,
            lampStatus
        });
    };

    updateThresholdLevels = async (lightToUpdate) => {
        let {useGroup} = this.props;
        let response = null;

        if (!useGroup)
            response = await updateThresholdAndLevels(lightToUpdate);
        else
            response = await updateGroupThresholdAndLevels(lightToUpdate);

        if (response !== undefined && response !== null) {
            this.setState({ showApplyChangesPrompt: false, buttonState: false }, () => {
                toast.success(Messages.DevicePropertiesUpdatedSuccess);
            });
        } else {
            toast.error(Messages.DevicePropertiesUpdatedFailure);
        }
    }

    renderApplyChangesModal() {
        let heading = 'Apply Changes';
        let title = 'Are you sure you want to apply these values';
        let applyChangesButtonTitle = 'Apply Changes';
        let cancelButtonTitle = 'Cancel';
        if (this.state.showApplyChangesPrompt) {
            return (
                <NotificationModal>
                    <div className={applyChangesContainer}>
                        <div className={headingClass}>
                            {heading}
                        </div>
                        <div className={applyChangesTitle}>
                            {title}
                        </div>
                        <div className={applyChangesButtons}>
                            <Button
                                btnClassName={notificationCancelButton}
                                buttonTextStyle={cancelButtonText}
                                text={cancelButtonTitle}
                                buttonContainerStyle={buttonContainerStyle}
                                buttonClickCallback={this.onCancelChanges}
                            />
                            <Button
                                btnClassName={notificationButton}
                                buttonTextStyle={applyButtonText}
                                text={applyChangesButtonTitle}
                                buttonContainerStyle={buttonContainerStyle}
                                buttonClickCallback={this.onApplyChangesConfirmed}
                            />
                        </div>
                    </div>
                </NotificationModal>
            );
        }
        return null;
    }

    render() {
        const objectToArray = item => {
            return Object.keys(item).map(key => {
                return { key: key, label: item[key] };
            });
        };
        const modeTypes = objectToArray(MODETYPE);
        const modeLevels = objectToArray(MODELEVELS);
        const delivery = objectToArray(DELIVERY);
        const {useGroup} = this.props;

        return (
            <div className="customTab">
                <div className={rowClass}>
                    <div className={rowClassTwoColumnDecreasedInputSwitchPadding} style={{width: '100%', padding: '0px'}}>
                        <span className={spanTextHeaderClass}>Delivery Method</span>
                        <div className={pullLeft}>
                            <SingleSelectDropDown
                                title={'Immediate'}
                                options={delivery}
                                onSelect={this.setImmediateValue}
                                showErrorState={!this.state.validModeType}
                                containerStyle={containerControlWidth}
                                headerStyle={controlWidth}
                                arrowContainerStyle={arrowControlWidth}
                                titleContainerStyle={{width: '100%'}}
                            />
                        </div>
                    </div>
                    <Button
                        buttonTextStyle={applyButtonActionText}
                        btnClassName={applyChangesControlStyle}
                        text="On Mode 1"
                        buttonContainerStyle={buttonLightActionLeftContainerStyle}
                        buttonClickCallback={async () => await this.onModeTypeSelected(1)}
                        style={buttonCss}
                    />
                    <Button
                        buttonTextStyle={applyButtonActionText}
                        btnClassName={applyChangesControlStyle}
                        text="On Mode 2"
                        buttonContainerStyle={buttonLightActionRightContainerStyle}
                        buttonClickCallback={async () => await this.onModeTypeSelected(2)}
                        style={buttonCss}
                    />
                    <Button
                        buttonTextStyle={applyButtonActionText}
                        btnClassName={applyChangesControlStyle}
                        text="Off"
                        buttonContainerStyle={buttonLightActionLeftContainerStyle}
                        buttonClickCallback={async () => await this.onModeTypeSelected(3)}
                        style={buttonCss}
                    />
                    <Button
                        buttonTextStyle={applyButtonActionText}
                        btnClassName={applyChangesControlStyle}
                        text="Flash"
                        buttonContainerStyle={buttonLightActionRightContainerStyle}
                        buttonClickCallback={async () => await this.onModeTypeSelected(4)}
                        style={buttonCss}
                    />
                    <Button
                        buttonTextStyle={applyButtonActionText}
                        btnClassName={applyChangesControlStyle}
                        text="GPS On"
                        buttonContainerStyle={buttonLightActionLeftContainerStyle}
                        buttonClickCallback={async (e) =>  await this.onTurnOnGps(e)}
                        style={buttonCss}
                    />
                    <Button
                        buttonTextStyle={applyButtonActionText}
                        btnClassName={applyChangesControlStyle}
                        text="GPS Off"
                        buttonContainerStyle={buttonLightActionRightContainerStyle}
                        buttonClickCallback={async (e) =>  await this.onTurnOffGps(e)}
                        style={buttonCss}
                    />
                </div>

                <div className={labelValuesContainer}>
                    <div className={rowClassTwoColumnDecreasedLeftPadding}>
                        <span className={spanTextHeaderClass}>Mode 1 Level</span>
                        <div className={pullLeft}>
                            <SingleSelectDropDown
                                title={'Select Mode'}
                                options={modeLevels}
                                onSelect={this.onModeLevel1Selected}
                                showErrorState={!this.state.validModeType}
                                containerStyle={containerControlWidth}
                                headerStyle={controlWidth}
                                arrowContainerStyle={arrowControlWidth}
                                titleContainerStyle={{width: '100%'}}
                            />
                        </div>
                    </div>
                    <div className={rowClassTwoColumnDecreasedRightPadding} style={{paddingTop: '32px !important'}}>
                        <span className={spanTextHeaderClass}>Mode 2 Level</span>
                        <div className={pullLeft}>
                            <SingleSelectDropDown
                                title={'Select Mode'}
                                options={modeLevels}
                                onSelect={this.onModeLevel2Selected}
                                showErrorState={!this.state.validModeType}
                                containerStyle={containerControlWidth}
                                headerStyle={controlWidth}
                                arrowContainerStyle={arrowControlWidth}
                                titleContainerStyle={{width: '100%'}}
                            />
                        </div>
                    </div>

                    <div className={rowClassTwoColumnDecreasedPadding}>
                        <span className={spanTextHeaderClass}>Ambient Threshold</span>
                        <input className={containerControlTextWidth} placeholder="(0-4000)" maxLength="4" type="text" onChange={e => { this.setThresholdValue(e); }} onFocus={e => { e.target.value = ''; }}/>
                    </div>
                    <div className={rowClassTwoColumnDecreasedRightPadding} style={{width: '100%', paddingLeft: '16px'}}>
                        <div className={pullLeft}>
                            <Button
                                buttonTextStyle={applyButtonActionText}
                                btnClassName={applyChangesControlStyle}
                                text="Apply"
                                buttonContainerStyle={buttonLightApplyRightContainerStyle}
                                buttonClickCallback={this.applyChangesButtonClicked}
                                disabled={!this.state.buttonState}
                                style={buttonCss}
                            />
                        </div>
                    </div>
                </div>
                {this.renderApplyChangesModal()}
                {!useGroup && (
                <div className={rowClass}>
                    <Button
                        text="Assign Schedule"
                        buttonClickCallback={this.onSetScheduleClicked}
                        btnClassName={applyChangesControlStyle}
                        style={buttonCssAssignSchedule}
                    />
                </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    map: state.map,
    user: state.user,
    site: state.site
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            checkLoginTimeout,
            clearLoginTimeout,
            showColumns,
            applyChanges
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(LampControls);

const masterControlTitleRow = css({
    padding: '16px',
    borderBottom: 'solid 1px #ebecf0'
});

const masterControlTitle = css({
    fontFamily: 'OpenSans',
    fontSize: '18px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#000000',
    display: 'block',
    backgroundColor: '#d6eaf8',
    paddingLeft: '10px'
});

const rowClass = css({
    padding: '16px'
});

const rowClassTwoColumn = css({
    padding: '16px',
    float: 'left'
});
const rowClassTwoColumnDecreasedPadding = css({
    paddingTop: '8px',
    paddingLeft: '16px',
    paddingRight: '8px',
    paddingBottom: '8px'
});
const rowClassTwoColumnDecreasedLeftPadding = css({
    paddingTop: '8px',
    paddingLeft: '16px',
    paddingRight: '0px',
    paddingBottom: '8px',
    float: 'left',
    width: '50%'
});
const rowClassTwoColumnDecreasedInputSwitchPadding = css({
    paddingTop: '10px',
    paddingLeft: '16px',
    paddingRight: '0px',
    paddingBottom: '8px',
    float: 'left',
    width: '50%'
});
const rowClassTwoColumnDecreasedRightPadding = css({
    paddingTop: '8px',
    paddingLeft: '8px',
    paddingRight: '16px',
    paddingBottom: '8px',
    float: 'left',
    width: '50%'
});

const applyButtonActionText = css({
    fontFamily: 'OpenSans',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1,
    letterSpacing: '1px',
    textAlign: 'center',
    color: '#ffffff'
});

const applyChangesContainer = css({
    display: 'flex',
    flexDirection: 'column'
});

const applyChangesTitle = css({
    display: 'flex',
    fontFamily: 'OpenSans',
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#112138',
    padding: '20px'
});

const applyChangesStyle = css({
    width: '100%',
});
const applyChangesControlStyle = css({
    width: '100%'
});

const applyChangesButtons = css({
    display: 'flex',
    alignSelf: 'center'
});

const buttonLightActionLeftContainerStyle = {
    padding: '7px 0px 0px 0px',
    width: '49%',
    float: 'left',
    fontWeight: '20px'
};
const buttonLightActionRightContainerStyle = {
    padding: '7px 0px 0px 2px',
    width: '49%',
    float: 'right',
    fontWeight: '20px'
};
const buttonLightApplyRightContainerStyle = {
    padding: '23px 0px 0px 2px',
    fontWeight: '20px'
}
const buttonLightAssignScheduleContainerStyle = {
    marginTop: '10px'
}

const buttonCss = css({
    padding: '5px 5px 5px 5px !important'
})

const buttonCssAssignSchedule = css({
    padding: '5px 5px 5px 5px !important',
    marginTop: '10px'
})

const labelValuesContainer = css({
    display: 'block',
    width: '100%'
});

const spanTextClass = css({
    display: 'block',
    fontFamily: 'OpenSans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#112138',
    fontWeight: 'normal'
});

const spanTextHeaderClass = css({
    display: 'block',
    fontSize: '14px',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#112138',
    fontWeight: 'bold'
});

const spanTextClassForControls = css({
    fontFamily: 'OpenSans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 2,
    letterSpacing: 'normal',
    color: '#112138',
    fontWeight: 'normal',

});

const containerControlWidth = {
    float: 'left !important',
    width: '100% !important'
}
const containerControlTextWidth = css({
    float: 'left !important',
    width: '49% !important'
});
const controlWidth = {
    float: 'left !important',
    width: '100% !important'
}

const pullRight = css({
    float: 'right',
    width: '100%'
});

const pullLeft = css({
    float: 'left',
    width: '100%'
});

const arrowControlWidth = {
    float: 'left !important'
}

const headingClass = css({
    fontFamily: 'OpenSans',
    fontSize: '18px',
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#000000',
    padding: '24px 0px 0px 24px'
});

const notificationButton = css({
    height: '48px'
});

const notificationCancelButton = css({
    height: '48px',
    borderRadius: '2px',
    backgroundColor: '#ffffff',
    border: 'solid 1px #c4cdd5',
    ':hover': {
        backgroundColor: 'rgba(23, 44, 76, 0.2)',
        border: 'solid 1px rgba(23, 44, 76, 0.2)'
    }
});

const applyButtonText = css({
    fontFamily: 'OpenSans',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: '1px',
    textAlign: 'center',
    color: '#ffffff'
});

const cancelButtonText = css({
    fontFamily: 'OpenSans',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: '1px',
    textAlign: 'center',
    color: '#5e6c84'
});

const buttonContainerStyle = {
    padding: '15px'
};
