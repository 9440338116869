import { login } from '../components/api/login';
import { LOGIN_API_ROOT } from '../components/constants/api';
import { REHYDRATE } from 'redux-persist/constants';
import { isTokenInLocalStorageValid, getToken } from '../utils/user';
import persistor from '../index';
import { Cookies } from 'react-cookie';
import {setApiToken} from "../utils/user"; //added on 9th september
import { clearApiToken } from '../utils/user';
const cookies = new Cookies();
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOG_IN_REQESTED = 'USER_LOG_IN_REQESTED';
export const USER_LOG_IN_ERROR = 'USER_LOG_IN_ERROR';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const CHECK_USER_TIMEOUT = 'CHECK_USER_TIMEOUT';
export const INTERVAL_UPDATE = 'INTERVAL_UPDATE';
export const TIMER_INTERVAL = 600000;   //10mins interval, adjust as needed
const jwtDecode = require('jwt-decode');
const initialState = {
  isLoggedIn: false,
  timerID: null,
  expiresAt: null,
  loggedusername: '',
  name: '',
  token: null,
  status: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REHYDRATE:
      let isValid = isTokenInLocalStorageValid(state);
      if(isValid) {
        let storedState = action.payload.user;
        let newState = Object.assign({}, storedState, { status: null });
        return newState;
      }
      persistor.purge();
      return state;
    case USER_LOGGED_IN:
      let payload = action.payload;
      return {
        ...state,
        ...payload,
        isLoggedIn: true,
        status: USER_LOGGED_IN
      };
    case USER_LOGGED_OUT:
      clearInterval(state.timerID);
      return {
        ...state,
        isLoggedIn: false,
        expiresAt: null,
        //email: null,
        name: null,
        token: null,
        timerID: null,
        status: USER_LOGGED_OUT
      };
    case USER_LOG_IN_REQESTED:
      return {
        ...state,
        isLoggedIn: false,
        status: USER_LOG_IN_REQESTED
      };
    case USER_LOG_IN_ERROR:
      return {
        ...state,
        isLoggedIn: false,
        status: USER_LOG_IN_ERROR
      };
    case CHECK_USER_TIMEOUT:
      return checkUserTimeout(state);
    case INTERVAL_UPDATE:
      return {
        ...state,
        timerID: action.payload.timerID
      };
    default:
      return state;
  }
};

export const loginWithCreds = (userName, password) => {
  return dispatch => {
    dispatch({
      type: USER_LOG_IN_REQESTED
    });

    login(LOGIN_API_ROOT + 'login', userName, password).then(json => {
      if (json === null) {
        // show login error.
        dispatch({
          type: USER_LOG_IN_ERROR
        });
      } else {
        //Added on 9th september for setting authentication token value in cookies
        setApiToken('Bearer ' + json.jwt);
        let decoded = jwtDecode(json.jwt);

        //cookies.set('apitoken', "Bearer " + json.token);
        //cookies.set('loggedusername', json.name); //for showing the logged in user name on the header section

        dispatch({
          type: USER_LOGGED_IN,
          payload: {
            //email: decoded.email,
            expiresAt: decoded.exp,
            name: decoded.display_name,
            token: json.jwt,
            loggedusername: userName
          }
        });
      }
    });
  };
};

export function logout(informServer = true) {
  let token = getToken();
  if (informServer && token) {
    fetch(LOGIN_API_ROOT + 'logout.json', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Xauthtoken: getToken()
      }
    }).then(response => {
      if (response.status !== 201) {
        console.log('logout error:', response);
        return;
      }
      else{ //Added on 9th september for removing authentication token from cookies on logout.
        clearApiToken();
        /*
        if(cookies.get('apitoken') !== undefined && cookies.get('apitoken') !== ""){
          cookies.remove('apitoken');
        }
        if(cookies.get('loggedusername') !== undefined && cookies.get('loggedusername') !== ""){
          cookies.remove('loggedusername');  //for removing the logged in user name on the header section on logout.
        }
         */
      }
    });
  } else if (!token) {
    clearApiToken();
  }
  return dispatch => {
    dispatch({
      type: USER_LOGGED_OUT
    });
    persistor.purge();
  };
}

function checkUserTimeout(state) {
  if ( state.isLoggedIn == false || !isTokenInLocalStorageValid(state)  ) {
    //logout

    //persistor.purge();
    window.location.href = '/';

    return {
      ...state,
      isLoggedIn: false,
      expiresAt: null,
      //email: null,
      name: null,
      token: null,
      status: USER_LOGGED_OUT
    };
  }

  return { ...state };
}
export function checkUserTimeoutAndLogout() {
  return dispatch => {
    dispatch({
      type: CHECK_USER_TIMEOUT
    });
  };
}

export function checkLoginTimeout(userState){
  return dispatch => {
    if(userState.timerID){
      clearTimeout(userState.timerID);
    }
    let timer = setInterval(()=>{
        dispatch({
            type: CHECK_USER_TIMEOUT
        });
      },TIMER_INTERVAL);
    dispatch({
      type: INTERVAL_UPDATE,
      payload:{
        timerID: timer
      }
    });
  }
}
export function clearLoginTimeout(userState){
  return dispatch => {
    if(userState.timerID){
      clearTimeout(userState.timerID);
    }
    dispatch({
      type: INTERVAL_UPDATE,
      payload:{
        timerID: null
      }
    });
  }
}
