import React, { Component } from 'react';
import { SplitButton } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import Messages from '../constants/messages.js';
import { deleteWorkOrderFromDb } from '../../modules/work-order';
import { toast } from 'react-toastify';
import AppLoader from '../Loader/loader';
import CreateWorkOrder from '../work-order/create-work-order.js';
import Confirmation from '../confirmation/confirmation.js';
import moment from 'moment';
import filterFactory, { textFilter, numberFilter } from 'react-bootstrap-table2-filter';

export default class WorkOrderTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedWorkOrder: [],
            openDeleteModal: false,
            selectedWorkOrderId: '',
            showLoader: false,
            showCreateWorkOrderModal: false,
            isFormDirty: false
        };
    }

    cellButton = (cell, row) => {
        return (
            <div>
                <SplitButton className="remove-outline" drop="down" title="ACTIONS">
                    <div className="workorder-actions-ddl">
                        <div title="Edit" className="action-items" onClick={() => this.onEditClicked(cell, row)}><i className="fa fa-edit action-items" aria-hidden="true"></i> Edit </div>
                        <div title="Delete" className="action-items" onClick={() => this.onDeleteClicked(cell, row)}><i className="fa fa-trash action-items" aria-hidden="true"></i> Delete </div>
                        <div title="viewIssues" className="action-items" onClick={() => this.onViewIssuesClicked(cell, row)}><i className="fa fa-eye action-items" aria-hidden="true"></i>View Issues</div>
                    </div>
                </SplitButton>
            </div>
        );
    };

    onEditClicked(cell, row) {
        let selectedWorkOrder = row.work_order_id;
        this.setState({ showCreateWorkOrderModal: true, selectedWorkOrder: row, selectedWorkOrderId: selectedWorkOrder });
    }

    onDeleteClicked(cell, row) {
        let selectedWorkOrder = row.work_order_id;
        this.setState({ openDeleteModal: !this.state.openDeleteModal, selectedWorkOrderId: selectedWorkOrder });
    }

    onViewIssuesClicked(cell, row) {
        let selectedWorkOrder = row.work_order_id;
        window.location.href = "/issues?" + selectedWorkOrder;
    }

    closeDeleteModal = () => {
        this.setState({ openDeleteModal: false });
    }

    renderComments = (cell, row) => {
        let newVal = cell;
        if (cell !== undefined && cell !== null && cell !== "") {
            if (cell.length > 15) {
                newVal = newVal.substr(0, 15) + "...";
            }
        }
        return (
            <div className="pointer-class" title={cell}>{newVal}</div>
        );
    }

    onDeleteWork = () => {
        this.setState({ showLoader: true }, () => {
            this.deleteWorkOrder();
        });
    }

    deleteWorkOrder = async () => {
        let response = await deleteWorkOrderFromDb(this.state.selectedWorkOrderId); //api call for deleting work order details from database
        if (response !== undefined && response !== null) {
            this.setState({ openDeleteModal: false }, () => {
                this.props.onUpdate();
                toast.success(Messages.DeleteWorkOrderSuccessText);
                setTimeout(() => {

                    this.setState({ showLoader: false });
                    // window.location.reload(false);
                }, 2000);
            });
        }
    }

    renderCreatedDates = (cell) => {
        let date = new Date(cell);
        let localDate = date.toLocaleDateString();
        let localeTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        let timezone = moment.tz(moment.tz.guess()).zoneAbbr();
        return (
            <span>
                <span>{localDate}</span><span>&nbsp;&nbsp;&nbsp;</span><span>{localeTime}</span>&nbsp;&nbsp;&nbsp;<span>{timezone}</span>
            </span>
        );
    }

    renderAssignedDate = (cell) => {
        let date = new Date(cell);
        let localDate = date.toLocaleDateString();
        return (
            <span>{localDate}</span>
        );
    }

    createNewWorkOrder = () => {
        this.setState({ showCreateWorkOrderModal: true });
    }

    closeWorkModal = () => {
        this.setState({ showCloseConfirmation: this.state.isFormDirty });

        if (!this.state.isFormDirty)
            this.setState({showCreateWorkOrderModal: false});
    }

    closeCloseConfirmation = () => {
        this.setState({ showCloseConfirmation: false });
    }

    onCloseConfirm = () => {
        this.setState({ showCloseConfirmation: false });
        this.setState({ showCreateWorkOrderModal: false });
        this.setFormDirty(false);
    }

    onAddAndUpdate = () => {
        this.setState({ showCreateWorkOrderModal: false });
    }

    onUpdate = () => {
        this.props.onUpdate();
    }

    setFormDirty = (isDirty) => {
        this.setState({isFormDirty: isDirty})
    }

    render() {
        const { data } = this.props;
        const { columns } = this.props;

        //for column filtering
        let showName = "column-hide", showCreatedDate = "column-hide", showStatus = "column-hide", showPriority = "column-hide", showDateAssigned = "column-hide", showAssignedTo = "column-hide", showComments = "column-hide";
        for (let i = 0; i < columns.length; i++) {
            let keyVal = columns[i].key;
            switch (keyVal) {
                case "work_order_name":
                    showName = "column-show";
                    break;
                case "date_created":
                    showCreatedDate = "column-show";
                    break;
                case "status":
                    showStatus = "column-show";
                    break;
                case "priority":
                    showPriority = "column-show";
                    break;
                case "date_assigned":
                    showDateAssigned = "column-show";
                    break;
                case "assigned_to":
                    showAssignedTo = "column-show";
                    break;
                case "comments":
                    showComments = "column-show";
                    break;
                default:
                    break;
            }
        }

        //for resizing functionality
        const { rowType } = this.props;
        let rowDisplayClass = "workorder-thin-row-size";
        if (rowType === 'thick') {
            rowDisplayClass = "workorder-thick-row-size";
        } else if (rowType === 'medium') {
            rowDisplayClass = "workorder-medium-row-size";
        } else if (rowType === 'thin') {
            rowDisplayClass = "workorder-thin-row-size";
        }

        const dataColumns = [{
            dataField: 'work_order_id',
            text: 'ID',
            hidden: true,
            sort: true,
        }, {
            dataField: 'work_order_name',
            text: 'WORK ORDER NAME',
            sort: true,
            classes: showName,
            headerClasses: showName,
            filter: textFilter()
        }, {
            dataField: 'date_created',
            text: 'CREATED DATE',
            sort: true,
            classes: showCreatedDate,
            headerClasses: showCreatedDate,
            formatter: this.renderCreatedDates
        },
        {
            dataField: 'issue_count',
            text: 'ISSUE COUNT',
            sort: true,
            classes: showStatus,
            headerClasses: showStatus,
            filter: textFilter()
        },
        {
            dataField: 'status',
            text: 'STATUS',
            sort: true,
            classes: showStatus,
            headerClasses: showStatus,
            filter: textFilter()
        }, {
            dataField: 'priority',
            text: 'PRIORITY',
            sort: true,
            classes: showPriority,
            headerClasses: showPriority,
            filter: numberFilter()
        }, {
            dataField: 'date_assigned',
            text: 'ASSIGNED DATE',
            sort: true,
            classes: showDateAssigned,
            headerClasses: showDateAssigned,
            formatter: this.renderAssignedDate
        }, {
            dataField: 'assigned_to',
            text: 'ASSIGNED TO',
            sort: true,
            classes: showAssignedTo,
            headerClasses: showAssignedTo,
            filter: textFilter()
        }, {
            dataField: 'comments',
            text: 'COMMENTS',
            sort: true,
            classes: showComments,
            headerClasses: showComments,
            formatter: this.renderComments
        }, {
            dataField: 'button',
            text: 'ACTIONS',
            formatter: this.cellButton
        }];
        const defaultSorted = [{
            dataField: 'date_created',
            order: 'desc'
        }];

        let tableClasses = "table-container fixed-table-footer";
        tableClasses = tableClasses.trim();
        return (
            <div className={tableClasses}>
                <BootstrapTable rowClasses={rowDisplayClass} wrapperClasses="table-common table-workorder-mob" keyField='work_order_id' data={data} columns={dataColumns} pagination={paginationFactory()} filter={filterFactory()} />
                <div id="deleteModalDiv">
                    <Modal classNames={{ modal: "delete-modal-dialog-div" }} open={this.state.openDeleteModal} onClose={this.closeDeleteModal} center closeOnOverlayClick={false}>
                        <div>
                            <h4>Delete this work order ? </h4>
                            <p className="delete-p-text">{Messages.DeleteWorkOrderText}</p>
                            <div className="delete-buttons-div">
                                <button onClick={this.onDeleteWork} className="delete-confirm-button">DELETE WORK ORDER</button>
                                <button onClick={this.closeDeleteModal} className="workorder-confirmation-cancel-btn">CANCEL</button>
                            </div>
                        </div>
                    </Modal>
                </div>
                <div id="editWorkOrder">
                    <Modal classNames={{ modal: "create-work-order-modal" }} open={this.state.showCreateWorkOrderModal} onClose={this.closeWorkModal} right closeOnOverlayClick={false}>
                        <CreateWorkOrder closeModal={this.onAddAndUpdate} editedWorkOrderId={this.state.selectedWorkOrderId} onClose={this.closeWorkModal} onUpdate={this.onUpdate} isDirty={this.state.isFormDirty} isFormDirty={this.setFormDirty}></CreateWorkOrder>
                    </Modal>
                </div>
                {
                    this.state.showCloseConfirmation &&
                    (
                        <Confirmation confirmationText={Messages.WorkOrderCloseConfirmation} confirmButtonText="Confirm" rejectButtonText="Cancel" openConfirmation={true} closeConfirmationModal={this.closeCloseConfirmation} onConfirmClicked={this.onCloseConfirm} />
                    )
                }
                {
                    this.state.showLoader &&
                    (
                        <div className="overlay">
                            <div className="application-loader">
                                <AppLoader />
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
}
