import {
  get2xBgStyle,
  get2xSelectedBgStyle,
  get2xTurnedOnBgStyle,
  get2xSelectedOutlineStyle,
  get2xSelectedBgSiteStyle,
  get2xSelectedOutlineSiteStyle,
  get2xSelectedBgOffStyle,
  get2xSelectedOutlineOffStyle, get2xBgStyleSite
} from '../styles/2x-styles';
import {
  get2xBgClusteredStyle, get2xBgClusteredStyleSite, get2xFgCountStyleSite,
  get2xSelectedBgClusteredStyle, get2xSelectedBgOffClusteredStyle,
  get2xSelectedBgSiteClusteredStyle,
  get2xSelectedOutlineClusteredStyle, get2xSelectedOutlineOffClusteredStyle,
  get2xSelectedOutlineSiteClusteredStyle,
  get2xTurnedOnBgClusteredStyle
} from "../styles/2x-styles-clustered";
import {
  getGroup2xBgStyle,
  getGroup2xSelectedBgSiteStyle,
  getGroup2xSelectedOutlineSiteStyle
} from "../styles/2x-styles-group";

const defaultOptions = {
  minZoom: 11,
  maxZoom: 22
};

export function getSource(features) {
  return {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: features
    },
    cluster: true,
    clusterMaxZoom: 17.9, // Max zoom to cluster points on
    clusterRadius: 25,
    clusterMinPoints: 4
  };
}

function applyStyles(sourceId, options = defaultOptions) {
  let bgStyle = get2xBgStyle('2x-bg-style', sourceId, options.minZoom, options.maxZoom);
  let get2xTurnedOnStyle = get2xTurnedOnBgStyle('2x-turned-on-one-style', sourceId, options.minZoom, options.maxZoom);
  let bgSelectedStyle = get2xSelectedBgStyle('2x-selected-bg-style', sourceId, options.minZoom, options.maxZoom);
  let selectedOutlineStyle = get2xSelectedOutlineStyle('2x-selected-outline-style', sourceId, options.minZoom, options.maxZoom);
  let selectedBgSiteStyle = get2xSelectedBgSiteStyle('2x-selected-outline-bg-site-style', sourceId, options.minZoom, options.maxZoom);
  let selectedOutlineSiteStyle = get2xSelectedOutlineSiteStyle('2x-selected-outline-site-style', sourceId, options.minZoom, options.maxZoom);
  let selectedBgSiteOffStyle = get2xSelectedBgOffStyle('2x-selected-outline-bg-site-off-style', sourceId, options.minZoom, options.maxZoom);
  let selectedOutlineSiteOffStyle = get2xSelectedOutlineOffStyle('2x-selected-outline-site-off-style', sourceId, options.minZoom, options.maxZoom);
  let bgStyleSite = get2xBgStyleSite('2x-bg-style-site', sourceId, options.minZoom, options.maxZoom);

  return [bgStyle, get2xTurnedOnStyle, selectedOutlineStyle, bgSelectedStyle, selectedOutlineSiteStyle, selectedBgSiteStyle, selectedOutlineSiteOffStyle, selectedBgSiteOffStyle, bgStyleSite];
}

function applyClusteredStyles(sourceId, options = defaultOptions) {
  let bgStyle = get2xBgClusteredStyle('2x-bg-clustered-style', sourceId, options.minZoom, options.maxZoom);
  let get2xTurnedOnStyle = get2xTurnedOnBgClusteredStyle('2x-turned-on-one-clustered-style', sourceId, options.minZoom, options.maxZoom);
  let bgSelectedStyle = get2xSelectedBgClusteredStyle('2x-selected-bg-clustered-style', sourceId, options.minZoom, options.maxZoom);
  let selectedOutlineStyle = get2xSelectedOutlineClusteredStyle('2x-selected-outline-clustered-style', sourceId, options.minZoom, options.maxZoom);
  let selectedBgSiteStyle = get2xSelectedBgSiteClusteredStyle('2x-selected-outline-bg-site-clustered-style', sourceId, options.minZoom, options.maxZoom);
  let selectedOutlineSiteStyle = get2xSelectedOutlineSiteClusteredStyle('2x-selected-outline-site-clustered-style', sourceId, options.minZoom, options.maxZoom);
  let selectedBgSiteOffStyle = get2xSelectedBgOffClusteredStyle('2x-selected-outline-bg-site-off-clustered-style', sourceId, options.minZoom, options.maxZoom);
  let selectedOutlineSiteOffStyle = get2xSelectedOutlineOffClusteredStyle('2x-selected-outline-site-off-clustered-style', sourceId, options.minZoom, options.maxZoom);
  let bgStyleSite = get2xBgClusteredStyleSite('2x-bg-style-clustered-site', sourceId, options.minZoom, options.maxZoom);

  return [bgStyle, get2xTurnedOnStyle, selectedOutlineStyle, bgSelectedStyle, selectedOutlineSiteStyle, selectedBgSiteStyle, selectedOutlineSiteOffStyle, selectedBgSiteOffStyle, bgStyleSite];
}

function applyGroupStyles(sourceId, options = defaultOptions) {
  let bgStyle = getGroup2xBgStyle('2x-bg-group-style', sourceId, options.minZoom, options.maxZoom);
  let selectedOutlineStyle = getGroup2xSelectedOutlineSiteStyle('2x-selected-outline-group-style', sourceId, options.minZoom, options.maxZoom);
  let bgSelectedStyle = getGroup2xSelectedBgSiteStyle('2x-selected-bg-group-style', sourceId, options.minZoom, options.maxZoom);

  return [bgStyle, selectedOutlineStyle, bgSelectedStyle];
}

export function applyCountStyles(sourceId, options = defaultOptions) {
  let fgCountColor = get2xFgCountStyleSite('2x-fg-count-style', sourceId, options.minZoom, options.maxZoom);

  return [fgCountColor];
}

export function getSourceLayer(source, sourceId, options = defaultOptions) {
  return {
    source: { id: sourceId, sourceObj: source },
    styles: applyStyles(sourceId, options)
  };
}

export function getSourceCountLayer(source, sourceId, options = defaultOptions) {
  return {
    source: { id: sourceId, sourceObj: source },
    styles: applyCountStyles(sourceId, options)
  };
}

export function getClusteredSourceLayer(source, sourceId, options = defaultOptions) {
  return {
    source: { id: sourceId, sourceObj: source },
    styles: applyClusteredStyles(sourceId, options)
  };
}


export function getGroupSourceLayer(source, sourceId, options = defaultOptions) {
  return {
    source: { id: sourceId, sourceObj: source },
    styles: applyGroupStyles(sourceId, options)
  };
}
