import 'isomorphic-fetch';
import { Cookies } from 'react-cookie';
import {getApiToken} from "../../utils/user";
const cookies = new Cookies();

//for getting the list of devices from database
export async function getDeviceList(url) {
  let response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    }
  });
  let status = response.status;
  let data = status >= 400 ? null : await response.json();
  return data;
}

export async function getSiteSuggestionList(url) {
  let response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    }
  });
  let status = response.status;

  let data = status >= 400 ? null : await response.json();
  return data;
}
