import React from 'react';
import Icon from 'react-fa';
import PropTypes from 'prop-types';
import { css } from 'emotion';

export default class Button extends React.Component {

  static propTypes = {
    buttonContainerStyle: PropTypes.object,
    buttonStyle: PropTypes.object,
    buttonTextStyle: PropTypes.string,
    buttonClickCallback: () => {},
    btnClassName: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.string,
    iconStyle: PropTypes.object,
    disabled: PropTypes.bool,
    style: PropTypes.object
  };

  static defaultProps = { };

  render() {
    const {
      buttonContainerStyle,
      buttonStyle,
      btnClassName,
      text,
      icon,
      iconStyle,
      disabled,
      style
     } = this.props;

    return (
      <div style={buttonContainerStyle}>
        <button
          disabled = {disabled} //added for disabling the login button in case of no cookie acceptance
          className={defaultButtonClass + ' ' + btnClassName + ' ' + style + (disabled ? ' ' + disabledStyle : ' ')}
          onClick={this._buttonClick}
          style={buttonStyle}
          >
          {text ?
            <span className={spanClass + ' ' + this.props.buttonTextStyle} >{text}</span>
            : icon ?
            <Icon name={icon} className="icon" style={iconStyle}/>
          : null}
        </button>
      </div>
    );
  }

  _buttonClick = () => {
    this.props.buttonClickCallback();
  }
}

const defaultButtonClass = css({
  backgroundColor: '#0151cb',
  border: 'solid 2px #0151cb',
  fontFamily: 'OpenSans-Bold',
  color: '#000000',
  position: 'relative',
  margin: 0,
  minWidth: '64px',
  padding: '12px 35px 13px 35px',
  display: 'inline-block',
  overflow: 'hidden',
  willChange: 'box-shadow',
  transition: 'box-shadow 0.15s cubic-bezier(0.4, 0, 1, 1),background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1),color 0.15s cubic-bezier(0.4, 0, 0.2, 1)',
  outline: 'none',
  cursor: 'pointer',
  textDecoration: 'none',
  textAlign: 'center',
  verticalAlign: 'middle',
  //borderRadius: '2px',
  textTransform: 'uppercase',
  '&:hover': {
    backgroundColor: '#0647a6',
    //border: 'solid 2px #0647a6'
  },
  '&:active': {
    backgroundColor: '#0647a6',
    //border: 'solid 2px #0647a6'
  },
  '&:focus': {
    backgroundColor: '#0151cb',
    //border: 'solid 2px #0647a6'
  }
});

const disabledStyle = css({
  backgroundColor: '#b2d4ff',
  border: 'solid 2px #b2d4ff',
  '&:hover': {
    backgroundColor: '#b2d4ff',
    border: 'solid 2px #b2d4ff',
  },
  '&:active': {
    backgroundColor: '#b2d4ff',
    border: 'solid 2px #b2d4ff',
  },
  '&:focus': {
    backgroundColor: '#b2d4ff',
    border: 'solid 2px #b2d4ff',
  }
})

const spanClass = css({
  color: '#FFFFFF',
  fontSize: '14px',
  letterSpacing: '1px',
  lineHeight: '21px',
});
