import React, { Component } from 'react';
import { SplitButton } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import Messages from '../constants/messages.js';
import { MODETYPE } from '../../modules/inventory';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import Allocate from "../site/allocate";
import {allocateDeviceInDb, allocateSiteInDb} from "../../modules/site";
import {toast} from "react-toastify";
import {allocateSite} from "../api/site";
import LampControls from "../lamp-controls";
import AssignSchedule from "../scheduling/assign-schedule";

export default class InventoryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDeleteModal: false,
      selectedSiteId : '',
      selectedDeviceId : '',
      allocateDeviceModal: false,
      controlsModal: false,
      openScheduleModal: false
    };
  }

  cellButton = (cell, row) => {
    //<div title="View" className="action-items" onClick={() => this.onViewClicked(cell, row)}><i className="fa fa-edit action-items" aria-hidden="true"></i> View </div>
    //           <div title="Edit" className="action-items" onClick={() => this.onEditClicked(cell, row)}><i className="fa fa-edit action-items" aria-hidden="true"></i> Edit </div>
    //
    return (
        <div>
          <SplitButton drop="down" title="ACTION" style={{float: 'left'}}>
            <div className="actions-ddl" style={{height: 'auto'}}>
              <div title="Delete" className="action-items" onClick={() => this.onDeleteClicked(cell, row)}><i className="fa fa-trash action-items" aria-hidden="true"></i> Delete </div>
              <div title="Allocate Site" className="action-items" onClick={() => this.onAllocateClicked(cell, row)}><i className="fa fa-plus-circle action-items" aria-hidden="true"></i> Allocate site </div>
            </div>
          </SplitButton>&nbsp;
            <button onClick={() => this.onControlsClicked(cell, row)} style={{width: '100px', height: '37px', textAlign: 'center', color: '#fff', backgroundColor: '#0151cb',
                borderColor: '#0151cb'}}>CONTROLS</button>
        </div>
    );
  };

  cellSingleButton = (cell, row) => {
    return (
        <div>
          {row.device_id && (
              <button onClick={() => this.onControlsClicked(cell, row)}>CONTROLS</button>
          )}
        </div>
    );
  };

  onEditClicked(cell, row) {

  }

  onViewClicked(cell, row) {

  }

  onControlsClicked = (cell, row) => {
    let selectedDevice = row.device_id;
    let selectedSite = row.site_id;
    this.setState({ controlsModal: true, selectedDeviceId: selectedDevice, selectedSiteId: selectedSite});
  }

  onAllocateClicked = (cell, row) => {
    let selectedDevice = row.device_id;
    this.setState({ allocateDeviceModal: true, selectedDeviceId: selectedDevice});
  }

  enumFormatter(cell, row, enumObject) {
    return enumObject[cell];
  }

  renderGroupColor(cell, row) {
    return (
      <div style={{ backgroundColor: cell, width: '20px', height: '20px' }}></div>
    );
  }

  closeControlsModal = () => {
    this.setState({controlsModal: false});
  }

  onDeleteClicked = (cell, row) => {
    let selectedDevice = row.id;
    this.setState({ openDeleteModal: !this.state.openDeleteModal, selectedDeviceId : selectedDevice});
  }

  closeDeleteModal = () => {
    this.setState({ openDeleteModal: false });
  }

  deleteInventory = async () => {
  }

  renderInstallDate = (cell) => {
    let date = new Date(cell);
    let localDate = date.toLocaleDateString();
    return (
        <span>{localDate}</span>
    );
  }

  renderModeText = (cell) => {
    if(cell !== undefined && cell !== null && cell !== ""){
      return MODETYPE[cell];
    }else{
      return "";
    }
  }

  onCancelAllocateDevice = () => {
    this.props.onUpdate();
    this.setState({ allocateDeviceModal: false });
  }

  OnUpdate=()=>{
    this.props.onUpdate();
    this.setState({showNewSiteModal:false})
  }

  closeScheduleModal = () => {
    this.setState({ openScheduleModal: false })
  }

  getSuggestions = async value => {
    let {siteSuggestion} = this.props;
    let suggestionList = [];
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (inputLength > 4) {

      let suggestions = await siteSuggestion(inputValue);

      if (suggestions) {
        suggestionList = suggestions.map((item) => ({
          name: item.clients_name,
          value: item.site_id
        }));
      } else {
        suggestionList = []
      }
    } else {
      suggestionList = [];
    }

    return suggestionList;
  };

  allocate = async (siteId, longitude, latitude) => {

    let allocation = {
      site_id: siteId,
      device_id: this.state.selectedDeviceId,
      longitude: longitude,
      latitude: latitude
    };

    allocateSiteInDb(allocation).then((response) => {
      if (response) {
        toast.success(Messages.SiteAllocationSuccessText);
        setTimeout(() => {
          this.setState({showLoader: false});
          this.onCancelAllocateDevice();
        }, 2000);
      }
    });

    /*
    let response = await allocateSiteInDb(allocation);
    if (response !== undefined && response !== null) {
      this.props.onUpdate();
      toast.success(Messages.CreateSiteSuccessText);
      setTimeout(() => {
        this.setState({showLoader: false});
        this.onCancelAllocateDevice();
      }, 2000);
    }
     */
  }

  render() {
    const { data } = this.props;
    const { columns } = this.props;

    //for column filtering
    let showSiteId = "column-hide", showDeviceId = "column-hide", showSiteName = "column-hide", showMac = "column-hide", showInstallDate = "column-hide", showMode = "column-hide", showSiteStatus = "column-hide";
    for (let i = 0; i < columns.length; i++) {
      let keyVal = columns[i].key;
      switch (keyVal) {
        case "site_id":
          showSiteId = "column-show";
          break;
        case "site_name":
          showSiteName = "column-show";
          break;
        case "mac_address":
          showMac = "column-show";
          break;
        case "installation_date":
          showInstallDate = "column-show";
          break;
        case "mode":
          showMode = "column-show";
          break;
        case "site_status":
          showSiteStatus = "column-show";
          break;
        default:
          break;
      }
    }

    //for resizing functionality
    const { rowType } = this.props;
    let rowDisplayClass = "inventory-thin-row-size";
    if (rowType === 'thick') {
      rowDisplayClass = "inventory-thick-row-size";
    } else if (rowType === 'medium') {
      rowDisplayClass = "inventory-medium-row-size";
    } else if (rowType === 'thin') {
      rowDisplayClass = "inventory-thin-row-size";
    }

    const dataColumns = [{
      dataField: 'site_id',
      text: 'SITE ID',
      sort: true,
      classes: showSiteId,
      headerClasses: showSiteId
    }, {
      dataField: 'site_name',
      text: 'SITE NAME',
      sort: true,
      classes: showSiteName,
      headerClasses: showSiteName,
      filter: textFilter()
    }, {
      dataField: 'mac_address',
      text: 'MAC',
      sort: true,
      classes: showMac,
      headerClasses: showMac
    },
     {
      dataField: 'installation_date',
      text: 'INSTALL DATE',
      sort: true,
      classes: showInstallDate,
      headerClasses: showInstallDate,
      formatter : this.renderInstallDate
    },
     {
      dataField: 'mode',
      text: 'MODE',
      sort: true,
      classes: showMode,
      headerClasses: showMode,
      formatter : this.renderModeText
    }, {
      dataField: 'site_status',
      text: 'SITE STATUS',
      sort: true,
      classes: showSiteStatus,
      headerClasses: showSiteStatus
    }, {
        dataField: 'button',
        text: 'ACTIONS',
        formatter: this.cellButton
      }];

    const defaultSorted = [{
      dataField: 'site_name',
      order: 'asc'
    }];

    let tableClasses = "table-container fixed-table-footer";
    tableClasses = tableClasses.trim();

    return (
      <div className={tableClasses}>
        <BootstrapTable wrapperClasses="table-common table-inventory-mob" rowClasses={rowDisplayClass} keyField='site_id' data={data} columns={dataColumns} defaultSorted={defaultSorted} pagination={paginationFactory()}  filter={filterFactory()}  />
        <div id="ControlsModalDiv">
          <Modal classNames={{ modal: "controls-modal" }} open={this.state.controlsModal} onClose={this.closeControlsModal} center closeOnOverlayClick={false}>
            <div>
              <LampControls id={this.state.selectedDeviceId} siteId={this.state.selectedSiteId} openAssignModal={() => this.setState({openScheduleModal: true})} closeAssignModal={this.closeScheduleModal} useGroup={false}></LampControls>
            </div>
          </Modal>
        </div>
        <div id="deleteModalDiv">
          <Modal classNames={{ modal: "delete-modal-dialog-div" }} open={this.state.openDeleteModal} onClose={this.closeDeleteModal} center closeOnOverlayClick={false}>
            <div>
              <h4>Delete this inventory ? </h4>
              <p className="delete-p-text">{Messages.DeleteInventoryText}</p>
              <div className="delete-buttons-div">
                <button onClick={this.deleteInventory} className="delete-confirm-button">DELETE INVENTORY</button>
                <button onClick={this.closeDeleteModal} className="confirmation-cancel-btn">CANCEL</button>
              </div>
            </div>
          </Modal>
        </div>
        <div id="allocateDevice">
          <Modal classNames={{ modal: "create-site-modal" }} open={this.state.allocateDeviceModal} onClose={this.onCancelAllocateDevice} right closeOnOverlayClick={false}>
            <Allocate
                allocateDevice={false}
                onCancel={this.onCancelAllocateDevice}
                onUpdate={this.OnUpdate}
                getSuggestions={this.getSuggestions}
                submit={this.allocate}
                deviceId={this.state.selectedDeviceId}
            />
          </Modal>
        </div>
        <div id="assignScheduleModalDiv">
          <Modal classNames={{ modal: "assign-schedule-modal-dialog" }} open={this.state.openScheduleModal} onClose={this.closeScheduleModal} center closeOnOverlayClick={false}>
            <div>
              <AssignSchedule siteId={this.state.selectedSiteId} closeAssignModal={this.closeScheduleModal}/>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}
