import React, { Component } from 'react';
import { css } from 'emotion';
import radio from '../../assets/radio.svg';
import radioChecked from '../../assets/radio-checked.svg';

class RadioBox extends Component {

  handleChange(event) {
    this.props.onSelect(event);
  }

  render() {
    let name = this.props.name ? this.props.name : 'radio-group';
    return (
      <div className={container}>
        <input id={this.props.id} type="radio"
          onChange={(e)=>this.handleChange(e)}
          value={this.props.id}
          name={name}
          />
        <label htmlFor={this.props.id}>{this.props.label}</label>
      </div>
    );
  }
}

const container = css({
  fontFamily: 'OpenSans',
  fontSize: '14px',
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  color: '#112138',
  '& label':{
    paddingLeft: '8px'
  },
  '& input[type="radio"]':{
    opacity: 0,
    float: 'left',
    width: '0px',
  },
  '& input[type="radio"] + label':{
    margin: 0,
    clear: 'none',
    padding: '5px 0 4px 26px',
    cursor: 'pointer',
    background: `url(${radio}) left center no-repeat`
  },
  '& input[type="radio"]:checked + label':{
    margin: 0,
    clear: 'none',
    padding: '5px 0 4px 26px',
    cursor: 'pointer',
    background: `url(${radioChecked}) left center no-repeat`
  }
});

export default (RadioBox);
