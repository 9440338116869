import React, { Component } from 'react';
import { css } from 'emotion';

class NotificationModal extends Component {
  render() {
    return (
      <div className={modalClass}>
        <section className={modalMain}>
          {this.props.children}
        </section>
      </div>
    );
  }
}

const modalMain = css({
  position: 'fixed',
  background: 'white',
  width: '404px',
  minHeight: '223px',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  borderRadius: '4px'
});

const modalClass = css({
  position: 'absolute',
  zIndex: 1,
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.6)'
});

export default NotificationModal;
