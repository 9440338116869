import React, { Component } from 'react';
import { SplitButton } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import AssignSchedule from '../scheduling/assign-schedule';
import CreateSchedule from '../scheduling/create-schedule';
import Confirmation from '../confirmation/confirmation.js';
import Messages from '../constants/messages.js';
import { deleteScheduleFromDb } from '../../modules/scheduling';
import { toast } from 'react-toastify';
import AppLoader from '../Loader/loader';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

export default class SchedulingTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showGroupData: false,
      selectedSchedule: [],
      openDeleteModal: false,
      setScheduleModal: false,
      editScheduleModal: false,
      showCloseConfirmation: false,
      selectedScheduleId: '',
      showLoader: false
    };
  }

  cellButton = (cell, row) => {
    return (
      <div>
        <SplitButton drop="down" title="ACTIONS">
          <div className="scheduling-actions">
            <div title="Edit" className="action-items" onClick={() => this.onEditClicked(cell, row)}><i className="fa fa-edit action-items" aria-hidden="true"></i> Edit </div>
            {/* <div title="Assign Schedule" className="action-items" onClick={() => this.onSetScheduleClicked(cell, row)}><i className="fa fa-calendar-check-o action-items" aria-hidden="true"></i> Assign schedule </div> */}
            <div title="Delete" className="action-items" onClick={() => this.onDeleteClicked(cell, row)}><i className="fa fa-trash action-items" aria-hidden="true"></i> Delete </div>
          </div>
        </SplitButton>
      </div>
    );
  };


  onEditClicked = (cell, row) => {
    let selectedSchedule = row.schedule_id;
    this.setState({ editScheduleModal: true, selectedSchedule: row, selectedScheduleId: selectedSchedule });
  }

  closeEditScheduleModal = () => {
    const {isDirty, isFormDirty} = this.props;
    this.setState({ showCloseConfirmation: isDirty });

    if (!isDirty)
      this.setState({editScheduleModal: false});
  }

  closeCloseConfirmation = () => {
    this.setState({ showCloseConfirmation: false });
  }

  onCloseConfirm = () => {
    const {isFormDirty} = this.props
    this.setState({ showCloseConfirmation: false , editScheduleModal: false}, () => {
      //window.location.reload(false);
      isFormDirty(false);
    });
  }

  onDeleteClicked(cell, row) {
    let selectedSchedule = row.schedule_id;
    this.setState({ openDeleteModal: !this.state.openDeleteModal, selectedScheduleId: selectedSchedule });
  }

  onSetScheduleClicked = (cell, row) => {
    this.setState({ setScheduleModal: true });
  }

  closeScheduleModal = () => {
    this.setState({ setScheduleModal: false })
  }

  enumFormatter(cell, row, enumObject) {
    return enumObject[cell];
  }

  openGroupModal = (e, column, columnIndex, row, rowIndex) => {
    this.setState({ showGroupData: true });
    this.setState({ selectedSchedule: row });
  }

  closeGroupModal = () => {
    this.setState({ showGroupData: false });
  }

  closeDeleteModal = () => {
    this.setState({ openDeleteModal: false });
  }

  //on delete confirm
  onDeleteSchedule = () => {
    this.setState({ showLoader: true }, () => {
      this.deleteSchedule();
    });
  }

  deleteSchedule = async () => {
    let response = await deleteScheduleFromDb(this.state.selectedScheduleId); //api call for deleting group details from database
    if (response !== undefined && response !== null) {
      this.props.createSchedule();
      this.setState({ openDeleteModal: false }, () => {
        toast.success(Messages.DeleteScheduleSuccessText);
        setTimeout(() => {
          this.setState({showLoader:false})
        }, 2000);
      });
    }
  }

   onScheduleUpdate=()=>{
     this.props.createSchedule();
     setTimeout(() => {
      this.setState({editScheduleModal:false});
    }, 2000);
   }

  render() {
    const { data } = this.props;
    const { columns } = this.props;
    const {isFormDirty, isDirty} = this.props;
    //for column filtering
    let showScheduleName = "column-hide", showScheduleId = "column-hide";
    for (let i = 0; i < columns.length; i++) {
      let keyVal = columns[i].key;
      switch (keyVal) {
        case "schedule_id":
          showScheduleId = "column-show";
          break;
        case "schedule_name":
          showScheduleName = "column-show";
          break;
        default:
          break
      }
    }

    //for resizing functionality
    const { rowType } = this.props;
    let rowDisplayClass = "schedule-thin-row-size";
    if (rowType === 'thick') {
      rowDisplayClass = "schedule-thick-row-size";
    } else if (rowType === 'medium') {
      rowDisplayClass = "schedule-medium-row-size";
    } else if (rowType === 'thin') {
      rowDisplayClass = "schedule-thin-row-size";
    }

    const dataColumns = [{
      dataField: 'id',
      text: 'ID',
      hidden: true,
      sort: true,
    }, {
      dataField: 'schedule_id',
      text: 'SCHEDULE ID',
      sort: true,
      classes: showScheduleId,
      headerClasses: showScheduleId
    }, {
      dataField: 'schedule_name',
      text: 'SCHEDULE NAME',
      sort: true,
      classes: showScheduleName,
      headerClasses: showScheduleName,
      filter: textFilter(),
      style:{'width': '60%' }
    }, {
      dataField: 'button',
      text: 'ACTIONS',
      formatter: this.cellButton
    }];

    const defaultSorted = [{
      dataField: 'schedule_id',
      order: 'desc'
    }];

    let tableClasses = "table-responsive table-container fixed-table-footer";
    return (
      <div className={tableClasses}>
        <BootstrapTable wrapperClasses="table-common table-schedule-mob" rowClasses={rowDisplayClass} keyField='schedule_id' data={data} columns={dataColumns}  pagination={paginationFactory()}  filter={filterFactory()}  />
        <div id="deleteModalDiv">
          <Modal classNames={{ modal: "delete-modal-dialog-div" }} open={this.state.openDeleteModal} onClose={this.closeDeleteModal} center closeOnOverlayClick={false}>
            <div>
              <h4>Delete this schedule ? </h4>
              <p className="delete-p-text">{Messages.DeleteScheduleText}</p>
              <div className="delete-buttons-div">
                <button onClick={this.onDeleteSchedule} className="delete-confirm-button">DELETE SCHEDULE</button>
                <button onClick={this.closeDeleteModal} className="confirmation-cancel-btn">CANCEL</button>
              </div>
            </div>
          </Modal>
        </div>

        <div id="setScheduleModalDiv">
          <Modal classNames={{ modal: "assign-schedule-modal-dialog" }} open={this.state.setScheduleModal} onClose={this.closeScheduleModal} center closeOnOverlayClick={false}>
            <div>
              <AssignSchedule></AssignSchedule>
            </div>
          </Modal>
        </div>

        <div id="editScheduleModalDiv">
          <Modal classNames={{ modal: "edit-schedule-modal" }} open={this.state.editScheduleModal} onClose={this.closeEditScheduleModal} center closeOnOverlayClick={false}>
            <div>
              <CreateSchedule selectedScheduleId={this.state.selectedScheduleId} UpdateSchedule={this.onScheduleUpdate} isFormDirty={isFormDirty} isDirty={isDirty} ></CreateSchedule>
            </div>
          </Modal>
        </div>
        {
          this.state.showCloseConfirmation &&
          (
            <Confirmation confirmationText={Messages.ScheduleCloseConfirmation} confirmButtonText="Confirm" rejectButtonText="Cancel" openConfirmation={true} closeConfirmationModal={this.closeCloseConfirmation} onConfirmClicked={this.onCloseConfirm}></Confirmation>
          )
        }
        {
          this.state.showLoader &&
          (
            <div className="overlay">
              <div className="application-loader">
                <AppLoader />
              </div>
            </div>
          )
        }
      </div>
    );
  }
}
