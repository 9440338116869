import React, { Component } from 'react';
import GroupTable from '../group/group-table';
import RowResizer from '../group/row-resizer';
import Tabs from '../tabs/tabs.js';
import ColumnEditor from '../group/column-editor';
import { tabs } from '../constants/tabs';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { css } from 'emotion';
import { checkLoginTimeout, clearLoginTimeout } from '../../modules/user.js';
import { showColumns } from '../../modules/grouping';
import Button from '../button/button.js';
import Modal from 'react-responsive-modal';
import CreateGroup from '../group/create-group';
import Confirmation from '../confirmation/confirmation.js';
import Messages from '../constants/messages.js';
import AppLoader from '../Loader/loader';

import { getGroupListFromDb, setGroupListInStore } from '../../modules/grouping';

class Grouping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setScheduleModal: false,
      createGroupModal: false,
      showCloseConfirmation: false,
      shown: false,
      tableData: [],
      showLoader: true,
      isFormDirty: false
    };
  }

  componentDidMount() {
    this.getGroupList();
  }

  showMenuOnMobile = () => {
    this.setState({ shown: !this.state.shown });
  }

  getGroupList = async () => {
    let data = await getGroupListFromDb(); //api call for getting the group list from database
    if(data !== null && data !== undefined){
      this.setState({ tableData: data, showLoader : false });
    }else{
      this.setState({ tableData: [] });
    }
    this.props.setGroupListInStore(); //called for setting the values redux store
  }

  componentWillMount() {
    this.props.checkLoginTimeout(this.props.user);
  }

  componentWillUnmount() {
    this.props.clearLoginTimeout(this.props.user);
  }

  onEditColumns = (id, show) => {
    this.props.showColumns(id, show);
  }

  createNewGroup = () => {
    this.setState({ createGroupModal: true });
  }

  onRowSizeChanged = (type) => {
    this.setState({
      rowType: type
    });
  }

  setScheduleClose = () => {
    this.setState({ setScheduleModal: false });
  }

  closeCreateGroupModal = () => {
    this.setState({ showCloseConfirmation: this.state.isFormDirty });

    if (!this.state.isFormDirty)
      this.setState({createGroupModal: false});
  }

  closeCloseConfirmation = () => {
    this.setState({ showCloseConfirmation: false, isFormDirty: false });
  }

  onCloseConfirm = () => {
    this.setState({ showCloseConfirmation: false , createGroupModal: false}, () => {
     // window.location.reload(false);
    });

    this.setFormDirty(false);
  }

  onAddUpdateData =()=>{
    this.getGroupList();
  }

  setFormDirty = (isDirty) => {
    this.setState({isFormDirty: isDirty})
  }

  render() {
    const {
      columns
    } = this.props.grouping;
    return (
      <div className="grouping customTab">
        <div className="desktopViewForTabs">
          <Tabs tabs={tabs} activeTab="group" />
        </div>
        <div className="mobileViewForTabs">
          <div onClick={() => this.showMenuOnMobile()}>
            <i className="fa fa-bars mobile-menu-icon" aria-hidden="true"></i>
          </div>
          <div className={this.state.shown ? "show" : "hide"}>
            <Tabs tabs={tabs} activeTab="group" />
          </div>
        </div>

        {/* <Tabs tabs={tabs} activeTab="group" /> */}
        <div>
          <div className={container}>
            <div className="toprow">
              <div className={lhs}>
                <ColumnEditor onEdit={this.onEditColumns} columns={columns} />
              </div>
              <div className="midbox"></div>
              <div className={rhs}>
                <Button
                  buttonTextStyle={btnStyle}
                  text="CREATE NEW GROUP"
                  buttonClickCallback={this.createNewGroup}
                  btnClassName="create-new-schedule-btn"
                />
              </div>
            </div>
            <div className={row}>
              <div className="table-responsive">
                <GroupTable
                  columns={columns.filter(col => col.isRequired)}
                  data={this.state.tableData}
                  rowType={this.state.rowType}
                  onUpdate={this.onAddUpdateData}
                  isFormDirty={this.setFormDirty}
                  isDirty={this.state.isFormDirty}
                />
              </div>
            </div>
            <div className={rowResizerStyle}>
              <RowResizer onRowSizeChanged={this.onRowSizeChanged} />
            </div>
          </div>

          <div id="createGroupModalDiv">
            <Modal classNames={{ modal: "create-group-modal" }} open={this.state.createGroupModal} onClose={this.closeCreateGroupModal} center closeOnOverlayClick={false}>
              <div>
                <CreateGroup onCreate={this.onAddUpdateData} isFormDirty={this.setFormDirty} isDirty={this.state.isFormDirty} />
              </div>
            </Modal>
          </div>
          {
            this.state.showCloseConfirmation &&
            (
              <Confirmation confirmationText={Messages.GroupCloseConfirmation} confirmButtonText="Confirm" rejectButtonText="Cancel" openConfirmation={true} closeConfirmationModal={this.closeCloseConfirmation} onConfirmClicked={this.onCloseConfirm} />
            )
          }
        </div>
        {
          this.state.showLoader &&
          (
            <div className="overlay">
              <div className="application-loader">
                <AppLoader />
              </div>
            </div>
          )
        }
      </div>
    );
  }
}

const rhs = css({});

const btnStyle = css({
  fontFamily: 'OpenSans',
  fontSize: '13px',
  fontWeight: 'bold',
  fontStyle: 'normal',
  fontStretch: 'normal',
  letterSpacing: '1px'
});

const container = css({
  display: 'flex',
  flexDirection: 'column',
  padding: '10px'
});

const lhs = css({
  alignSelf: 'center',
});

const row = css({
  display: 'flex',
});

const rowResizerStyle = css({
  display: 'flex',
  marginLeft: '5px'
});

const mapStateToProps = state => ({
  grouping: state.grouping,
  user: state.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      checkLoginTimeout,
      clearLoginTimeout,
      showColumns,
      getGroupListFromDb,
      setGroupListInStore
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Grouping);
