import 'isomorphic-fetch';
import { Cookies } from 'react-cookie';
import {getApiToken} from "../../utils/user";
const cookies = new Cookies();

//for getting the list of groups from database
export async function getGroupList(url) {
  //alert(cookies.get('apitoken'));
  let response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    }
  });
  let status = response.status;
  let data = status >= 400 ? null : await response.json();
  return data;
}

//for deleting the group details from group id
export async function deleteGroupData(url) {
  let response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    }
  });
  let status = response.status;
  let data = status >= 400 ? null : await response;
  return data;
}

//for creating a group entry in database
export async function createGroup(url, groupName) {
  let response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    },
    body: JSON.stringify({
      group_name: groupName,
    })
  });
  let status = response.status;
  let data = status >= 400 ? null : await response;
  return data;
}

//for updating a group in database
export async function updateGroup(url, groupToUpdate) {
  let response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    },
    body: JSON.stringify({
      group_name : groupToUpdate.groupName,
    })
  });
  let status = response.status;
  let data = status >= 400 ? null : await response;
  return data;
}

//for getting the group details from group id
export async function getGroupData(url) {
  let response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    }
  });
  let status = response.status;
  let data = status >= 400 ? null : await response.json();
  return data;
}

//for attaching devices to a group
export async function assignDevices(url, devicesToAttach) {
  let response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    },
    body: JSON.stringify(devicesToAttach.deviceList)
  });
  let status = response.status;
  let data = status >= 400 ? null : await response;
  return data;
}

//for deleting the devices/sites from group
export async function deleteDevicesfromGroup(url) {
  let response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    }
  });
  let status = response.status;
  let data = status >= 400 ? null : await response;
  return data;
}

//for adding group mask to devices/sites
export async function addSubgroups(url, subgroupToAdd) {
  let response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    },
    body: JSON.stringify({
      group_id : subgroupToAdd.groupId,
      group_mask : subgroupToAdd.groupMask
    })
  });
  let status = response.status;
  let data = status >= 400 ? null : await response;
  return data;
}

//for getting devices list inside group from database
export async function getDevicesInsideGroup(url) {
  let response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    }
  });
  let status = response.status;
  let data = status >= 400 ? null : await response.json();
  return data;
}

//for assigning schedule to a particular group
export async function AssignSchedule(url, scheduleToAssign) {
  let response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    },
    body: JSON.stringify({
      "group_name" : scheduleToAssign.group_name,
      "schedule_id": Number(scheduleToAssign.schedule_id)
    })
  });
  let status = response.status;
  let data = status >= 400 ? null : await response;
  return data;
}

//AssignSchedule
