import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import infoIcon from '../../assets/info.svg';
import classNames from 'classnames';

export default class InfoBox extends React.Component {

    render() {
      const icon = this.props.icon ? this.props.icon : infoIcon;
      const infoBoxClass = classNames({
        [`${this.props.className}`]: this.props.className?true:false,
        [`${infoBox}`]: true
      });
      return (
        <div className={infoBoxClass}>
          <img src={icon} alt='i' className={infoiconClass} />
          <p className={infoText}>{this.props.children}</p>
        </div>
      );
    }
}

InfoBox.propTypes = {
  children: PropTypes.any
};

const infoBox = css({
  display: 'flex',
  flexDirection: 'row',
  borderRadius: '2px',
  backgroundColor: '#ffffff',
  boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.18)',
});

const infoiconClass = css({
  float: 'left',
  flexGrow: '1',
  padding: '0 5px 0 10px'
});

const infoText = css({
  fontFamily: 'OpenSans',
  fontSize: '14px',
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  color: '#112138',
  flexGrow: '1',
  alignSelf: 'center',
  padding: '0 10px 0 5px'
});
