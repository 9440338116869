export const LOG_APP_VERSION = 'LOG_APP_VERSION';

const initialState = {
  version: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOG_APP_VERSION:
      let version = action.payload.version;
      return {
        ...state,
        version
      };
    default:
      return state;
  }
};

export function logAppVersion(version) {
  return dispatch => {
    dispatch({
      type: LOG_APP_VERSION,
      payload: {
        version
      }
    });
  };
}
