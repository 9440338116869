import {
  get3xBgStyle,
  get3xSelectedBgStyle,
  get3xTurnedOnBgStyle,
  get3xSelectedOutlineStyle,
  get3xSelectedBgSiteStyle,
  get3xSelectedOutlineSiteStyle,
  get3xSelectedBgOffStyle,
  get3xSelectedOutlineOffStyle, get3xBgStyleSite
} from '../styles/3x-styles';
import {
  get3xBgClusteredStyle, get3xBgClusteredStyleSite, get3xFgCountStyleSite,
  get3xSelectedBgClusteredStyle, get3xSelectedBgOffClusteredStyle,
  get3xSelectedBgSiteClusteredStyle,
  get3xSelectedOutlineClusteredStyle, get3xSelectedOutlineOffClusteredStyle,
  get3xSelectedOutlineSiteClusteredStyle,
  get3xTurnedOnBgClusteredStyle
} from "../styles/3x-styles-clustered";
import {
  getGroup3xBgStyle,
  getGroup3xSelectedBgSiteStyle,
  getGroup3xSelectedOutlineSiteStyle
} from "../styles/3x-styles-group";

const defaultOptions = {
  minZoom: 16,
  maxZoom: 25
};

export function getSource(features) {
  return {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: features
    },
    cluster: true,
    clusterMaxZoom: 22, // Max zoom to cluster points on
    clusterRadius: 50,
  };
}

function applyStyles(sourceId, options = defaultOptions) {
  let bgStyle = get3xBgStyle('3x-bg-style', sourceId, options.minZoom, options.maxZoom);
  let get3xTurnedOnStyle = get3xTurnedOnBgStyle('3x-turned-on-one-style', sourceId, options.minZoom, options.maxZoom);
  let bgSelectedStyle = get3xSelectedBgStyle('3x-selected-bg-style', sourceId, options.minZoom, options.maxZoom);
  let selectedOutlineStyle = get3xSelectedOutlineStyle('3x-selected-outline-style', sourceId, options.minZoom, options.maxZoom);
  let selectedBgSiteStyle = get3xSelectedBgSiteStyle('3x-selected-outline-bg-site-style', sourceId, options.minZoom, options.maxZoom);
  let selectedOutlineSiteStyle = get3xSelectedOutlineSiteStyle('3x-selected-outline-site-style', sourceId, options.minZoom, options.maxZoom);
  let selectedBgSiteOffStyle = get3xSelectedBgOffStyle('3x-selected-outline-bg-site-off-style', sourceId, options.minZoom, options.maxZoom);
  let selectedOutlineSiteOffStyle = get3xSelectedOutlineOffStyle('3x-selected-outline-site-off-style', sourceId, options.minZoom, options.maxZoom);
  let bgStyleSite = get3xBgStyleSite('3x-bg-style-site', sourceId, options.minZoom, options.maxZoom);

  return [bgStyle, get3xTurnedOnStyle, selectedOutlineStyle, bgSelectedStyle, selectedOutlineSiteStyle, selectedBgSiteStyle, selectedOutlineSiteOffStyle, selectedBgSiteOffStyle, bgStyleSite];
}

function applyClusteredStyles(sourceId, options = defaultOptions) {
  let bgStyle = get3xBgClusteredStyle('3x-bg-clustered-style', sourceId, options.minZoom, options.maxZoom);
  let get3xTurnedOnStyle = get3xTurnedOnBgClusteredStyle('3x-turned-on-one-clustered-style', sourceId, options.minZoom, options.maxZoom);
  let bgSelectedStyle = get3xSelectedBgClusteredStyle('3x-selected-bg-clustered-style', sourceId, options.minZoom, options.maxZoom);
  let selectedOutlineStyle = get3xSelectedOutlineClusteredStyle('3x-selected-outline-clustered-style', sourceId, options.minZoom, options.maxZoom);
  let selectedBgSiteStyle = get3xSelectedBgSiteClusteredStyle('3x-selected-outline-bg-site-clustered-style', sourceId, options.minZoom, options.maxZoom);
  let selectedOutlineSiteStyle = get3xSelectedOutlineSiteClusteredStyle('3x-selected-outline-site-clustered-style', sourceId, options.minZoom, options.maxZoom);
  let selectedBgSiteOffStyle = get3xSelectedBgOffClusteredStyle('3x-selected-outline-bg-site-off-clustered-style', sourceId, options.minZoom, options.maxZoom);
  let selectedOutlineSiteOffStyle = get3xSelectedOutlineOffClusteredStyle('3x-selected-outline-site-off-clustered-style', sourceId, options.minZoom, options.maxZoom);
  let bgStyleSite = get3xBgClusteredStyleSite('3x-bg-style-clustered-site', sourceId, options.minZoom, options.maxZoom);

  return [bgStyle, get3xTurnedOnStyle, selectedOutlineStyle, bgSelectedStyle, selectedOutlineSiteStyle, selectedBgSiteStyle, selectedOutlineSiteOffStyle, selectedBgSiteOffStyle, bgStyleSite];
}

function applyGroupStyles(sourceId, options = defaultOptions) {
  let bgStyle = getGroup3xBgStyle('3x-bg-group-style', sourceId, options.minZoom, options.maxZoom);
  let selectedOutlineStyle = getGroup3xSelectedOutlineSiteStyle('3x-selected-outline-group-style', sourceId, options.minZoom, options.maxZoom);
  let bgSelectedStyle = getGroup3xSelectedBgSiteStyle('3x-selected-bg-group-style', sourceId, options.minZoom, options.maxZoom);

  return [bgStyle, selectedOutlineStyle, bgSelectedStyle];
}

export function applyCountStyles(sourceId, options = defaultOptions) {
  let fgCountColor = get3xFgCountStyleSite('3x-fg-count-style', sourceId, options.minZoom, options.maxZoom);

  return [fgCountColor];
}

export function getSourceLayer(source, sourceId, options = defaultOptions) {
  return {
    source: { id: sourceId, sourceObj: source },
    styles: applyStyles(sourceId, options)
  };
}

export function getSourceCountLayer(source, sourceId, options = defaultOptions) {
  return {
    source: { id: sourceId, sourceObj: source },
    styles: applyCountStyles(sourceId, options)
  };
}

export function getClusteredSourceLayer(source, sourceId, options = defaultOptions) {
  return {
    source: { id: sourceId, sourceObj: source },
    styles: applyClusteredStyles(sourceId, options)
  };
}


export function getGroupSourceLayer(source, sourceId, options = defaultOptions) {
  return {
    source: { id: sourceId, sourceObj: source },
    styles: applyGroupStyles(sourceId, options)
  };
}
