import React, { Component } from 'react';
import Button from '../button/button.js';
import { css } from 'emotion';
import Select from "react-select";
import { ISSUE, STATUS, PRIORITY, createIssueInDb, getIssueDetailsFromDb, updateIssueInDb } from '../../modules/issues';
import Messages from '../constants/messages.js';
import { toast } from 'react-toastify';
import AppLoader from '../Loader/loader';


export default class NewIssue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      siteId: '',
      device: '',
      location: '',
      priority: '',
      issueType: '',
      status: '',
      comment: '',
      errors: [],
      showLoader: false,
      editedIssueId: 0
    };
  }

  componentDidMount() {
    if (this.props.selectedIssueId !== undefined && this.props.selectedIssueId !== null) {
      this.setState({ editedIssueId: this.props.selectedIssueId, showLoader: true }, () => {
        this.getIssueDetailsById();
      });
    }
  }

  getIssueDetailsById = async () => {

    if (this.state.editedIssueId !== undefined && this.state.editedIssueId !== null && this.state.editedIssueId !== 0) {
      let response = await getIssueDetailsFromDb(this.state.editedIssueId);

      if (response !== undefined && response !== null) {
        let deviceLabel= this.props.deviceList.filter(l => {
         return l.key === response.site_id
        });

        this.setState({ siteId: response.site_id , device: deviceLabel[0],  comment: response.comments, priority: { key: response.priority, label: PRIORITY[response.priority] }, status: { key: response.status, label: STATUS[response.status] }, location: response.location, issueType: { key: response.issue_type, label: ISSUE[response.issue_type] }, showLoader: false })
      }
    }
  }

  onIssueTypeSelected = (issue) => {
    const {isFormDirty} = this.props;
    this.setState({ issueType: { key: issue.key, label: issue.label }, errors: [] });
    isFormDirty(true);
  }

  onPrioritySelected = (priority) => {
    const {isFormDirty} = this.props;
    this.setState({ priority: { key: priority.key, label: priority.label }, errors: [] });
    isFormDirty(true);
  }

  onDeviceSelected = (device) => {
    const {isFormDirty} = this.props;
    this.setState({ device: { key: device.key, label: device.label }, errors: [] });
    isFormDirty(true);
  }

  onStatusSelected = (status) => {
    const {isFormDirty} = this.props;
    this.setState({ status: { key: status.key, label: status.label }, errors: [] });
    isFormDirty(true);
  }

  handleValidation = () => {
    let isValid = true;
    let errors = [];
    let state = this.state;

    if(!state["device"]){
      errors["device"] = Messages.IssueSiteIdRequiredText;
      isValid = false;
    }
    if (!state["location"]) {
      errors["location"] = Messages.IssueLocationRequiredText;;
      isValid = false;
    }
    if (!state["priority"]) {
      errors["selectedPriority"] = Messages.IssuePriorityRequiredText;
      isValid = false;
    }
    if (!state["issueType"]) {
      errors["selectedIssueType"] = Messages.IssueTypeRequiredText;
      isValid = false;
    }
    if (!state["status"]) {
      errors["selectedStatus"] = Messages.IssueStatusRequiredText;
      isValid = false;
    }
    this.setState({ errors: errors });
    return isValid;
  }

  onSubmit = () => {
    const {isFormDirty} = this.props;
    if (this.handleValidation()) {
      this.setState({ showLoader: true }, () => {
        this.createIssue();
        isFormDirty(false);
      });
    }
  }

  createIssue = async () => {
    let {location, status, issueType, priority, comment , device} = this.state;
    let issueToAdd = {
      siteId: device.key,
      priority: priority.key,
      type: issueType.key,
      location: location,
      comments: comment,
      status: status.key
    };

    let response = await createIssueInDb(issueToAdd);
    if (response !== undefined && response !== null) {
      this.props.onAddIssue();
      toast.success(Messages.CreateIssueSuccessText);
      setTimeout(() => {
        this.setState({ showLoader:false});
      }, 2000);
    }
  }

  onUpdateClick = () => {
    const {isFormDirty} = this.props;
    if (this.handleValidation()) {
      this.setState({ showLoader: true }, () => {
        this.updateIssue();
        isFormDirty(false);
      });
    }
  }

  updateIssue = async () => {
    let { editedIssueId, siteId, location, status, issueType, priority, comment, device} = this.state;
    let issueToUpdate = {
      id: editedIssueId,
      siteId: device.key,
      priority: priority.key,
      type: issueType.key,
      location: location,
      comments: comment,
      status: status.key
    };

    let response = await updateIssueInDb(issueToUpdate);
    if (response !== undefined && response !== null) {
      this.props.onUpdate();
      toast.success(Messages.UpdateIssueSuccessText);
      setTimeout(() => {
        this.setState({showLoader:false});
      }, 2000);
    }
  }

  checkForSpace = (e) => {
    let errors = {};
    let formIsValid = true;
    e = e || window.event;
    if (e.charCode == 32 && e.target.value.length === 0) {
      errors["generalErrors"] = Messages.FirstCharacterAsSpaceErrorText;
      formIsValid = false;
    }
    if (!formIsValid) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  setSiteId = (e) => {
    let errors = [];
    if (e.target.value.length > 15) {
      errors["siteId"] = Messages.LargeSiteIdValue;
      this.setState({ errors: errors });
    } else if (Number(e.target.value) < 0) {
      errors["siteId"] = Messages.NegativeValue;
      this.setState({ errors: errors });
    } else if ((Number(e.target.value) - Math.floor(Number(e.target.value))) !== 0) {
      errors["siteId"] = Messages.DecimalValue;
      this.setState({ errors: errors });
    } else {
      this.setState({ siteId: e.target.value, errors: errors });
    }
  }


  render() {
    const {isFormDirty} = this.props;
    let createUpdateBtn = <Button buttonTextStyle={btnStyle} text="CREATE" buttonClickCallback={this.onSubmit} />;
    let title = "Create new issue";
    if (this.props.selectedIssueId !== undefined && this.props.selectedIssueId !== null) {
      title = "Edit Issue";
      createUpdateBtn = <Button buttonTextStyle={btnStyle} text="UPDATE" buttonClickCallback={this.onUpdateClick} />;
    }
    return (
      <div>
        <h4 className="class-bold">{title}</h4>
        <div className={table}>
          <div className={wrapper}>

            <div className={item}>
              <div className="class-bold">Site/Device<span className="important-fields">*</span></div>
              <div className="dropdown-select">
                <Select value={this.state.device} onChange={this.onDeviceSelected} options={this.props.deviceList}></Select>
              </div>
              <div className="application-error-messages">{this.state.errors["device"]}</div>
            </div>

            <div className={item}>
              <div className="class-bold">Location <span className="important-fields">*</span></div>
              <input className="text-box" value={this.state.location} onChange={(event) => {isFormDirty(true); this.setState({ location: event.target.value, errors: [] })}} type="text" maxLength="200" onKeyPress={(e) => this.checkForSpace(e)}></input>
              <div className="application-error-messages">{this.state.errors["location"]}</div>
            </div>

            <div className={item}>
              <div className="class-bold">Priority <span className="important-fields">*</span></div>
              <div className="dropdown-select">
                <Select value={this.state.priority} onChange={this.onPrioritySelected} options={this.props.priorityTypes}></Select>
              </div>
              <div className="application-error-messages">{this.state.errors["selectedPriority"]}</div>
            </div>

            <div className={item}>
              <div className="class-bold">Type <span className="important-fields">*</span></div>
              <div className="dropdown-select">
                <Select value={this.state.issueType} onChange={this.onIssueTypeSelected} options={this.props.issueType}></Select>
              </div>
              <div className="application-error-messages">{this.state.errors["selectedIssueType"]}</div>
            </div>

            <div className={item}>
              <div className="class-bold">Status <span className="important-fields">*</span></div>
              <div className="dropdown-select">
                <Select value={this.state.status} onChange={this.onStatusSelected} options={this.props.statusTypes}></Select>
              </div>
              <div className="application-error-messages">{this.state.errors["selectedStatus"]}</div>
            </div>

            <div className={rightItem}>
              <div className="class-bold">Comments </div>
              <textarea className={textAreaStyle} value={this.state.comment} onChange={(e) => {isFormDirty(true); this.setState({ comment: e.target.value, errors: [] }) }} placeholder={'Add Comments...'} onKeyPress={(e) => this.checkForSpace(e)} />
            </div>

          </div>
        </div>
        <div className="application-error-messages error-messages-center">{this.state.errors["generalErrors"]}</div>
        <div className={footer}>
          <div className={cancelButtonContainer} onClick={this.props.onCancel}>
            <div className={cancelButton}>
              <div>CANCEL</div>
            </div>
          </div>
          <div>
            {createUpdateBtn}
          </div>
        </div>
        {
          this.state.showLoader &&
          (
            <div className="overlay">
              <div className="application-loader">
                <AppLoader />
              </div>
            </div>
          )
        }
      </div>
    );
  }

}

const btnStyle = css({
  fontFamily: 'OpenSans',
  fontSize: '14px',
  fontWeight: 'bold',
  fontStyle: 'normal',
  fontStretch: 'normal',
  letterSpacing: '1px',
});

const footer = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  marginTop: '70px'
});


const table = css({
});

const wrapper = css({
  display: 'grid',
  gridTemplateColumns: '300px 300px',
  gridTemplateRows: '80px 80px 80px 80px',
  gridGap: '10px',
});

const item = css({
  padding: '20px 20px 5px 20px'
});

const rightItem = css({
  gridColumn: '2 / 2',
  gridRow: '2 / 5',
  padding: '28px 20px 15px 20px'
});

const textAreaStyle = css({
  height: '100%',
  width: '96%',
  borderRadius: '2px',
  resize: 'none',
  backgroundColor: '#ffffff',
  border: 'solid 1px #dbdbdb',
  '&:focus': {
    outline: 'none',
    border: '1.5px solid #0151cb'
  },
  boxSizing: 'border-box',
  padding: '10px'
});

const cancelButton = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
  borderRadius: '2px',
  backgroundColor: '#FFFFFF',
  width: '153px',
  height: '100%',
  fontFamily: 'OpenSans',
  fontSize: '14px',
  fontWeight: 'bold',
  fontStyle: 'normal',
  fontStretch: 'normal',
  letterSpacing: '1px',
  color: '#5e6c84',
  border: '1px solid #C4CDD5',
  '&:hover': {
    backgroundColor: '#F4F5F7',
  },
});

const cancelButtonContainer = css({
  display: 'flex',
  flexGrow: '0.1'
});
