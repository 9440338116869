import 'isomorphic-fetch';
import { Cookies } from 'react-cookie';
import {getApiToken} from "../../utils/user";
const cookies = new Cookies();

//for getting the list of issues from database
export async function getIssuesList(url) {
  let response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    }
  });
  let status = response.status;
  let data = status >= 400 ? null : await response.json();
  return data;
}

//for deleting the issue details from issue id
export async function deleteIssueData(url) {
  let response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    }
  });
  let status = response.status;
  let data = status >= 400 ? null : await response;
  return data;
}

//for creating new issue in database
export async function createIssue(url, issueToAdd) {
  let response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    },
    body: JSON.stringify({
      issue_type: issueToAdd.type,
      site_id: issueToAdd.siteId,
      priority: issueToAdd.priority,
      status: issueToAdd.status,
      location: issueToAdd.location,
      comments: issueToAdd.comments
    })
  });
  let status = response.status;
  let data = status >= 400 ? null : await response;
  return data;
}

//for getting issue details from database using issue id
export async function getIssueDetails(url) {
  let response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    }
  });
  let status = response.status;
  let data = status >= 400 ? null : await response.json();
  return data;
}


//for updating issue in database
export async function updateIssue(url, issueToUpdate) {
  let response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getApiToken()
    },
    body: JSON.stringify({
      issue_type: issueToUpdate.type,
      site_id: issueToUpdate.siteId,
      priority: issueToUpdate.priority,
      status: issueToUpdate.status,
      location: issueToUpdate.location,
      comments: issueToUpdate.comments
    })
  });

  let status = response.status;
  let data = status >= 400 ? null : await response;
  return data;

}
