import React, { Component } from 'react';
import Map from '../map/map';
import { css } from 'emotion';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { tabs } from '../constants/tabs';
import NotificationModal from '../modal/notification-modal';
import Tabs from '../tabs/tabs.js';
import InfoBox from '../infobox/infobox.js';
import Toggle from '../toggle/toggle.js';
import SideBar from '../sidebar/sidebar.js';
import BrightnessBar from '../brightness-bar/brightness-bar';
import InputSlider from '../inputslider/inputslider.js';
import BottomButtons from '../bottombuttons/bottombuttons.js';
import Button from '../button/button.js';
import crossIcon from '../../assets/cross.svg';
import inputCheckedSVG from '../../assets/input_checked.svg';
import { LAYER_1X, LAYER_2X, LAYER_3X, ON, OFF } from '../constants/map.js';
import {
  selectedDistricts,
  selectedBlocks,
  selectedLights,
  updateSelectedBrightness,
  updateSelectedSwitch,
  applyChanges,
  MODETYPE,
  MODELEVELS,
  DELIVERY,
  DEVICETYPES,
  updateGps,
  updateMode,
  updateThresholdAndLevels,
  toggleDeviceSiteDisplay,
  updateLayer
} from '../../modules/map.js';
import { checkLoginTimeout, clearLoginTimeout } from '../../modules/user.js';
import AssignSchedule from '../scheduling/assign-schedule';

import {
  getBrightnessRangeForDistricts,
  getBrightnessRangeForBlocks,
  getBrightnessRangeForLights,
  getLampSwitchStateForDistricts,
  getLampSwitchStateForBlocks,
  getLampSwitchStateForLights,
  getTotalLightsInDistricts,
  getTotalLightsInBlocks,
  getBrightnessBucketsForDistricts,
  getBrightnessBucketsForBlocks
} from '../../utils/utils.js';
import SingleSelectDropDown from '../single-select-dropdown/single-select-dropdown';
import Modal from 'react-responsive-modal';
import AppLoader from '../Loader/loader';
import { toast } from 'react-toastify';
import Messages from '../constants/messages.js';
import ReactSwitch from "react-switch";
import {removeUnderscoreCapitalize} from "../../utils/utils";
import Allocate from "../site/allocate";
import {allocateDeviceInDb, getDeviceCoordinatesInDb, updateSiteCoordinatesInDb} from "../../modules/site";
import MapLegend from "../maplegend/maplegend";
import Select from "react-select";
import chroma from 'chroma-js';
import {updateGroupGps, updateGroupMode, updateGroupThresholdAndLevels} from "../../modules/map";

class StreetLightPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showApplyChangesPrompt: false,
      buttonState: false,
      brightness: 0,
      toggleLampState: 0,
      totalLights: 0,
      brightnessBuckets: [],
      toggleGpsState: 0,
      modeType: '0',
      validModeType: true,
      modeLevel1: '0',
      validModeLevel1: true,
      modeLevel2: '0',
      validModeLevel2: true,
      ambientThreshold: 0,
      immediate: true,
      openScheduleModal: false,
      shown: false,
      editSiteModal: false,
      allocateDeviceModal: false,
      visible: false,
      currentDeviceCategory: { key: 'DEVICES', label: 'Devices'},
      pageRefresh: false,
      autoRefresh: false,
      currentInterval: null
    };
  }

  componentDidMount() {
    let {toggleDeviceSiteDisplay} = this.props;
    toggleDeviceSiteDisplay(this.state.currentDeviceCategory.key);
  }

  componentWillUnmount() {

    clearLoginTimeout(this.props.user);
  }

  showMenuOnMobile = () => {
    this.setState({ shown: !this.state.shown });
  }

  onCancelAllocateDevice = () => {
    this.props.onUpdate();
    this.setState({ allocateDeviceModal: false });
  }

  getSuggestions = async value => {
    let {siteSuggestion} = this.props;
    let suggestionList = [];
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (inputLength > 4) {
      let suggestions = await siteSuggestion(inputValue);
      if (suggestions) {
        suggestionList = suggestions.map((item) => ({
          name: item.mac_address,
          value: item.device_id
        }));
      } else {
        suggestionList = [];
      }
    } else {
      suggestionList = [];
    }

    return suggestionList;
  };

  applyCoordinates = async () => {
    let {deviceId} = this.props;
    let {pairId} = this.state;
    let selectedDeviceId = deviceId ? deviceId : pairId;
    let selectedSiteId = this.siteId;

    if (selectedDeviceId && parseInt(selectedDeviceId)) {
      getDeviceCoordinatesInDb(selectedDeviceId).then((response) => {
        if (response && (response.latitude && response.longitude)) {
          let coordinatesObj = {
            id: selectedSiteId
          };

          if (this.state.allocateDevice) {
            coordinatesObj.longitude = response.longitude;
            coordinatesObj.latitude = response.latitude;
          }

          updateSiteCoordinatesInDb(coordinatesObj).then((response2) => {
            this.setState({latitude: response.latitude, longitude: response.longitude});
            toast.success(Messages.AllocateCoordinatesSuccessText);
          });
        } else {
          toast.success(Messages.MissingLatLongText);
        }
      });
    } else {
      toast.error(Messages.NoDeviceSelectedText);
      setTimeout(() => {
      }, 2000);
    }
  }

  allocate = async (deviceId, longitude, latitude) => {
    //let {deviceId} = this.props;
    //let {pairId} = this.state;
    //let selectedDeviceId = deviceId ? deviceId : pairId;
    //let selectedSiteId = this.siteId;

    if (deviceId && parseInt(deviceId)) {
      let coordinatesObj = {
        site_id: this.state.siteId,
        device_id: deviceId
      };

      updateSiteCoordinatesInDb(coordinatesObj).then((response2) => {
        this.setState({latitude: latitude, longitude: longitude});
        toast.success(Messages.DeviceAllocationSuccessText);
        setTimeout(() => {
          this.setState({showLoader: false});
          this.onCancelAllocateDevice();
        }, 2000);
      });
    } else {
      toast.error(Messages.NoDeviceSelectedText);
      setTimeout(() => {
      }, 2000);
    }
  }

  onAllocateClicked = (site_id, device_id) => {
    this.setState({ allocateDeviceModal: true, selectedSiteId: site_id, selectedDeviceId: device_id});
  }

  getTotalLights(props) {
    let totalLights = 0;
    switch (props.map.layerType) {
      case LAYER_1X:
        //let selectedDistrict = props.map.selectedDistricts;
        totalLights = getTotalLightsInDistricts(props.map.selectedDistricts, props);
        break;
      case LAYER_2X:
        //let selectedBlock = props.map.selectedBlocks;
        totalLights = getTotalLightsInBlocks(props.map.selectedBlocks, props);
        break;
      default:
        break;
    }
    return totalLights;
  }

  getCurrentBrightness(props) {
    let brightnessRange = [0, 0];
    switch (props.map.layerType) {
      case LAYER_1X:
        //let selectedDistricts = props.map.selectedDistricts;
        brightnessRange = getBrightnessRangeForDistricts(props.map.selectedDistricts, props);
        break;
      case LAYER_2X:
        //let selectedBlocks = props.map.selectedBlocks;
        brightnessRange = getBrightnessRangeForBlocks(props.map.selectedBlocks, props);
        break;
      case LAYER_3X:
        //let selectedLights = props.map.selectedLights;
        brightnessRange = getBrightnessRangeForLights(props.map.selectedLights);
        break;
      default:
        break;
    }
    return brightnessRange;
  }

  getCurrentLampSwitchState(props) {
    let lampSwitchState = 0;
    switch (props.map.layerType) {
      case LAYER_1X:
        //let selectedDistricts = props.map.selectedDistricts;
        lampSwitchState = getLampSwitchStateForDistricts(props.map.selectedDistricts, props);
        break;
      case LAYER_2X:
        //let selectedBlocks = props.map.selectedBlocks;
        lampSwitchState = getLampSwitchStateForBlocks(props.map.selectedBlocks, props);
        break;
      case LAYER_3X:
        //let selectedLights = props.map.selectedLights;
        lampSwitchState = getLampSwitchStateForLights(props.map.selectedLights);
        break;
      default:
        break;
    }
    return lampSwitchState;
  }

  getBrightnessBuckets(props) {
    let brightnessBuckets = {};
    switch (props.map.layerType) {
      case LAYER_1X:
        //let selectedDistricts = props.map.selectedDistricts;
        brightnessBuckets = getBrightnessBucketsForDistricts(props.map.selectedDistricts, props);
        break;
      case LAYER_2X:
        //let selectedBlocks = props.map.selectedBlocks;
        brightnessBuckets = getBrightnessBucketsForBlocks(props.map.selectedBlocks, props);
        break;
      default:
        break;
    }
    return brightnessBuckets;
  }

  onSetScheduleClicked = () => {
    this.setState({ openScheduleModal: true });
  }

  closeScheduleModal = () => {
    this.setState({ openScheduleModal: false })
  }


  componentWillReceiveProps(nextProps) {
    this.setState({
      brightness: this.getCurrentBrightness(nextProps),
      toggleLampState: this.getCurrentLampSwitchState(nextProps),
      totalLights: this.getTotalLights(nextProps),
      brightnessBuckets: this.getBrightnessBuckets(nextProps)
    });
  }

  componentWillMount() {

    checkLoginTimeout(this.props.user);
    this.setState({
      brightness: this.getCurrentBrightness(this.props),
      toggleLampState: this.getCurrentLampSwitchState(this.props),
      totalLights: this.getTotalLights(this.props),
      brightnessBuckets: this.getBrightnessBuckets(this.props)
    });
  }

  /* Toggle on/off light sensor */
  onToggle = e => {
    let val = e ? ON : OFF;
    let update = {
      toggleLampState: val === ON ? 1 : 0,
      buttonState: true
    };
    if (val === ON) {
      update.brightness = [4095, 4095];
    } else {
      update.brightness = [0, 0];
    }
    this.setState(update);
  };

  onTurnLightOff = e => {
    let val = OFF;
    let update = {
      toggleLampState: val === ON ? 1 : 0,
      buttonState: true
    };
    if (val === ON) {
      update.brightness = [4095, 4095];
    } else {
      update.brightness = [0, 0];
    }
    this.setState(update);
  };

  /* Toggle on/off mode */
  onToggleGps = async e => {
    let val = e ? ON : OFF;
    let update = {
      toggleGpsState: val === ON ? 1 : 0
    };
    let selectedLight = this.props.map.selectedLights[0];
    let lightImmediate = this.state.immediate;
    await this.updateGPSinDb(selectedLight.device_id, update.toggleGpsState, lightImmediate, update);
  };

  /* Toggle on/off mode */
  onTurnOffGps = async e => {
    let update = {
      toggleGpsState: 0
    };
    let selectedLight = this.props.map.selectedLights[0];
    let lightImmediate = this.state.immediate;
    await this.updateGPSinDb(selectedLight.device_id, update.toggleGpsState, lightImmediate, update);
  };

  onTurnOnGps = async e => {
    let update = {
      toggleGpsState: 1
    };
    let selectedLight = this.props.map.selectedLights[0];
    let lightImmediate = this.state.immediate;
    await this.updateGPSinDb(selectedLight.device_id, update.toggleGpsState, lightImmediate, update);
  };

  updateGPSinDb = async (site_id, gpsValue, immediate, update) => {
    //let response = await updateGps(site_id, gpsValue, immediate);
    let {updateGps: updateCurrentGps} = this.props;
    //let response = await updateGps(site_id, gpsValue, immediate);

    let response = this.state.currentDeviceCategory.key === 'DEVICES' ? await updateCurrentGps(site_id, gpsValue, immediate) : updateGroupGps(site_id, gpsValue, immediate);
    if (response !== undefined && immediate !== null) {
      this.setState(update);
      toast.success(Messages.GPSStatusChangedSuccess);
      this.setState({pageRefresh: true});
    } else {
      toast.error(Messages.GPSStatusChangedFailure);
    }
  }

  /* Text Input for brightness field */
  getBrightnessText = () => {
    let range = this.state.brightness;
    return this.rangeToPercentage(range);
  }

  adjustBrightness = e => {
    let val = parseInt(e.target.value, 10);
    if (!Number.isInteger(val) || val > 100) {
      e.target.value = '';
      return;
    }
    val = (val * 40.95).toFixed(0);
    this.setState({
      buttonState: true,
      brightness: [val, val]
    });
  };

  /* Brightness slider */
  adjustSliderBrightness = e => {
    let val = e.target.value;
    this.setState({
      buttonState: true,
      toggleLampState: 1,
      brightness: [val, val]
    });
  }

  lumensToPercentage(lumens) {
    return (lumens / 4095) * 100;
  }

  rangeToPercentage(range = [0, 0]) {
    let start = this.lumensToPercentage(range[0]).toFixed(0);
    let end = this.lumensToPercentage(range[1]).toFixed(0);
    let perc = 0;
    perc = range[0] === range[1] ? this.lumensToPercentage(range[1]).toFixed(0) : `${start} - ${end}`;
    return perc;
  }

  onApplyChangesConfirmed = async () => {
    let brightness = this.state.brightness[1];
    let lampStatus = this.state.toggleLampState === 0 ? OFF : ON;

    let lightToUpdate = {
      lightModeLevel1: parseInt(this.state.modeLevel1) ? parseInt(this.state.modeLevel1) : 0,
      lightModeLevel2: parseInt(this.state.modeLevel2) ? parseInt(this.state.modeLevel2) : 0,
      lightAmbientThreshold: this.state.ambientThreshold,
      siteId: this.props.map.selectedLights[0].site_id
    }
    await this.updateThresholdLevels(lightToUpdate);
    this.props.applyChanges({
      brightness,
      lampStatus
    });
  };

  updateThresholdLevels = async (lightToUpdate) => {
    let response = this.state.currentDeviceCategory.key === 'DEVICES' ? await updateThresholdAndLevels(lightToUpdate) : await updateGroupThresholdAndLevels(lightToUpdate);
    if (response !== undefined && response !== null) {
      this.setState({ showApplyChangesPrompt: false, buttonState: false }, () => {
        toast.success(Messages.DevicePropertiesUpdatedSuccess);
        this.setState({pageRefresh: true});
      });
    } else {
      toast.error(Messages.DevicePropertiesUpdatedFailure);
    }
  }

  deselectDistrict = id => {
    let districts = this.props.map.selectedDistricts
        .filter(e => {
          return e.id !== id;
        })
        .map(e => {
          return e.id;
        });
    this.props.selectedDistricts(districts);
  };

  deselectBlock = id => {
    let blocks = this.props.map.selectedBlocks
        .filter(e => {
          return e.id !== id;
        })
        .map(e => {
          return e.id;
        });
    this.props.selectedBlocks(blocks);
  };

  //Modified the code for deselection on basis of mac_address
  deselectLights = mac_address => {
    let lights = this.props.map.selectedLights
        .filter(e => {
          return e.mac_address !== mac_address;
        })
        .map(e => {
          return e.mac_address;
        });
    this.props.selectedLights(lights);
  };

  checkSideBar = () => {
    let showSideBar = true;
    switch (this.props.map.layerType) {
      case LAYER_1X:
        showSideBar = false;
        break;
      case LAYER_2X:
        showSideBar = false;
        break;
      case LAYER_3X:
      default:
        if (this.props.map.selectedLights.length === 0) {
          showSideBar = false;
        }
        break;
    }
    return showSideBar;
  };

  /*
  checkSideBar = () => {
    let showSideBar = true;
    switch (this.props.map.layerType) {
      case LAYER_1X:
        if (this.props.map.selectedDistricts.length === 0 && this.props.map.selectedLights.length === 0) {
          showSideBar = false;
        }
        break;
      case LAYER_2X:
        if (this.props.map.selectedBlocks.length === 0) {
          showSideBar = false;
        }
        break;
      case LAYER_3X:
        if (this.props.map.selectedLights.length === 0) {
          showSideBar = false;
        }
        break;
      default:
        break;
    }
    return showSideBar;
  };
  */

  applyChangesButtonClicked = () => {
    if (this.state.buttonState === true) {
      this.setState({
        showApplyChangesPrompt: true
      });
    }
  }

  onCancelChanges = () => {
    this.setState({
      showApplyChangesPrompt: false
    });
  };

  toggleAutoRefresh = () => {
    this.setState({pageRefresh: !this.state.autoRefresh, autoRefresh: !this.state.autoRefresh}, () => {
      if (this.state.pageRefresh) {
        let interval = setInterval(() => {
          this.setState({pageRefresh: true});
        }, 30000);
        this.setState({currentInterval: interval});
      } else {
        clearInterval(this.state.currentInterval);
        this.setState({currentInterval: null});
      }
    });
  }

  renderApplyChangesModal() {
    let heading = 'Apply Changes';
    let title = 'Are you sure you want to apply these values';
    let applyChangesButtonTitle = 'Apply Changes';
    let cancelButtonTitle = 'Cancel';
    if (this.state.showApplyChangesPrompt) {
      return (
          <NotificationModal>
            <div className={applyChangesContainer}>
              <div className={headingClass}>
                {heading}
              </div>
              <div className={applyChangesTitle}>
                {title}
              </div>
              <div className={applyChangesButtons}>
                <Button
                    btnClassName={notificationCancelButton}
                    buttonTextStyle={cancelButtonText}
                    text={cancelButtonTitle}
                    buttonContainerStyle={buttonContainerStyle}
                    buttonClickCallback={this.onCancelChanges}
                />
                <Button
                    btnClassName={notificationButton}
                    buttonTextStyle={applyButtonText}
                    text={applyChangesButtonTitle}
                    buttonContainerStyle={buttonContainerStyle}
                    buttonClickCallback={this.onApplyChangesConfirmed}
                />
              </div>
            </div>
          </NotificationModal>
      );
    }
    return null;
  }

  onModeTypeSelected = async (modeType) => {
    let selectedLight = this.props.map.selectedLights[0];
    let immediate = this.state.immediate;
    await this.setMode(selectedLight.device_id, modeType, immediate);
  }

  onDeviceSelected = (device) => {
    let {toggleDeviceSiteDisplay} = this.props;
    this.setState({currentDeviceCategory: {key: device.key, label: device.label}}, () => {
      toggleDeviceSiteDisplay(device.key);
    })
  }

  async setMode(siteId, modeType, immediate) {
    let response = this.state.currentDeviceCategory.key === 'DEVICES' ? await updateMode(siteId, modeType, immediate) : await updateGroupMode(siteId, modeType, immediate);
    if (response !== undefined && response !== null) {
      this.setState({ modeType: modeType, validModeType: true }, () => {
        toast.success(Messages.ModeChangedSuccess);
        this.setState({pageRefresh: true});
      });
    } else {
      toast.error(Messages.ModeChangedFailure);
    }
  }

  onModeLevel1Selected = (level) => {
    this.setState({
      modeLevel1: level,
      validModeLevel1: true,
      buttonState: true,
    });
  }

  onModeLevel2Selected = (level) => {
    this.setState({
      modeLevel2: level,
      validModeLevel2: true,
      buttonState: true,
    });
  }

  setThresholdValue = e => {
    let val = parseInt(e.target.value);
    if (!Number.isInteger(val) || val < 0 || val > 4000) {
      e.target.value = '';
      return;
    } else {
      this.setState({
        buttonState: true,
        ambientThreshold: val
      });
    }
  }

  setImmediateValue = e => {
    let val = e;
    this.setState({
      buttonState: true,
      immediate: val === 'true' || val === null || val === undefined
    });
  }

  displaySitesToggleLink = (deviceTypes) => {
    return <div className={buttonLinkClass} style={{position: 'absolute'}}>
            <Select
                value={this.state.currentDeviceCategory}
                onChange={this.onDeviceSelected}
                options={deviceTypes}
                styles={colourStyles}
            >
            </Select>
           </div>
  }

  autoRefreshControl = () => {
    return <div className={refreshToggleClass} style={{position: 'absolute'}}>
      <label style={{textAlign: 'center'}}>
        <span style={{color: '#ffffff', display: 'block'}}>Auto Refresh</span>
        <ReactSwitch onChange={this.toggleAutoRefresh} checked={this.state.autoRefresh} />
      </label>
    </div>
  }

  toggleVisible = () => {
    this.setState({visible: !this.state.visible});
  }

  render() {
    const objectToArray = item => {
      return Object.keys(item).map(key => {
        return { key: key, label: item[key] };
      });
    };
    const modeTypes = objectToArray(MODETYPE);
    const modeLevels = objectToArray(MODELEVELS);
    const delivery = objectToArray(DELIVERY);
    const deviceTypes = objectToArray(DEVICETYPES);

    return (
        <div className="customTab">
          <div className="desktopViewForTabs">
            <Tabs tabs={tabs} activeTab="controls" />
          </div>
          <div className="mobileViewForTabs">
            <div onClick={() => this.showMenuOnMobile()}>
              <i className="fa fa-bars mobile-menu-icon" aria-hidden="true"></i>
            </div>
            <div className={this.state.shown ? "show" : "hide"}>
              <Tabs tabs={tabs} activeTab="controls" />
            </div>
          </div>
          {/* <Tabs tabs={tabs} activeTab="controls" /> */}
          <Map className={mapClass} currentDeviceCategory={this.props.map.currentDeviceCategory} isPageRefresh={this.state.pageRefresh} pageRefresh={(refresh) => this.setState({pageRefresh: refresh})}>
          </Map>
          {this.checkSideBar() && (
              <SideBar sidebarClassCustom={sideBarClass}>
                {this.props.map.layerType === LAYER_1X &&
                this.props.map.selectedDistricts.length > 0 && (
                    <div className={filterClass}>
                      {this.props.map.selectedDistricts.map((val, key) => {
                        return (
                            <div className={filterTab} key={key}>
                              <span>{val.name}</span>
                              <img
                                  src={crossIcon}
                                  alt="x"
                                  className={filterCross}
                                  onClick={() => this.deselectDistrict(val.id)}
                              />
                            </div>
                        );
                      })}
                    </div>
                )}
                {this.props.map.layerType === LAYER_2X &&
                this.props.map.selectedBlocks.length > 0 && (
                    <div className={filterClass}>
                      {this.props.map.selectedBlocks.map((val, key) => {
                        return (
                            <div className={filterTab} key={key}>
                              <span>{val.name}</span>
                              <img
                                  src={crossIcon}
                                  alt="x"
                                  className={filterCross}
                                  onClick={() => {
                                    this.deselectBlock(val.id);
                                  }}
                              />
                            </div>
                        );
                      })}
                    </div>
                )}
                {/* {this.props.map.layerType === LAYER_3X && */}
                {this.props.map.layerType === LAYER_3X &&
                this.props.map.selectedLights.length > 0 && (
                    <div className={filterClass}>
                      {this.props.map.selectedLights.map((val, key) => {
                        return (
                            <div className={filterTab} key={key}>
                              <span>{val.site_name}</span>
                              <img
                                  src={crossIcon}
                                  alt="x"
                                  className={filterCross}
                                  onClick={() => {
                                    this.deselectLights(val.mac_address);
                                  }}
                              />
                            </div>
                        );
                      })}
                    </div>
                )}
                {/* {this.props.map.layerType !== LAYER_3X && (
              <div>
                <div className={sidebarTitleClass}>Lights as brightness groups</div>
                <div className={sidebarSubtitleClass}>
                  Total Lights
                  <span className={rightAlignClass}>{this.state.totalLights}</span>
                </div>
                <BrightnessBar buckets={this.state.brightnessBuckets} totalLights={this.state.totalLights} />
                <div className={totalLightsStyle}>All lights in this district
                <div className={checkbox}></div>
                </div>
              </div>
            )} */}
                <div style={{display: 'block', width: '100%'}}>
                  <div className={masterControlTitleRow}>
                    <span className={masterControlTitle}>Basic Information</span>
                  </div>
                  <div className={rowClass} style={{marginBottom: '20px'}}>
                    {
                      this.props.map.selectedLights.map((item) => {
                        return (
                           <div className={labelValuesContainer} style={{float: 'left'}}>
                            <div className={rowClassTwoColumn} style={{float: 'left', width: '50%', padding: '0px'}}>
                              <span className={spanTextHeaderClass}>Name</span>
                              <div className={pullLeft}>
                                <span className={spanTextClass}>{item.site_name}</span>
                              </div>
                            </div>
                             <div className={rowClassTwoColumn} style={{float: 'left', width: '50%', padding: '0px'}}>
                               <span className={spanTextHeaderClass}>MAC Address</span>
                               <div className={pullLeft}>
                                 <span className={spanTextClass}>{item.mac_address}</span>
                               </div>
                             </div>
                           </div>
                        )
                      })
                    }
                  </div>

                  {this.props.map.currentDeviceCategory === 'SITES' && (
                    <div style={{float: 'left', width: '100%'}}>
                      <div className={masterControlTitleRow}>
                        <span className={masterControlTitle}>Lamp Information</span>
                      </div>
                      <div className={rowClass} style={{marginBottom: '20px'}}>
                        <div className={labelValuesContainer}>
                          <div className={rowClassTwoColumn} style={{float: 'left', width: '50%', padding: '0px'}}>
                            <span className={spanTextHeaderClass}>Lamp Type</span>
                            <div className={pullLeft}>
                              <span className={spanTextClass}>{this.props.map.selectedLights[0].site_details && this.props.map.selectedLights[0].site_details.lamp_type ? this.props.map.selectedLights[0].site_details.lamp_type : 'N/A'}</span>
                            </div>
                          </div>
                          <div className={rowClassTwoColumn} style={{float: 'left', width: '50%', padding: '0px'}}>
                            <span className={spanTextHeaderClass}>Lamp Wattage</span>
                            <div className={pullLeft}>
                              <span className={spanTextClass}>{this.props.map.selectedLights[0].site_details && this.props.map.selectedLights[0].site_details.lamp_wattage ? this.props.map.selectedLights[0].site_details.lamp_wattage : 'N/A'}</span>
                            </div>
                          </div>
                          <div className={rowClassTwoColumn} style={{float: 'left', width: '50%', padding: '0px', marginTop: '20px'}}>
                            <span className={spanTextHeaderClass}>Lamp Health</span>
                            <div className={pullLeft}>
                              <span className={spanTextClass}>{this.props.map.selectedLights[0].site_details && this.props.map.selectedLights[0].site_details.lamp_health ? this.props.map.selectedLights[0].site_details.lamp_health : 'N/A'}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {(this.props.map.currentDeviceCategory === 'DEVICES' || this.props.map.currentDeviceCategory === 'GROUPS') && (
                  <div style={{float: 'left', width: '100%'}}>
                    <div className={masterControlTitleRow}>
                      <span className={masterControlTitle}>Controls</span>
                    </div>
                    <div className={rowClass}>
                      {this.props.map.currentDeviceCategory !== 'GROUPS' && (
                      <div className={rowClassTwoColumnDecreasedPadding} style={{width: '100%', paddingLeft: '0px', paddingRight: '0px'}}>
                        <span className={spanTextHeaderClass}>Delivery Method</span>
                        <div className={pullLeft}>
                          <SingleSelectDropDown
                              title={'Immediate'}
                              options={delivery}
                              defaultValue={delivery.filter((item) => item.label === 'Immediate')}
                              onSelect={this.setImmediateValue}
                              showErrorState={!this.state.validModeType}
                              containerStyle={containerControlWidth}
                              headerStyle={controlWidth}
                              arrowContainerStyle={arrowControlWidth}
                          />
                        </div>
                      </div>
                      )}

                      <Button
                          buttonTextStyle={applyButtonActionText}
                          btnClassName={applyChangesControlStyle}
                          text="On Mode 1"
                          buttonContainerStyle={buttonLightActionLeftContainerStyle}
                          buttonClickCallback={async () => await this.onModeTypeSelected(1)}
                          style={buttonCss}
                      />
                      <Button
                          buttonTextStyle={applyButtonActionText}
                          btnClassName={applyChangesControlStyle}
                          text="On Mode 2"
                          buttonContainerStyle={buttonLightActionRightContainerStyle}
                          buttonClickCallback={async () => await this.onModeTypeSelected(2)}
                          style={buttonCss}
                          /*disabled={!this.state.buttonState}*/
                      />
                      <Button
                          buttonTextStyle={applyButtonActionText}
                          btnClassName={applyChangesControlStyle}
                          text="Off"
                          buttonContainerStyle={buttonLightActionLeftContainerStyle}
                          buttonClickCallback={async () => await this.onModeTypeSelected(3)}
                          style={buttonCss}
                          /*disabled={!this.state.buttonState}*/
                      />
                      <Button
                          buttonTextStyle={applyButtonActionText}
                          btnClassName={applyChangesControlStyle}
                          text="Flash"
                          buttonContainerStyle={buttonLightActionRightContainerStyle}
                          buttonClickCallback={async () => await this.onModeTypeSelected(4)}
                          style={buttonCss}
                          /*disabled={!this.state.buttonState}*/
                      />
                      <Button
                          buttonTextStyle={applyButtonActionText}
                          btnClassName={applyChangesControlStyle}
                          text="GPS On"
                          buttonContainerStyle={buttonLightActionLeftContainerStyle}
                          buttonClickCallback={async (e) =>  await this.onTurnOnGps(e)}
                          style={buttonCss}
                          /*disabled={!this.state.buttonState}*/
                      />
                      <Button
                          buttonTextStyle={applyButtonActionText}
                          btnClassName={applyChangesControlStyle}
                          text="GPS Off"
                          buttonContainerStyle={buttonLightActionRightContainerStyle}
                          buttonClickCallback={async (e) =>  await this.onTurnOffGps(e)}
                          style={buttonCss}
                          /*disabled={!this.state.buttonState}*/
                      />
                    </div>
                  </div>
                  )}
                </div>

                {/* added on 20th sept 2019 */}
                {/* {this.props.map.layerType === LAYER_3X && */}
                {this.props.map.selectedLights.length > 0 && (
                    <div style={addTopSpacing} style={{float: 'left', width: '100%'}}>
                      {(this.props.map.currentDeviceCategory === 'DEVICES' || this.props.map.currentDeviceCategory === 'GROUPS') && (
                      <div>
                        <div className={labelValuesContainer}>
                          <div className={rowClassTwoColumnDecreasedLeftPadding}>
                            <span className={spanTextHeaderClass}>Mode 1 Level</span>
                            <div className={pullLeft}>
                              <SingleSelectDropDown
                                  title={'Select Mode'}
                                  options={modeLevels}
                                  onSelect={this.onModeLevel1Selected}
                                  showErrorState={!this.state.validModeType}
                                  containerStyle={containerControlWidth}
                                  headerStyle={controlWidth}
                                  arrowContainerStyle={arrowControlWidth}
                              />
                            </div>
                          </div>
                          <div className={rowClassTwoColumnDecreasedRightPadding} style={{paddingTop: '32px !important'}}>
                            <span className={spanTextHeaderClass}>Mode 2 Level</span>
                            <div className={pullLeft}>
                              <SingleSelectDropDown
                                  title={'Select Mode'}
                                  options={modeLevels}
                                  onSelect={this.onModeLevel2Selected}
                                  showErrorState={!this.state.validModeType}
                                  containerStyle={containerControlWidth}
                                  headerStyle={controlWidth}
                                  arrowContainerStyle={arrowControlWidth}
                              />
                            </div>
                          </div>

                          <div className={rowClassTwoColumnDecreasedPadding} style={{paddingLeft: '16px'}}>
                            <span className={spanTextHeaderClass}>Ambient Threshold</span>
                            <input className={containerControlTextWidth} style={{width: '95% !important'}} placeholder="(0-4000)" maxLength="4" type="text" onChange={e => { this.setThresholdValue(e); }} onFocus={e => { e.target.value = ''; }}/>
                          </div>
                          <div className={rowClassTwoColumnDecreasedRightPadding} style={{width: '100%', paddingLeft: '12px'}}>
                            <div className={pullLeft}>
                              <Button
                                  buttonTextStyle={applyButtonActionText}
                                  btnClassName={applyChangesControlStyle}
                                  text="Apply"
                                  buttonContainerStyle={buttonLightApplyRightContainerStyle}
                                  buttonClickCallback={this.applyChangesButtonClicked}
                                  disabled={!this.state.buttonState}
                                  style={buttonCss}
                              />
                            </div>
                          </div>
                        </div>

                        <div className={rowClass}>
                          <Button
                              text="Assign Schedule"
                              buttonClickCallback={this.onSetScheduleClicked}
                              btnClassName={applyChangesControlStyle}
                              style={buttonCssAssignSchedule}
                          />
                        </div>
                      </div>
                      )}

                      <div className={masterControlTitleRow} style={{float: 'left', width: '100%'}}>
                        {this.props.map.currentDeviceCategory === 'DEVICES' && (<span className={masterControlTitle}>Readings</span>)}
                        {this.props.map.currentDeviceCategory === 'SITES' && (<span className={masterControlTitle}>Site Details</span>)}
                        {this.props.map.currentDeviceCategory === 'GROUPS' && (<span className={masterControlTitle}>Group Details</span>)}
                      </div>

                      {this.props.map.currentDeviceCategory === 'DEVICES' && (
                      <div className={labelValuesContainer}>
                        <div className={rowClassTwoColumn} style={endCurrentRowWhole}>
                          <span className={spanTextHeaderClass}>Mode</span>
                          <div className={pullLeft}>
                            <span className={spanTextClass}>{this.props.map.selectedLights[0].mode}</span>
                          </div>
                        </div>
                        <div className={rowClassTwoColumn} style={endCurrentRowHalf}>
                          <span className={spanTextHeaderClass}>Mode 1 Level</span>
                          <div className={pullLeft}>
                            <span className={spanTextClass}>{this.props.map.selectedLights[0].mode_1_level}</span>
                          </div>
                        </div>
                        <div className={rowClassTwoColumn} style={endCurrentRowHalf}>
                          <span className={spanTextHeaderClass}>Mode 2 Level</span>
                          <div className={pullLeft}>
                            <span className={spanTextClass}>{this.props.map.selectedLights[0].mode_2_level}</span>
                          </div>
                        </div>
                        <div className={rowClassTwoColumn} style={endCurrentRowWhole}>
                          <span className={spanTextHeaderClass}>Ambient Threshold</span>
                          <div className={pullLeft}>
                            <span className={spanTextClass}>{this.props.map.selectedLights[0].ambient_threshold}</span>
                          </div>
                        </div>
                        <div className={rowClassTwoColumn} style={endCurrentRowHalf}>
                          <span className={spanTextHeaderClass}>Current</span>
                          <div className={pullLeft}>
                            <span className={spanTextClass}>{this.props.map.selectedLights[0].current_amps}&nbsp;A</span>
                          </div>
                        </div>
                        <div className={rowClassTwoColumn} style={endCurrentRowHalf}>
                          <span className={spanTextHeaderClass}>Voltage</span>
                          <div className={pullLeft}>
                            <span className={spanTextClass}>{this.props.map.selectedLights[0].voltage}&nbsp;V</span>
                          </div>
                        </div>
                        <div className={rowClassTwoColumn} style={endCurrentRowHalf}>
                          <span className={spanTextHeaderClass}>Power</span>
                          <div className={pullLeft}>
                            <span className={spanTextClass}>{this.props.map.selectedLights[0].power_watts}&nbsp;W</span>
                          </div>
                        </div>
                        <div className={rowClassTwoColumn} style={endCurrentRowHalf}>
                          <span className={spanTextHeaderClass}>Reactive Power</span>
                          <div className={pullLeft}>
                            <span className={spanTextClass}>{this.props.map.selectedLights[0].reactive_power_watts}&nbsp;W</span>
                          </div>
                        </div>
                        <div className={rowClassTwoColumn} style={endCurrentRowHalf}>
                          <span className={spanTextHeaderClass}>Frequency</span>
                          <div className={pullLeft}>
                            <span className={spanTextClass}>{this.props.map.selectedLights[0].frequency_hz}&nbsp;Hz</span>
                          </div>
                        </div>
                        <div className={rowClassTwoColumn} style={endCurrentRowHalf}>
                          <span className={spanTextHeaderClass}>Temperature</span>
                          <div className={pullLeft}>
                            <span className={spanTextClass}>{this.props.map.selectedLights[0].temperature_celsius}&nbsp;&deg;C</span>
                          </div>
                        </div>

                        <div className={rowClassTwoColumn} style={endCurrentRowOneThird}>
                          <span className={spanTextHeaderClass}>Mag X</span>
                          <div className={pullLeft}>
                            <span className={spanTextClass}>{this.props.map.selectedLights[0].mag_x}</span>
                          </div>
                        </div>
                        <div className={rowClassTwoColumn} style={endCurrentRowOneThird}>
                          <span className={spanTextHeaderClass}>Mag Y</span>
                          <div className={pullLeft}>
                            <span className={spanTextClass}>{this.props.map.selectedLights[0].mag_y}</span>
                          </div>
                        </div>
                        <div className={rowClassTwoColumn} style={endCurrentRowOneThird}>
                          <span className={spanTextHeaderClass}>Mag Z</span>
                          <div className={pullLeft}>
                            <span className={spanTextClass}>{this.props.map.selectedLights[0].mag_z}</span>
                          </div>
                        </div>
                        <div className={rowClassTwoColumn}>
                          <span className={spanTextHeaderClass}>Longitude</span>
                          <div className={pullLeft}>
                            <span className={spanTextClass}>{this.props.map.selectedLights[0].longitude}</span>
                          </div>
                        </div>
                        <div className={rowClassTwoColumn}>
                          <span className={spanTextHeaderClass}>Latitude</span>
                          <div className={pullLeft}>
                            <span className={spanTextClass}>{this.props.map.selectedLights[0].latitude}</span>
                          </div>
                        </div>
                      </div>
                      )}

                      {this.props.map.currentDeviceCategory === 'GROUPS' && (
                          <div className={labelValuesContainer}>
                            <div className={rowClassTwoColumn} style={endCurrentRowHalf}>
                              <span className={spanTextHeaderClass}>Schedule Name</span>
                              <div className={pullLeft}>
                                <span className={spanTextClass}>{this.props.map.selectedLights[0].schedule_name ? this.props.map.selectedLights[0].schedule_name : 'N/A'}</span>
                              </div>
                            </div>
                            <div className={rowClassTwoColumn} style={endCurrentRowHalf}>
                              <span className={spanTextHeaderClass}>No. of Devices</span>
                              <div className={pullLeft}>
                                <span className={spanTextClass}>{this.props.map.selectedLights[0].device_count ? this.props.map.selectedLights[0].device_count : 'N/A'}</span>
                              </div>
                            </div>
                          </div>
                      )}

                      {(this.props.map.currentDeviceCategory === 'SITES' || this.props.map.currentDeviceCategory === 'GROUPS') && (
                          <div className={labelValuesContainer} style={{float: 'left', width: '100%'}}>
                            {this.props.map.selectedLights[0].site_details && Object.keys(this.props.map.selectedLights[0].site_details).map((key) => {
                              if (key !== 'lamp_type' && key !== 'lamp_wattage') {
                                return (<div className={rowClassTwoColumn} style={endCurrentRowHalf}>
                                  <span className={spanTextHeaderClass}
                                        style={{textTransform: 'capitalize'}}>{removeUnderscoreCapitalize(key)}</span>
                                  <div className={pullLeft}>
                                    <span
                                        className={spanTextClass}>{this.props.map.selectedLights[0].site_details[key] ? this.props.map.selectedLights[0].site_details[key] : 'N/A'}</span>
                                  </div>
                                </div>)
                              }
                            })}
                            <div className={rowClassTwoColumn} style={endCurrentRowHalf}>
                              <span className={spanTextHeaderClass}>Latitude</span>
                              <div className={pullLeft}>
                                <span className={spanTextClass}>{this.props.map.selectedLights[0].latitude ? this.props.map.selectedLights[0].latitude : 'N/A'}</span>
                              </div>
                            </div>
                            <div className={rowClassTwoColumn} style={endCurrentRowHalf}>
                              <span className={spanTextHeaderClass}>Longitude</span>
                              <div className={pullLeft}>
                                <span className={spanTextClass}>{this.props.map.selectedLights[0].longitude ? this.props.map.selectedLights[0].longitude : 'N/A'}</span>
                              </div>
                            </div>
                          </div>

                      )}



                      {this.props.map.currentDeviceCategory === 'DEVICES' && (
                          <div style={{float: 'left', width: '100%'}}>
                            <div className={masterControlTitleRow}>
                              <span className={masterControlTitle}>Lamp Information</span>
                            </div>
                            <div className={rowClass} style={{marginBottom: '20px'}}>
                              <div className={labelValuesContainer}>
                                <div className={rowClassTwoColumn} style={{float: 'left', width: '50%', padding: '0px'}}>
                                  <span className={spanTextHeaderClass}>Lamp Type</span>
                                  <div className={pullLeft}>
                                    <span className={spanTextClass}>{this.props.map.selectedLights[0].site_details && this.props.map.selectedLights[0].site_details.lamp_type ? this.props.map.selectedLights[0].site_details.lamp_type : 'N/A'}</span>
                                  </div>
                                </div>
                                <div className={rowClassTwoColumn} style={{float: 'left', width: '50%', padding: '0px'}}>
                                  <span className={spanTextHeaderClass}>Lamp Wattage</span>
                                  <div className={pullLeft}>
                                    <span className={spanTextClass}>{this.props.map.selectedLights[0].lamp_wattage && this.props.map.selectedLights[0].site_details.lamp_wattage ? this.props.map.selectedLights[0].site_details.lamp_wattage : 'N/A'}</span>
                                  </div>
                                </div>
                                <div className={rowClassTwoColumn} style={{float: 'left', width: '50%', padding: '0px', marginTop: '20px'}}>
                                  <span className={spanTextHeaderClass}>Lamp Health</span>
                                  <div className={pullLeft}>
                                    <span className={spanTextClass}>{this.props.map.selectedLights[0].lamp_health && this.props.map.selectedLights[0].site_details.lamp_health ? this.props.map.selectedLights[0].site_details.lamp_health : 'N/A'}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      )}
                    </div>
                )}
              </SideBar>
          )}

          <div id="allocateDevice">
            <Modal classNames={{ modal: "create-site-modal" }} open={this.state.allocateDeviceModal} onClose={this.onCancelAllocateDevice} right closeOnOverlayClick={false}>
              <Allocate
                  allocateDevice={true}
                  onCancel={this.onCancelAllocateDevice}
                  onUpdate={this.onCancelAllocateDevice}
                  getSuggestions={this.getSuggestions}
                  submit={this.allocate}
                  siteId={this.state.selectedSiteId}
              />
            </Modal>
          </div>
          <div id="assignScheduleModalDiv">
            <Modal classNames={{ modal: "assign-schedule-modal-dialog" }} open={this.state.openScheduleModal} onClose={this.closeScheduleModal} center closeOnOverlayClick={false}>
              <div>
                <AssignSchedule siteId={(this.props.map.selectedLights[0] !== undefined ? this.props.map.selectedLights[0].site_id : '' ) } closeAssignModal={this.closeScheduleModal}/>
              </div>
            </Modal>
          </div>

          <MapLegend headerText="Legend" toggleVisible={this.toggleVisible} visible={this.state.visible}/>
          {this.renderApplyChangesModal()}
          {this.displaySitesToggleLink(deviceTypes)}
          {this.autoRefreshControl()}
        </div >
    );
  }
}

const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isFocused ? "#ffffff" : null,
      minWidth: "150px",
      color: "#000000"
    };
  }
};

const sideBarClass = css({
  position: 'absolute',
  top: '115px',
  right: '0px',
  width: '300px',
  bottom: '0px',
  backgroundColor: '#ffffff',
  boxShadow: '2px 2px 4px 2px rgba(0, 0, 0, 0.1)',
  overflowY: 'auto', //added on 20th sept 2019 for scroll in the sidebar for more values
  zIndex: 1000
});

const checkbox = css({
  display: 'inline-block',
  width: '12px',
  height: '12px',
  position: 'relative',
  '& input[type=checkbox]': {
    visibility: 'hidden',
  },
  '& label': {
    display: 'inline-block',
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '14px',
    height: '14px',
    boxSizing: 'border-box',
    marginLeft: '15px',
    marginTop: '3px',
    border: 'solid 1px #c1c7d0',
    borderRadius: '2px',
  },
  '& input[type=checkbox]:checked + label': {
    content: `url(${inputCheckedSVG})`,
    transition: 'all .5s ease',
    border: 0,
  }
});

const buttonLinkClass = css({
  position: 'absolute',
  right: '32px',
  marginTop: '10px',
  width: 'auto',
  display: 'flex',
  alignItems: 'center'
});

const refreshToggleClass = css({
  position: 'absolute',
  right: '32px',
  marginTop: '10px',
  width: 'auto',
  display: 'flex',
  alignItems: 'center',
  bottom: '20px'
});

const buttonLinkTextClass = css({
  color: '#4c9aff'
});

const applyChangesContainer = css({
  display: 'flex',
  flexDirection: 'column'
});

const applyChangesTitle = css({
  display: 'flex',
  fontFamily: 'OpenSans',
  fontSize: '14px',
  fontWeight: 'normal',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  textAlign: 'left',
  color: '#112138',
  padding: '20px'
});

const applyChangesStyle = css({
  width: '100%',
});
const applyChangesControlStyle = css({
  width: '100%'
});

const applyChangesButtons = css({
  display: 'flex',
  alignSelf: 'center'
});

const mapClass = css({
});

/*
const mapClass = css({
  position: 'fixed',
  width: '100%',
  float: 'left',
  height: 'calc(100vh - 110px)'
});
 */

const centerAlign = css({
  position: 'absolute',
  margin: '24px 0 0 38.25%'
});

const filterClass = css({
  display: 'flex',
  flexWrap: 'wrap',
  backgroundColor: '#f4f5f7',
  padding: '8px 16px 16px 8px'
});

const filterTab = css({
  borderRadius: '2px',
  backgroundColor: '#0151cb',
  fontFamily: 'OpenSans',
  fontSize: '12px',
  fontWeight: 600,
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: '18px',
  letterSpacing: 'normal',
  color: '#ffffff',
  padding: '8px 7px',
  marginLeft: '8px',
  marginTop: '8px',
  display: 'inline-flex',
  border: '0 solid rgba(0,0,0,0.01)'
});
const filterCross = css({
  padding: '0px 0px 0 4px',
  float: 'right',
  cursor: 'pointer'
});
const spanTextClass = css({
  display: 'block',
  fontFamily: 'OpenSans',
  fontSize: '14px',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  color: '#112138',
  fontWeight: 'normal'
});
const spanTextHeaderClass = css({
    display: 'block',
    fontSize: '14px',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#112138',
    fontWeight: 'bold'
});
const spanTextClassForControls = css({
  fontFamily: 'OpenSans',
  fontSize: '14px',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 2,
  letterSpacing: 'normal',
  color: '#112138',
  fontWeight: 'normal',

});
const labelValuesContainer = css({
  display: 'block',
  width: '100%'
});
const sidebarSubtitleClass = css([
  spanTextClass,
  {
    padding: '16px'
  }
]);

const sidebarTitleClass = css({
  fontFamily: 'OpenSans',
  fontSize: '14px',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  color: '#112138',
  padding: '16px',
  fontWeight: 600,
  borderBottom: 'solid 1px #ebecf0'
});

const rightAlignClass = css({
  float: 'right',
  fontWeight: 600
});

const masterControlTitleRow = css({
  padding: '16px',
  borderBottom: 'solid 1px #ebecf0'
});

const masterControlTitle = css({
  fontFamily: 'OpenSans',
  fontSize: '18px',
  fontWeight: 'bold',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  color: '#000000',
  display: 'block',
  backgroundColor: '#d6eaf8',
  paddingLeft: '10px'
});

const buttonContainerStyle = {
  padding: '15px'
};
const buttonLightActionLeftContainerStyle = {
  padding: '7px 0px 0px 0px',
  width: '49%',
  float: 'left',
  fontWeight: '20px'
};
const buttonLightActionRightContainerStyle = {
  padding: '7px 0px 0px 2px',
  width: '49%',
  float: 'right',
  fontWeight: '20px'
};
const buttonLightApplyRightContainerStyle = {
  padding: '23px 0px 0px 2px',
  fontWeight: '20px'
}
const buttonLightAssignScheduleContainerStyle = {
  marginTop: '10px'
}

const headingClass = css({
  fontFamily: 'OpenSans',
  fontSize: '18px',
  fontWeight: 600,
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  color: '#000000',
  padding: '24px 0px 0px 24px'
});

const notificationButton = css({
  height: '48px'
});

const notificationCancelButton = css({
  height: '48px',
  borderRadius: '2px',
  backgroundColor: '#ffffff',
  border: 'solid 1px #c4cdd5',
  ':hover': {
    backgroundColor: 'rgba(23, 44, 76, 0.2)',
    border: 'solid 1px rgba(23, 44, 76, 0.2)'
  }
});

const applyButtonText = css({
  fontFamily: 'OpenSans',
  fontSize: '14px',
  fontWeight: 'bold',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: '1px',
  textAlign: 'center',
  color: '#ffffff'
});

const applyButtonActionText = css({
  fontFamily: 'OpenSans',
  fontSize: '14px',
  fontWeight: 'bold',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1,
  letterSpacing: '1px',
  textAlign: 'center',
  color: '#ffffff'
});

const cancelButtonText = css({
  fontFamily: 'OpenSans',
  fontSize: '14px',
  fontWeight: 'bold',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: '1px',
  textAlign: 'center',
  color: '#5e6c84'
});

const pullRight = css({
  float: 'right',
  width: '100%'
});

const pullLeft = css({
  float: 'left',
  width: '100%'
});

const rowClass = css({
  padding: '16px'
});

const rowClassTwoColumn = css({
  padding: '16px',
  float: 'left'
});
const rowClassTwoColumnDecreasedPadding = css({
  paddingTop: '8px',
  paddingLeft: '16px',
  paddingRight: '8px',
  paddingBottom: '8px',
  float: 'left'
});
const rowClassTwoColumnDecreasedLeftPadding = css({
  paddingTop: '8px',
  paddingLeft: '16px',
  paddingRight: '0px',
  paddingBottom: '8px',
  float: 'left',
  width: '50%'
});
const rowClassTwoColumnDecreasedInputSwitchPadding = css({
  paddingTop: '10px',
  paddingLeft: '16px',
  paddingRight: '0px',
  paddingBottom: '8px',
  float: 'left',
  width: '50%'
});
const rowClassTwoColumnDecreasedRightPadding = css({
  paddingTop: '8px',
  paddingLeft: '8px',
  paddingRight: '16px',
  paddingBottom: '8px',
  float: 'left',
  width: '50%'
});

const brightnessDisplay = css({
  float: 'right',
  width: '72px',
  height: '21px',
  borderRadius: '2px',
  backgroundColor: '#ffffff',
  color: '#5E6C84',
  padding: '6px 7px 5px 7px',
  border: 'solid 1px #dfe1e5',
  textAlign: 'center',
  outline: 'none'
});

const totalLightsStyle = css({
  width: '126px',
  height: '18px',
  fontFamily: 'OpenSans',
  fontSize: '12px',
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.5,
  letterSpacing: 'normal',
  textAlign: 'center',
  color: '#5e6c84',
});

const endCurrentRowQuarter = {
  float: 'left',
  width: '25%'
}
const endCurrentRowOneThird = {
  float: 'left',
  width: '33%'
}
const endCurrentRowHalf = {
  float: 'left',
  width: '50%'
}
const endCurrentRowThreeQuarter = {
  float: 'left',
  width: '75%'
}
const endCurrentRowWhole = {
  float: 'left',
  width: '100%'
}
const addTopSpacing = {
  float: 'left',
  marginTop: '10px'
}
const containerControlWidth = {
  float: 'left !important',
  width: '100% !important'
}
const containerControlTextWidth = css({
  float: 'left !important',
  width: '77% !important'
});
const controlWidth = {
  float: 'left !important',
  width: '100% !important'
}
const arrowControlWidth = {
  float: 'left !important'
}
const buttonCss = css({
  padding: '5px 5px 5px 5px !important'
})
const buttonCssAssignSchedule = css({
  padding: '5px 5px 5px 5px !important',
  marginTop: '10px'
})

const mapStateToProps = state => ({
  sensors: state.sensors,
  map: state.map,
  user: state.user
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
          selectedDistricts,
          selectedBlocks,
          selectedLights,
          updateSelectedBrightness,
          updateSelectedSwitch,
          checkLoginTimeout,
          clearLoginTimeout,
          applyChanges,
          updateGps, //for updating the gps for light
          updateMode, //for updating the mode of light
          updateThresholdAndLevels, //for updating levels and threshold
          toggleDeviceSiteDisplay,
          updateLayer
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(StreetLightPage);
