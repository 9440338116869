import React, { Component } from 'react';
import Button from '../button/button';
import pageNotFound from '../../assets/page-not-found.svg';
import { css } from 'emotion';

class PageNotFound extends Component {

  render() {
    const title = "Oops";
    const description = "Sorry, the page you were trying to load doesn't exist";
    const buttonTitle = "Go to home page";
    return (
      <div className={container}>
        <div className={image}>
          <img src={pageNotFound} alt="Page Not Found" />
        </div>
        <div className={titleStyle}>{title}</div>
        <div className={descriptionStyle}>{description}</div>
        <div className={buttonContainer}>
          <Button
            label={buttonTitle}
            className={buttonStyle}
            buttonClickCallback={() => {
              this.props.history.push('/');
            }}
            text={buttonTitle}
          />
        </div>
      </div>
    );
  }

}

const container = css({
  display: 'flex',
  flexDirection: 'column',
  height: '700px'
});

const image = css({
  display: 'flex',
  flexGrow: '5',
  justifyContent: 'space-around',
  flexDirection: 'column'
});

const titleStyle = css({
  display: 'flex',
  justifyContent: 'space-around',
  fontFamily: 'OpenSans',
  fontSize: '36px',
  fontWeight: '300',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: '1.5',
  letterSpacing: '0.5px',
  textAlign: 'center',
  color: '#212121'
});

const descriptionStyle = css({
  display: 'flex',
  justifyContent: 'space-around',
  fontFamily: 'OpenSans',
  fontSize: '14px',
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: '1.5',
  letterSpacing: '0.5px',
  textAlign: 'center',
  color: '#212121'
});

const buttonContainer = css({
  display: 'flex',
  flexGrow: '3',
  justifyContent: 'center',
  alignItems: 'center'
});

const buttonStyle = css({
  margin: 15
});

export default PageNotFound;
