import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';

export default class SideBar extends React.Component {

    render() {
        let {sidebarClassCustom} = this.props;
        if (sidebarClassCustom)
            sideBarClass = sidebarClassCustom;
        return (
          <div className={sideBarClass}>
                {this.props.children}
          </div>
        );
      }
}

SideBar.propTypes = {
    children: PropTypes.any
  };
let sideBarClass = css({
    position: 'absolute',
    right: '0px',
    width: '300px',
    height: 'calc(100% - 110px)',
    backgroundColor: '#ffffff',
    boxShadow: '2px 2px 4px 2px rgba(0, 0, 0, 0.1)',
    overflowY: 'auto', //added on 20th sept 2019 for scroll in the sidebar for more values
    zIndex: 1000
});
